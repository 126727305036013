import React, { useState, useRef, useEffect } from 'react';

const Tabs = ({ tabs, selectedIndex = 0, onTabChange, children, divBorder = true }) => {
  const [activeTab, setActiveTab] = useState(selectedIndex);

  useEffect(() => {
    setActiveTab(selectedIndex);
  }, [selectedIndex]);

  const handleTabClick = (index) => {
    setActiveTab(index);
    if (onTabChange) {
      onTabChange(index);
    }
  };

  return (
    <div>
      {/* Tab Headers */}
      <div className="tab-container">
        {tabs.map((tab, index) => (
          <button
            key={index}
            className={activeTab === index ? 'tab active-tab' : 'tab'}
            onClick={() => handleTabClick(index)}
          >
            {tab}
          </button>
        ))}
      </div>

      {/* Tab Content */}
      {divBorder ?
        <div className="content-container">
          {children[activeTab]}
        </div>
        : <div>
          {children[activeTab]}
        </div>}

    </div>
  );
};

export default Tabs;
