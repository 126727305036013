import React, { useState } from 'react';
import backIcon from '../../../../Assets/Icons/back.png';
import { useNavigate } from 'react-router-dom';
import Toggle from '../../../../Components/Toggle';
import AddUserPrimaryInformationScreen from './AddUserPrimaryInformationScreen';


const AddUserScreen = () => {
    const [activeTab, setActiveTab] = useState('PrimaryInformation');

    const navigate = useNavigate();

    const handleBack = () => {
        navigate('/teams-userlist');
    }

    const renderContent = () => {
        switch (activeTab) {
            case 'PrimaryInformation':
                return <AddUserPrimaryInformationScreen/>;
            case 'Specialization':
                return <></>;
            default:
                return null;
        }
    };


    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Add Users</span>
            </div>
            {/* User Section */}
            <div >
                <div style={{ ...styles.userInfo }}>
                    <div className="company-name" style={{ marginRight: 15 }}>Add User</div>
                    <Toggle initialStatus={true} size='big' disabled={true} />
                </div>
                <div style={{ ...styles.userInfo, width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                </div>
            </div>

            {/* Tab Navigation */}
            <div className="tab-container">
                <div
                    className={activeTab === 'PrimaryInformation' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                >
                    Primary Information
                </div>
                <div
                    className={activeTab === 'Tasks' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{ opacity: 0.5 }}
                >
                    Specialization
                </div>
                <div
                    className={activeTab === 'BillingFees' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{ opacity: 0.5 }}
                >
                    Assignments
                </div>
                <div
                    className={activeTab === 'Comments' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{ opacity: 0.5 }}
                >
                    User Access
                </div>
            </div>

            {/* Tab Content */}
            <div className="content-container" >
                {renderContent()}
            </div>

        </div>
    )

};

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        letterSpacing: 1
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
    },
    busyworkborder: {
        border: '1px #40513B solid',
        borderRadius: '20px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "white",
        color: "#40513B",
        padding: "5px 8px",
        cursor: "pointer",
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        height: '20px',
        alignSelf: 'center'
    }
}

export default AddUserScreen;