import React, { useState, useRef, useEffect } from 'react';
import UsersListController from '../../../Controllers/AdminScreens/Users/UsersListController';
import AddUserModal from '../../../../Components/AddUserModal';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../Assets/Icons/filter.png';
import search from '../../../../Assets/Icons/search.png';
import TextInput from '../../../../Components/TextInput';
import { useNavigate } from 'react-router-dom';

const UsersListScreen = () => {
    const navigate = useNavigate();
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
    } = UsersListController();

    const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [filters, setFilters] = useState({
        clientName: '',
        clientType: '',
        gstin: '',
        city: '',
        assignedTo: '',
        groupName: '',
        pendingTask: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        //console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            clientName: '',
            clientType: '',
            gstin: '',
            city: '',
            assignedTo: '',
            groupName: '',
            pendingTask: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const handleAddUserClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleNav = (pageName)=>{
        //console.log('page',pageName);
        navigate(`/${pageName}`);    
      }

    return (
        <>
        <div style={{ width: '93%', margin: 'auto' }}>
            <h3 style={styles.header}>
                <div style={styles.headerContainer}>
                    <span>Users</span>
                    <button className="add-client-button" style={{ marginBottom: 10, marginLeft: 'auto' }} onClick={handleAddUserClick}>
                        Add User
                    </button>
                </div>
            </h3>
        </div>
        <div className="tasksScreen-main-content">
            {/* Filters Section */}
            <div className="tasksScreen-tasks-container">
                <div className="tasksScreen-tasks-header">
                    <h2 className="tasksScreen-tasks-title">Search User</h2>
                </div>
                <div className="tasksScreen-filters-row">
                    <div className="tasksScreen-filters-button-wrapper">
                        <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                            <img src={filter} alt="Filter" />
                            {showFilters ? 'Filters' : 'Filters'}
                        </button>
                    </div>
                </div>

                {showFilters && (
                    <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                        <div className="tasksScreen-filters-flex">
                            {/* Filters Section */}
                            <div className="filter-field">
                                <label>Username</label>
                                <TextInput
                                    placeholder="Search"
                                    value={filters.username || ''} // Bind to username filter
                                    onChange={(e) => handleFilterChange('username', e.target.value)}
                                    type="text"
                                    right={
                                        <img
                                            src={require('../../../../Assets/Icons/search.png')}
                                            alt="Search"
                                            className="search-icon"
                                        />
                                    }
                                />
                            </div>

                            <div className="filter-field">
                                <label>Mobile</label>
                                <TextInput
                                    placeholder="Search"
                                    value={filters.mobile || ''} // Bind to mobile filter
                                    onChange={(e) => handleFilterChange('mobile', e.target.value)}
                                    type="text"
                                    right={
                                        <img
                                            src={require('../../../../Assets/Icons/search.png')}
                                            alt="Search"
                                            className="search-icon"
                                        />
                                    }
                                />
                            </div>

                            <div className="filter-field">
                                <label>Email</label>
                                <TextInput
                                    placeholder="Search"
                                    value={filters.email || ''} // Bind to email filter
                                    onChange={(e) => handleFilterChange('email', e.target.value)}
                                    type="text"
                                    right={
                                        <img
                                            src={require('../../../../Assets/Icons/search.png')}
                                            alt="Search"
                                            className="search-icon"
                                        />
                                    }
                                />
                            </div>

                            <div className="filter-field">
                                <label>Group Name</label>
                                <TextInput
                                    placeholder="Search"
                                    value={filters.groupName || ''} // Bind to groupName filter
                                    onChange={(e) => handleFilterChange('groupName', e.target.value)}
                                    type="text"
                                    right={
                                        <img
                                            src={require('../../../../Assets/Icons/search.png')}
                                            alt="Search"
                                            className="search-icon"
                                        />
                                    }
                                />
                            </div>
                        </div>


                        <div className="tasksScreen-filter-buttons">
                            <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                            <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                            <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                        </div>
                    </div>
                )}
            </div>

            {/* Table Section */}
            <div className="tasksScreen-tasks-container" style={{ marginTop: 20 }}>
                <div className="tasksScreen-task-table-wrapper" >
                    <table className="tasksScreen-task-table">
                        <thead>
                            <tr>
                                <th>Username</th>
                                <th>Mobile #</th>
                                <th>Email ID</th>
                                <th>Group Name</th>
                                <th>No. Of Task Assigned</th>
                                <th>Manager</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: '#fff' }}>
                            {tasks.map((task, index) => (
                                <React.Fragment key={index}>
                                    <tr
                                        className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}
                                    >
                                        <td style={{ color: '#5A55D2', textDecoration: 'underline',cursor:'pointer' }} onClick={()=>{handleNav('manage-user')}}>{task.userName}</td>
                                        <td>{task.mobile}</td>
                                        <td>{task.email_id}</td>
                                        <td>{task.groupName}</td>
                                        <td style={{ color: '#5A55D2', textDecoration: 'underline' }}>{task.taskAssign}</td>
                                        <td>{task.manager}</td>
                                    </tr>
                                </React.Fragment>
                            ))}
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="tasksScreen-table-footer" >
                    <span className="tasksScreen-pagination-info">
                        {`${currentPage}-${totalPages} of items`}
                    </span>
                    <div className="tasksScreen-pagination">
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <img
                                src={downArrow}
                                alt="Previous"
                                className="tasksScreen-arrow-left tasksScreen-icon-image"
                            />
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <img
                                src={downArrow}
                                alt="Next"
                                className="tasksScreen-arrow-right tasksScreen-icon-image"
                            />
                        </button>
                    </div>
                </div>
                <AddUserModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>
        </>
    );
};

const styles = {
    header: {
      fontSize: "22px",
      color: "#060606",
      marginBottom: "10px",
      fontWeight:500,
    //   fontFamily:'Montserrat',
    //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader:{
        marginTop:'-5px'
    }
  };

export default UsersListScreen;