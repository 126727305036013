import React, { useState, useRef, useEffect } from "react";

const TaxDashboardController = () => {
    // Static information for the top section
    const [organizationDetails, setOrganizationDetails] = useState({
        organizationName: "DLS INDIA Corp",
        licenseNumber: "Company",
        username: "Admin", // Admin username
        password: "123456", // Masked password
    });

    // Summary data for "ITR Client Statistics"
    const [itrClientStats, setItrClientStats] = useState({
        filed: 21, // Filed count
        inProgress: 21, // In Progress count
        pending: 21, // Pending count
    });

    return {
        organizationDetails,
        setOrganizationDetails,
        itrClientStats,
        setItrClientStats,
    };
};

export default TaxDashboardController;
