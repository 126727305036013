import react,{ useState, useEffect, useRef } from 'react';
import { getData,postData } from '../../../../ApiServices/ApiService';
import { ORG_ADD_CLIENT, ORG_GET_GROUP_LIST, ORG_GET_CLIENT_TYPE_LIST,
    ORG_GET_ALL_CODES_LIST,ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER } from '../../../../ApiServices/BaseURL';
import { useNavigate } from 'react-router-dom';
import { validateEmail } from '../../../../Utils/Validation';

const AddPrimaryInformationController = (clientInfo) => {
  const getCurrentFormattedDate = () => {
    const today = new Date();
    const year = today.getFullYear();
    const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(today.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  };
  //console.log('clientInfo?.client_other_type_Name:---',clientInfo?.client_other_type_name)
    const [basicInformation, setBasicInformation] = useState({
        client_name: clientInfo?.client_name || "",
        client_type_id: clientInfo?.client_type?.value || null,
        group_id: clientInfo?.group_info?.value || null,
        client_email: clientInfo?.client_email || "",
        client_status: null,
        client_file_id: "",
        client_mobile_no: clientInfo?.client_mobile_no || null,
        client_mobile_code: clientInfo?.client_mobile_code || null,
        client_pan_no: clientInfo?.client_identity_doc.value === "PAN" ? clientInfo?.client_identity_doc_id_number || null : null,
        client_gstin: clientInfo?.client_identity_doc.value === "GSTIN" ? clientInfo?.client_identity_doc_id_number || null : null,
        client_aadhar_no: clientInfo?.client_identity_doc.value === "AADHAR" ? clientInfo?.client_identity_doc_id_number || null : null,
        client_enrolled_on: getCurrentFormattedDate() || null,
        client_address: null,
        client_state: null,
        client_city: null,
        client_pin: null,
        emp_id_incharge: null,
        emp_id_incharge_name: '',
        is_india_client: null,
        client_type_name: clientInfo?.client_type?.label || "",
        group_name: clientInfo?.group_info?.label || "",
        is_india_client:null,
        client_id:null,
        emp_id_incharge_name:'',
        identificationType: clientInfo?.client_identity_doc?.value || null,
        identificationNumber: clientInfo?.client_identity_doc_id_number || null,
        clientOtherTypeName:clientInfo?.client_other_type_name || ''
      });
    
      const clientTypeOptionsRef=useRef([]);
      const [clientTypeOptions, setClientTypeOptions] = useState([]);
      const groupClientOptionsRef=useRef([]);
      const [groupClientOptions, setGroupClientOptions] = useState([]);
      const stateListRef=useRef([]);
      const [stateList, setStateListRef] = useState([]);
      const [updateLoading,setAddLoading]=useState(false);
      const [addStatusError,setAddStatusError]=useState('');
      const [clientAssignedUserError, setClientAssignedUserError] = useState('');
      const [addEmployeeError, setAddEmployeeError] = useState('');
      const [employeeList, setEmployeeList] = useState([]);
      const [selectedEmployee, setSelectedEmployee] = useState(null);

      const [loading, setLoading] = useState(false)
      const [error, setError] = useState('');
      const [groupListLoading, setGroupListLoading] = useState(false);
      const [clientTypeLoading, setClientTypeLoading] = useState(false);
      const [stateListLoading, setStateListLoading] = useState(false);
      const navigate = useNavigate();
    
// State for fieldConfigs with error handling
const [fieldConfigs, setFieldConfigs] = useState([
  { label: "Client Name", key: "client_name", type: "text", error: "" },
  { label: "Client Type", key: "client_type_name", type: "dropdown", options: [], loading: clientTypeLoading, error: "" },
  { label: "Date of Enrollment", key: "client_enrolled_on", type: "date", error: "" },
  { label: "Group Name", key: "group_name", type: "dropdown", options: [], loading: groupListLoading, error: "" },
  { label: "Address", key: "client_address", type: "text", error: "" },
  { label: "City", key: "client_city", type: "text", error: "" },
  { label: "State", key: "client_state", type: "dropdown", options: [], loading: stateListLoading, error: "" },
  { label: "Pin Code", key: "client_pin", type: "text", error: "" ,inputType:'number',maxLength:6},
  { label: "Mobile No", key: "client_mobile_no", type: "mobile", error: "" },
  { label: "GSTIN", key: "client_gstin", type: "text", error: "", maxLength:15},
  { label: "Aadhaar Number (UIDAI)", key: "client_aadhar_no", type: "text", error: "",inputType:'number', maxLength:12},
  { label: "PAN Card", key: "client_pan_no", type: "text", error: "",maxLength:10 },
  { label: "Client Assigned User", key: "emp_id_incharge_name", type: "search", error: "" },
  { label: "NRI", key: "is_india_client", type: "dropdown", options: ["Yes", "No"], error: "" },
  { label: "Email ID", key: "client_email", type: "text", error: "" },
  { label: "Specify Other", key: "clientOtherTypeName", type: "other", error: "" },
]);
 

    useEffect(()=>{
        fetchClientTypesList();
        fetchGroupList();
        fetchStateList();
    },[])

   


  const handleAddContactClick = () => {
    // contactInfoRef.current = null;
    // setIsModalOpen(true);
  };



  const fetchClientTypesList = async () => {
    try {
      setClientTypeLoading(true);
      setFieldConfigs((prevConfigs) =>
        prevConfigs.map((field) =>
          field.key === "client_type_name"
            ? { ...field, loading: true }
            : field
        )
      );
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_TYPE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();
              // Transforming the data
              clientTypeOptionsRef.current =[
                ...(await responseData?.dataJ.map(service => ({
                    label: service.client_type_name,
                    value: service.client_type_id,
                }))),
                { label: "Other", value: -1 }, // Append the "Other" option
              ];
              setClientTypeOptions(clientTypeOptionsRef.current);
                  // Update the fieldConfigs dynamically with clientTypeOptions
              setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                  field.key === "client_type_name"
                    ? { ...field, options: clientTypeOptionsRef.current }
                    : field
                )
              );return
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              clientTypeOptionsRef.current =[
                ...(await responseData?.dataJ.map(service => ({
                    label: service.client_type_name,
                    value: service.client_type_id,
                }))),
                { label: "Other", value: -1 }, // Append the "Other" option
              ];
              setClientTypeOptions(clientTypeOptionsRef.current);
                  // Update the fieldConfigs dynamically with clientTypeOptions
              setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                  field.key === "client_type_name"
                    ? { ...field, options: clientTypeOptionsRef.current }
                    : field
                )
              );
              
              //console.log('type list',clientTypeOptionsRef.current)
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
        setClientTypeLoading(false);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "client_type_name"
              ? { ...field, loading: false }
              : field
          )
        );
    }
  }

  const fetchGroupList = async () => {
    try {
      setGroupListLoading(true);
      setFieldConfigs((prevConfigs) =>
        prevConfigs.map((field) =>
          field.key === "group_name"
            ? { ...field, loading: true }
            : field
        )
      );
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              groupClientOptionsRef.current =await  responseData?.dataJ.map(service => ({
                label: service.group_name,
                value: service.group_id
              }));
              setGroupClientOptions(groupClientOptionsRef.current)
              // Update the fieldConfigs dynamically
              setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                  field.key === "group_name"
                    ? { ...field, options: groupClientOptionsRef.current }
                    : field
                )
              );
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
        setGroupListLoading(false);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "group_name"
              ? { ...field, loading: false }
              : field
          )
        );
    }
  }

  const fetchStateList =async ()=>{
    try {
        setStateListLoading(true);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "client_state"
              ? { ...field, loading: true }
              : field
          )
        );
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const payLoad = {"code_id": "states_list"}
        const response = await postData(ORG_GET_ALL_CODES_LIST,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ) {               
                      // Transforming the data
                  // Transforming the data
              stateListRef.current = await responseData?.dataJ.map(service => ({
                label: service.state_name,
                value: service.state_id
              }));  
              setStateListRef(stateListRef.current);     
              // Update the fieldConfigs dynamically
              setFieldConfigs((prevConfigs) =>
                prevConfigs.map((field) =>
                  field.key === "client_state"
                    ? { ...field, options: stateListRef.current }
                    : field
                )
              );               
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setStateListLoading(false);
        setFieldConfigs((prevConfigs) =>
          prevConfigs.map((field) =>
            field.key === "client_state"
              ? { ...field, loading: false }
              : field
          )
        );
      }
  }

  const handleFieldChange = (key, fieldData) => {
    setBasicInformation((prev) => {
      // If the key is "client_type_name", update label and related ID
      if (key === "client_type_name") {
        return {
          ...prev,
          [key]: fieldData.label, // Set the label in client_type_name
          client_type_id: fieldData.value, // Set the value in client_type_id
        };
      }
      if (key === "client_state") {
        return {
          ...prev,
          [key]: fieldData.label, // Set the label in client_type_name
          // client_status_id: fieldData.value, // Set the value in client_type_id
        };
      }

      if(key === "group_name"){
        return {
          ...prev,
          [key]: fieldData.label, // Set the label in client_type_name
          group_id: fieldData.value, // Set the value in client_type_id
        };
      }
      // For other keys, update normally
      return {
        ...prev,
        [key]: fieldData, // Update with the provided value
      };
    });
  };
  

  const clearData = ()=>{
    setBasicInformation({
        client_name: "",
        client_type_id: null,
        group_id: null,
        client_email: "",
        client_status: null,
        client_file_id: "",
        client_mobile_no: null,
        client_mobile_code: null,
        client_pan_no: null,
        client_gstin: null,
        client_aadhar_no: null,
        client_enrolled_on: null,
        client_address: null,
        client_state: null,
        client_city: null,
        client_pin: null,
        emp_id_incharge: null,
        emp_id_incharge_name: '',
        is_india_client: null,
        client_type_name: "",
        group_name: "",
        is_india_client:null,
        client_id:null,
        emp_id_incharge_name:'',
        identificationType:null,
        identificationNumber:null
    })
  }





  const onAddClientInfo=async ()=>{
    //console.log('update');

  //   setClientAssignedUserError('');
  //   setAddStatusError('')

  //   if(!basicInformation?.client_enrolled_on){
  //     setClientEnrolledError('Date of enrollment is required.');
  //     return;
  //   }

  // if (!selectedEmployee?.emp_id && !basicInformation?.emp_id_incharge) {
  //   setClientAssignedUserError('Client assigned user is required.');
  //   return;
  // }

    try {

      const updatedFieldConfigs = fieldConfigs.map((field) => ({
        ...field,
        error: "", // Reset errors initially for all fields
      }));
    
      let hasError = false;
    
      // Validate each field based on its key
      const validatedFields = updatedFieldConfigs.map((field) => {
        let error = "";
        const value = basicInformation?.[field.key]; // Retrieve the value for this field using its key
    
        // Validation logic based on the key
        switch (field.key) {
          case "client_name":
          case "client_address":
          case "client_city":
            if (!value?.trim()) {
              error = `${field.label} is required.`;
              hasError = true;
            }
            break;
          case "client_email":
            if (!validateEmail(value)) {
              error = `${field.label} is required.`;
              hasError = true;
            }
            break;
          case "client_gstin":
            if (basicInformation?.client_type_name?.toLowerCase() !== 'individual' && (!value?.trim() || value.length !== 15)) {
              error = `${field.label} is required and number must be exactly 15 characters.`;
              hasError = true;
            }
            break;
          case "client_aadhar_no":
            if (!value?.trim() || value.length !== 12) {
              error = `${field.label} is required and number must be exactly 12 characters.`;
              hasError = true;
            }
            break;
          case "client_pan_no":
            if (!value?.trim() || value.length !== 10) {
              error = `${field.label} is required and number must be exactly 10 characters.`;
              hasError = true;
            }
            break;
    
          case "client_mobile_no":
            if (!value || value.length !== 10 || !/^\d+$/.test(value)) {
              error = `${field.label} must be a valid 10-digit number.`;
              hasError = true;
            }
            break;
    
          case "client_enrolled_on":
            if (!value) {
              error = `${field.label} is required.`;
              //console.log('value date----if',value)
              hasError = true;
            }else{
              //console.log('value date----else',value)
            }
            break;
    
          case "client_type_name":
          // case "group_name":
          case "client_state":
          case "is_india_client":
            if (!value) {
              error = `${field.label} must be selected.`;
              hasError = true;
            }
            break;
    
          case "emp_id_incharge_name":
            if (!selectedEmployee?.emp_id && !basicInformation?.emp_id_incharge) {
              error = "Client assigned user is required.";
              hasError = true;
            }
            break;
    
          default:
            break;
        }
    
        return { ...field, error }; // Attach the error message to the field
      });
    
      // Update fieldConfigs with errors
      setFieldConfigs(validatedFields);
    
      if (hasError) {
        return; // Stop submission if there are validation errors
      }
      setAddLoading(true);

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_id": basicInformation?.client_id,
        "client_type_id": basicInformation?.client_type_id,
        "client_name": basicInformation?.client_name,
        "client_email": basicInformation?.client_email,
        "client_file_id": basicInformation?.client_file_id,
        "client_mobile_no": basicInformation?.client_mobile_no,
        "client_mobile_code": basicInformation?.client_mobile_code,
        "client_pan_no": basicInformation?.client_pan_no,
        "client_gstin": basicInformation?.client_gstin,
        "client_aadhar_no": basicInformation?.client_aadhar_no,
        "client_address": basicInformation?.client_address,
        "client_state": basicInformation?.client_state,
        "client_city": basicInformation?.client_city,
        "client_pin": basicInformation?.client_pin,
        "emp_id_incharge": selectedEmployee?.emp_id || basicInformation?.emp_id_incharge,
        "client_enrolled_on": basicInformation?.client_enrolled_on,
        "client_status":basicInformation?.client_status,
        "group_id": basicInformation?.group_id || 0,
        "client_identity_doc_id": basicInformation?.identificationType || 0,
        "client_identity_doc_id_number": basicInformation?.identificationNumber || 0,
        "client_type_name":basicInformation?.clientOtherTypeName || ''
      }
      //console.log('Add Client:---',payLoad);
      const response = await postData(ORG_ADD_CLIENT,payLoad);
      const responseData = await response.json();
  
      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            //console.log("Team update success");
            setAddStatusError(responseData?.info || 'Team update success.');
            clearData();
            navigate(`/manage-clients`,{ state: { task:'PrimaryInformation',clientId: responseData?.dataJ[0].client_id} })
          } else {
            setAddStatusError(responseData?.info || 'Failed to update team status. Please try again.');
          }
        } else {
          setAddStatusError(responseData?.info || 'Failed to update team status.');
        }
      } 
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setAddStatusError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setAddStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } 
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setAddStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
      } 
      // Unexpected or network-related errors
      else {
        setAddStatusError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setAddStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update team status. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setAddStatusError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setAddStatusError(`Failed to update team status. Please check your network connection and try again.`);
      }
    } finally {
      setAddLoading(false);
    }
  }

  const handleSearchEmployee = async () => {
   
    try {
        setError('');    
        if (!basicInformation?.emp_id_incharge_name) {
          setError('Please enter valid text'); return;
      }       
        setLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  search_type: "GENERIC", search_word: basicInformation?.emp_id_incharge_name}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
                setAddEmployeeError('')
                setEmployeeList(responseData?.dataJ || []);
            //   setError(responseData?.info);
              setTimeout(() => {
                // handleClose();
                // onValueChange();
              }, 3000);
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
      }

    // onValueChange(teamData);
    // onClose();
};

const handleSelectedEmployee = (employee) => {
  setSelectedEmployee(employee); // Set selected client data
  handleFieldChange('emp_id_incharge_name',employee?.emp_name);
  setEmployeeList([]); // Clear the dropdown
};

const fieldConfigs2 =[
    { label: "Client Name", key: "client_name", type: "text" },
    { label: "Client Type", key: "client_type_name", type: "dropdown", options: clientTypeOptions || [], loading:clientTypeLoading},
    { label: "Date of Enrollment", key: "client_enrolled_on", type: "date" },
    { label: "Group Name", key: "group_name", type: "dropdown", options: groupClientOptions || [], loading:groupListLoading },
    { label: "Address", key: "client_address", type: "textarea" },
    { label: "City", key: "client_city", type: "text" },
    { label: "State", key: "client_state", type: "dropdown", options: stateList || [],loading:stateListLoading },
    { label: "Pin Code", key: "client_pin", type: "text" },
    { label: "Mobile No.", key: "client_mobile_no", type: "mobile" },
    { label: "GSTIN", key: "client_gstin", type: "text" },
    { label: "Aadhaar Number (UIDAI)", key: "client_aadhar_no", type: "text" },
    // { label: "Fix Assigned User", key: "fix_assigned_user", type: "text" },
    { label: "PAN Card", key: "client_pan_no", type: "text" },
    { label: "Client Assigned User", key: "emp_id_incharge_name", type: "search",error:clientAssignedUserError },
    { label: "Out Of India Client", key: "is_india_client", type: "dropdown", options: ["Yes", "No"] },
    { label: "Email ID", key: "client_email", type: "text" },
  ]

    return {
        basicInformation,
        fieldConfigs,
        handleFieldChange,
        handleAddContactClick,
        loading,
        error,
        onAddClientInfo, 
        updateLoading,
        addStatusError,
        addEmployeeError,
        employeeList,
        fieldConfigs2,
        handleSearchEmployee,
        handleSelectedEmployee
    };
};

export default AddPrimaryInformationController;
