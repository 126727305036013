import React, { useState,useEffect } from 'react';

const Toggle = ({ initialStatus = false, onToggle, size = 'small',disabled = false }) => {
    const [isChecked, setIsChecked] = useState(initialStatus);
    useEffect(()=>{
        setIsChecked(initialStatus)
    },[initialStatus])

    const handleToggleChange = () => {
        const newState = !isChecked;
        setIsChecked(newState);
        if (onToggle) {
            onToggle(newState); // Notify parent component about state change
        }
    };

    const styles = {
        toggleContainer: {
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            fontFamily: 'Arial, sans-serif',
            fontSize: '16px',
        },
        toggleSwitch: {
            position: 'relative',
            display: 'inline-block',
            width: '33px',
            height: '20px',
        },
        toggleSwitchBig: {
            position: 'relative',
            display: 'inline-block',
            width: '48px',
            height: '30px',
        },
        toggleSwitchInput: {
            opacity: 0,
            width: 0,
            height: 0,
        },
        slider: {
            position: 'absolute',
            cursor: 'pointer',
            top: 0,
            left: 0,
            right: 0,
            bottom: 0,
            backgroundColor: isChecked ? '#4caf50' : '#ccc',
            transition: '0.4s',
            borderRadius: '34px',
        },
        sliderBefore: {
            position: 'absolute',
            content: '""',
            height: '18px',
            width: '18px',
            borderRadius: '50%',
            left: isChecked ? '13px' : '2px',
            bottom: '0.75px',
            backgroundColor: 'white',
            transition: '0.4s',
        },
        sliderBeforeBig: {
            position: 'absolute',
            content: "",
            height: '26px',
            width: '26px',
            borderRadius: '50%',
            left: isChecked ? '20px' : '2px',
            bottom: '1.75px',
            backgroundColor: 'white',
            transition: '0.4s',
        },
    };

    return (
        <div style={styles.toggleContainer}>
            <label style={size === 'big' ? styles.toggleSwitchBig : styles.toggleSwitch}>
                <input
                    type="checkbox"
                    style={styles.toggleSwitchInput}
                    checked={isChecked}
                    onChange={handleToggleChange}
                    disabled={disabled}
                />
                <span style={styles.slider}>
                    <span style={size === 'big' ? styles.sliderBeforeBig : styles.sliderBefore}></span>
                </span>
            </label>
        </div>
    );
};

export default Toggle;