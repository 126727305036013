import React, { useState, useRef, useEffect } from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import MobileInput from "../../../../Components/MobileInput";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput'
import backIcon from '../../../../Assets/Icons/back.png'
import search from '../../../../Assets/Icons/search.png';
import TextInput from '../../../../Components/TextInput';
import CreateEditEstimateController from "../../../Controllers/AdminScreens/Invoices/CreateEditEstimateController";
import Loader from "../../../../Components/Loader";
import LoadingSkeleton from "../../../../Components/LoadingSkeleton";
import downIcon from '../../../../Assets/Icons/chevron-down.png';
import circleTick from "../../../../Assets/Icons/circle-tick.png";
import circleIcon from "../../../../Assets/Icons/circle.png";

const CreateEditEstimateScreen = () => {


  const {
    handleBasicInfoChange,
    estimateOptions,
    handleEstimateOptionsChange,
    sendUpdateToClient,
    setSendUpdateToClient,
    sendUpdateToTeam,
    setSendUpdateToTeam,
    estimateWithoutTask,
    setEstimateWithoutTask,
    handleSelectClient,
    handleSearchInputText,
    handleSearchClient,
    searchUser,
    selectedClientError,
    clientList,
    loading,
    groupClientOptionsList,
    groupLoading,
    groupClientError,
    groupClient, setGroupClient,
    taskList, taskListError, taskListLoading,
    addServiceRow, removeServiceRow, rows, setRows,
    serviceCategoryOptions, serviceOptions,
    serviceCategoryError, serviceCategoryLoading,
    serviceError, serviceLoading, fetchServiceCategoryList,
    phoneNo, dateOfEstimate, handleSubmitEstimate,
    rowData, selectAll, checkedStates, handleBack, getStatusStyle, handleSelectAll,
    handleCheckboxChange, employeeList, empLoading, empError, dateError,
    submitLoading, submitError, submitSuccess, estimateAmtError, billAmtError, serviceSelectError,
    showGroupEstimateList, groupTaskListLoading, groupTaskList, groupTaskListError, isShowGroupWise,
    expandedClients, toggleClient, handleSetGroupClient, handleSelectGroupOrClient, handleSelectServiceOrTask,
    handleClientSelectAll, handleTaskCheckboxChange, selectedClientsTasks
  } = CreateEditEstimateController();



  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>{rowData ? "Edit Estimate" : "Create Estimate"}</span>
          </div>
        </h3>
      </div>
      <div style={styles.container}>
        <h2 style={styles.title}>Create Estimates</h2>

        {/* Basic Information */}
        <div style={styles.section}>
          <div style={{ display: 'flex', alignItems: 'center', gap: 10, marginBottom: "12px" }}>
            <h3 style={{ ...styles.sectionTitle, }}>Basic Information</h3>
            <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }} onClick={() => handleSelectGroupOrClient(false)}>
                <div style={styles.checkbox}>
                  {!isShowGroupWise ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                  )}
                </div>
                <div>
                  Client wise
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }} onClick={() => handleSelectGroupOrClient(true)}>
                <div style={styles.checkbox}>
                  {isShowGroupWise ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                  )}
                </div>
                <div>
                  Group wise
                </div>
              </div>


            </div>

          </div>
          <div className="basic-info-row" style={styles.row} >
            {!isShowGroupWise ?
              <div className="filter-field">
                <label
                  style={{
                    ...styles.labaleStyle,
                    display: 'block',
                  }}
                >
                  Client Name
                </label>
                <TextInput
                  type="text"
                  placeholder="Client Name"
                  value={searchUser}
                  onChange={(e) => handleSearchInputText(e.target.value)}
                  onRightClick={handleSearchClient}
                  right={
                    loading ? (
                      <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                    ) : (
                      <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                    )
                  }
                  style={{ padding: '20px 10px' }}
                  searchBoxWidth={220}
                  errorMessage={selectedClientError}
                />
                {clientList.length > 0 && (
                  <ul style={styles.dropdownList}>
                    {clientList.map((client, index) => (
                      <li
                        key={client?.client_id}
                        onClick={() => handleSelectClient(client)}
                        style={{
                          ...styles.dropdownItem,
                          borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                        }}
                      >
                        {client?.client_name}
                      </li>
                    ))}
                  </ul>
                )}

              </div> :

              <Dropdown
                ref={(el) => (dropdownRefs.current["groupName"] = el)}
                isOpen={openDropdown === "groupName"}
                toggleDropdown={() => toggleDropdown("groupName")}
                label="Group Name" options={groupClientOptionsList || []}
                sideLabel="Group Estimate"
                labelClick={showGroupEstimateList}
                value={groupClient} onChange={handleSetGroupClient}
                searchBoxWidth={220}
                errorMessage={groupClientError} loading={groupLoading} />
            }

              <Input
                label="Phone No."
                placeholder="--"
                value={phoneNo}
                isEdit={false}
                searchBoxWidth={220}
              />

            <div>

              <div className="filter-field" style={{ width: '100%' }}> {/* Add margin-bottom here */}
                <label style={{ ...styles.labaleStyle, marginBottom: "5px", display: "block" }}>
                  Date of Estimate
                </label>
                <DateInput
                  value={dateOfEstimate}
                  onChange={(e) => handleBasicInfoChange('dateOfEstimate', e.target.value)}
                  // style={{ padding: "20px 10px" }}
                  isDateTime={true}
                  errorMessage={dateError}

                  width={220}
                />
              </div>
            </div>
          </div>
        </div>
        <div style={{ display: 'flex', alignItems: 'center', gap: 10, paddingBottom: 10 }}>
              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }} onClick={() => handleSelectServiceOrTask(false)}>
                <div style={styles.checkbox}>
                  {!estimateWithoutTask ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                  )}
                </div>
                <div>
                  Without Task
                </div>
              </div>

              <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', gap: 10, cursor: 'pointer' }} onClick={() => handleSelectServiceOrTask(true)}>
                <div style={styles.checkbox}>
                  {estimateWithoutTask ? (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                  ) : (
                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                  )}
                </div>
                <div>
                 With Task
                </div>
              </div>
            </div>

        {!isShowGroupWise ?
          <>
          
           {/* Client Task List  */}

            {estimateWithoutTask &&
              <div style={{ ...styles.tableBorder, marginBottom: 20 }}>
                <div style={{ paddingTop: 10 }}>
                  <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Select Tasks</h3>
                  <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
                    <div style={styles.tableWrapper}>
                      <table className="tasksScreen-task-table">
                        <thead>
                          <tr>
                            <th style={{ width: "5%" }}>
                              {/* <input type="checkbox" /> */}
                              <CheckBox
                                checked={selectAll}
                                onChange={handleSelectAll}
                              />
                            </th>
                            <th style={{ width: "10%" }}>Service Name</th>
                            <th style={{ width: "10%" }}>Financial Year</th>
                            <th style={{ width: "10%" }}>Billing Amt</th>
                            <th style={{ width: "10%" }}>Est Amt</th>
                            <th style={{ width: "10%" }}>Assigned To</th>
                            <th style={{ width: "10%" }}>Task Leader</th>
                            <th style={{ width: "10%" }}>Start Date</th>
                            <th style={{ width: "10%" }}>ETD</th>
                            <th style={{ width: "10%" }}>Status</th>
                          </tr>
                        </thead>
                        <tbody style={{ backgroundColor: "white" }}>
                          {taskList?.map((category, index) => (
                            <tr key={index}>
                              <td>
                                {/* <input type="checkbox" /> */}
                                <CheckBox
                                  checked={checkedStates[index] || false}
                                  onChange={(isChecked) => handleCheckboxChange(index, isChecked)}
                                />
                              </td>
                              <td >{category?.service_name}</td>
                              <td >{category?.financial_year}</td>
                              <td>{category?.task_bill_amount}</td>
                              <td>{category?.task_bill_amount_estimated}</td>
                              <td >{category?.emp_id_assigned_name}</td>
                              <td >{category?.emp_id_tl_name}</td>
                              <td >{category?.task_start_date}</td>
                              <td >{category?.task_due_date}</td>
                              <td ><div
                                style={{
                                  display: "inline-block", // Makes the div wrap content and have its own width
                                  padding: "5px 5px", // Padding for the button-like appearance
                                  borderRadius: "15px",
                                  ...getStatusStyle(category?.task_status_name), // Dynamic styles based on the status
                                  width: "auto", // Width adjusts automatically to content
                                }}
                              >
                                {category?.task_status_name}
                              </div></td>


                            </tr>
                          ))}
                          {taskListLoading && <tr>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                            <td><LoadingSkeleton width="40%" height="10px" /></td>
                          </tr>}
                        </tbody>
                      </table>
                      {taskListError && <div className="list-error-msg"> {taskListError} </div>}
                    </div>

                  </div>
                </div>
              </div>
            }
          </> : <>
           {/* Group All Client Task List  */}
            <div>
              <div>
                {groupTaskListLoading && <Loader loading={groupTaskListLoading} color={'var(--primary-color)'} />}
                {groupTaskListError && <div className="list-error-msg" > {groupTaskListError} </div>}
                {taskListError && <div className="list-error-msg"> {taskListError} </div>}

                {/* Clients List */}
                {groupTaskList?.length > 0 &&
                  <div>
                    {groupTaskList.map((client) => (
                      <div key={client.client_id} style={styles.clientContainer}>
                        {/* Client Header */}
                        <div style={{ display: 'flex', alignItems: "center", gap: 5, borderBottom: '1px solid #eff0f4', boxShadow: '0px 4px 4px 0px rgba(0, 0, 0, 0.07), 0px 1px 1px -1px rgba(0, 0, 0, 0.25) inset' }}>

                          <img
                            src={downIcon}
                            alt="downIcon"
                            style={{ width: '20px', height: '20px', cursor: 'pointer', padding: 10 }}
                          />
                          <div style={{ borderLeft: "1px solid #eff0f4", display: "flex", flex: 'auto', justifyContent: "space-between", alignItems: "center", cursor: "pointer", padding: '10px' }} onClick={() => toggleClient(client.client_id)}>
                            <div >

                              <div style={styles.clientNameSty}>{client.client_id} | {client.client_name}</div>
                            </div>
                            <span style={{ background: "#D4EDDA", padding: "5px 10px", borderRadius: "5px", fontSize: "12px" }}>
                              {client.task_list.length} Tasks
                            </span>
                          </div></div>

                        {/* Task List (Expandable) */}
                        {expandedClients[client.client_id] && (
                          <div style={{ padding: 10 }}>
                            <h3 style={{ ...styles.sectionTitle, paddingBottom: "10px" }}>Select Tasks without Invoices</h3>
                            <table className="tasksScreen-task-table">
                              <thead>
                                <tr>
                                  <th style={{ width: "5%" }}>
                                    {/* <input type="checkbox" /> */}
                                    <CheckBox
                                      checked={selectedClientsTasks[client.client_id]?.length === client.task_list.length}
                                      onChange={(isChecked) => handleClientSelectAll(client.client_id, isChecked)}
                                    />
                                  </th>
                                  <th style={{ width: "10%" }}>Service Name</th>
                                  <th style={{ width: "10%" }}>Financial Year</th>
                                  <th style={{ width: "10%" }}>Billing Amt</th>
                                  <th style={{ width: "10%" }}>Est Amt</th>
                                  <th style={{ width: "10%" }}>Assigned To</th>
                                  <th style={{ width: "10%" }}>Task Leader</th>
                                  <th style={{ width: "10%" }}>Start Date</th>
                                  <th style={{ width: "10%" }}>ETD</th>
                                  <th style={{ width: "10%" }}>Status</th>
                                </tr>
                              </thead>
                              <tbody style={{ backgroundColor: "white" }}>
                                {client.task_list.map((task) => (
                                  <tr key={task.task_id}>
                                    <td style={styles.tableCell}>
                                      <CheckBox
                                        checked={selectedClientsTasks[client.client_id]?.includes(task.task_id) || false}
                                        onChange={(isChecked) => handleTaskCheckboxChange(client.client_id, task.task_id, task.task_bill_amount_estimated, isChecked)}
                                      />
                                    </td>
                                    <td style={styles.tableCell}>{task.service_name}</td>
                                    <td style={styles.tableCell}>{task.financial_year}</td>
                                    <td style={styles.tableCell}>{task.task_bill_amount}</td>
                                    <td style={styles.tableCell}>{task.task_bill_amount_estimated}</td>
                                    <td style={styles.tableCell}>{task.emp_id_assigned_name}</td>
                                    <td style={styles.tableCell}>{task.emp_id_tl_name}</td>
                                    <td style={styles.tableCell}>{task.task_start_date}</td>
                                    <td style={styles.tableCell}>{task.task_due_date}</td>
                                    <td >

                                      <div
                                        style={{
                                          display: "inline-block", // Makes the div wrap content and have its own width
                                          padding: "5px 5px", // Padding for the button-like appearance
                                          borderRadius: "15px",
                                          ...getStatusStyle(task.task_status_name), // Dynamic styles based on the status
                                          width: "auto", // Width adjusts automatically to content
                                        }}
                                      >
                                        {task.task_status_name}
                                      </div>
                                    </td>
                                  </tr>
                                ))}
                              </tbody>
                            </table>
                          </div>
                        )}
                      </div>
                    ))}
                  </div>}

              </div>

            </div>
          </>}

        {/* Requested Services */}
        <div style={styles.mainContainer}>

          <div style={{ flex: 'auto' }}>
            {!estimateWithoutTask &&
              <> <div style={{ display: 'flex', gap: '10px', marginBottom: '12px' }}>
                <h3 style={styles.sectionTitle}>Requested Services</h3>

              </div>
                <div style={{ ...styles.row }}>
                  <div style={{ height: 210, overflow: 'auto' }}>
                    {rows.map((row, index) => (
                      <div key={row.id} style={{ display: 'flex', flexWrap: 'wrap', gap: 10, alignItems: 'center', paddingBottom: 8 }}  >
                        <Dropdown
                          ref={(el) => (dropdownRefs.current[`serviceCategory${row.id}`] = el)}
                          isOpen={openDropdown === `serviceCategory${row.id}`}
                          toggleDropdown={() => toggleDropdown(`serviceCategory${row.id}`)}
                          label="Service"
                          options={serviceOptions || []}
                          value={row.serviceCategory}
                          onChange={(value) => {
                            const updatedRows = rows.map((r) =>
                              r.id === row.id ? { ...r, serviceCategory: value } : r
                            );
                            setRows(updatedRows);
                            fetchServiceCategoryList(value, row.id);
                          }}
                          loading={serviceLoading}
                          errorMessage={serviceError}
                          searchBoxWidth={200}
                        />
                        {/* <div> */}
                        <Dropdown
                          ref={(el) => (dropdownRefs.current[`serviceType${row.id}`] = el)}
                          isOpen={openDropdown === `serviceType${row.id}`}
                          toggleDropdown={() => toggleDropdown(`serviceType${row.id}`)}
                          label="Service Category"
                          options={serviceCategoryOptions[row.id] || []}
                          value={row.serviceType}
                          onChange={(value) => {
                            const updatedRows = rows.map((r) =>
                              r.id === row.id ? { ...r, serviceType: value } : r
                            );
                            setRows(updatedRows);
                          }}
                          loading={serviceCategoryLoading[row.id]}
                          errorMessage={serviceCategoryError[row.id]}
                          searchBoxWidth={200}
                          isMulti
                        />
                        <div>
                          {index > 0 && ( // Show close icon only for dynamically added rows
                            <img
                              src={closeIcon}
                              style={{ cursor: "pointer", marginLeft: "10px", width: "20px", height: "20px", paddingTop: 20 }}
                              alt="Remove"
                              onClick={() => removeServiceRow(row.id)}
                            />
                          )}
                        </div>
                      </div>
                    ))}
                  </div>
                  {serviceSelectError && <div className="list-error-msg"> {serviceSelectError} </div>}
                  <button onClick={addServiceRow} style={{ ...styles.addServiceButton }}>
                    <span style={{ marginRight: "10px" }}>+</span> Add More Services
                  </button>
                </div></>}
          </div>

          {/* Estimate Options */}
          <div style={{ flex: !estimateWithoutTask ? '10 1 auto' : 'auto' }}>
            <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Estimate Options</h3>
            <div className="task-fields" style={{ ...styles.servicesContainer, paddingBottom: '48px' }}>
              <Dropdown
                ref={(el) => (dropdownRefs.current["estimateOptions"] = el)}
                isOpen={openDropdown === "estimateOptions"}
                toggleDropdown={() => toggleDropdown("estimateOptions")} label="Generate Separate Estimates" options={[{ label: "Yes", value: 1 }, { label: "No", value: 0 }]} value={estimateOptions?.groupEstimate}
                onChange={(value) => handleEstimateOptionsChange('groupEstimate', value)} />
              <Input label="No. Of Clients" placeholder="0" value={groupClient?.group_clients_count} isEdit={false} />

              <Input label="System Billing Amount" placeholder="Enter Bill Amount" value={estimateOptions?.systemBillingAmount}
                onChange={(e) => handleEstimateOptionsChange('systemBillingAmount', e.target.value)}
                errorMessage={billAmtError} inputType="number" />
              <Input label="Estimate Amount" placeholder="Estimate Amount" value={estimateOptions?.estimateAmount}
                onChange={(e) => handleEstimateOptionsChange('estimateAmount', e.target.value)}
                errorMessage={estimateAmtError} inputType="number" />

              <Dropdown
                ref={(el) => (dropdownRefs.current["Generated By"] = el)}
                isOpen={openDropdown === "Generated By"}
                toggleDropdown={() => toggleDropdown("Generated By")} label="Generated By" options={employeeList || []}
                value={estimateOptions?.generatedBy}
                loading={empLoading}
                errorMessage={empError}
                onChange={(value) => handleEstimateOptionsChange('generatedBy', value)} />

            </div>
          </div>
        </div>

        {/* Footer Options */}
        <div style={{ marginTop: '18px' }}>
          <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>

            <CheckBox
              label="Send Update to Client"
              checked={sendUpdateToClient}
              onChange={(isChecked) => setSendUpdateToClient(isChecked)}
            />
          </div>
          <div style={{ display: "flex", alignItems: "center", }}>

            <CheckBox
              label="Send Update to Team"
              checked={sendUpdateToTeam}
              onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
            />
          </div>
        </div>

        {/* Footer Buttons */}

        {submitError && <div className="list-error-msg"> {submitError} </div>}
        {submitSuccess && <div className="list-success-msg"> {submitSuccess} </div>}
        <div className="button-container" style={{ marginTop: '32px' }}>
          {rowData ? (
            // Render buttons if data is present in `rowData`
            <>
              <button
                className="add-client-button"
                style={{ paddingLeft: '30px', paddingRight: '30px', marginRight: '10px' }}
              >
                Update
              </button>
              {/* <button
                className="convert-button"
                style={{ ...styles.addServiceButton, paddingLeft: '30px', paddingRight: '30px' }}
              >
                Convert Estimate to Bill
              </button> */}
              <button className="cancel-button">Cancel</button>
            </>
          ) : (
            // Render default buttons if no data in `rowData`
            <>
              <button
                className="add-client-button"
                style={{ paddingLeft: '30px', paddingRight: '30px', opacity: submitLoading ? 0.5 : 1 }}
                onClick={handleSubmitEstimate}
                disabled={submitLoading}
              >
                {submitLoading && (
                  <span style={{ paddingRight: 10 }}><Loader loading={submitLoading} color={'var(--background-color)'} size="small" /></span>
                )} <span>Create </span>
              </button>
              <button className="cancel-button">Cancel</button>
            </>
          )}
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    marginBottom: "23px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  section: {
    marginBottom: "21px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#609966",
    fontFamily: 'Roboto Slab'
  },
  row: {
    padding: '16px 11px',
    // display: "flex",
    // flexWrap: "wrap",
    // columnGap: "70px",
    // rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid'
  },
  estimatedRow: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    paddingBottom: '63px'
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  serviceRow: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px 70px", // Gap between two elements in the same row
    marginBottom: "15px", // Space between rows
    flexWrap: "wrap",
  },
  dropdownWithClose: {
    display: "flex",
    alignItems: "center", // Vertically aligns the dropdown and close icon
    gap: "26px", // Gap between dropdown and close icon
  },
  closeButton: {
    width: '15px',
    height: '15px',
    cursor: "pointer",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  removeButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#f00",
    fontSize: "16px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  createButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  servicesContainer: {
    // display: "flex",
    // flexWrap: "wrap", // Allows items to wrap to the next row if needed
    // gap: "20px", // Space between rows and items
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
  },
  mainContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    // overflowX: "auto",
    // whiteSpace: "nowrap",
  },
  "@media (max-width: 768px)": {
    // For tablets and smaller screens
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',

    }
  },
  "@media (max-width: 768px)": {
    serviceRow: {
      flexDirection: "column", // Stack items vertically
      gap: "15px", // Reduce the gap for smaller screens
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    // fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  leftHeader: {
    marginTop: '-5px'
  },
  labaleStyle: {
    fontSize: '12px',
    marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    marginBottom: '10px'
  },
  tableBorder: {
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    padding: '10px'
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
  clientNameSty: {
    fontSize: '14px',
    fontWeight: '400',
    color: '#060606'
  },
  circleTickIcon: {
    width: "18px",
    height: "18px",
  },
  // checkboxContainer: { display: "flex", alignItems: "center", gap: "10px" },
  clientContainer: { borderBottom: "2px solid #000", borderTop: "1px solid #eff0f4", borderRight: "1px solid #eff0f4", borderLeft: "1px solid #eff0f4", marginBottom: "10px" },
  taskTable: { width: "100%", borderCollapse: "collapse", marginTop: "10px" },
  tableHeader: { background: "#f4f4f4", fontWeight: "bold" },
  tableCell: {},
  statusBadge: { padding: "5px 10px", borderRadius: "5px", fontSize: "12px" },
};

export default CreateEditEstimateScreen;
