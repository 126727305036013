import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import closeIcon from "../../Assets/Icons/close.png";
import { ORG_NATURE_OF_CALLS_ADD, ORG_NATURE_OF_CALLS_UPDATE } from '../../ApiServices/BaseURL';
import { postData } from '../../ApiServices/ApiService';
import Loader from '../Loader';

const AddCallTypeModal = ({ isOpen, onClose, fetchNatureOfCallsList, documentData, onValueChange }) => {
  const [natureOfCall, setNatureOfCall] = useState("");
  const [description, setDescription] = useState("");
  const [status, setStatus] = useState("");
  const [natureOfCallError, setNatureOfCallError] = useState('')
  const [descriptionError, setDescriptionError] = useState('')
  const [statusError, setStatusError] = useState('');
  const [error, setError] = useState('');
  const [natureOfCallsLoading, setNatureOfCallsLoading] = useState(false);
  const [success, setSuccess] = useState('');

  const statusOptions = ["Active", "Inactive"];

  useEffect(() => {
    if (isOpen && documentData) {
      // //console.log("documentData", documentData);

      // Set client name
      setNatureOfCall(documentData.sc_type_name || '');
      setDescription(documentData.sc_type_desc || '');
      setStatus(documentData.sc_type_status === 1 ? 'Active' : 'Inactive');

    }
  }, [isOpen, documentData]);

  const resetForm = () => {
    setNatureOfCall("");
    setDescription("");
    setStatus("");
  };

  const handleAddCallType = () => {
    const newCallType = {
      natureOfCall,
      description,
      status,
    };
    // //console.log("New Call Type:", newCallType);
    onClose();
  };

  const handleClose = () => {
    setError('');
    onClose();
    setNatureOfCallError('')
    setDescriptionError('');
    setStatusError('');
    setNatureOfCall('')
    setDescription('');
    setStatus('');
    setSuccess('')
  }

  const handleAddNatureOfCalls = async () => {

    try {
      setError('');
      setNatureOfCallError('')
      setDescriptionError('');
      setStatusError('');

      if (!natureOfCall) {
        setNatureOfCallError("Please enter nature of calls");
        return
      }
      // if(!description){
      //   setDescriptionError("Please enter nature of calls description");
      //   return
      // }
      if (!status) {
        setStatusError("Please select status");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setNatureOfCallsLoading(true);
      const statusvalue = status === "Active" ? 1 : status === "Inactive" ? 0 : null;
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, sc_type_name: natureOfCall, sc_type_desc: description, sc_type_status: statusvalue }

      //console.log('handleadddocumentRack payload', payLoad);
      // Make the API call
      const response = await postData(ORG_NATURE_OF_CALLS_ADD, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            // fetchNatureOfCallsList();
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to upload document. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to upload document.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to upload document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to upload document. Please check your network connection and try again.`);
      }
    } finally {
      setNatureOfCallsLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const handleUpdateNatureOfCalls = async () => {

    try {
      setError('');
      setNatureOfCallError('')
      setDescriptionError('');
      setStatusError('');

      if (!natureOfCall) {
        setNatureOfCallError("Please enter nature of calls");
        return
      }
      // if(!description){
      //   setDescriptionError("Please enter nature of calls description");
      //   return
      // }
      if (!status) {
        setStatusError("Please select status");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setNatureOfCallsLoading(true);
      const statusvalue = status === "Active" ? 1 : status === "Inactive" ? 0 : null;
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, sc_type_id: documentData.sc_type_id, sc_type_name: natureOfCall, sc_type_desc: description, sc_type_status: statusvalue }

      //console.log('handleadddocumentRack payload', payLoad);
      // Make the API call
      const response = await postData(ORG_NATURE_OF_CALLS_UPDATE, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            // fetchNatureOfCallsList();
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to upload document. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to upload document.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to upload document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to upload document. Please check your network connection and try again.`);
      }
    } finally {
      setNatureOfCallsLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => { handleClose(); }}>
          <img src={closeIcon} alt="Close" style={styles.closeIcon} />
        </button>
        {/* <h2 className="modal-title">Add New Call Type</h2> */}
        <h2 className="modal-title">{documentData ? `Update New Call Type` : 'Add New Call Type'}</h2>
        <hr className="title-divider" />
        <div className="form-grid" style={{ gridTemplateColumns: '1fr' }}>
          <Input
            label="Nature of Call"
            placeholder="Enter nature of call"
            value={natureOfCall}
            onChange={(e) => setNatureOfCall(e.target.value)}
            width={575}
            errorMessage={natureOfCallError}
          />
          <Input
            label="Description"
            placeholder="Enter description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            width={575}
            errorMessage={descriptionError}
          />
        </div>
        <div style={styles.fullWidth}>
          <Dropdown
            ref={(el) => (dropdownRefs.current["statusOptions"] = el)}
            isOpen={openDropdown === "statusOptions"}
            toggleDropdown={() => toggleDropdown("statusOptions")}
            label="Status"
            options={statusOptions}
            value={status}
            onChange={setStatus}
            width={'50%'}
            errorMessage={statusError}
          />
        </div>
        <hr className="footer-divider" />
        <div className="button-container" style={{ display: 'flex', alignItems: 'center' }}>
          <button
            className="add-client-button"
            // onClick={handleUploadDocument}
            onClick={documentData ? handleUpdateNatureOfCalls : handleAddNatureOfCalls}
            disabled={natureOfCallsLoading}
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px', // Add spacing between text and loader
              position: 'relative',
              opacity: natureOfCallsLoading ? 0.5 : 1, // Reduce opacity when loading
              cursor: natureOfCallsLoading ? 'not-allowed' : 'pointer',
            }}
          >
            <span>{documentData ? ' Update' : 'Add'}</span>
            {natureOfCallsLoading && (
              <Loader loading={natureOfCallsLoading} color={'var(--background-color)'} size="small" />
            )}
          </button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>
            Cancel
          </button>
          {success && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{success} </div>}
          {error && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{error} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  closeIcon: {
    width: "12px",
    height: "12px",
  },
  buttonContainer: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "10px",
    marginTop: "20px",
  },
  fullWidth: {
    gridColumn: "span ",
    width: "50%", // Full width
    marginTop: '14px'
  },
};

export default AddCallTypeModal;
