import React, { useState, useRef, useEffect } from 'react';
import InfoIcon from '../../../../Assets/Icons/Info.png';
import UserPrimaryInformationController from '../../../Controllers/AdminScreens/Users/UserPrimaryInformationController';
import Input from '../../../../Components/Input';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';
import MobileInput from '../../../../Components/MobileInput';
import { formatToDMY } from '../../../../Utils/Validation';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';

const UserPrimaryInformationScreen = ({ isUserEditable, userInfo, loading, error }) => {



    const { handleUpdateUser, handleCancel,
        fieldConfigs,
        primaryData,
        handleInputChange,
        updateDeleteError,
        updateDeleteSuccess,
        updateLoading,
        isEditModalOpen,
        handleOpenEditModal,
        handleCloseEditModal,
        callUpdateUser
    } = UserPrimaryInformationController(userInfo, isUserEditable);
    //console.log('UserPrimaryInfo isUserEditable @@ ', isUserEditable, 'userInfo:-', primaryData)

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };
    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='basic-info-container'>
            <h3 className='basic-info-header'>Basic Information</h3>
            <div className='basic-info-card'>
                <div className='basic-info-row'>
                    {fieldConfigs.map((field) => (
                        <div className="basic-info-item" key={field.key}>
                            {isUserEditable ? (
                                field.type === "dropdown" ? (
                                    <Dropdown
                                        ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                                        isOpen={openDropdown === `${field.label}`}
                                        toggleDropdown={() => toggleDropdown(`${field.label}`)}
                                        label={field.label} // Dropdown already renders the label internally
                                        options={field.options}
                                        value={primaryData[field.key]}
                                        onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}
                                        loading={field?.loading}
                                    />
                                ) : field.type === "date" ? (
                                    <div className="basic-info-label">
                                        <label style={styles.label}>{field.label}</label>
                                        <DateInput
                                            value={primaryData[field.key] || ""}
                                            onChange={(e) => handleInputChange(field.key, e.target.value)}
                                            errorMessage={field?.error}
                                        />
                                    </div>
                                ) : field.type === "display" ? (
                                    <><span className="basic-info-label">{field.label}</span>
                                        {field.key === 'emp_mobile_no' ? <span> {primaryData?.emp_mobile_code}{primaryData[field.key]}{loading && <LoadingSkeleton width="40%" height="10px" />} </span> :
                                            <span className="basic-info-value"> {primaryData[field.key]} {!primaryData[field.key] && !loading && <span>--</span>} {loading && <LoadingSkeleton width="40%" height="10px" />}</span>}</>
                                ) : field.type === "mobile" ? (
                                    <MobileInput
                                        label={field.label}
                                        countryCode={primaryData?.emp_mobile_code || ""}
                                        mobileNumber={primaryData[field.key] || ""}
                                        onCountryChange={(value) => handleInputChange("emp_mobile_code", value)}
                                        onMobileChange={(value) => handleInputChange(field.key, value)}
                                        placeholder={`Enter ${field.label}`}
                                        countries={[
                                            { code: "IN", dialCode: "+91" },
                                            { code: "US", dialCode: "+1" },
                                            { code: "UK", dialCode: "+44" },
                                        ]}
                                        errorMessage={field?.error}
                                    />
                                ) : (
                                    <Input
                                        label={field.label} // Input already renders the label internally
                                        placeholder={`Enter ${field.label}`}
                                        value={primaryData[field.key] || '--'}
                                        onChange={(e) => handleInputChange(field.key, e.target.value)}
                                        isEdit={!(field.label === 'Team Leader' || field.label === 'Manager' || field.label === 'Email ID')}
                                        errorMessage={field?.error}

                                    />
                                )
                            ) : (
                                <><span className="basic-info-label">{field.label}</span>
                                    <span className="basic-info-value">
                                        {field.key === 'emp_mobile_no' ? <>{primaryData?.emp_mobile_code}{primaryData[field.key]}</> :
                                            field.key === "employee_join_at" ? (formatToDMY(primaryData[field.key])) : <>{primaryData[field.key]}</>}

                                        {loading && <LoadingSkeleton width="40%" height="10px" />}</span></>
                            )}
                        </div>
                    ))}
                </div>
                {/* <div className='basic-info-row'>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>User Name</span>
                        <span className='basic-info-value'>{primaryData.userName}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Email ID</span>
                        <span className='basic-info-value'>{primaryData.emailId}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Phone No.</span>
                        <span className='basic-info-value'>{primaryData.phoneNo}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Group Name</span>
                        <span className='basic-info-value'>{primaryData.groupName}</span>
                    </div>
                </div>
                <div style={{ ...styles.row, marginTop: 20 }}>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Team Name</span>
                        <span className='basic-info-value'>{primaryData.teamName}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Date Of Joining</span>
                        <span className='basic-info-value'>{primaryData.dateOfJoining}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Manager</span>
                        <span className='basic-info-value'>{primaryData.manager}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Task Type</span>
                        <span className='basic-info-value'>{primaryData.taskType}</span>
                    </div>
                </div> */}
            </div>
            <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 17 }}>
                <h3 className='basic-info-header'>Recent Activity</h3>
                <div className="basic-info-outer-card">
                    <table className='tasksScreen-task-table'>
                        <thead>
                            <tr>
                                <th>Task Type</th>
                                <th>Client</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white' }}>
                            <tr>
                                <td >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                        <img
                                            src={InfoIcon}
                                            alt="No Data"
                                            style={{ width: '25px', height: '25px', objectFit: 'contain' }}
                                        />
                                        <span style={styles.recentData}>No Data to Display</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            {updateDeleteError && <div className="list-error-msg"> {updateDeleteError} </div>}
            {updateDeleteSuccess && <div className="list-success-msg"> {updateDeleteSuccess} </div>}
            <div className="button-container" style={{ gap: 20, flexWrap: 'wrap' }}>
                {isUserEditable && <button onClick={handleUpdateUser} className="add-client-button" style={{ opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer' }}>
                    {updateLoading && (
                        <span style={{ paddingRight: 10 }}><Loader loading={updateLoading} color={'var(--background-color)'} size="small" /> </span>
                    )} Update User</button>}
                {isUserEditable &&
                    <button onClick={handleCancel} className="tasksScreen-cancel-button">Cancel</button>}
            </div>
            <DeleteConfirmationModal
            isOpen={isEditModalOpen}
            message={`Are you sure you want to update user ?`}
            onClose={handleCloseEditModal}
            onConfirm={() => {
              handleCloseEditModal(); // Close modal
              callUpdateUser(); 
            }}
          />

        </div>
    )

};

const styles = {
    container: {
        margin: "20px",
        //   fontFamily: "Arial, sans-serif",
        backgroundColor: '#fff',
        // marginTop: '-18px'
    },
    header: {
        fontSize: "18px",
        color: "#609966",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Roboto Slab'
    },
    card: {
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "20px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        borderBottom: "2px solid black",
    },
    row: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
    },
    item: {
        flex: "1 1 calc(25% - 20px)",
        minWidth: "200px",
    },
    label: {
        fontSize: "12px",
        marginBottom: "5px",
        fontWeight: 400,
        fontFamily: "Roboto",
        color: "#343C44",
    },
    value: {
        fontSize: "14px",
        color: '#060606',
        fontWeight: 400,
        fontFamily: 'Roboto'
    },
    recentData: {
        color: '#B3261E',
        fontWeight: 400,
        fontSize: 12,
        fontFamily: 'Roboto'
    }
}

export default UserPrimaryInformationScreen;