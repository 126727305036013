
import React,{useState, useEffect} from "react";
import { getData, postData } from "../../../../ApiServices/ApiService";
import {ORG_GET_DOCUMENTS_LIST_RACK} from '../../../../ApiServices/BaseURL';
const DocumentRackController = ()=>{
    const [kycListLoading, setKycListLoading] = useState(false);
    const [kycListError, setKYCListError] = useState('');
    const [kycDocumentList, setKycDocumentList] = useState([]);

    useEffect(()=>{
        fetchDocumentsList()
    },[])

    const fetchDocumentsList =async ()=>{
        
        try {
          setKycDocumentList([])
            setKycListLoading(true);
            setKYCListError('');
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_DOCUMENTS_LIST_RACK}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setKycDocumentList(responseData?.dataJ);
                    //console.log("Rack")
                    // selTeamInfo.current = responseData?.dataJ[0];
                  } else {
                    setKYCListError(responseData?.info || 'Data Not Found');
                  }
                } else {
                    setKYCListError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                }
              } else {
                setKYCListError(responseData?.info || 'Failed to fetch Team Information.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setKYCListError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setKYCListError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setKYCListError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setKYCListError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setKYCListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setKYCListError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setKYCListError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
          } finally {
            setKycListLoading(false);
          }
      }

      const fetchKYCDocumentsRefresh =()=>{
        //console.log('call the refresh')
        fetchDocumentsList();
      }

      return{
        fetchKYCDocumentsRefresh,
        kycDocumentList,
        kycListError,
        kycListLoading,
        setKycDocumentList,
        setKYCListError,
        setKycListLoading
      }

}

export default DocumentRackController