import React, { useState, useRef, useEffect } from 'react';
import InwardOutwardListController from '../../../Controllers/AdminScreens/DocumentRack/InwardOutwardListController';
import editImg from '../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../Assets/Icons/delete.png';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../Assets/Icons/filter.png';
import Dropdown from '../../../../Components/Dropdown';
import CreateEntryModal from '../../../../Components/Modals/CreateEntryModal';
import { useNavigate } from 'react-router-dom';
import TextInput from '../../../../Components/TextInput';
import search from '../../../../Assets/Icons/search.png'
import DateInput from '../../../../Components/DateInput';
import Loader from '../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';
import leftBtuIcon from '../../../../Assets/Icons/back.png';
import filterIcon from '../../../../Assets/Icons/filter.png'
import Input from '../../../../Components/Input';

const InwardOutwardListScreen = ({ kycListLoading, kycListError, kycDocumentList, onRefresh, setKycListLoading, setKYCListError, setKycDocumentList }) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    // const [searchUser, setSearchUser] = useState('');

    const handleNav = (pageName, task) => {
        //console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } }); // Pass task in state
    };
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails,
        setSelectedDocument,
        selectedDocument,
        handleDocumentDelete,
        loading,
        success,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        currentDocDeleteIndex,
        downloadDocuments,
        downloadKycLoading,
        kycDownloadError,
        currentDocIndex,
        currentTasks,

        handleSearchEmployee,
        handleSelectedEmployee,
        clientLoading,
        employeeList,
        clientError,

        listOfEmployees,
        filterError,
        empLoading,


        deliveryType,
        source,
        documentNumber,
        selectedEmployee,
        deliveryTypeOptions,
        setDeliveryType,
        sourceOptions,
        setSource,
        setDOcumentNumber,
        setSelectedEmployee,
        handleEndtDateChange,
        handleStartDateChange,
        setEndDate,
        endDate,
        setStartDate,
        startDate,
        setFileNumber,
        flineNumber,
        fileError,
        fileLoading,
        handleSubmitClientSearch,
        fileEmployeeList,
        handleSelectedFileEmployee,
        searchUser,
        setSearchUser,
        activeDropdown,
        setClientError,
        setFileError,
        setReceived,
        receivedBy,
        setFilterError,
        docInfoRef,
        handleSearchInputText,docFiltterError,setDocFiltterError
    } = InwardOutwardListController(onRefresh, kycDocumentList, setKycListLoading, setKYCListError, setKycDocumentList);
    // //console.log('active dropdown', activeDropdown);


    const [showFilters, setShowFilters] = useState(false); // Control the visibility of filters
    const [filters, setFilters] = useState({
        taskType: '',
        financialYear: '',
        period: '',
        status: '',
    });
    // const [startDate, setStartDate] = useState("");
    // const [endDate, setEndDate] = useState("");

    // const handleStartDateChange = (value) => {
    //     setStartDate(value);
    // };

    // const handleEndtDateChange = (value) => {
    //     setEndDate(value);
    // };

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        //console.log('Applying filters:', filters);
        setShowFilters(false);
    };

    const resetFilters = () => {
        setSearchUser('')
        setFileNumber('');
        setDOcumentNumber('')
        setSelectedEmployee(null);
        setSource();
        setDeliveryType()
        setStartDate('');
        setEndDate('')
        setClientError('')
        setFileError('')
        setReceived('')
        setDocFiltterError('');
        // setFilterError('')
    };

    const toggleFilters = () => {
        onRefresh();
        setShowFilters(!showFilters);
        resetFilters()
    };
    const showFilter = () => {
        setShowFilters(!showFilters);
    }

    const [modalMode, setModalMode] = useState("create");

    const createEntryModalVisible = () => {
        setSelectedDocument(null);
        setModalVisible(true)
        setModalMode('create')
    }

    const handleCloseModal = () => {
        // setSelectedDocument(null);
        setModalVisible(false);
    };

    const handleEditDocument = (doc) => {
        setSelectedDocument(doc);
        setModalVisible(true);
        setModalMode('edit')
    };

    //   const[documentNumber, setDOcumentNumber] = useState('');
    //   const sourceOptions = [
    //     { label: "Inward (Sent by Client)", value: 1 },
    //     { label: "Outward (Sent by Employee)", value: 2 },
    //   ];
    //   const deliveryTypeOptions = [
    //     { label: "Email", value: "EMAIL" },
    //     { label: "Whatsapp", value: "WATSAPP" },
    //     { label: "Physical", value: "PHYSICAL" },
    //     { label: "Others", value: "OTHERS" },
    //     ];
    //   const [source, setSource] = useState(sourceOptions[0]?.value || '');
    //   const [deliveryType, setDeliveryType] = useState(deliveryTypeOptions[0]?.value || '');
    //   const [selectedEmployee, setSelectedEmployee] = useState(null)

    const formatDate = (dateString) => {
        if (!dateString) return '--';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const maxVisiblePages = 3;

    const getVisiblePages = () => {
        const startPage = Math.max(1, currentPage - Math.floor(maxVisiblePages / 2));
        const endPage = Math.min(totalPages, startPage + maxVisiblePages - 1);

        // Adjust startPage if we're near the end
        const adjustedStartPage = Math.max(1, endPage - maxVisiblePages + 1);

        return Array.from({ length: endPage - adjustedStartPage + 1 }, (_, index) => adjustedStartPage + index);
    };

    const visiblePages = getVisiblePages();


    return (
        <>
            <div className="tasksScreen-tasks-header" style={{ marginBottom: '15px' }}>
                <h2 className="tasksScreen-tasks-title" style={{ fontSize: '19px' }}>Inward/Outward</h2>
                <button className="tasksScreen-apply-button" style={{ paddingTop: '10px', paddingBottom: '10px' }} onClick={createEntryModalVisible}>Create Entry</button>
            </div>

            {/* Filters Row */}
            <div style={styles.container}>
                <div style={styles.filtersHeader}>
                    <button style={styles.filterButton} onClick={showFilter}> <img src={filterIcon} alt="Filter" style={{ marginRight: '8px' }} />  <span>
                        {showFilters ? 'Filters' : 'Filters'}
                    </span></button>
                    <div >
                        <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
                        <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
                    </div>
                </div>
            </div>

            {showFilters && (
                <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}
                // style={{position:'absolute', zIndex:'10', width:'98%'}}
                >
                    <div className="tasksScreen-filters" style={{ display: 'flex', flexDirection: 'row' }}>
                        {/* Replace each filter select with Dropdown component */}
                        <div className="filter-field" >
                            <label
                                style={{
                                    ...styles.labaleStyle,
                                    // marginBottom: '8px',
                                    display: 'block',
                                }}
                            >
                                Client Name
                            </label>
                            <TextInput
                                type="text"
                                placeholder="Client Name"
                                value={searchUser}
                                onChange={(e) => handleSearchInputText(e.target.value, 'GENERIC')}
                                onRightClick={(e) => handleSearchEmployee("GENERIC")}
                                width={240}
                                right={
                                    clientLoading ? (
                                        <Loader loading={clientLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                    ) : (
                                        <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                    )
                                }
                                style={{ padding: '20px 10px' }}
                                searchBoxWidth={240}
                                errorMessage={clientError}
                                disabled={clientLoading}
                            />
                            {activeDropdown === 'GENERIC' && employeeList?.length > 0 && (
                                <ul style={styles.dropdownList}>
                                    {employeeList?.map((employee, index) => (
                                        <li
                                            key={employee?.emp_id}
                                            onClick={() => handleSelectedEmployee(employee)}
                                            style={{
                                                ...styles.dropdownItem,
                                                borderBottom: index !== employeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                            }}
                                        >
                                            {employee?.client_name}
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>
                        <div className="filter-field" >
                            <label
                                style={{
                                    ...styles.labaleStyle,
                                    // marginBottom: '8px',
                                    display: 'block',
                                }}
                            >
                                File Number
                            </label>
                            <TextInput
                                type="text"
                                placeholder="File Number"
                                value={flineNumber}
                                onChange={(e) => handleSearchInputText(e.target.value, 'FILEID')}
                                onRightClick={(e) => handleSearchEmployee("FILEID")}
                                width={240}
                                right={
                                    fileLoading ? (
                                        <Loader loading={fileLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                    ) : (
                                        <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                    )
                                }
                                // right={<img src={search} alt="Search" style={{ cursor: 'pointer' }} />}
                                style={{ padding: '20px 10px' }}
                                searchBoxWidth={240}
                                errorMessage={fileError}
                                disabled={fileLoading}
                            />
                            {activeDropdown === 'FILEID' && fileEmployeeList?.length > 0 && (
                                <ul style={styles.dropdownList}>
                                    {fileEmployeeList?.map((employee, index) => (
                                        <li
                                            key={employee?.emp_id}
                                            onClick={() => handleSelectedFileEmployee(employee)}
                                            style={{
                                                ...styles.dropdownItem,
                                                borderBottom: index !== fileEmployeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                            }}
                                        >
                                            {employee?.client_file_id}
                                        </li>
                                    ))}
                                </ul>
                            )}

                        </div>


                        <Input
                            type="text"
                            placeholder="Document Number"
                            label="Document Number"
                            value={documentNumber}
                            onChange={(e) => setDOcumentNumber(e.target.value)}
                            // onRightClick={handleSearchEmployee}
                            width={225}
                            // right={
                            //     loading ? (
                            //       <Loader loading={loading} color={'var(--primary-color)'} size='small'/> // Show loader when loading
                            //     ) : (
                            //       <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                            //     )
                            //   }
                            // right={<img src={search} alt="Search" style={{ cursor: 'pointer' }} />}
                            // style={{ padding: '20px 10px' }}
                            searchBoxWidth={223}
                        // errorMessage={error || clientSearchError}
                        />
                        {/* {employeeList.length > 0 && (
                            <ul style={styles.dropdownList}>
                                {employeeList.map((employee, index) => (
                                <li
                                    key={employee?.emp_id}
                                    onClick={() => handleSelectedEmployee(employee)}
                                    style={{
                                    ...styles.dropdownItem,
                                    borderBottom: index !== employeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                    }}
                                >
                                    {employee?.client_name}
                                </li>
                                ))}
                            </ul>
                            )} */}
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Received By"] = el)}
                            isOpen={openDropdown === "Received By"}
                            toggleDropdown={() => toggleDropdown("Received By")}
                            label="Received By"
                            options={listOfEmployees || []}
                            value={receivedBy}
                            onChange={setReceived}
                            errorMessage={filterError}
                            loading={empLoading}
                            searchBoxWidth={220}
                        />

                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Source"] = el)}
                            isOpen={openDropdown === "Source"}
                            toggleDropdown={() => toggleDropdown("Source")}
                            label="Source"
                            options={sourceOptions}
                            value={sourceOptions.find((option) => option.value === source)}
                            onChange={(selectedOption) => setSource(selectedOption.value)}
                            width={220}
                            searchBoxWidth={220}
                        // errorMessage={sourceError}
                        />
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Delivery Type"] = el)}
                            isOpen={openDropdown === "Delivery Type"}
                            toggleDropdown={() => toggleDropdown("Delivery Type")}
                            label="Delivery Type"
                            options={deliveryTypeOptions}
                            value={deliveryTypeOptions.find((option) => option.value === deliveryType)}
                            onChange={(selectedOption) => setDeliveryType(selectedOption.value)}
                            width={220}
                            searchBoxWidth={220}
                        // errorMessage={deliveryTypeError}
                        />

                        <div >
                            <div className="filter-field">
                                <label style={{ ...styles.labaleStyle, display: "block" }}>Period</label>
                                <DateInput
                                    value={startDate}
                                    onChange={(e) => handleStartDateChange(e.target.value)}
                                    placeholder='From'
                                    width={150}
                                />
                            </div>
                            <span style={{ margin: '0 10px' }}>-</span>
                            <div className="filter-field">
                                {/* <label>Period</label> */}
                                <DateInput
                                    value={endDate || ""}
                                    onChange={(e) => handleEndtDateChange(e.target.value)}
                                    placeholder='To'
                                    width={150}
                                />
                            </div>
                        </div>
                    </div>
                    <div>
                    {docFiltterError && <div className="list-error-msg" style={{textAlign:'left'}}>{docFiltterError} </div>}
                    <div className="tasksScreen-filter-buttons">
                        <button onClick={handleSubmitClientSearch} className="tasksScreen-apply-button"
                            style={{
                                cursor: kycListLoading ? "not-allowed" : "pointer",
                                opacity: kycListLoading ? 0.6 : 1,
                            }} disabled={kycListLoading}>Apply</button>
                        <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                        <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                    </div>
                    </div>
                </div>
            )}

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th style={{ width: "20%", }}>Document Name</th>
                            <th style={{ width: "13%", }}>Source</th>
                            <th style={{ width: "10%", }}>Delivery Type</th>
                            <th style={{ width: "10%", }}>Client Name</th>
                            <th style={{ width: "10%", }}>Document No.</th>
                            <th style={{ width: "10%", }}>Document Type</th>
                            <th style={{ width: "10%", }}>Created By</th>
                            <th style={{ width: "10%", }}>Date</th>
                            <th style={{ width: "7%", }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {currentTasks?.map((doc, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${doc.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td style={{ color: '#5A55D2', textDecoration: doc.doc_name ? 'underline' : 'none', cursor: 'pointer', maxWidth: 'none', cursor: downloadKycLoading && currentDocIndex === index ? 'not-allowed' : 'pointer', opacity: downloadKycLoading && currentDocIndex === index ? 0.6 : 1, }} onClick={() => !downloadKycLoading && downloadDocuments(doc.doc_id, index, doc.client_id)}>
                                        <span style={{ display: 'flex', alignItems: 'center', gap: '8px', }}> {doc.doc_name || '--'}
                                            {downloadKycLoading && currentDocIndex === index && (
                                                <span style={{ marginLeft: '5px' }}>
                                                    <Loader loading={downloadKycLoading} color={'var(--primary-color)'} size="small" />
                                                </span>
                                            )}</span>
                                    </td>
                                    <td>{doc?.source === 1 ? "Sent by Client" : doc?.source === 2 ? "Sent by Employee" : "--"}</td>
                                    <td>{doc?.delivery_type}</td>
                                    <td>{doc?.client_name}</td>
                                    <td>{doc?.doc_number}</td>
                                    <td style={{ color: doc?.doc_type === 1 ? "red" : "inherit", }}>{doc.doc_type === 1 ? "Internal" : doc.doc_type === 2 ? "Client Documents" : "Unknown"}</td>
                                    <td>{doc.created_by}</td>
                                    <td>{formatDate(doc.doc_upload_date)}
                                        {success[doc?.doc_id] &&
                                            <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{success[doc?.doc_id]}</div>}
                                        {kycDownloadError[doc?.doc_id] &&
                                            <div style={{ color: 'red', paddingTop: 10 }} >{kycDownloadError[doc?.doc_id]}</div>}
                                    </td>
                                    <td>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "flex-end", }}>
                                            <button className="tasksScreen-edit-button">
                                                <img src={editImg} alt="Edit" onClick={() => handleEditDocument(doc)} />
                                            </button>
                                            <button className="tasksScreen-delete-button">
                                                <img src={deleteImg} alt="Delete" onClick={() => { handleDelete(doc) }} />
                                            </button>
                                            {loading[doc?.doc_id] && (
                                                <span style={{ marginLeft: '5px' }}>
                                                    <Loader loading={loading} color={'var(--primary-color)'} size="small" />
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                                {doc.showDetails && (
                                    <tr className="tasksScreen-additional-info-row">
                                        <td colSpan="10">
                                            <div className="tasksScreen-additional-info">
                                                <div>
                                                    <span>File No.</span>
                                                    <p>{doc.fileNumber}</p>
                                                </div>
                                                <div>
                                                    <span>Period</span>
                                                    <p>{doc.period}</p>
                                                </div>
                                                <div>
                                                    <span>Task Leader</span>
                                                    <p>{doc.taskLeader}</p>
                                                </div>
                                                <div>
                                                    <span>Completed Check</span>
                                                    <p>{doc.completedCheck}</p>
                                                </div>
                                                <div>
                                                    <span>Completed By</span>
                                                    <p>{doc.completedBy || '--'}</p>
                                                </div>
                                                <div>
                                                    <span>Description</span>
                                                    <p>{doc.description || '--'}</p>
                                                </div>
                                                <div>
                                                    <span>Pending Issues</span>
                                                    <p>{doc.pendingIssues || '--'}</p>
                                                </div>
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {kycListError && <div className="list-error-msg">{kycListError} </div>}
                {/* {kycDownloadError && <div className="list-error-msg">{kycDownloadError} </div>} */}
                {kycListLoading && <Loader loading={kycListLoading} color={'var(--primary-color)'} />}
                {/* {success && <div className="list-success-msg">{success} </div>} */}
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || totalPages === 0}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {/* {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))} */}
                    {/* Page Numbers */}
                    {visiblePages.map((page) => (
                        <button
                            key={page}
                            className={`tasksScreen-pagination-button ${currentPage === page ? "active" : ""}`}
                            onClick={() => handlePageChange(page)}
                        >
                            {page}
                        </button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <CreateEntryModal isOpen={modalVisible} onClose={handleCloseModal} onValueChange={onRefresh} documentData={selectedDocument} setSelectedDocument={setSelectedDocument} modalMode={modalMode} />
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    message={`Are you sure you want to delete ${docInfoRef.current?.doc_name}?`}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => {
                        handleCloseDeleteModal(); // Close modal
                        handleDocumentDelete(docInfoRef.current); // Perform delete operation
                    }}
                />
            </div>
            {/* </div> */}
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    },
    container: {
        padding: "5px",
        backgroundColor: "#f8f9fa",
        border: "none",
        // borderRadius: "5px",
        border: '1px #EFF0F4 solid'
    },
    filtersHeader: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        // marginBottom: "15px",
    },
    filterButton: {
        backgroundColor: "white",
        color: "#40513B",
        border: "none",
        borderRadius: "5px",
        padding: "7px 13px",
        cursor: "pointer",
        borderRadius: '20px',
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        border: '1px #40513B solid',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center'

    },
    filterIcon: {
        width: '10px',
        height: '10px',
        paddingRight: '5px',
    },
    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },
    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
};

export default InwardOutwardListScreen;
