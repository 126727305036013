import { useState, useRef, useEffect } from "react";

const UserSpecializationController = (userInfo, userSpecializationList,userSpecializationLoading,userSpecializationError) => {

    const [selUserSpecializationService, setSelUserSpecializationService] = useState(null);
    const [isUserSpecializationModalOpen, setIsUserSpecializationModalOpen] = useState(false);

    const handleCloseUserSpecializationModal = () => {
        setIsUserSpecializationModalOpen(false);
        setSelUserSpecializationService(null); 
      };

    const handleEditSpecialization = (service) => {
        setSelUserSpecializationService(service); 
        setIsUserSpecializationModalOpen(true);
    }

    const handleToggleChange = (newState) => {
        //console.log('Toggle is now:', newState);
    }

    const getActiveSubServiceNames = (list) => {
        const activeSubServiceNames = list
          ?.filter((subservice) => subservice.is_active === 1)
          ?.map((subservice) => subservice.service_sub_name);
      
        return activeSubServiceNames?.length > 0 ? activeSubServiceNames.join(", ") : "--";
    };


    return {
        handleEditSpecialization,
        handleToggleChange,
        selUserSpecializationService,
        isUserSpecializationModalOpen,
        handleCloseUserSpecializationModal,
        getActiveSubServiceNames
    };

}

export default UserSpecializationController;