import { useState, useRef, useEffect } from "react";
import { ORG_NATURE_OF_CALLS_LIST, ORG_NATURE_OF_CALLS_DELETE} from '../../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../../ApiServices/ApiService";

const NatureCallTypeListController = (onRefresh,callsList) => {
  const [tasks, setTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;
  const[loading,setLoading]= useState(false);
  const[error,setError] = useState('');
  // const[callsList,setCallsList] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const[natureOfCallsDeleteError, setNatureOfCallsDeleteError] = useState('')
  const [success, setSuccess]= useState('');
  const[natureOfCallsDeleteLoading, setNatureOfCallsDeleteLoading] = useState('')
  const [currentCallDeleteIndex, setCurrentCallDeleteIndex] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    // Simulated data
    const data = [
        {
          clientName: "Follow up",
          description: "--",
          assignedTo: "John Doe",
          status: "Active",
        },
        {
          clientName: "Schedule Appointment",
          description: "--",
          assignedTo: "John Doe",
          status: "Incomplete",
        },
        {
          clientName: "Call Back",
          description: "--",
          assignedTo: "John Doe",
          status: "Disabled",
        },
        {
          clientName: "More Info",
          description: "--",
          assignedTo: "John Doe",
          status: "Disabled",
        },
        {
          clientName: "Company Name/Group Name",
          description: "--",
          assignedTo: "John Doe",
          status: "Disabled",
        },
        {
          clientName: "Follow up",
          description: "Follow-up for client queries.",
          assignedTo: "Jane Smith",
          status: "Active",
        },
        {
          clientName: "Feedback Collection",
          description: "Collecting feedback after task completion.",
          assignedTo: "Alice Brown",
          status: "Incomplete",
        },
        {
          clientName: "Move Info",
          description: "Additional client information update.",
          assignedTo: "Michael Scott",
          status: "Disabled",
        },
        {
          clientName: "Reminder Call",
          description: "Task reminder call for the client.",
          assignedTo: "Dwight Schrute",
          status: "Active",
        },
        {
          clientName: "Support Call",
          description: "Handling client support-related calls.",
          assignedTo: "Jim Halpert",
          status: "Incomplete",
        },
        {
          clientName: "Follow Up Call",
          description: "Ongoing project follow-ups.",
          assignedTo: "Pam Beesly",
          status: "Disabled",
        },
        {
          clientName: "Schedule Next Meeting",
          description: "Planning next meeting schedules.",
          assignedTo: "Angela Martin",
          status: "Active",
        },
        {
          clientName: "Update Profile",
          description: "Client's profile update and verification.",
          assignedTo: "Oscar Martinez",
          status: "Incomplete",
        },
        {
          clientName: "Document Collection",
          description: "Gathering required documents.",
          assignedTo: "Kevin Malone",
          status: "Disabled",
        },
        {
          clientName: "Schedule Review",
          description: "Reviewing client’s submitted details.",
          assignedTo: "Stanley Hudson",
          status: "Active",
        },
        {
          clientName: "Payment Reminder",
          description: "Reminding clients about due payments.",
          assignedTo: "Phyllis Vance",
          status: "Incomplete",
        },
        {
          clientName: "Follow-Up Visit",
          description: "Scheduling follow-up visits for the client.",
          assignedTo: "Creed Bratton",
          status: "Disabled",
        },
        {
          clientName: "Client Onboarding",
          description: "Starting the onboarding process.",
          assignedTo: "Toby Flenderson",
          status: "Active",
        },
        {
          clientName: "General Inquiry",
          description: "Handling general client inquiries.",
          assignedTo: "Kelly Kapoor",
          status: "Incomplete",
        },
        {
          clientName: "Technical Support",
          description: "Resolving client technical issues.",
          assignedTo: "Ryan Howard",
          status: "Disabled",
        },
      ];      
    setTasks(data);
  }, []);


//   useEffect(()=>{
//     fetchNatureOfCallsList();
// },[])

// const fetchNatureOfCallsList =async ()=>{
//     try {
//       setLoading(true);
//         setError('');
//         // setTeamInfo({});
//         const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
//         // Make the API call
//         const response = await getData(`${ORG_NATURE_OF_CALLS_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
//         const responseData = await response.json();
    
//         // Check for success (200) and internal status code
//         if (response?.status === 200) {
//           if (responseData?.statusCode === 200) {
//             if (responseData?.message.toLowerCase() === 'success') {
//               if (responseData?.dataJ?.length > 0) {
//                 const records = responseData?.dataJ || [];
//                 setCallsList(records)
//               } else {
//                 setError(responseData?.info || 'Data Not Found');
//               }
//             } else {
//               setError(responseData?.info || 'Failed to fetch nature of calls. Please try again.');
//             }
//           } else {
//             setError(responseData?.info || 'Failed to fetch nature of calls.');
//           }
//         } 
//         // Token-related errors: 400, 401, 403
//         else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
//           if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
//             setError('Unauthorized access. Your session may have expired. Please log in again.');
//             // Optionally, redirect to the login page or refresh the token
//             // Example: redirectToLogin();
//           } else {
//             setError(responseData?.info || 'Bad request. Please check the request parameters.');
//           }
//         } 
//         // Internal Server Error: 500
//         else if (response?.status === 500) {
//           setError(responseData?.info || 'Internal Server Error. Please try again later.');
//         } 
//         // Unexpected or network-related errors
//         else {
//           setError('Unexpected error occurred. Please try again later.');
//         }
//       } catch (error) {
//         // Handle various error scenarios
//         if (error.response) {
//           // Server responded with a status outside the 2xx range
//           setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch nature of calls. Please try again.'}`);
//         } else if (error.request) {
//           // Request was made but no response was received
//           setError('No response received from the server. Please check your network connection and try again.');
//         } else {
//           // An error occurred in setting up the request
//           setError(`Failed to fetch nature of calls. Please check your network connection and try again.`);
//         }
//       } finally {
//         setLoading(false);
//       }
//   }

  const totalPages = Math.ceil(callsList.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = callsList.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
      setSelectedDocument(null);
      setNatureOfCallsDeleteError("")
    }
  };

  const handleDelete = (call, index) => {
    setSelectedDocument({ call, index, page: currentPage }); // Store the credential to delete
    setIsDeleteModalOpen(true); // Open the confirmation modal
};

const handleOpenDeleteModal = () => {
  setIsDeleteModalOpen(true); // Open the modal
};

const handleCloseDeleteModal = () => {
  setIsDeleteModalOpen(false); // Close the modal
};

const [deletedRows, setDeletedRows] = useState([]);
  const handleNatureOfCallsDelete = async (docId) => {
   
    try {
      const { call, index } = selectedDocument;
      //console.log("index", index)
      setNatureOfCallsDeleteError('');  
        setSuccess('')
        // if (!searchUser) {
        //   setError('Please enter valid text'); return;
        // }         
        setNatureOfCallsDeleteLoading(true);
        setCurrentCallDeleteIndex(index)
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, sc_type_id:call.sc_type_id}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_NATURE_OF_CALLS_DELETE,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              setDeletedRows(prev => [...prev, call?.sc_type_id]); 
                // setKYCDownloadError('');
                setSuccess(responseData?.info);
                setTimeout(() => {
                  setSuccess('');
                  onRefresh();
                }, 3000);
                // setEmployeeList(responseData?.dataJ || []);
            } else {
              setNatureOfCallsDeleteError(responseData?.info || 'Failed to delete kyc document. Please try again.');
            }
          } else {
            setNatureOfCallsDeleteError(responseData?.info || 'Failed to delete kyc document.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setNatureOfCallsDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setNatureOfCallsDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setNatureOfCallsDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setNatureOfCallsDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setNatureOfCallsDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete kyc document. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setNatureOfCallsDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setNatureOfCallsDeleteError(`Failed to delete kyc document. Please check your network connection and try again.`);
        }
      } finally {
        setNatureOfCallsDeleteLoading(false);
        // setCurrentCallDeleteIndex(null)
      }

    // onValueChange(teamData);
    // onClose();
};

  return {
    currentTasks,
    currentPage,
    totalPages,
    handlePageChange,
    loading,
    error,
    callsList,
    // fetchNatureOfCallsList,
    selectedDocument,
    setSelectedDocument,
    handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        natureOfCallsDeleteError,
        natureOfCallsDeleteLoading,
        currentCallDeleteIndex,
        success,
        handleNatureOfCallsDelete,
        deletedRows
  };
};

export default NatureCallTypeListController;
