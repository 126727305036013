import { useState, useEffect,useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { getData,postData } from "../../../../ApiServices/ApiService";
import { ORG_GET_EMPLOYEE_INFO, ORG_EMP_SPECIALIZATION_LIST,ORG_EMP_TOGGLE_ON_OF,ORG_EMP_WORK_STATUS, ORG_EMP_ACCESS_PERMISSION_LIST,ORG_EMP_ASSIGNMENTS } from "../../../../ApiServices/BaseURL";

const ManageUserController = () => {

    const navigate = useNavigate();
    const location = useLocation();
    // const { userInfo } = location.state || {};
    const [userData,setUserData] = useState({})
    const [userToggleState, setUserToggleState] = useState(userData?.emp_status === 1 ? true : false);

    const [loading, setLoading] = useState(false);
    const [error, setError] = useState('');
    const [userSpecializationList, setUserSpecialaizationList] = useState([]);
    const [userSpecializationLoading, setUserSpecialaizationLoading] = useState(false);
    const [userSpecializationError, setUserSpecialaizationError] = useState('');
    const [userAccessPermissionList, setUserAccessPermissionList] = useState([]);
    const [userAccessPermissionLoading, setUserAccessPermissionLoading] = useState(false);
    const [userAccessPermissionError, setUserAccessPermissionError] = useState('');

    useEffect(() => {
        // if (!location.state?.isUserEditable) {
        //         fetchUserInfo(location.state?.userInfo?.emp_id)
        // } else {

        // }
        if (location.state?.userInfo?.emp_id) {
          fetchUserInfo(location.state?.userInfo?.emp_id);
          fetchUserSpecializationList(location.state?.userInfo?.emp_id);
          fetchUserAccessPermissionList(location.state?.userInfo?.emp_id);
          fetchAssignmentsList(location.state?.userInfo?.emp_id)
        }
    }, [location.state?.userInfo])
    
    useEffect(() => {
      setUserToggleState(userData?.emp_status === 1);
    }, [userData]);

    const fetchUserInfo = async (userId) => {
        try {
            setLoading(true);
            setError('');
            setUserData({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_EMPLOYEE_INFO}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${userId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setUserData(responseData?.dataJ[0]);
                            // selGroupInfo.current = responseData?.dataJ[0]
                        } else {
                            setError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setLoading(false);
        }
    }

    const fetchUserSpecializationList = async (empId) => {
        try {
            setUserSpecialaizationLoading(true);
            setUserSpecialaizationError('');
            setUserSpecialaizationList([]);

            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            const response = await getData(`${ORG_EMP_SPECIALIZATION_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${empId}`);
            const responseData = await response.json();

            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setUserSpecialaizationList(responseData?.dataJ);
                  } else {
                    setUserSpecialaizationError(responseData?.info || 'No specializations available.');
                  }
                } else {
                    setUserSpecialaizationError(responseData?.info || 'Failed to fetch specialization list. Please try again.');
                }
              } else {
                setUserSpecialaizationError(responseData?.info || 'Failed to fetch specialization list.');
              }
            } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setUserSpecialaizationError('Unauthorized access. Your session may have expired. Please log in again.');
              } else {
                setUserSpecialaizationError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } else if (response?.status === 500) {
                setUserSpecialaizationError(responseData?.info || 'Internal Server Error. Please try again later.');
            } else {
                setUserSpecialaizationError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            if (error.response) {
              setUserSpecialaizationError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch specialization list. Please try again.'}`);
            } else if (error.request) {
              setUserSpecialaizationError('No response received from the server. Please check your network connection and try again.');
            } else {
              setUserSpecialaizationError(`Failed to fetch specialization list. Please check your network connection and try again.`);
            }
          } finally {
            setUserSpecialaizationLoading(false);
          }
    }

    const fetchUserAccessPermissionList = async (empId) => {
      try {
          setUserAccessPermissionLoading(true);
          setUserAccessPermissionError('');
          setUserAccessPermissionList([]);

          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

          const response = await getData(`${ORG_EMP_ACCESS_PERMISSION_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${empId}`);
          const responseData = await response.json();

          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                if (responseData?.dataJ?.length > 0) {
                  setUserAccessPermissionList(responseData?.dataJ);
                } else {
                  setUserAccessPermissionError(responseData?.info || 'No specializations available.');
                }
              } else {
                setUserAccessPermissionError(responseData?.info || 'Failed to fetch user access list. Please try again.');
              }
            } else {
              setUserAccessPermissionError(responseData?.info || 'Failed to fetch user access list.');
            }
          } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setUserAccessPermissionError('Unauthorized access. Your session may have expired. Please log in again.');
            } else {
              setUserAccessPermissionError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          } else if (response?.status === 500) {
            setUserAccessPermissionError(responseData?.info || 'Internal Server Error. Please try again later.');
          } else {
            setUserAccessPermissionError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          if (error.response) {
            setUserAccessPermissionError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch user access list. Please try again.'}`);
          } else if (error.request) {
            setUserAccessPermissionError('No response received from the server. Please check your network connection and try again.');
          } else {
            setUserAccessPermissionError(`Failed to fetch user access list. Please check your network connection and try again.`);
          }
        } finally {
          setUserAccessPermissionLoading(false);
        }
  }

    const handleBack = () => {
        //console.log('handleBack')
        navigate('/teams-userlist');
    }

    const handleUserToggleChange1 = (newState) => {

    }

    const refreshUserSpecializationList = () => {
        fetchUserSpecializationList(location.state?.userInfo?.emp_id);
    }

    const refreshUserAccessPermissionList = () => {
      fetchUserAccessPermissionList(location.state?.userInfo?.emp_id);
  }

    const[userToggleError, setUserToggleError] = useState('');
    const[userToggleLoading, setUserToggleLoading]= useState(false);
    const[userToggleSuccess, setUserToggleSuccess] = useState('')

    const onToggle = async () => {
      const previousState = userToggleState; // Save the previous state
      setUserToggleState((prevState) => !prevState); // Optimistically update the state
    
      const isSuccessful = await handleUserToggleChange();
      if (!isSuccessful) {
        setUserToggleState(previousState); // Revert the state if the service fails
      }
    };
    const handleUserToggleChange = async () => {
   
      try {
       
        setUserToggleError('');  
        setUserToggleSuccess('')
          // if (!searchUser) {
          //   setError('Please enter valid text'); return;
          // }         
          setUserToggleLoading(true);
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
          const empStatus = userToggleState ? 0 : 1;
          const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, emp_id:userData?.emp_id, emp_status:empStatus}
      
          //console.log('handleSearchEmployee payload',payLoad);
          // Make the API call
          const response = await postData(ORG_EMP_TOGGLE_ON_OF,payLoad);
          const responseData = await response.json();
          //console.log('response Data',responseData);
      
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                setUserToggleError('');
                  setUserToggleSuccess(responseData?.info);
                  setTimeout(()=>{
                    setUserToggleSuccess('');
                  },3000);
                  return true;
                  // setEmployeeList(responseData?.dataJ || []);
                // onRefresh()
              } else {
                setUserToggleError(responseData?.info || 'Failed to delete kyc document. Please try again.');
                return false;
              }
            } else {
              setUserToggleError(responseData?.info || 'Failed to delete kyc document.');
              return false;
            }
          } 
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setUserToggleError('Unauthorized access. Your session may have expired. Please log in again.');
              return false;
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setUserToggleError(responseData?.info || 'Bad request. Please check the request parameters.');
              return false;
            }
          } 
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setUserToggleError(responseData?.info || 'Internal Server Error. Please try again later.');
            return false;
          } 
          // Unexpected or network-related errors
          else {
            setUserToggleError('Unexpected error occurred. Please try again later.');
            return false;
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setUserToggleError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete kyc document. Please try again.'}`);
            return false;
          } else if (error.request) {
            // Request was made but no response was received
            setUserToggleError('No response received from the server. Please check your network connection and try again.');
            return false;
          } else {
            // An error occurred in setting up the request
            setUserToggleError(`Failed to delete kyc document. Please check your network connection and try again.`);
            return false;
          }
        } finally {
          setUserToggleLoading(false);
        }
  
      // onValueChange(teamData);
      // onClose();
  };

  const [taskAssignError, setTaskAssignError] = useState('');
  const [taskAssignLoading, setTaskAssignLoading] = useState(false);
  const [taskAssignSuccess, setTaskAssignSuccess] = useState('')
  const handleTaskAssignChange = async () => {
   
    try {
     
      setTaskAssignError('');  
      setTaskAssignSuccess('')
        // if (!searchUser) {
        //   setError('Please enter valid text'); return;
        // }         
        setTaskAssignLoading(true);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const empStatus = userData?.emp_task_assign_flag === 1 ? 0 : 1;
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, emp_id:userData?.emp_id, emp_task_assign_flag:empStatus}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_EMP_WORK_STATUS,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              setTaskAssignError('');
                setTaskAssignSuccess(responseData?.info);
                return true;
                // setEmployeeList(responseData?.dataJ || []);
              // onRefresh()
            } else {
              setTaskAssignError(responseData?.info || 'Failed to delete kyc document. Please try again.');
              return false;
            }
          } else {
            setTaskAssignError(responseData?.info || 'Failed to delete kyc document.');
            return false;
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setTaskAssignError('Unauthorized access. Your session may have expired. Please log in again.');
            return false;
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setTaskAssignError(responseData?.info || 'Bad request. Please check the request parameters.');
            return false;
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setTaskAssignError(responseData?.info || 'Internal Server Error. Please try again later.');
          return false;
        } 
        // Unexpected or network-related errors
        else {
          setTaskAssignError('Unexpected error occurred. Please try again later.');
          return false;
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setTaskAssignError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete kyc document. Please try again.'}`);
          return false;
        } else if (error.request) {
          // Request was made but no response was received
          setTaskAssignError('No response received from the server. Please check your network connection and try again.');
          return false;
        } else {
          // An error occurred in setting up the request
          setTaskAssignError(`Failed to delete kyc document. Please check your network connection and try again.`);
          return false;
        }
      } finally {
        setTaskAssignLoading(false);
      }

    // onValueChange(teamData);
    // onClose();
};

const[assignmentsLoading, setAssignmentsLoading] = useState(false);
const [assignmentError, setAssignmentError] = useState('');
const [assignmentList, setAssignmentList] = useState([]);

const fetchAssignmentsList = async (userId) => {
  try {
    setAssignmentsLoading(true);
    setAssignmentError('');

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_EMP_ASSIGNMENTS}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${userId}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setAssignmentList(responseData?.dataJ);
                      // selGroupInfo.current = responseData?.dataJ[0]
                  } else {
                    setAssignmentError(responseData?.info || 'Data Not Found');
                  }
              } else {
                setAssignmentError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
              }
          } else {
            setAssignmentError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setAssignmentError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
          } else {
            setAssignmentError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setAssignmentError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setAssignmentError('Unexpected error occurred. Please try again later.');
      }
  } catch (error) {
      // Handle various error scenarios
      if (error.response) {
          // Server responded with a status outside the 2xx range
          setAssignmentError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Group Data. Please try again.'}`);
      } else if (error.request) {
          // Request was made but no response was received
          setAssignmentError('No response received from the server. Please check your network connection and try again.');
      } else {
          // An error occurred in setting up the request
          setAssignmentError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
  } finally {
    setAssignmentsLoading(false);
  }
}

    return {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        loading,
        error,
        userData,
        userSpecializationList,
        userSpecializationLoading,
        userSpecializationError,
        refreshUserSpecializationList,
        onToggle,
        userToggleError,
        userToggleLoading,
        userToggleSuccess,

        handleTaskAssignChange,
        taskAssignSuccess,
        taskAssignLoading,
        taskAssignError,
        userAccessPermissionList, refreshUserAccessPermissionList,
        userAccessPermissionLoading, userAccessPermissionError,

        assignmentList,
        assignmentError,
        assignmentsLoading
    };

};

export default ManageUserController;