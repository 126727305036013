import React, { useState, useRef, useEffect } from 'react';

const AdminPersonalProfileScreen = () => {

    const personalProfileData = {
        clientName: "HPCL Corp",
        clientType: "Company",
        dateOfEnrollment: "31/10/2024",
        groupName: "--",
        address: "Street Name, Landmark",
        city: "Hyderabad",
        state: "Telangana",
        pinCode: "500080",
        gstin: "22AAAAA0000A1Z5",
        aadhaar: "1111 2222 3333",
        fixAssignedUser: "Q3 2024",
        clientIncharge: "--",
        outOfIndiaClient: "No",
    };

    return (
        <div className='basic-info-container'>
            <h3 className='basic-info-header'>Personal Information</h3>
            <div className='basic-info-card'>
                <div className='basic-info-row'>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Client Name</span>
                        <span className='basic-info-value'>{personalProfileData.clientName}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Client Type</span>
                        <span className='basic-info-value'>{personalProfileData.clientType}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Date of Enrollment</span>
                        <span className='basic-info-value'>{personalProfileData.dateOfEnrollment}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Group Name</span>
                        <span className='basic-info-value'>{personalProfileData.groupName}</span>
                    </div>
                </div>
                <div style={{...styles.row, marginTop: 20}}>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Address</span>
                        <span className='basic-info-value'>{personalProfileData.address}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>City</span>
                        <span className='basic-info-value'>{personalProfileData.city}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>State</span>
                        <span className='basic-info-value'>{personalProfileData.state}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Pin Code</span>
                        <span className='basic-info-value'>{personalProfileData.pinCode}</span>
                    </div>
                </div>
                <div style={{...styles.row, marginTop: 20}}>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>GSTIN No.</span>
                        <span className='basic-info-value'>{personalProfileData.gstin}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>AADHAAR No. (UIDAI)</span>
                        <span className='basic-info-value'>{personalProfileData.aadhaar}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Fix Assigned User</span>
                        <span className='basic-info-value'>{personalProfileData.fixAssignedUser}</span>
                    </div>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Client Incharge</span>
                        <span className='basic-info-value'>{personalProfileData.clientIncharge}</span>
                    </div>
                </div>
                <div style={{...styles.row, marginTop: 20}}>
                    <div className='basic-info-item'>
                        <span className='basic-info-label'>Out of India Client</span>
                        <span className='basic-info-value'>{personalProfileData.outOfIndiaClient}</span>
                    </div>
                </div>
            </div>
        </div>

    )

};

const styles = {
    container: {
        margin: "20px",
        //   fontFamily: "Arial, sans-serif",
        backgroundColor: '#fff',
        marginTop: '-18px'
    },
    header: {
        fontSize: "18px",
        color: "#609966",
        marginBottom: "10px",
        fontWeight: 500,
        fontFamily: 'Roboto Slab'
    },
    card: {
        backgroundColor: "#fff",
        borderRadius: "5px",
        padding: "20px",
        boxShadow: "0 1px 3px rgba(0, 0, 0, 0.1)",
        borderBottom: "2px solid black",
    },
    row: {
        display: "flex",
        flexWrap: "wrap",
        gap: "20px",
    },
    item: {
        flex: "1 1 calc(25% - 20px)",
        minWidth: "200px",
    },
    label: {
        fontWeight: 400,
        color: "#767676",
        display: "block",
        marginBottom: "5px",
        fontSize: '12px',
        fontFamily: 'Roboto',
    },
    value: {
        fontSize: "14px",
        color: '#060606',
        fontWeight: 400,
        fontFamily: 'Roboto'
    },
}

export default AdminPersonalProfileScreen;