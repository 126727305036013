import React, { useState, useRef, useEffect} from 'react';
import Input from '../../../../Components/Input';
import TextInput from '../../../../Components/TextInput';
import DateInput from '../../../../Components/DateInput';
import search from '../../../../Assets/Icons/search.png';
import Dropdown from '../../../../Components/Dropdown';

const GenerateProgressReports = () => {

    const [reportName, setReportName] = useState("");
    const [clientName, setClientName] = useState("");
    const [roleType, setRoleType] = useState([]);
    const [status, setStatus] = useState("");
    const [startDate, setStartDate] = useState("");
    const [endDate, setEndDate] = useState("");

    const deliveryTypeOptions = ["Employee Report", "Work Process Revenue", "Consolidated Report", "User Activity Report", "To-Do List Report", "User Revenue Report", "Other Report", "Client Login Documents", "Attendance Report"];
    const roleTypes = [{ label: 'Type 1', value: 'Type 1' }, { label: 'Type 2', value: 'Type 2' }];
    const statusOptions = ['Pending', 'In Progress'];

    const handleStartDateChange = (value) => {
        setStartDate(value);
    };

    const handleEndtDateChange = (value) => {
        setEndDate(value);
    };
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <>
            <div style={{ width: '93%', margin: 'auto' }}>
                <h3 style={styles.header}>
                    <div style={styles.headerContainer}>
                        <span>Progress Card - Reports</span>
                    </div>
                </h3>
            </div>
            <div className="tasksScreen-main-content" style={{ width: 'unset' }}>
                <div className="tasksScreen-tasks-container" >
                    <div className="tasksScreen-tasks-header">
                        <h2 className="tasksScreen-tasks-title">Generate Reports</h2>
                    </div>
                    <hr className="footer-divider" />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Report Name"] = el)}
                        isOpen={openDropdown === "Report Name"}
                        toggleDropdown={() => toggleDropdown("Report Name")}
                        label="Report Name"
                        options={deliveryTypeOptions}
                        value={reportName}
                        onChange={setReportName}
                    />
                    <div className="filter-field" style={{ paddingBottom: '10px' }}>
                        <label>Select User</label>
                        <TextInput
                            type="text"
                            placeholder="Client Name"
                            value={clientName}
                            onChange={(e) => setClientName(e.target.value)}
                            right={<img src={search} alt="Search" className="search-icon" />}
                        />
                    </div>
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Select Role"] = el)}
                        isOpen={openDropdown === "Select Role"}
                        toggleDropdown={() => toggleDropdown("Select Role")}
                        label="Select Role"
                        options={roleTypes}
                        value={roleType}
                        onChange={setRoleType}
                        isMulti
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["Select Status"] = el)}
                        isOpen={openDropdown === "Select Status"}
                        toggleDropdown={() => toggleDropdown("Select Status")}
                        label="Select Status"
                        options={statusOptions}
                        value={status}
                        onChange={setStatus}
                    />
                    <div>
                        <div className="filter-field">
                            <label>Period</label>
                            <DateInput
                                value={startDate || ""}
                                onChange={(e) => handleStartDateChange('startDate', e.target.value)}
                            />
                        </div>
                        <span style={{ margin: '0 10px' }}>-</span>
                        <div className="filter-field">
                            <label>Period</label>
                            <DateInput
                                value={endDate || ""}
                                onChange={(e) => handleEndtDateChange('endDate', e.target.value)}
                            />
                        </div>
                    </div>
                    <div className="button-container" style={{ gap: "10px" }}>
                        <button className="add-client-button">
                            Generate
                        </button>
                        <button style={styles.cancelButton}>
                            Cancel
                        </button>
                    </div>
                </div>
            </div>
            <div>
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    cancelButton: {
        display: "flex",
        alignItems: "center",
        backgroundColor: "white",
        color: "#40513B",
        border: "1px solid #858585",
        padding: "8px 16px",
        borderRadius: '20px',
        cursor: "pointer",
    }
};

export default GenerateProgressReports;
