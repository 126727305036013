import React from "react";
import error from "../Assets/Icons/error_message.png";

const Textarea = ({
  label,
  placeholder,
  value,
  onChange,
  width = null,
  height = 87,
  errorMessage,
  maxLength = null,
  readOnly = false,
}) => {
  const handleTextareaChange = (e) => {
    onChange(e);
  };

  return (
    <div style={styles.container}>
      {label && <label style={styles.label}>{label}</label>}
      <textarea
        placeholder={placeholder}
        style={{
          ...styles.textarea,
          width: width,
          height: height,
          maxWidth:590,
          borderColor: errorMessage ? "red" : "#D6E4EC",
        }}
        value={value || ""}
        onChange={handleTextareaChange}
        maxLength={maxLength}
        readOnly={readOnly}
      ></textarea>
      {errorMessage && (
        <div style={styles.errorMessage}>
          <span>
            <img
              src={error}
              alt="Error"
              style={{ width: "12px", height: "12px" }}
            />
          </span>{" "}
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const styles = {
  container: {
    display: "flex",
    flexDirection: "column",
  },
  label: {
    fontSize: "12px",
    marginBottom: "5px",
    color: "#343C44",
    fontWeight: 400,
    fontFamily: "Roboto",
  },
  textarea: {
    padding: "10px",
    border: "1px solid #D6E4EC",
    borderRadius: "5px",
    resize: "none",
    fontSize: "14px",
    color: "#333",
    fontFamily: "Roboto",
  },
  errorMessage: {
    color: "#EE201C",
    fontSize: "11px",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
    fontWeight: "400",
  },
};

export default Textarea;
