import React, { useState, useRef, useEffect } from "react";
import leftArrowBtsIcon from '../../../../Assets/Icons/left-btn.png'
import Dropdown from "../../../../Components/Dropdown";
import GstServiceController from "../../../Controllers/AdminScreens/Services/GstServiceController";
import LoadingSkeleton from "../../../../Components/LoadingSkeleton";
import Loader from "../../../../Components/Loader";

const GstServiceScreen = () => {
  const [selectedYear, setSelectedYear] = useState("2023-24");
  const [selectedStatus, setSelectedStatus] = useState("completed");
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [selectedCheck, setSelectedCheck] = useState(null);

  const sampleNotifications = [
    { message: "Lorem ipsum dolor sit amet, consectetur adipiscing elit." },
    { message: "Curabitur accumsan turpis pharetra augue tincidunt." },
    { message: "Pellentesque habitant morbi tristique senectus et netus." },
    { message: "Suspendisse potenti. Sed porttitor lectus nibh." }
  ];
  const [currentIndex, setCurrentIndex] = useState(0);

  const {
    serviceOptions,
    serviceSubOptions,
    financialYearOptions,
    statusOptions,
    taskOverview,
    sections,
    checkwiseGroupReport,
    loading,
    handlePendingTask,
    servicesLoading,
    servicesError,
    serviceName,
    subServiceName,
    subServicesLoading,
    subServicesError,
    handleSubServices,
    handleData,
    overViewLoading,
    overViewReportError,
    taskStatus,
    taskStatusLoading,
    taskStatusError,
    dateStatus,
    dateStatusLoading,
    dateStatusError,
    checkwiseStatus,
    checkwiseStatusLoading,
    checkwiseStatusError,
    scheduleInfoList,
    scheduleLoading,
    scheduleResError, scheduleInfoListLoading, checkwiseStatusList
  } = GstServiceController();


  // Function to navigate to the next notification
  const handleNext = () => {
    setCurrentIndex((prevIndex) => (prevIndex + 1) % sampleNotifications.length);
  };

  // Function to navigate to the previous notification
  const handlePrevious = () => {
    setCurrentIndex((prevIndex) =>
      (prevIndex - 1 + sampleNotifications.length) % sampleNotifications.length
    );
  };
  // Function to truncate notification if it exceeds 100 characters
  const getTruncatedNotification = (notification) => {
    return notification.message.length > 100 ? `${notification.message.substring(0, 100)}...` : notification.message;
  };

  const cards = [
    {
      title: "Financial Year Report",
      dropdowns: [
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
      ],
    },
    {
      title: "Userwise Task at a Glance",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
      ],
    },
    {
      title: "Overall Task at a Glance",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
        { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
      ],
    },
  ];

  const cards1 = [
    {
      title: "Taskwise Group Report",
      dropdowns: [
        { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
        { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
      ],
    },
    // {
    //   title: "To Do List Report",
    //   dropdowns: [
    //     { label: "Status", options: statusOptions, value: selectedStatus, setValue: setSelectedStatus },
    //     { label: "Select Checks", options: financialYearOptions, value: selectedCheck, setValue: setSelectedCheck },
    //     { label: "Year", options: financialYearOptions, value: selectedYear, setValue: setSelectedYear },
    //   ],
    // },
  ];

  const toDoListData = [
    { taskStatus: "Completed", taskwise: "03", fileNo: "03", without: "03" },
    { taskStatus: "Pending", taskwise: "32", fileNo: "32", without: "32" },
  ];
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div style={styles.container}>

      <div className="notifications-taskoverview-wrapper" style={{ marginBottom: 50 }}>
        <div className='title-container' style={{ paddingBottom: 10, justifyContent: 'flex-start', gap: '25px' }}>
          <h2 className='title'>Service Dashboard</h2>
          <div>
            <Dropdown
              ref={(el) => (dropdownRefs.current["serviceName"] = el)}
              isOpen={openDropdown === "serviceName"}
              toggleDropdown={() => toggleDropdown("serviceName")}
              options={serviceOptions}
              style={{ backgroundColor: '#fff', borderRadius: '50px', width: '165px' }}
              onChange={(selectedOption) => handleSubServices(selectedOption)}
              value={serviceName}
              loading={servicesLoading}
            />
            {servicesError && !servicesLoading && (
              <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }}>{servicesError}</p>
            )}
          </div>
          <div>
            <Dropdown
              ref={(el) => (dropdownRefs.current["subServiceName"] = el)}
              isOpen={openDropdown === "subServiceName"}
              toggleDropdown={() => toggleDropdown("subServiceName")}
              options={serviceSubOptions}
              style={{ backgroundColor: '#fff', borderRadius: '50px', width: '165px' }}
              onChange={(selectedOption) => handleData(selectedOption)}
              value={subServiceName}
              loading={subServicesLoading}
            />
            {subServicesError && !subServicesLoading && (
              <p style={{ color: 'red', fontSize: '12px', textAlign: 'center' }}>{subServicesError}</p>
            )}
          </div>
        </div>
        <div className="notifications-section">
          <div className="notifications-header">
            <h3 className='notifications-heading'>Notifications</h3>
            <p className="notification-message">
              {getTruncatedNotification(sampleNotifications[currentIndex])}
            </p>
            <div className="notification-navigation">
              <button className="nav-button" onClick={handlePrevious}>
                <img src={leftArrowBtsIcon} alt="Previous" />
              </button>

              <button className="nav-button" onClick={handleNext}>
                <img src={leftArrowBtsIcon} alt="Next" className="rotate-icon" />
              </button>
            </div>
          </div>

        </div>
        <div className="task-overview-section">
          <div className="overview-content">
            <h3 className="task-overview-title">Tasks<br />Overview</h3>
            {taskOverview?.length === 0 && !overViewLoading
              ? // Initial state: Display '--'
              [...Array(5)].map((_, index) => (
                <div className="task-overview-item" key={index}>
                  <h4 className="task-count">--</h4>
                  <div className="task-label-container">
                    <p className="task-label">--</p>
                  </div>
                </div>
              ))
              : overViewLoading
                ? // Loading state: Display skeletons
                [...Array(5)].map((_, index) => (
                  <div className="task-overview-item" key={index}>
                    <h4 className="task-count" style={{paddingBottom:overViewLoading ? 5 : 0}}>
                      <LoadingSkeleton width="40px" height="10px" />
                    </h4>
                    <div className="task-label-container">
                      <p className="task-label">
                        <LoadingSkeleton width="80px" height="10px" />
                      </p>
                    </div>
                  </div>
                ))
                : // Data state: Display data
                taskOverview?.map((item, index) => (
                  <div className="task-overview-item" key={index} onClick={() => handlePendingTask(item)}>
                    <h4 className="task-count">{item?.value ?? '--'}</h4>
                    <div className="task-label-container">
                      <p className="task-label">{item?.label ?? '--'}</p>
                    </div>
                  </div>
                ))}
          </div>
        </div>

      </div>

      {/* Sections */}

      <div style={styles.sections}>
        <div style={styles.sectionCard}>
          <h4 style={styles.sectionTitle}>Task with Status</h4>

          {/* Display placeholders or data */}
          {taskStatus?.length > 0 &&
            taskStatus?.map((task, index) => (
              <div key={index} style={styles.sectionItem} className="nth-child-color">
                <p>{task?.label}</p>
                <span style={{ ...styles.itemValue, textDecoration: task.value === '---' ? 'underline' : 'none' }}>
                  {taskStatusLoading ? (
                    <LoadingSkeleton width="50px" height="10px" />
                  ) : (
                    task?.value
                  )}
                </span>
              </div>
            ))}

          {/* Display error if there's an error and not loading */}
          {taskStatusError && !taskStatusLoading && (
            <p style={{ color: 'red', fontSize: '12px' }}>{taskStatusError}</p>
          )}
        </div>

        <div style={styles.sectionCard}>
          <h4 style={styles.sectionTitle}>Task with Due Dates</h4>

          {/* Display placeholders or data */}
          {dateStatus?.length > 0 &&
            dateStatus?.map((task, index) => (
              <div key={index} style={styles.sectionItem} className="nth-child-color">
                <p>{task?.label}</p>
                <span style={{ ...styles.itemValue, textDecoration: task.value === '---' ? 'underline' : 'none' }}>
                  {dateStatusLoading ? (
                    <LoadingSkeleton width="50px" height="10px" />
                  ) : (
                    task?.value
                  )}
                </span>
              </div>
            ))}

          {/* Display error if there's an error and not loading */}
          {dateStatusError && !dateStatusLoading && (
            <p style={{ color: 'red', fontSize: '12px' }}>{dateStatusError}</p>
          )}
        </div>

        <div style={styles.sectionCard}>
          <h4 style={styles.sectionTitle}>Schedule</h4>
          <div className="scrollableContainer"
            style={{
              height: '190px',
              overflowY: 'auto',
              paddingRight: '10px',
              marginRight: '-15px',
            }}>
            {/* Display placeholders or data */}
            {scheduleInfoList?.length > 0 ?
              scheduleInfoList?.map((task, index) => (
                <div key={index} style={styles.sectionItem} className="nth-child-color">
                  <p>{task?.sc_type_name}</p>
                  <span style={{ ...styles.itemValue, textDecoration: task.value === '---' ? 'underline' : 'none' }}>
                    {scheduleLoading ? (
                      <LoadingSkeleton width="50px" height="10px" />
                    ) : (
                      task?.sc_count
                    )}
                  </span>
                </div>
              )) : scheduleInfoListLoading?.map((task, index) => (
                <div key={index} style={styles.sectionItem} className="nth-child-color">
                  <p>{task?.label}</p>
                  <span style={{ ...styles.itemValue, textDecoration: task.value === '---' ? 'underline' : 'none' }}>
                    {scheduleLoading ? (
                      <LoadingSkeleton width="50px" height="10px" />
                    ) : (
                      task?.value
                    )}
                  </span>
                </div>
              ))}
          </div>



          {/* Display error if there's an error and not loading */}
          {scheduleResError && !scheduleLoading && (
            <p style={{ color: 'red', fontSize: '12px' }}>{scheduleResError}</p>
          )}
        </div>
        <div style={{ flex: '0 1 calc(34% - 20px)', }}>
          <div style={{ ...styles.sectionCard, height: '220px' }}>
            <h4 style={styles.sectionTitle}>Checkwise pending Tasks</h4>
            <div
              className="scrollableContainer"
              style={{
                height: '190px',
                overflowY: 'auto',
                paddingRight: '10px',
                marginRight: '-15px',
              }}
            >
              {/* Display placeholders or data */}
              {checkwiseStatusList?.length > 0 ?
                checkwiseStatusList?.map((task, index) => (
                  <div key={index} style={styles.sectionItem} className="nth-child-color">
                    <p>{task?.service_check_name}</p>
                    <span style={{ ...styles.itemValue, textDecoration: task.value === '---' ? 'underline' : 'none' }}>
                      {checkwiseStatusLoading ? (
                        <LoadingSkeleton width="50px" height="10px" />
                      ) : (
                        task?.task_check_count
                      )}
                    </span>
                  </div>
                )) : checkwiseStatus?.map((task, index) => (
                  <div key={index} style={styles.sectionItem} className="nth-child-color">
                    <p>{task?.label}</p>
                    <span style={{ ...styles.itemValue, textDecoration: task.value === '---' ? 'underline' : 'none' }}>
                      {checkwiseStatusLoading ? (
                        <LoadingSkeleton width="50px" height="10px" />
                      ) : (
                        task?.value
                      )}
                    </span>
                  </div>
                ))}
            </div>

            {/* Display error if there's an error and not loading */}
            {checkwiseStatusError && !checkwiseStatusLoading && (
              <p style={{ color: 'red', fontSize: '12px' }}>{checkwiseStatusError}</p>
            )}

          </div>
          <div style={{ ...styles.sectionCard, marginTop: '10px', padding: '15px' }}>
            <div style={{ textAlign: 'center' }}>
              <div style={styles.sectionTitle1}>View All Tasks</div>
            </div>
          </div>
        </div>




        {/* <div style={{ display: 'flex', flexDirection: 'row' }}> */}
        <div style={styles.container1}>
          {cards.map((card, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardTitle}>{card.title}</div>
              <div style={styles.dropdownContainer}>
                <div style={styles.dropdownContainer}>
                  {card.dropdowns.map((dropdown, idx) => (
                    <Dropdown
                      ref={(el) => (dropdownRefs.current[" "] = el)}
                      isOpen={openDropdown === " "}
                      toggleDropdown={() => toggleDropdown(" ")}
                      key={idx}
                      options={dropdown.options}
                      value={dropdown.value}
                      onChange={dropdown.setValue}
                      style={{ backgroundColor: '#fff', borderRadius: '50px', width: '80px' }}
                    />
                  ))}
                </div>
                <div>
                  <button style={styles.iconButton}>
                    <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={styles.container1}>
          {cards1.map((card, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardTitle}>{card.title}</div>
              <div style={styles.dropdownContainer}>
                <div style={styles.dropdownContainer}>
                  {card.dropdowns.map((dropdown, idx) => (
                    <Dropdown
                      ref={(el) => (dropdownRefs.current[" "] = el)}
                      isOpen={openDropdown === " "}
                      toggleDropdown={() => toggleDropdown(" ")}
                      key={idx}
                      options={dropdown.options}
                      value={dropdown.value}
                      onChange={dropdown.setValue}
                      style={{ backgroundColor: '#fff', borderRadius: '50px', width: '80px' }}
                    />
                  ))}
                </div>
                <div>
                  <button style={styles.iconButton}>
                    <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" />
                  </button>
                </div>
              </div>
            </div>
          ))}
          <div style={styles.card}>
            <div style={styles.cardTitle}>To Do List Report</div>
            <div style={styles.dropdownContainer}>
              <div style={styles.tableContainer}>
                <table style={styles.table}>
                  <thead>
                    <tr>
                      <th style={styles.tableHeader}>Task Status</th>
                      <th style={styles.tableHeader}>Taskwise</th>
                      <th style={styles.tableHeader}>File No.</th>
                      <th style={{ ...styles.tableHeader, ...styles.lastColumnCell }}>Without</th>
                    </tr>
                  </thead>
                  <tbody>
                    {toDoListData.map((row, index) => (
                      <tr
                        key={index}
                        style={styles.tableRowEven}
                      >
                        <td style={{ ...styles.tableTextCell, color: '#40513B', fontSize: '10px', fontWeight: '400', backgroundColor: '#fff' }}>{row.taskStatus}</td>
                        <td style={styles.tableCell}>{row.taskwise}</td>
                        <td style={styles.tableCell}>{row.fileNo}</td>
                        <td style={{ ...styles.tableCell, ...styles.lastColumnCell }}>{row.without}</td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        {/* </div> */}
      </div>


      {/* Financial Year Report */}
      {/* <div style={{ padding: '0px 20px' }}>
      </div>
      <div style={{ display: 'flex', flexDirection: 'row' }}>
        <div style={styles.container1}>
          {cards.map((card, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardTitle}>{card.title}</div>
              <div style={styles.dropdownContainer}>
                <div style={styles.dropdownContainer}>
                  {card.dropdowns.map((dropdown, idx) => (
                    <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                      key={idx}
                      options={dropdown.options}
                      value={dropdown.value}
                      onChange={dropdown.setValue}
                      style={{ backgroundColor: '#fff', borderRadius: '50px', width: '80px' }}
                    />
                  ))}
                </div>
                <div>
                  <button style={styles.iconButton}>
                    <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
        <div style={styles.container1}>
          {cards1.map((card, index) => (
            <div key={index} style={styles.card}>
              <div style={styles.cardTitle}>{card.title}</div>
              <div style={styles.dropdownContainer}>
                <div style={styles.dropdownContainer}>
                  {card.dropdowns.map((dropdown, idx) => (
                    <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                      key={idx}
                      options={dropdown.options}
                      value={dropdown.value}
                      onChange={dropdown.setValue}
                      style={{ backgroundColor: '#fff', borderRadius: '50px', width: '80px' }}
                    />
                  ))}
                </div>
                <div>
                  <button style={styles.iconButton}>
                    <img src={require("../../../../Assets/Icons/right_arrow_button.png")} alt="Go" />
                  </button>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div> */}
    </div>
  );
};

const styles = {
  container: {
    fontFamily: "Arial, sans-serif",
    padding: "20px",
    display: 'grid',
  },
  notifications: {
    backgroundColor: "#f9f9f9",
    padding: "10px",
    borderRadius: "5px",
    marginBottom: "20px",
  },
  taskOverview: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "20px",
  },
  taskOverviewCard: {
    flex: 1,
    textAlign: "center",
    margin: "5px",
    backgroundColor: "#fff",
    padding: "15px",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  taskLabel: {
    fontSize: "14px",
    color: "#888",
  },
  taskValue: {
    fontSize: "24px",
    fontWeight: "bold",
    margin: "5px 0",
  },
  sections: {
    display: "flex",
    flexWrap: "wrap",
    gap: "20px",
    padding: '0px 20px'
  },
  sectionCard: {
    flex: "1 1 calc(25% - 20px)",
    backgroundColor: "#fff",
    borderRadius: "5px",
    padding: "15px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "10px",
    color: '#060606',
    marginTop: '0px',
    fontFamily: 'Roboto Slab',

  },
  sectionItem: {
    display: "flex",
    justifyContent: "space-between",
    marginBottom: "5px",
    padding: '20px 10px',
    fontSize: '14px',
    color: '#505470',
    fontWeight: '400',
  },
  itemValue: {
    fontWeight: "700",
    color: "#5A55D2",
    fontSize: '16px',
  },
  reportSection: {
    marginTop: "20px",
    padding: "15px",
    backgroundColor: "#fff",
    borderRadius: "5px",
    boxShadow: "0px 2px 5px rgba(0,0,0,0.1)",
  },
  dropdown: {
    margin: "10px 0",
    padding: "10px",
    borderRadius: "5px",
    border: "1px solid #ddd",
    width: "100%",
  },
  container1: {
    display: "flex",
    flexDirection: "column",
    gap: "20px",
    // padding: "20px",
    flex: "1 1 calc(25% - 20px)",
  },
  card: {
    // width: "300px",
    // minHeight: "160px",
    padding: "20px",
    backgroundColor: "#fff",
    borderRadius: "8px",
    boxShadow: "0 4px 6px rgba(0, 0, 0, 0.1)",
    display: "flex",
    flexDirection: "column",
  },
  cardTitle: {
    fontSize: "18px",
    fontWeight: "400",
    marginBottom: "15px",
    textAlign: "left",
    color: '#060606',
    fontFamily: 'Roboto Slab',
  },
  dropdownContainer: {
    backgroundColor: '#F7F8FA',
    display: "flex",
    flexDirection: "row",
    gap: "10px",
    padding: '10px',
    borderRadius: '10px',
    flexWrap: 'wrap',
  },
  iconButton: {
    border: "none",
    borderRadius: "50%",
    height: '10px',
    width: '10px',
    cursor: "pointer",
    marginTop: '10px',
  },
  scrollableContainer: {
    height: "200px", // Set fixed height
    overflowY: "auto", // Enable vertical scrolling
    paddingRight: "10px", // Add padding for scrollbar spacing
  },
  sectionTitle1: {
    textDecoration: 'underline',
    color: '#5A55D2',
    fontFamily: 'Roboto',
    fontWeight: '590',
    fontSize: '16px',
    cursor: 'pointer',
  },
  tableContainer: {
    width: "100%",
    overflowX: "auto",
    backgroundColor: "#fff",
  },
  table: {
    width: "100%",
    borderCollapse: "collapse",
    textAlign: "center",
    fontFamily: "Roboto, Arial, sans-serif",
    fontSize: "14px",
    border: "1px solid #ddd",
  },
  tableHeader: {
    color: "#40513B",
    padding: "8px",
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    fontSize: '10px',
    fontWeight: '600',
  },
  tableRowEven: {
    backgroundColor: "#FAFAFA",
  },
  tableCell: {
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    color: "#5A55D2",
    fontWeight: "700",
    cursor: "pointer",
    textDecoration: 'underline',
    fontSize: '16px',
  },
  tableTextCell: {
    padding: "8px",
    borderBottom: "1px solid #ddd",
    borderRight: "1px solid #ddd",
    color: "#505470",
    fontWeight: "normal",
  },
  lastColumnCell: {
    borderRight: "none",
  },
};

export default GstServiceScreen;
