
import React, { useState, useRef, useEffect } from "react";

const EmailLogsListController = () => {
    const [filterData, setFilterData] = useState({
        contactName: "",
        phoneNo: "",
        optOut: "",
        countryCode: "",
    });

    const handleInputChange = (field, value) => {
        setFilterData((prevData) => ({
            ...prevData,
            [field]: value,
        }));
    };
    const emailLogsData = [
        {
            date: "11/11/2024",
            messageContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            sentBy: "John Doe"
        },
        {
            date: "11/11/2024",
            messageContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            sentBy: "John Doe"
        },
        {
            date: "11/11/2024",
            messageContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Username",
            sentBy: "John Doe"
        },
        {
            date: "11/11/2024",
            messageContent: "Lorem ipsum dolor sit amet, Lorem ipsum dolor sit amet Lorem ipsum",
            sentTo: "List Name, Usernames",
            sentBy: "John Doe"
        }
    ]

    return {
        emailLogsData,
        filterData,
        setFilterData,
        handleInputChange
    }

}

export default EmailLogsListController