import React, { useState, useRef, useEffect } from "react";
import error from '../Assets/Icons/error_message.png';

const MobileInput = ({ label, countryCode, mobileNumber, onCountryChange, onMobileChange, placeholder, countries, width = '220px', errorMessage, }) => {
    const [selectedCountry, setSelectedCountry] = useState(
        countries.find((country) => country.dialCode === countryCode) || countries[0]
    );

    const handleCountryChange = (event) => {
        const countryCode = event.target.value;
        const selected = countries.find((country) => country.code === countryCode);
        setSelectedCountry(selected);

        // Notify parent of the country code change
        onCountryChange(selected.dialCode);
    };

    const handleMobileChange = (event) => {
        const inputValue = event.target.value;
        if (/^\d*$/.test(inputValue)) {
            // Notify parent of the mobile number change
            onMobileChange(inputValue);
        }
    };

    return (
        <div style={styles.container}>
            {label && <label style={styles.label}>{label}</label>}
            <div style={{ ...styles.inputGroup, borderColor: errorMessage ? "red" : "#D6E4EC", }}>
                <select
                    style={styles.countryDropdown}
                    value={selectedCountry.code}
                    onChange={handleCountryChange}
                >
                    {countries.map((country) => (
                        <option key={country.code} value={country.code} style={styles.dropdownOption}>
                            {country.dialCode}
                        </option>
                    ))}
                </select>
                <input
                    type="text"
                    placeholder={placeholder}
                    value={mobileNumber}
                    onChange={handleMobileChange}
                    style={styles.inputField}
                    maxLength={10}
                />
            </div>
            {errorMessage && (
                <div style={styles.errorMessage}>
                    <span><img
                          src={error}
                          alt="Error"
                          style={{ width: "12px", height: "12px" }}
                        /></span> {errorMessage}
                </div>
            )}
        </div>
    );
};

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        // marginBottom: "16px",
    },
    label: {
        fontSize: '12px',
        marginBottom: '5px',
        color: '#343C44',
        fontWeight: 400,
        fontFamily: 'Roboto'
    },
    inputGroup: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #D6E4EC",
        borderRadius: "4px",
        overflow: "hidden",
        height: '38px'
    },
    countryDropdown: {
        border: "none",
        borderRight: "1px solid #D6E4EC",
        fontSize: "14px",
        color: "#333",
        outline: "none",
        cursor: 'pointer'
    },
    inputField: {
        flex: 1,
        border: "none",
        padding: "8px",
        fontSize: "14px",
        color: "#333",
        outline: "none",
    },
    dropdownOption: {
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        color: "#333",
    },
    errorMessage: {
        color: "#EE201C",
        fontSize: "10px",
        marginTop: "4px",
        display: "flex",
        gap: "4px",
        fontWeight:'400'
    },
};

export default MobileInput;
