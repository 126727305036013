import React, { useState, useEffect, useRef } from 'react';
import Input from './Input';
import Dropdown from './Dropdown';
import CheckBox from './CheckBox';
import MobileInput from './MobileInput';
import closeIcon from '../Assets/Icons/close.png';
import pluseIcon from '../Assets/Icons/Pluse.png';
import uploadIcon from '../Assets/Icons/upload.png';
import { ORG_GET_GROUP_LIST, ORG_ADD_CLIENT, ORG_GET_CLIENT_TYPE_LIST, ORG_GET_ORG_CODE, ORG_UPLOAD_FORM16 } from '../ApiServices/BaseURL';
import { postData, getData } from '../ApiServices/ApiService';
import { validateEmail, validateMobileNumber } from '../Utils/Validation';
import Loader from './Loader';
import { useNavigate } from 'react-router-dom';

const AddClientModal = ({ isOpen, onClose, onValueChange }) => {
  const [clientName, setClientName] = useState('');
  const [clientEmail, setClientEmail] = useState('');
  const [clientFileId, setClientFiledId] = useState('');
  const [clientType, setClientType] = useState(null);
  const [identificationType, setIdentificationType] = useState(null);
  const [identificationNumber, setIdentificationNumber] = useState(null);
  const [groupClient, setGroupClient] = useState(null);
  const [mobileNumber, setMobileNumber] = useState('');
  const [countryCode, setCountryCode] = useState('+91');
  const [existingClient, setExistingClient] = useState(false);
  const [showUpload, setShowUpload] = useState(false);
  const [otherType, setOtherType] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [base64File, setBase64File] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [uploadFormError, setUploadFormError] = useState('');
  const [uploadFormSuccess, setUploadFormSuccess] = useState('');

  const navigate = useNavigate();

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const [emailError, setEmailError] = useState('');
  const [mobileError, setMobileError] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [clientTypeError, setClientTypeError] = useState('');
  const [otherTypeError, setOtherTypeError] = useState('');
  const [identificationTypeError, setIdentificationTypeError] = useState('');
  const [identificationNumberError, setIdentificationNumberError] = useState('');
  const [groupClientError, setGroupClientError] = useState('');
  const [success, setSuccess] = useState('');

  // const clientTypeOptions = ["Individual", "Company", "Company - GST Exempt", "Type - 4", "New Entity", "Other"];
  // const identificationOptions = ["Passport", "Driver's License", "National ID"];
  // const groupClientOptions = ["Group A", "Group B", "Group C"];
  const countryCodeOptions = [[
    { code: "IN", dialCode: "+91" },
    { code: "US", dialCode: "+1" },
    { code: "UK", dialCode: "+44" },
  ]];

  const groupClientOptionsRef = useRef(null);
  const [groupClientOptionsList, setGroupClientOptionsList] = useState([]);
  const [groupLoading, setGroupLoading] = useState(false)
  const identificationOptionsRef = useRef(null);
  const [identificationOptionsList, setIdentificationOptionsList] = useState([]);
  const [identificationLoading, setIdentificationLoading] = useState(false)
  const clientTypeOptionsRef = useRef(null);
  const [clientTypeOptionsList, setClientTypeOptionsList] = useState([]);
  const [clientTypeLoading, setClientTypeLoading] = useState(false)


  useEffect(() => {
    setError("");
    fetchGroupList();
    fetchClientTypesList();
    fetchIdentityCodes();
    setEmailError('');
    setMobileError('');
    setGroupClientError('')
  }, [])

  const fetchGroupList = async () => {
    try {
      setGroupClientError('')
      setGroupLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const filterData = responseData?.dataJ.map(service => ({
                label: service.group_name,
                value: service.group_id
              }));
              setGroupClientOptionsList(filterData)
            } else {
              setGroupClientError(responseData?.info || 'Data Not Found');
            }
          } else {
            setGroupClientError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setGroupClientError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setGroupClientError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setGroupClientError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setGroupClientError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setGroupClientError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setGroupClientError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setGroupClientError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setGroupClientError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setGroupLoading(false);
    }
  }
  const fetchClientTypesList = async () => {
    try {
      setClientTypeError('')
      setClientTypeLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_TYPE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const filterData = [
                ...(await responseData?.dataJ.map(service => ({
                  label: service.client_type_name,
                  value: service.client_type_id,
                }))),
                { label: "Other", value: -1 }, // Append the "Other" option
              ];
              setClientTypeOptionsList(filterData)

            } else {
              const filterData = [
                { label: "Other", value: -1 }, // Append the "Other" option
              ];
              setClientTypeOptionsList(filterData)
              // setClientTypeError(responseData?.info || 'Data Not Found');
            }
          } else {
            const filterData = [
              { label: "Other", value: -1 }, // Append the "Other" option
            ];
            setClientTypeOptionsList(filterData)
            // setClientTypeError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          const filterData = [
            { label: "Other", value: -1 }, // Append the "Other" option
          ];
          setClientTypeOptionsList(filterData)
          // setClientTypeError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        const filterData = [
          { label: "Other", value: -1 }, // Append the "Other" option
        ];
        setClientTypeOptionsList(filterData)
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          // setClientTypeError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          // setClientTypeError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        const filterData = [
          { label: "Other", value: -1 }, // Append the "Other" option
        ];
        setClientTypeOptionsList(filterData)
        // setClientTypeError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        const filterData = [
          { label: "Other", value: -1 }, // Append the "Other" option
        ];
        setClientTypeOptionsList(filterData)
        // setClientTypeError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      const filterData = [
        { label: "Other", value: -1 }, // Append the "Other" option
      ];
      setClientTypeOptionsList(filterData)
      // Handle various error scenarios
      // if (error.response) {
      //   // Server responded with a status outside the 2xx range
      //   // setClientTypeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      // } else if (error.request) {
      //   // Request was made but no response was received
      //   setClientTypeError('No response received from the server. Please check your network connection and try again.');
      // } else {
      //   // An error occurred in setting up the request
      //   setClientTypeError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      // }
    } finally {
      setClientTypeLoading(false);
    }
  }

  const fetchIdentityCodes = async () => {
    try {
      setIdentificationLoading(true);
      // Make the API call
      const payLoad = {
        "code_id": "client_identity_codes"
      }
      const response = await postData(ORG_GET_ORG_CODE, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const filterData = responseData?.dataJ?.map(service => ({
                label: service.identity_name,
                value: service.identity_id
              }));
              setIdentificationOptionsList(filterData)
            } else {
              setIdentificationTypeError(responseData?.info || 'Data Not Found');
            }
          } else {
            setIdentificationTypeError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setIdentificationTypeError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setIdentificationTypeError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setIdentificationTypeError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setIdentificationTypeError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setIdentificationTypeError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setIdentificationTypeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setIdentificationTypeError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setIdentificationTypeError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setIdentificationLoading(false);
    }
  }

  const fileInputRef = useRef(null);

  useEffect(() => {
    if (clientType?.label === 'Individual') {
      setShowUpload(true);
    } else {
      setShowUpload(false);
    }
  }, [clientName, clientType, identificationType, identificationNumber, groupClient, mobileNumber]);

  // Reset form fields to initial values
  const resetForm = () => {
    setClientName('');
    setClientType('');
    setIdentificationType('');
    setIdentificationNumber('');
    setGroupClient('');
    setMobileNumber('');
    setExistingClient(false);
    setClientEmail('');
    setEmailError('');
    setMobileError('');
    setSuccess('');
    setUploadFormSuccess('');
    setUploadFormError('');
    setFileExtension('');
    setFileExtension('');
    setBase64File('');
    setUploadedFileName('');
  };

  // Run resetForm when modal closes
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleAddClient = async () => {


    try {
      setError('');
      setEmailError('');
      setMobileError('');
      setClientNameError('');
      setClientTypeError('');
      setOtherTypeError('');
      setIdentificationTypeError('');
      setIdentificationNumberError('');
      setGroupClientError('');

      // Safely validate inputs
      if (!clientName?.trim()) {
        setClientNameError("Client name is required.");
        return;
      }



      if (!clientType) {
        setClientTypeError("Client type is required.");
        return;
      }

      if (clientType?.label?.toLowerCase() === "other") {
        if (!otherType) {
          setOtherTypeError("Please specify the other client type.");
          return;
        }
      }

      if (!identificationType) {
        setIdentificationTypeError("Identification type is required.");
        return;
      }

      // if (!identificationNumber?.trim()) {
      //   setIdentificationNumberError("Identification number is required.");
      //   return;
      // }

      if (!identificationNumber?.trim()) {
        setIdentificationNumberError("Identification number is required.");
        return;
      } else {
        // Validate based on the selected identification type
        const trimmedValue = identificationNumber.trim();

        if (identificationType?.value === 'PAN' && trimmedValue.length !== 10) {
          setIdentificationNumberError("PAN number must be exactly 10 characters.");
          return;
        } else if (identificationType?.value === 'AADHAR' && trimmedValue.length !== 12) {
          setIdentificationNumberError("AADHAR number must be exactly 12 digits.");
          return;
        } else if (identificationType?.value === 'GSTIN' && trimmedValue.length !== 15) {
          setIdentificationNumberError("GSTIN number must be exactly 15 characters.");
          return;
        } else if (!['PAN', 'AADHAR', 'GSTIN'].includes(identificationType?.value) && trimmedValue.length < 1) {
          setIdentificationNumberError("Identification number must be at least 1 character.");
          return;
        } else {
          setIdentificationNumberError(""); // Clear error if valid
        }
      }

      // if (!groupClient) {
      //   setGroupClientError("Group Client is required.");
      //   return;
      // }

      if (!validateMobileNumber(mobileNumber)) {
        setMobileError('Mobile number must be 10 digits.');
        return;
      }

      // if (!clientEmail?.trim() || !validateEmail(clientEmail)) {
      //   setEmailError('Invalid email address.');
      //   return;
      // }

      const clientData = {
        "client_name": clientName,
        "client_type": clientType,
        "group_info": groupClient,
        "client_email": clientEmail,
        "client_file_id": clientFileId,
        "client_identity_doc": identificationType,
        "client_identity_doc_id_number": identificationNumber,
        "client_mobile_no": mobileNumber,
        "client_mobile_code": countryCode,
        "client_other_type_name": otherType
      }
      // onValueChange(clientData); /add-client
      //console.log('clientData:----', clientData)
      onClose();
      navigate(`/add-client`, { state: { task: "PrimaryInformation", clientInfo: clientData } })

      return

      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_name": clientName,
        "client_type_id": clientType.value,
        "group_id": groupClient.value,
        "client_email": clientEmail,
        "client_file_id": clientFileId,
        "client_identity_doc_id": identificationType.value,
        "client_identity_doc_id_number": identificationNumber,
        "client_mobile_no": mobileNumber,
        "client_mobile_code": countryCode
      }

      //console.log('catagory Data', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_CLIENT, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }

    // onValueChange(clientData); // Send data to Dashboard
    // onClose(); // Close modal
  };

  const handleClose = () => {
    resetForm();
    setError('');
    setEmailError('');
    setMobileError('');
    setClientNameError('');
    setClientTypeError('');
    setOtherTypeError('');
    setIdentificationTypeError('');
    setIdentificationNumberError('');
    setGroupClientError('');
    onClose();
    setSuccess('');
    setBase64File('');
    setUploadedFileName('');
    setUploadFormSuccess('');
    setUploadFormError('');
    setFileExtension('');
    setFileExtension('');
    setBase64File('');
  }

  // Handle file input change
  const handleFileChange = async (event) => {
    setUploadFormError('');
    setUploadFormSuccess('');
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size; // File size in bytes
      const maxFileSize = 2 * 1024 * 1024;
      const fileExtension = fileName.split('.').pop(); // Extract the file extension

      //console.log("Uploaded File Name:", fileName);
      //console.log("File Extension:", fileExtension);
      setUploadedFileName(fileName);

      if (fileSize > maxFileSize) {
        setUploadFormError("File size exceeds the maximum limit of 2MB.");
        return;
      }

      const reader = new FileReader();

      try {
        reader.onload = async () => {
          try {
            const base64String = reader.result.split(',')[1]; // Extract Base64 string from result
            //console.log("Base64 Encoded File:", base64String);

            setBase64File(base64String); // Add the Base64 string to the payload
            // Optionally, save the file extension in state or payload
            setFileExtension(fileExtension);
            await uploadForm16(base64String);
          } catch (parseError) {
            console.error("Error parsing file to Base64:", parseError);
            setUploadFormError("Failed to process the file. Please try again.");
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          setUploadFormError("Failed to read the file. Please try again.");
        };

        reader.readAsDataURL(file); // Convert file to Base64 string
      } catch (readError) {
        console.error("File upload error:", readError);
        setUploadFormError("An unexpected error occurred during the file upload. Please try again.");
      }
    } else {
      if (!base64File) {
        setUploadFormError("No file selected. Please choose a file to upload.");
      }
    }
  };

  const uploadForm16 = async (base64String) => {
    try {
      setUploadFormError('');
      setUploadFormSuccess('');
      setLoading(true);

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payload = {
        emp_id_auth: storedUserInfo?.emp_id,
        doc_data_base_64: base64String,
      };

      const response = await postData(ORG_UPLOAD_FORM16, payload);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message === 'success') {
            const extractedData = responseData?.dataJ[0];
            setClientName(extractedData.name || '');
            setIdentificationNumber(extractedData.pan || '');
            const panOption = identificationOptionsList?.find(
              (option) => option.label === 'PAN Card'
            );
            setIdentificationType(panOption);
            setUploadFormSuccess('Form 16 processed successfully');
          } else {
            setUploadFormError(responseData?.info || 'Form 16 processing failed.');
          }
        } else {
          setUploadFormError(responseData?.info || 'Unexpected response code from the server.');
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setUploadFormError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setUploadFormError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setUploadFormError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setUploadFormError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setUploadFormError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update kyc document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setUploadFormError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setUploadFormError(`Failed to update kyc document. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  };
  const handleDrop = (e) => {
    const files = e.dataTransfer.files; // Get the files from the drop event
    if (files && files.length > 0) {
      //console.log("Dropped files:", files);
      handleFileChange({ target: { files } }); // Reuse handleFileChange for processing
    }
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={handleClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        <h2 className="modal-title">Add Client</h2>
        <hr className="title-divider" />

        <div className="form-grid">
          <Input label="Client Name" placeholder="Enter client name" value={clientName} onChange={(e) => setClientName(e.target.value)} errorMessage={clientNameError} />

          <Dropdown
            ref={(el) => (dropdownRefs.current["clientType"] = el)}
            isOpen={openDropdown === "clientType"}
            toggleDropdown={() => toggleDropdown("clientType")}
            label="Client Type" options={clientTypeOptionsList || []} value={clientType} onChange={setClientType} errorMessage={clientTypeError} loading={clientTypeLoading} />
          {clientType?.label?.toLowerCase() === 'other' && <> <Input label="Specify Other" placeholder="Description" value={otherType} onChange={(e) => setOtherType(e.target.value)} errorMessage={otherTypeError} /> <br /></>}

          <Dropdown
            ref={(el) => (dropdownRefs.current["identification"] = el)}
            isOpen={openDropdown === "identification"}
            toggleDropdown={() => toggleDropdown("identification")} label="Select Identification" options={identificationOptionsList || []} value={identificationType} onChange={(value) => { setIdentificationType(value); setIdentificationNumber(null) }} errorMessage={identificationTypeError} loading={identificationLoading} />
          {identificationType?.value === 'PAN' ?
            <Input label="Identification Number" placeholder="Enter PAN number"
              value={identificationNumber || ''} onChange={(e) => setIdentificationNumber(e.target.value)}
              errorMessage={identificationNumberError}
               maxLength={10} />
            : identificationType?.value === 'AADHAR' ?
              <Input label="Identification Number" placeholder="Enter AADHAR number"
                value={identificationNumber || ''} onChange={(e) => setIdentificationNumber(e.target.value)}
                errorMessage={identificationNumberError}
                maxLength={12} />
              : identificationType?.value === 'GSTIN' ?
                <Input label="Identification Number" placeholder="Enter GSTIN number"
                  value={identificationNumber || ''} onChange={(e) => setIdentificationNumber(e.target.value)}
                  errorMessage={identificationNumberError}
                  maxLength={15} />
                : <Input label="Identification Number" placeholder="Enter ID number" value={identificationNumber || ''} onChange={(e) => setIdentificationNumber(e.target.value)} errorMessage={identificationNumberError} />
          }
          <Dropdown
            ref={(el) => (dropdownRefs.current["groupName"] = el)}
            isOpen={openDropdown === "groupName"}
            toggleDropdown={() => toggleDropdown("groupName")} label="Group Name" options={groupClientOptionsList || []} value={groupClient} onChange={setGroupClient} errorMessage={groupClientError} loading={groupLoading} />

          {/* <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
            isOpen={openDropdown === " "}
            toggleDropdown={() => toggleDropdown(" ")} label="Select Country Code" options={countryCodeOptions} value={countryCode} onChange={setCountryCode} />
          <Input label="Mobile No." placeholder="Enter mobile number" value={mobileNumber} onChange={(e) => {
            const value = e.target.value.replace(/[^0-9]/g, "");
            if (value.length <= 10) {
              setMobileNumber(value);
            }
          }} maxLength={10} /> */}
          <MobileInput
            label="Mobile No."
            countryCode={countryCode}
            mobileNumber={mobileNumber}
            onCountryChange={setCountryCode}
            onMobileChange={setMobileNumber}
            placeholder="Enter mobile number"
            countries={[
              { code: "IN", dialCode: "+91" },
              { code: "US", dialCode: "+1" },
              { code: "UK", dialCode: "+44" },
            ]}
            errorMessage={mobileError}

          />
          {/* <Input label="Client Email" placeholder="Enter email id" value={clientEmail} onChange={(e) => setClientEmail(e.target.value)} errorMessage={emailError} width={260}/> */}

          {/* <Input label="Client File Id" placeholder="Enter client file id" value={clientFileId} onChange={(e) => setClientFiledId(e.target.value)} /> */}

        </div>
        {showUpload && (
          <div
            style={styles.uploadContainer}
            onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDrop(e);
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              fileInputRef.current?.click(); // Programmatically trigger file input click
            }}
          >
            <div style={styles.uploadBox}>
              <span style={styles.uploadCircle}>
                <img src={pluseIcon} alt="Plus Icon" style={{ ...styles.iconImage, filter: 'brightness(0) invert(0)' }} />
              </span>
              <p style={uploadedFileName ? styles.uploadedFileName : styles.uploadText}>
                {uploadedFileName || // Show file name if uploaded
                  "Upload Form 16 for faster onboarding, Drop here or click on upload"}
              </p>
              <img src={uploadIcon} alt="Upload Icon" style={styles.uploadIconImage} />

              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                // accept="application/pdf" // Only allow PDF files
                onChange={handleFileChange} // Handle file selection
                onClick={(e) => e.stopPropagation()} // Prevent double triggering
              />
            </div>
          </div>
        )}
        {/* <div className="existing-client-checkbox">
          <label style={styles.exisitingHeading}>Existing Client?</label>
          <CheckBox
            label=""
            checked={existingClient}
            onChange={(isChecked) => setExistingClient(isChecked)}
          />
        </div> */}
        <hr className="footer-divider" />


        <div className="button-container" style={{ alignItems: 'center' }}>
          <button className="add-client-button" onClick={handleAddClient} style={{ opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add Client</button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
          {error && <div className="list-error-msg" style={{ marginBottom: '1px' }}>{error}</div>}
          {success && <div className="list-success-msg" style={{ marginBottom: '1px' }}>{success} </div>}
          {uploadFormError && <div className="list-error-msg" style={{ marginBottom: '1px' }}>{uploadFormError}</div>}
          {uploadFormSuccess && <div className="list-success-msg" style={{ marginBottom: '1px' }}>{uploadFormSuccess} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  uploadContainer: {
    marginTop: '20px',
    // width: '99%',
    marginBottom: '20px',
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #9DC08B',
    borderRadius: '10px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  iconImage: {
    width: '15px',
    height: '15px',
  },
  uploadIconImage: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  errorBox: {
    color: "red",
    padding: "10px",
    marginBottom: "20px",
    fontSize: "12px",
  },
}

export default AddClientModal;
