import React from 'react';
import closeIcon from '../../Assets/Icons/close.png';
import deleteInfoIcon from '../../Assets/Icons/Delete_Info.png';

const DeleteConfirmationModal = ({ isOpen, message, onClose, onConfirm }) => {
    if (!isOpen) return null; // Render nothing if the modal is not open

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title" style={{ letterSpacing:'1.0px', lineHeight:'26.3px'}}>Confirmation</h2>
                <hr className="title-divider" />
                <div style={styles.content}>
                    <img
                        src={deleteInfoIcon}
                        alt="Delete"
                        style={{ width: "35px", height: "35px" }}
                    />
                    <p style={styles.message}>{message}</p>
                </div>
                <hr className="footer-divider" />
                <div className="button-container">
                    <button className="add-client-button" onClick={onConfirm} style={{paddingLeft:'30px', paddingRight:'30px', marginRight:'8px'}}>Yes</button>
                    <button className="cancel-button" onClick={onClose}>No</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    content: {
        display: 'flex',
        flexDirection: 'row',
        gap: '10px',
        paddingTop: '20px',
        paddingBottom: '20px',
        alignItems: 'center'
    },
    message: {
        color: '#000000',
        fontSize: '12px',
        fontWeight: '400',
        wordWrap: 'break-word'
    },
    iconImage: {
        width: '15px',
        height: '15px',
    },
};

export default DeleteConfirmationModal;
