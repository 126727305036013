import './App.css';
import React, { useState, useRef, useEffect } from 'react';
import { BrowserRouter, Routes, Route, HashRouter, useLocation } from 'react-router-dom';
import { GlobalProvider } from './GlobalContext/GlobalContext';

import LoginScreen from './MVCPages/View/AuthenticationScreens/LoginScreen';
import DashboardScreen from './MVCPages/View/AdminScreens/DashboardScreen';

import CreateTaskScreen from './MVCPages/View/AdminScreens/CreateTaskScreen';
import TaskListScreen from './MVCPages/View/AdminScreens/TaskListScreen';
import ClientListScreen from './MVCPages/View/AdminScreens/Clients/ClientListScreen';
import AdminSettingsScreen from './MVCPages/View/AdminScreens/Settings/AdminSettingsScreen';
import ResourceDetailsScreen from './MVCPages/View/AdminScreens/ResourceDetailsScreen';
import ResourcesListScreen from './MVCPages/View/AdminScreens/ResourcesListScreen';
import ScheduleRemindersScreen from './MVCPages/View/AdminScreens/ScheduleRemindersScreen';
import ManageClientScreen from './MVCPages/View/AdminScreens/Clients/ManageClientScreen';
import BillingPaymentListScreen from './MVCPages/View/AdminScreens/BillingPaymentListScreen';
import ManageUserScreen from './MVCPages/View/AdminScreens/Users/ManageUserScreen';
import UsersListScreen from './MVCPages/View/AdminScreens/Users/UsersListScreen';
import ClientTaskDetailsScreen from './MVCPages/View/AdminScreens/Clients/ClientTaskDetailsScreen';
import TaskManagementScreen from './MVCPages/View/AdminManagementScreens/TaskManagement/TaskManagementScreen';
import DocumentRackScreen from './MVCPages/View/AdminScreens/DocumentRack/DocumentRackScreen'
import GenerateProgressReports from './MVCPages/View/AdminScreens/ProgressCard/GenerateProgressReports';

import GstServiceScreen from './MVCPages/View/AdminScreens/Services/GstServiceScreen';
import TeamsUsersManagementScreen from './MVCPages/View/AdminManagementScreens/TeamsAndUsers/TeamsUsersManagementScreen';
import ManageTeamDetails from './MVCPages/View/AdminManagementScreens/TeamsAndUsers/ManageTeamDetails';
import ManageGroupDetails from './MVCPages/View/AdminManagementScreens/TeamsAndUsers/ManageGroupDetails'
import BillingReceiptEstimateScreen from './MVCPages/View/AdminScreens/Invoices/BillingReceiptEstimateScreen';
import CreateEditEstimateScreen from './MVCPages/View/AdminScreens/Invoices/CreateEditEstimateScreen';
import ManageGroupsScreen from './MVCPages/View/AdminManagementScreens/TeamsAndUsers/ManageGroups';

import OtherSettingsScreen from './MVCPages/View/AdminScreens/Services/Settings/OtherSettingsScreen';
import GenerateEditInvoiceScreen from './MVCPages/View/AdminScreens/Invoices/GenerateEditInvoiceScreen';
import GenerateEditReceiptScreen from './MVCPages/View/AdminScreens/Invoices/GenerateEditReceiptScreen';
import GenerateInvoiceScreen from './MVCPages/View/AdminScreens/Invoices/GenerateInvoiceScreen';
import InvoiceAccountsScreen from './MVCPages/View/AdminScreens/Invoices/InvoiceAccountsScreen';
import InvoiceReportsScreen from './MVCPages/View/AdminScreens/Invoices/InvoiceReportsScreen';
import invoiceSettingsScreen from './MVCPages/View/AdminScreens/Invoices/InvoiceSettingsScreen';
import InvoiceAccountClientInformationScreen from './MVCPages/View/AdminScreens/Invoices/InvoiceAccountClientInformationScreen';
import ContactsCenterScreen from './MVCPages/View/AdminScreens/Configuration/ContactsCenter/ContactsCenterScreen';
import ActivityRegisterScreen from './MVCPages/View/AdminScreens/Configuration/ActivityRegister/ActivityRegisterScreen';
import TemplateRegisterScreen from './MVCPages/View/AdminScreens/Configuration/TemplateCenter/TemplateRegisterScreen';
import ReminderRegisterScreen from './MVCPages/View/AdminScreens/Configuration/ReminderRegisterScreen';
import ConfigurationCenterScreen from './MVCPages/View/AdminScreens/Configuration/ConfigurationCenter/ConfigurationCenterScreen';

import Sidebar from './Components/SideBar';
import Header from './Components/HeaderBar';

import Protected from './Utils/Protected';
import MFAScreen from './MVCPages/View/AuthenticationScreens/MFAScreen';
import ForgotPasswordScreen from './MVCPages/View/AuthenticationScreens/ForgotPasswordScreen';
import NoticeBoardScreen from './MVCPages/View/AdminScreens/AddOns/NoticeBoard/NoticeBoardScreen';
import DSCCenterScreen from './MVCPages/View/AdminScreens/AddOns/DSCCenter/DSCCenterScreen';
import IncomeTaxPortalScreen from './MVCPages/View/AdminScreens/AddOns/IncomeTaxPortalScreen';

import AddClientScreen from './MVCPages/View/AdminScreens/Clients/AddClientScreen';
import AddUserScreen from './MVCPages/View/AdminScreens/Users/AddUserScreen';

function App() {
  const location = useLocation();
  const isLoginPage = location.pathname === '/login' || location.pathname === '/' || location.pathname === '/mfa' || location.pathname === '/forgot-password';

  const [sidebarValue, setSidebarValue] = useState(false); // State for Sidebar value

  const handleSidebarValue = (value) => {
    setSidebarValue(value); // Update the state with the Sidebar value
    //console.log('Sidebar Value:', value); // Log the value
  };
  return (
    <GlobalProvider>
      {/* <HashRouter> this hashRouter added in index.js */}

      <div className='app'>
        {!isLoginPage && <Sidebar onValueChange={handleSidebarValue} />}
        <div className='app-contant' style={{
          width: isLoginPage
            ? '100%' // If it's the login page, width is 100%
            : sidebarValue
              && 'calc(100% - 50px)' // If sidebarValue is true, subtract 50px from width
        }}>
          {!isLoginPage && <Header />}
          <div className='main-contant'>
            <Routes>
              <Route path="/" element={<Protected Component={LoginScreen} />} />
              <Route path="/login" element={<Protected Component={LoginScreen} />} />
              <Route path="/forgot-password" element={<ForgotPasswordScreen />} />
              <Route path="/mfa" element={<Protected Component={MFAScreen} />} />
              <Route path="/dashboard" element={<Protected Component={DashboardScreen} />} />
              <Route path="/create-task" element={<Protected Component={CreateTaskScreen} />} />
              <Route path="/tasklist" element={<Protected Component={TaskListScreen} />} />
              <Route path="/clientlist" element={<Protected Component={ClientListScreen} />} />
              <Route path="/schedule-reminder" element={<Protected Component={ScheduleRemindersScreen} />} />
              <Route path="/resources-details" element={<Protected Component={ResourceDetailsScreen} />} />
              <Route path="/resourceslist" element={<Protected Component={ResourcesListScreen} />} />
              <Route path="/admin-settings" element={<Protected Component={AdminSettingsScreen} />} />
              <Route path="/manage-clients" element={<Protected Component={ManageClientScreen} />} />
              <Route path="/add-client" element={<Protected Component={AddClientScreen} />} />
              <Route path="/billing-payment-list" element={<Protected Component={BillingPaymentListScreen} />} />
              <Route path="/manage-user" element={<Protected Component={ManageUserScreen} />} />
              <Route path="/userlist" element={<Protected Component={UsersListScreen} />} />
              <Route path="/client-task-details" element={<Protected Component={ClientTaskDetailsScreen} />} />
              <Route path="/task-management-list" element={<Protected Component={TaskManagementScreen} />} />
              <Route path="/services/gst-services" element={<Protected Component={GstServiceScreen} />} />
              <Route path="/teams-userlist" element={<Protected Component={TeamsUsersManagementScreen} />} />
              <Route path="/manage-team-details" element={<Protected Component={ManageTeamDetails} />} />
              <Route path="/manage-group-details" element={<Protected Component={ManageGroupDetails} />} />
              <Route path="/documents" element={<Protected Component={DocumentRackScreen} />} />
              <Route path="/progress-reports" element={<Protected Component={GenerateProgressReports} />} />
              <Route path="/bills-receipts" element={<Protected Component={BillingReceiptEstimateScreen} />} />
              <Route path="/create-edit-estimates" element={<Protected Component={CreateEditEstimateScreen} />} />
              <Route path="/services/manage-group" element={<Protected Component={ManageGroupsScreen} />} />
              <Route path="/services/other-settings" element={<Protected Component={OtherSettingsScreen} />} />
              <Route path="/generate-edit-invoice" element={<Protected Component={GenerateEditInvoiceScreen} />} />
              <Route path="/generate-edit-receipt" element={<Protected Component={GenerateEditReceiptScreen} />} />
              <Route path="/generate-invoice" element={<Protected Component={GenerateInvoiceScreen} />} />
              <Route path="/invoice/accounts" element={<Protected Component={InvoiceAccountsScreen} />} />
              <Route path="/invoice/reports" element={<Protected Component={InvoiceReportsScreen} />} />
              <Route path="/invoice/settings" element={<Protected Component={invoiceSettingsScreen} />} />
              <Route path="/invoice/client-info" element={<Protected Component={InvoiceAccountClientInformationScreen} />} />
              <Route path="/configuration/contact-center" element={<Protected Component={ContactsCenterScreen} />} />
              <Route path="/configuration/activity-register" element={<Protected Component={ActivityRegisterScreen} />} />
              <Route path="/configuration/template-center" element={<Protected Component={TemplateRegisterScreen} />} />
              <Route path="/configuration/reminder-register" element={<Protected Component={ReminderRegisterScreen} />} />
              <Route path="/configuration/config-center" element={<Protected Component={ConfigurationCenterScreen} />} />

              <Route path="/notice-board" element={<Protected Component={NoticeBoardScreen} />} />
              <Route path="/dsc-center" element={<Protected Component={DSCCenterScreen} />} />
              <Route path="/income-tax-portal" element={<Protected Component={IncomeTaxPortalScreen} />} />

              <Route path="/add-user" element={<Protected Component={AddUserScreen} />} />
            </Routes>

          </div>
        </div>


      </div>



      {/* </HashRouter> */}
    </GlobalProvider>

  );
}

export default App;
