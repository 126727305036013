import React, { useState, useRef, useEffect } from "react";
import menuIcon from '../../../Assets/Icons/chevron-down-arrow.png';
import searchIcon from '../../../Assets/Icons/search-icon-b.png';
import ScheduleRemindersController from "../../Controllers/AdminScreens/ScheduleRemindersController";

const ScheduleRemindersScreen = () => {

  const {
    // filteredSchedules,
    loading,
    error,
  }=ScheduleRemindersController();
  const [view, setView] = useState("Week"); // Default view is 'Week'
  const [currentDate, setCurrentDate] = useState(new Date());
  const filteredSchedules = {
    "2025-01-01": [{ time: "08:00 AM", title: "New Year's Party" }, { time: "10:00 AM", title: "Family Brunch" }],
    "2025-01-15": [{ time: "02:00 PM", title: "Team Meeting" }],
    "2025-01-20": [{ time: "09:00 AM", title: "Project Kickoff" }],
    "2025-01-25": [{ time: "03:30 PM", title: "Client Meeting" }],
  };

  const filteredHours = Array.from({ length: 24 }, (_, i) => {
    const hour = i % 12 || 12;
    const period = i < 12 ? "AM" : "PM";
    return `${hour}:00 ${period}`;
  });
  // Filter hours to only include 8:00 AM to 8:00 PM
  const hours = filteredHours.slice(8, 21);

  const getHeaderTitle = () => {
    if (view === "Month") {
      return currentDate.toLocaleString("default", { month: "long", year: "numeric" });
    } else if (view === "Week") {
      const weekNumber = Math.ceil((currentDate.getDate() - currentDate.getDay() + 7) / 7);
      return `Week ${weekNumber}, ${currentDate.toLocaleString("default", { month: "long", year: "numeric" })}`;
    } else if (view === "Day") {
      return currentDate.toLocaleString("default", {
        weekday: "long",
        month: "long",
        day: "numeric",
        year: "numeric",
      });
    }
  };

  const handlePrev = () => {
    const newDate = new Date(currentDate);
    if (view === "Day") {
      newDate.setDate(currentDate.getDate() - 1);
    } else if (view === "Week") {
      newDate.setDate(currentDate.getDate() - 7);
    } else if (view === "Month") {
      newDate.setMonth(currentDate.getMonth() - 1);
    }
    setCurrentDate(newDate);
  };

  const handleNext = () => {
    const newDate = new Date(currentDate);
    if (view === "Day") {
      newDate.setDate(currentDate.getDate() + 1);
    } else if (view === "Week") {
      newDate.setDate(currentDate.getDate() + 7);
    } else if (view === "Month") {
      newDate.setMonth(currentDate.getMonth() + 1);
    }
    setCurrentDate(newDate);
  };

  const renderDayView = () => {
    const dayKey = currentDate.toISOString().split("T")[0];
    const daySchedules = filteredSchedules[dayKey] || [];
    return (
      <div style={styles.gridContainer}>
        {hours.map((hour, index) => (
          <div key={index} style={styles.hourRow}>
            <div style={styles.hour}>{hour}</div>
            <div style={styles.scheduleCell}>
              {daySchedules
                .filter((schedule) => schedule.time === hour)
                .map((schedule, i) => (
                  <div key={i} style={styles.scheduleItem}>
                    {schedule.title}
                  </div>
                ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderWeekView = () => {
    const startOfWeek = currentDate.getDate() - currentDate.getDay();
    const weekDates = Array.from({ length: 7 }, (_, i) =>
      new Date(new Date(currentDate).setDate(startOfWeek + i))
    );

    const filteredHours = Array.from({ length: 24 }, (_, i) => {
      const hour = i % 12 || 12;
      const period = i < 12 ? "AM" : "PM";
      return `${hour}:00 ${period}`;
    });
    // Filter hours to only include 8:00 AM to 8:00 PM
    const hours = filteredHours.slice(8, 21);
    return (
      <div style={styles.weekContainer}>
        {/* Time Column */}
        <div style={styles.timeColumn}>
          {hours.map((hour, index) => (
            <div key={index} style={styles.timeCell}>
              {hour}
            </div>
          ))}
        </div>
        {/* Schedule Table */}
        <div style={styles.scheduleTable}>
          {/* Table Header */}
          <div style={styles.weekHeader}>
            {weekDates.map((date, index) => {
              // Check if it's the last column
              const isLastColumn = index === weekDates.length - 1;

              return (
                <div
                  key={index}
                  style={{
                    ...styles.weekDayHeader,
                    border: isLastColumn ? "none" : styles.weekDayHeader.border,
                  }}
                >
                  {date.toLocaleDateString("default", { weekday: "short" })} <br />
                  {date.toLocaleDateString("default", { month: "short", day: "numeric" })}
                </div>
              );
            })}
          </div>
          {/* Table Body */}
          {hours.map((hour, rowIndex) => (
            <div key={rowIndex} style={styles.weekRow}>
              {weekDates.map((date, colIndex) => {
                const dayKey = date.toISOString().split("T")[0];
                const daySchedules = filteredSchedules[dayKey] || [];
                // Check if it's the last column
                const isLastColumn = colIndex === weekDates.length - 1;

                return (
                  <div key={colIndex} style={{ ...styles.scheduleCell, borderRight: isLastColumn ? "none" : styles.scheduleCell.borderRight, }}>
                    {daySchedules
                      .filter((schedule) => schedule.time === hour)
                      .map((schedule, i) => (
                        <div key={i} style={styles.scheduleItem}>
                          {schedule.title}
                        </div>
                      ))}
                  </div>
                );
              })}
            </div>
          ))}
        </div>
      </div>
    );
  };

  const renderMonthView = () => {
    const year = currentDate.getFullYear();
    const month = currentDate.getMonth();

    const firstDay = new Date(year, month, 1).getDay();
    const lastDate = new Date(year, month + 1, 0).getDate();

    const days = [];
    for (let i = 0; i < firstDay; i++) {
      days.push(null);
    }
    for (let day = 1; day <= lastDate; day++) {
      days.push(new Date(year, month, day));
    }

    return (
      <div style={styles.monthGrid}>
        {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day, index) => (
          <div key={index} style={styles.weekDayHeader}>
            {day}
          </div>
        ))}
        {days.map((date, index) => (
          <div key={index} style={styles.dayCell}>
            {date && (
              <>
                <span style={styles.dayNumber}>{date.getDate()}</span>
                <div style={styles.scheduleCell}>
                  {(filteredSchedules[date.toISOString().split("T")[0]] || []).map((event, i) => (
                    <div key={i} style={styles.scheduleItem}>
                      {event.title}
                    </div>
                  ))}
                </div>
              </>
            )}
          </div>
        ))}
      </div>
    );
  };

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <div style={styles.arrowBtnDiv}>
          <button onClick={handlePrev} style={{ ...styles.btnStyle }}>
            <img style={{ ...styles.btnImgStyle, transform: 'rotate(180deg)' }} src={menuIcon} alt="prv" />

          </button>
          <div style={{...styles.selectedDate }}>{getHeaderTitle()}</div>
          <button onClick={handleNext} style={{ ...styles.btnStyleRight }}>
            <img style={{ ...styles.btnImgStyle }} src={menuIcon} alt="next" />
          </button>
        </div>
        <div style={{margin:'auto'}}>
          {["Day", "Week", "Month"].map((v) => (
            <button
              key={v}
              onClick={() => setView(v)}
              style={{
                ...styles.viewButton,
                backgroundColor: view === v ? "red" : "#fff",
                color: view === v ? "#fff" : "#333",
              }}
            >
              {v}
            </button>
          ))}
        </div>
        <div style={styles.searchContainer}>
      <img
        src={searchIcon}
        alt="Search Icon"
        style={styles.icon}
      />
      <input
        type="text"
        style={styles.input}
        placeholder="Search"
      />
    </div>
      </div>
      {view === "Day" && renderDayView()}
      {view === "Week" && renderWeekView()}
      {view === "Month" && renderMonthView()}
    </div>
  );
};

const styles = {
  container: {
    width: "90%",
    padding: "30px",
    boxSizing: "border-box",
    backgroundColor: '#FFFFFF',
    margin: 'auto',
    borderRadius: '10px'
  },
  header: {
    display: "flex",
    // justifyContent: "space-between",
    alignItems: "center",
    marginBottom: "20px",
  },
  gridContainer: {
    display: "grid",
    gridTemplateRows: "repeat(auto-fill, minmax(50px, 1fr))",
    border: "1px solid #ccc",
    borderRadius: "8px",
    overflow: "hidden",
  },
  monthGrid: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    gap: "5px",
  },

  hourRow: {
    display: "grid",
    gridTemplateColumns: "1fr 7fr",
    borderBottom: "1px solid #ccc",
  },
  hour: {
    padding: "10px",
    textAlign: "right",
    fontWeight: "bold",
    backgroundColor: "#f1f1f1",
    borderRight: "1px solid #ccc",
  },
  dayCell: {
    border: "1px solid #ccc",
    borderRadius: "5px",
    minHeight: "100px",
    position: "relative",
    padding: "5px",
  },
  dayNumber: {
    fontWeight: "bold",
  },

  viewButton: {
    margin: "0 5px",
    padding: "5px 10px",
    border: "none",
    borderRadius: "5px",
    cursor: "pointer",
  },
  weekContainer: {
    display: "flex",
    flexDirection: "row",
    width: "100%",
    overflowX: "auto",
  },
  timeColumn: {
    display: "flex",
    flexDirection: "column",
    // borderRight: "1px solid #ccc",
    marginRight: "5px",
    paddingTop: '50px'
  },
  timeCell: {
    height: "45px",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontSize: "12px",
    fontWeight: "bold",
    color: "#333",
    // borderBottom: "1px solid #ccc",
  },
  scheduleTable: {
    flex: 1,
  },
  weekHeader: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    backgroundColor: "#f8f8f8",
    borderBottom: "1px solid #ccc",
  },
  weekDayHeader: {
    textAlign: "center",
    padding: "10px 0",
    fontWeight: "bold",
    fontSize: "12px",
    borderRight: "1px solid #ccc",
    backgroundColor: '#FFFFFF'
  },
  weekRow: {
    display: "grid",
    gridTemplateColumns: "repeat(7, 1fr)",
    borderBottom: "1px solid #ccc",
  },
  scheduleCell: {
    borderRight: "1px solid #ccc",
    height: "35px",
    padding: "5px",
    position: "relative",
  },
  scheduleItem: {
    backgroundColor: "#e0f7fa",
    color: "#00796b",
    fontSize: "10px",
    padding: "2px 5px",
    borderRadius: "3px",
    marginBottom: "2px",
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    height:'90%',
    borderLeft: '3px solid #056e7b'
  },
  btnStyle: {
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: '3px',
    borderTopLeftRadius: '5px',
    borderBottomLeftRadius: '5px',
  },
  btnStyleRight: {
    border: 'none',
    cursor: 'pointer',
    display: 'flex',
    padding: '6px 3px',
    borderTopRightRadius: '5px',
    borderBottomRightRadius: '5px',
  },
  btnImgStyle: {
    width: '16px',
    height: '16px',
    filter: 'brightness(1) invert(1)'
  },
  arrowBtnDiv: {
    display: 'flex',
    alignItems: 'center',
    gap: '5px'
  },
  selectedDate: {
    color: '#18181B',
    fontSize: '12px',
    fontWeight: 400,
    lineHeight: '16px',
    wordWrap: 'break-word',
    backgroundColor:'#F4F4F5',
    padding:'6px'
  },
  searchContainer: {
    display: "flex",
    alignItems: "center",
    backgroundColor: "#f5f5f5", // Background color matching the uploaded UI
    borderRadius: "8px", // Rounded corners
    padding: "5px 10px",
    width: "170px", // Adjust width as needed
    boxShadow: "0px 1px 2px rgba(0, 0, 0, 0.1)", // Optional shadow
  },
  icon: {
    width: "16px", // Icon size
    height: "16px",
    marginRight: "8px", // Space between icon and input
  },
  input: {
    border: "none",
    outline: "none",
    backgroundColor: "transparent",
    fontSize: "14px",
    color: "#7d7d7d", // Text color
    flex: 1, // Allow input to fill remaining space
  },
  placeholder: {
    color: "#b0b0b0", // Placeholder text color
  },
};

export default ScheduleRemindersScreen;
