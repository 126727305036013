import React, { useState, useRef, useEffect } from 'react';
import TextInput from '../TextInput';
import CheckBox from '../CheckBox';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import closeIcon from '../../Assets/Icons/close.png';
import search from '../../Assets/Icons/search.png';

const GenerateReceiptModal = ({ isOpen, onClose }) => {
  const [searchInvoice, setSearchInvoice] = useState('');
  const [advancePayment, setAdvancePayment] = useState(false);
  const navigate = useNavigate(); // Initialize navigate for routing

  // Handler for Add button
  const handleAdd = () => {
    const data = {
      searchInvoice,
      advancePayment,
    };
    //console.log('Receipt Data:', data); // Optional: log data for debugging

    // Navigate to another page with the data
    navigate('/generate-edit-receipt', { state: data });

    // Close the modal
    resetForm();
    onClose();
  };

  // Reset form values
  const resetForm = () => {
    setSearchInvoice('');
    setAdvancePayment(false);
  };

  // Handler for modal close
  const handleModalClose = () => {
    resetForm(); // Reset form when closing
    onClose(); // Trigger the parent onClose handler
  };

  // If `isOpen` is false, return null (don't render the modal)
  if (!isOpen) {
    return null;
  }

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        {/* Close Button */}
        <button className="close-button" onClick={handleModalClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>

        {/* Modal Title */}
        <h2 className="modal-title">Generate Receipt</h2>
        <hr className="title-divider" />

        {/* Search Invoice Input */}
        {/* <div className="form-grid"> */}
          <div className="filter-field" style={{ marginBottom: '15px' }}>
            <label
              style={{
                ...styles.labaleStyle,
                // marginBottom: '8px',
                display: 'block',
              }}
            >
              Search Invoice
            </label>
            <TextInput
              type="text"
              placeholder="Search Invoice"
              value={searchInvoice}
              onChange={(e) => setSearchInvoice(e.target.value)}
              right={<img src={search} alt="Search" className="search-icon" />}
              style={{ padding: '20px 10px' }}
            />
          </div>
        {/* </div> */}

        {/* Advance Payment Checkbox */}
        <div className="existing-client-checkbox" style={{marginBottom:'25px'}}>
          {/* <label style={styles.exisitingHeading}>Advance Payment Receipt</label> */}
          {/* <input
            type="checkbox"
            checked={advancePayment}
            onChange={(e) => setAdvancePayment(e.target.checked)}
          /> */}
          <CheckBox
            label="Advance Payment Receipt"
            checked={advancePayment}
            onChange={(isChecked) => setAdvancePayment(isChecked)}
            style={styles.checkboxContainer}
            labelStyle={styles.checkboxLabel}
          />
        </div>
        <hr className="footer-divider" />

        {/* Action Buttons */}
        <div className="button-container">
          <button className="add-client-button" onClick={handleAdd} style={{ paddingRight: '30px', paddingLeft: '30px' }}>
            Add
          </button>
          <button className="cancel-button" onClick={handleModalClose}>
            Cancel
          </button>
        </div>
      </div>
    </div>
  );
};

const styles = {
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)',
  },
  iconImage: {
    width: '12px',
    height: '12px',
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto'

  }
};

export default GenerateReceiptModal;
