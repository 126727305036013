import React,{useEffect,useState} from 'react';
import ForgotPasswordController from '../../Controllers/AuthenticationScreens/ForgotPasswordContoller';
import TextInput from '../../../Components/TextInput';
import Loader from '../../../Components/Loader';
import logo from '../../../Assets/Images/logo.png';

const ForgotPasswordScreen = () => {
    const {
        step,
        email,
        newPassword,
        confirmPassword,
        error,
        loading,
        setEmail,
        setNewPassword,
        setConfirmPassword,
        handleEmailSubmit,
        handlePasswordSubmit,
        resetState,
        showNewPassword,
        showConfirmPassword,
        toggleShowNewPassword,
        toggleShowConfirmPassword,
        handleLoginRedirect,
        emailError,
        newPasswordError,
        confirmPasswordError,
        verifyCode, setVerifyCode,
        verifyCodeError,
        success,
        emailLoading
    } = ForgotPasswordController();

      const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 550);
    
      useEffect(() => {
        const handleResize = () => {
          setIsSmallScreen(window.innerWidth <= 550);
        };
    
        window.addEventListener('resize', handleResize);
    
        return () => {
          window.removeEventListener('resize', handleResize);
        };
      }, []);

    return (
        <div className="login">
            <div className="frameParent" style={{ width: '80%' }}>
                {step === 1 && (
                    <div className="frameGroup">
                        <div className="groupParent">
                            <img className="frameChild" alt="" src={logo} />
                            <div className="companyName">Company Name</div>
                        </div>
                        <h2 style={{ color: '#609966', fontSize: '18px',fontFamily:'Roboto Slab' }}>Forgot Password</h2>
                        <div style={{width:isSmallScreen ?null:'275px', wordWrap: 'break-word', fontSize: '12px', textAlign: 'center',fontWeight:500 }}>
                            <p>Please enter your username and we will send you instructions to reset your password. address</p>
                        </div>
                            <TextInput
                                placeholder="Username"
                                value={email}
                                onChange={(e) => setEmail(e.target.value)}
                                // style={{ minWidth: '360px' }}
                                searchBoxWidth={360}
                                errorMessage={emailError}
                            />
                        {error && <p className="list-error-msg" style={{ padding: 0 }}>{error}</p>}
                        {success && <p className="list-success-msg" style={{ padding: 0 }}>{success}</p>}
                        <button className="verifyButton" onClick={handleEmailSubmit} disabled={emailLoading} style={{ opacity: emailLoading ? 0.5 : 1, cursor: emailLoading ? 'not-allowed' : 'pointer', }}>
                            <Loader loading={emailLoading} color={'#FFFFFF'} size="small" />
                            <span style={{ paddingLeft: emailLoading ? 10 : 0 }}>Continue</span>
                        </button>
                        <div style={{ textAlign: 'center' }}>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', wordWrap: 'break-word' }}>Not sure about username, </span>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', textDecoration: 'underline', wordWrap: 'break-word' }}>contact admin.</span>
                        </div>
                    </div>
                )}

                {step === 2 && (
                    <div className="frameGroup">
                        <div className="groupParent">
                            <img className="frameChild" alt="" src={logo} />
                            <div className="companyName">Company Name</div>
                        </div>
                        <h2 style={{ color: '#609966', fontSize: '18px',fontFamily:'Roboto Slab' }}>Forgot Password</h2>
                        <p>Update password for username: <strong>{email}</strong></p>
                      
                            <TextInput
                                placeholder="Verification Code"
                                value={verifyCode}
                                onChange={(e) => setVerifyCode(e.target.value)}
                                // style={{ maxWidth: '360px' }}
                                searchBoxWidth={360}
                                errorMessage={verifyCodeError}
                                inputType='number'
                                maxLength='6'
                            />
                            <TextInput
                                placeholder="New Password"
                                value={newPassword} // Ensure value is a string
                                onChange={(e) => setNewPassword(e.target.value)}
                                type={showNewPassword ? 'text' : 'password'}
                                // style={{ maxWidth: '360px' }}
                                // right={
                                //     <button onClick={toggleShowNewPassword} className="toggle-button">
                                //         {showNewPassword ? (
                                //             <img src={require('../../../Assets/Icons/Eye off.png')} alt="Hide " />
                                //         ) : (
                                //             <img src={require('../../../Assets/Icons/Eye-2.png')} alt="Show " />
                                //         )}
                                //     </button>
                                // }
                                searchBoxWidth={360}
                                errorMessage={newPasswordError}
                            />
                            <TextInput
                                placeholder="Re-Enter Password"
                                value={confirmPassword} // Ensure value is a string
                                onChange={(e) => setConfirmPassword(e.target.value)}
                                type={showConfirmPassword ? 'text' : 'password'}
                                // style={{ maxWidth: '360px' }}
                                // right={
                                //     <button onClick={toggleShowConfirmPassword} className="toggle-button">
                                //         {showConfirmPassword ? (
                                //             <img src={require('../../../Assets/Icons/Eye off.png')} alt="Hide " />
                                //         ) : (
                                //             <img src={require('../../../Assets/Icons/Eye-2.png')} alt="Show " />
                                //         )}
                                //     </button>
                                // }
                                searchBoxWidth={360}
                                errorMessage={confirmPasswordError}
                            />
                        {error && <p className="list-error-msg" style={{ padding: 0 }}>{error}</p>}
                        {success && <p className="list-success-msg" style={{ padding: 0 }}>{success}</p>}
                        <button className="verifyButton" onClick={handlePasswordSubmit} disabled={loading} style={{ opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }}>
                            <Loader loading={loading} color={'#FFFFFF'} size="small" />
                            <span style={{ paddingLeft: loading ? 10 : 0 }}>Continue</span>
                        </button>
                        <div style={{ textAlign: 'center', display: 'flex', flexWrap: 'wrap', gap: 5 }}>
                            <span style={{ color: '#8388A2', fontSize: '12px', fontWeight: '500', wordWrap: 'break-word' }}>Didn't receive the code? </span>
                            <span onClick={handleEmailSubmit} style={{
                                color: '#8388A2', fontSize: '12px', fontWeight: '500', textDecoration: 'underline',
                                wordWrap: 'break-word', opacity: emailLoading ? 0.5 : 1, cursor: emailLoading ? 'not-allowed' : 'pointer', display: 'flex', gap: 5
                            }}>
                                Resend now <Loader loading={emailLoading} color={'#FFFFFF'} size="small" /></span>
                        </div>
                    </div>
                )}

                {step === 3 && (
                    <div className="frameGroup" style={{ gap: '65px' }}>
                        <div className="groupParent">
                            <img className="frameChild" alt="" src={logo} />
                            <div className="companyName">Company Name</div>
                        </div>
                        <h2 style={{ color: '#609966', fontSize: '28px', }}>Password Reset Successful</h2>
                        <button className="verifyButton" onClick={handleLoginRedirect}>
                            <span style={{ paddingLeft: loading ? 10 : 0 }}>Login Now</span>
                        </button>
                    </div>
                )}
            </div>
        </div>
    );
};

export default ForgotPasswordScreen;
