import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import TextInput from "../TextInput";
import DateInput from "../DateInput";
import closeIcon from "../../Assets/Icons/close.png";
import search from "../../Assets/Icons/search.png";
import { postData, getData } from '../../ApiServices/ApiService';
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_TODO_SCHEDULE_ADD_DASHBOARD, ORG_TODO_SCHEDULE_UPDATE_DASHBOARD } from '../../ApiServices/BaseURL';
import Loader from "../Loader";
import errorIcon from '../../Assets/Icons/error_message.png';

const AddToDoModal = ({ isOpen, onClose, onValueChange, fetchToDoList, documentData, clientInfo, clientFileId, fetchPriorityList}) => {
  //console.log("documentData in tod edit", documentData);
  const typeOptions = ['To-Do'];
  const [type, setType] = useState(typeOptions[0]);
  const [dateTime, setDateTime] = useState("");
  const [clientName, setClientName] = useState("");
  const [fileNo, setFileNo] = useState("");
  const [description, setDescription] = useState("");
  const [loading, setLoading] = useState(false);
  const [clientList, setClientList] = useState([]);
  const [error, setError] = useState('');
  const [searchUser, setSearchUser] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [documentDescriptionError, setDocumentDescriptionError] = useState('');
  const [selectedClientError, setSelectedClientError] = useState('');
  const [selectedClient, setSelectedClient] = useState(null);
  const [descriptionError, setDescriptionError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [dateTimeError, setDateTimeError] = useState('');
  const [status, setStaus] = useState('')

  
    useEffect(()=>{
      if(clientInfo){
        //console.log('client_file_id:',clientFileId)
        setSelectedClient(clientInfo);
        setSearchUser(clientInfo?.client_name);
        setFileNo(clientFileId)
      }
  
    },[clientInfo])

  useEffect(() => {
    if (isOpen && documentData) {
      //console.log("documentData", documentData);

      setDescription(documentData?.sc_desc || '');
      setStaus(documentData?.sc_status);
      setSearchUser(documentData?.client_name || '');
      setSelectedClient(documentData);
      if (documentData?.sc_time) {
        const currentTime = new Date().toTimeString().split(' ')[0] + '.' + new Date().getMilliseconds(); // Get time in HH:mm:ss.SSS

        const datePart = documentData?.sc_time.split(' ')[0];
        const dateTimeWithTime = `${datePart} ${currentTime}`;
        setDateTime(documentData?.sc_time.replace(' ','T'));
      } else {
        setDateTime('');
      }
    }
  }, [isOpen, documentData]);

  const handleAddSchedule = () => {
    return
    const scheduleData = {
      type,
      dateTime,
      clientName,
      fileNo,
      description,
    };
    onValueChange(scheduleData);
    onClose();
  };

  const resetForm = () => {
    setType("");
    setDateTime("");
    setClientName("");
    setFileNo("");
    setDescription("");
  };

  const handleSearchInputText = (value) => {
    setSearchUser(value);
    setClientNameError('');
    setClientList([]);
    setSelectedClient([]);
    if (!value) {
      setSelectedClient([]);
      setSearchUser('');
    }
};

  const handleSelectClient = (client) => {
    setSelectedClient(client); // Set selected client data
    setSearchUser(client?.client_name); // Update TextInput with the selected client's name
    setClientList([]); // Clear the dropdown
    setSelectedClientError('');
  };

  const handleSearchClient = async () => {
    try {
      setError('');
      setSelectedClientError('');
      setLoading(true);
      setClientList([]);
      if (!searchUser) {
        setError('Please enter valid text'); return;
      }
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

      //console.log('team Data', payLoad);
      // Make the API call
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList(responseData?.dataJ || []);
            // setSuccess(responseData?.info);
            setTimeout(() => {
              // handleClose();
              // onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Client.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  };

  //   const handleDateChange = (date) => {
  //     setDateTime(date);
  //     if (!date) {
  //       setDateTimeError('Please select a date.');
  //     } else {
  //       setDateTimeError(''); 
  //     }
  //   };
  const handleDateChange = (date) => {
    if (!date) {
      setDateTimeError('Please select a date.');
      setDateTime(''); // Reset state if invalid
    } else {
      setDateTimeError('');
      const currentTime = new Date().toTimeString().split(' ')[0] + '.' + new Date().getMilliseconds(); // Get time in HH:mm:ss.SSS
      const dateTimeWithTime = `${date} ${currentTime}`;
      setDateTime(date); // Set date and time together
    }
  };

  const [addToDoScheduleError, setAddToDoScheduleError] = useState('')
  const [toDoScheduleLoading, setToDoScheduleLoading] = useState(false)
  const [success, setSuccess] = useState('');

  const handleClose = () => {
    setAddToDoScheduleError('');
    setDescriptionError('');
    setDateTimeError('');
    setTypeError('');
    setSuccess('')
    onClose();
    setDateTime('');
    setDescription('')
    setType(typeOptions[0]);
    setSearchUser('');
    setClientNameError('');
    setDocumentDescriptionError('');
    setError('')
    setSelectedClient([]);
    setClientList([]);
  }

  const handleScheduleToDoAdd = async () => {

    try {
      setAddToDoScheduleError('');
      setDescriptionError('');
      setDateTimeError('');
      setTypeError('');
      setClientNameError('');
      setDocumentDescriptionError('');

      if (!type) {
        setTypeError("Please enter select type");
        return
      }
      if (!dateTime) {
        setDateTimeError("Please select date");
        return
      }
      // if(!documentNumber){
      //   setDocumentNUmberError("Please enter document number");
      //   return
      // }
      // if(!description){
      //   setDateTimeError("Please enter description");
      //   return
      // }
      if (!searchUser || !selectedClient.client_id ) {
        setClientNameError("Please select client");
        return
      }
      if(!description){
        setDocumentDescriptionError("Please enter document description");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setToDoScheduleLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, client_id: selectedClient.client_id, sc_desc: description, sc_time: dateTime.replace('T',' '), emp_id: storedUserInfo?.emp_id }

      //console.log('handleSearchEmployee payload', payLoad);
      // Make the API call
      const response = await postData(ORG_TODO_SCHEDULE_ADD_DASHBOARD, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setAddToDoScheduleError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              // onValueChange();
              fetchToDoList();
              fetchPriorityList();
            }, 3000);
          } else {
            setAddToDoScheduleError(responseData?.info || 'Failed to add schedule. Please try again.');
          }
        } else {
          setAddToDoScheduleError(responseData?.info || 'Failed to add schedule.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setAddToDoScheduleError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setAddToDoScheduleError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setAddToDoScheduleError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setAddToDoScheduleError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setAddToDoScheduleError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add schedule. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setAddToDoScheduleError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setAddToDoScheduleError(`Failed to add schedule. Please check your network connection and try again.`);
      }
    } finally {
      setToDoScheduleLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const handleScheduleToDoUpdate = async () => {

    try {
      setAddToDoScheduleError('');
      setDescriptionError('');
      setDateTimeError('');
      setTypeError('');
      setClientNameError('');
      setDocumentDescriptionError('');

      if (!type) {
        setTypeError("Please enter select type");
        return
      }
      if (!dateTime) {
        setDateTimeError("Please select date");
        return
      }
      // if(!documentNumber){
      //   setDocumentNUmberError("Please enter document number");
      //   return
      // }
      // if(!description){
      //   setDateTimeError("Please enter description");
      //   return
      // }
      if (!searchUser) {
        setClientNameError("Please select client");
        return
      }
      if(!description){
        setDocumentDescriptionError("Please enter document description");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setToDoScheduleLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, client_id: documentData?.client_id, sc_desc: description, sc_time: dateTime.replace('T',' '), emp_id: storedUserInfo?.emp_id, sc_id: documentData?.sc_id, sc_status: status }

      //console.log('handleSearchEmployee payload', payLoad);
      // Make the API call
      const response = await postData(ORG_TODO_SCHEDULE_UPDATE_DASHBOARD, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setAddToDoScheduleError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              // onValueChange();
              fetchToDoList();
            }, 3000);
          } else {
            setAddToDoScheduleError(responseData?.info || 'Failed to add schedule. Please try again.');
          }
        } else {
          setAddToDoScheduleError(responseData?.info || 'Failed to add schedule.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setAddToDoScheduleError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setAddToDoScheduleError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setAddToDoScheduleError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setAddToDoScheduleError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setAddToDoScheduleError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add schedule. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setAddToDoScheduleError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setAddToDoScheduleError(`Failed to add schedule. Please check your network connection and try again.`);
      }
    } finally {
      setToDoScheduleLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => { resetForm(); handleClose(); }}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        <h2 className="modal-title">{documentData ? "Update Schedule/Reminder" : "Add Schedule/Reminder"}</h2>
        <hr className="title-divider" />

        <div className="form-grid">
          <Dropdown
            ref={(el) => (dropdownRefs.current[" "] = el)}
            isOpen={openDropdown === " "}
            toggleDropdown={() => toggleDropdown(" ")}
            label="Select Type"
            options={typeOptions}
            value={type}
            onChange={setType}
            // searchBoxWidth={260}
            errorMessage={typeError}
          />
          <div style={{ marginTop: '-5px' }} >
            <label style={{ fontSize: '12px', color: '#343C44' }}>Due By</label>
            <DateInput
              label="Published Date"
              value={dateTime}
              onChange={(e) => handleDateChange(e.target.value)}
              // searchBoxWidth={260}
              errorMessage={dateTimeError}
              isDateTime={true}
              // min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          {/* <div> */}
          {documentData?
          (
            <div style={{paddingTop:'5px'}}>
              <Input
                label="Client"
                type="text"
                value={searchUser}
                style={{ padding: '20px 10px' }}
                width={280}
                isEdit={false}
              />
            </div>
           
          ):(
            <div className="filter-field">
            <label>Client</label>
            <TextInput
              type="text"
              placeholder="Client"
              value={searchUser}
              onChange={(e) => handleSearchInputText(e.target.value)}
              onRightClick={handleSearchClient}
              right={
                loading ? (
                  <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                ) : (
                  <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                )
              }
              style={{ padding: '20px 10px' }}
              errorMessage={clientNameError || error}
              width={280}
            />
            {clientList.length > 0 && (
              <ul style={styles.dropdownList}>
                {clientList.map((client, index) => (
                  <li
                    key={client?.client_id}
                    onClick={() => handleSelectClient(client)}
                    style={{
                      ...styles.dropdownItem,
                      borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                    }}
                  >
                    {client?.client_name}
                  </li>
                ))}
              </ul>
            )}
          </div>
          )}
          
          {/* </div> */}

          {/* <div> */}
          <div className="filter-field">
            <label>File No.</label>
            <TextInput
              type="text"
              placeholder="File No."
              value={selectedClient?.client_file_id}
              onChange={(e) => setFileNo(e.target.value)}
              // right={<img src={search} alt="Search" className="search-icon" />}
              // searchBoxWidth={250}
              disabled={true}
            />
          </div>
          {/* </div> */}
        </div>
        <div style={{ marginTop: '14px' }}>
          <label style={{ ...styles.label, marginBottom: '5px' }}>Description</label>
          <textarea
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            placeholder="Enter Description"
            style={{ ...styles.textarea, borderColor: documentDescriptionError ? 'red' : '#D6E4EC' }}
          ></textarea>
          {documentDescriptionError && <div className="list-error-msg" style={{ display: 'flex', padding: '0px', fontSize: '10px', fontWeight: '400', }}><img
          src={errorIcon}
          alt="Error"
          style={{ width: "12px", height: "12px", marginRight: '4px' }}
          />{documentDescriptionError} </div>}
        </div>

        <hr className="footer-divider" />

        <div className="button-container" style={{ alignItems: 'center' }}>
          <button
            className="add-client-button"
            onClick={documentData ? handleScheduleToDoUpdate : handleScheduleToDoAdd}
            disabled={toDoScheduleLoading}
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px', // Add spacing between text and loader
              position: 'relative',
              opacity: toDoScheduleLoading ? 0.5 : 1, // Reduce opacity when loading
              cursor: toDoScheduleLoading ? 'not-allowed' : 'pointer',
            }}
          >
            <span>{documentData ? 'Update' : 'Add'}</span>
            {toDoScheduleLoading && (
              <Loader loading={toDoScheduleLoading} color={'var(--background-color)'} size="small" />
            )}
          </button>
          <button className="cancel-button" onClick={() => { resetForm(); handleClose(); }}>Cancel</button>
          {addToDoScheduleError && <div className="list-error-msg" style={{ marginBottom: '1px' }}>{addToDoScheduleError} </div>}
          {success && <div className="list-success-msg" style={{ marginBottom: '1px' }}>{success} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  iconImage: {
    width: '15px',
    height: '15px',
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  label: {
    fontSize: "12px",
    color: "#343c44",
    fontWeight: "500",
  },
  textarea: {
    width: "96%",
    padding: "10px",
    border: "1px solid #D6E4EC",
    borderRadius: "5px",
    fontSize: "12px",
    minHeight: "60px",
    resize: "none",
    marginTop: '5px',
    fontFamily: 'Roboto',
    color: '#343C44',
    outline: "none",
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
};

export default AddToDoModal;
