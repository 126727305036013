
import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { ORG_DOWNLOAD_KYC_DOCUMENTS, ORG_DELETE_KYC_DOCUMENT, ORG_DELETE_DOCUMENT_RACK, ORG_DOWNLOAD_DOCUMENT_RACK} from '../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../ApiServices/ApiService";

const KYCDocumentsController = (clientId, onRefresh)=>{
    const [downloadKycLoading, setDownloadKycLoading] = useState(false);
    const [kycDownloadError, setKYCDownloadError] = useState('');
    const [currentDocIndex, setCurrentDocIndex] = useState(null);
    const [selectedDocument, setSelectedDocument] = useState(null);
    const [loading, setLoading] = useState(false);
    const [success, setSuccess] = useState('');
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [currentDocDeleteIndex, setCurrentDocDeleteIndex] = useState(null);

    const handleDelete = (doc, index) => {
      setSelectedDocument({ doc, index }); // Store the credential to delete
      setIsDeleteModalOpen(true); // Open the confirmation modal
  };

  const handleOpenDeleteModal = () => {
      setIsDeleteModalOpen(true); // Open the modal
  };

  const handleCloseDeleteModal = () => {
      setIsDeleteModalOpen(false); // Close the modal
  };
    // const [success, setSuccess] = useState('');


    const downloadKYCDocuments =async (docId,index)=>{
        try {
            setCurrentDocIndex(index);
            setDownloadKycLoading(true);
            setKYCDownloadError('');
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_DOWNLOAD_DOCUMENT_RACK}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}&doc_id=${docId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const downloadUrl = responseData?.dataJ?.[0]?.download_url;
                    if (downloadUrl) {
                        try {
                          // Fetch the file as a blob
                          const fileResponse = await fetch(downloadUrl);
                          const blob = await fileResponse.blob();
              
                          // Create a temporary object URL for the blob
                          const url = window.URL.createObjectURL(blob);
              
                          // Create a link and trigger download
                          const link = document.createElement('a');
                          link.href = url;
              
                          // Extract the file extension from the download URL or set a default
                          const fileExtension = downloadUrl.includes('.')
                            ? downloadUrl.split('.').pop().split('?')[0]
                            : ''; // Default to 'pdf' if no extension found
              
                          const filename = `Document_${docId}.${fileExtension}`;
                          link.setAttribute('download', filename);
              
                          document.body.appendChild(link);
                          link.click();
              
                          // Clean up
                          document.body.removeChild(link);
                          window.URL.revokeObjectURL(url);
                        } catch (error) {
                          console.error('Error downloading file:', error);
                          setKYCDownloadError('An error occurred while attempting to download the file. Please try again.');
                        }
                      } else {
                        setKYCDownloadError('Download URL not available.');
                      }
                  } else {
                    setKYCDownloadError(responseData?.info || 'Data Not Found');
                  }
                } else {
                    setKYCDownloadError(responseData?.info || 'Failed to fetch KYC document. Please try again.');
                }
              } else {
                setKYCDownloadError(responseData?.info || 'Failed to fetch KYC document.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setKYCDownloadError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setKYCDownloadError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setKYCDownloadError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setKYCDownloadError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setKYCDownloadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch KYC document. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setKYCDownloadError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setKYCDownloadError(`Failed to fetch KYC document. Please check your network connection and try again.`);
            }
          } finally {
            setCurrentDocIndex(null);
            setDownloadKycLoading(false);
          }
      }

      const handleKYCDocumentDelete = async (docId) => {
   
        try {
          const { doc, index } = selectedDocument;
          //console.log("index", index)
            setKYCDownloadError('');  
            setSuccess('')
            // if (!searchUser) {
            //   setError('Please enter valid text'); return;
            // }         
            setLoading(true);
            setCurrentDocDeleteIndex(index)
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, doc_id:doc.doc_id}
        
            //console.log('handleSearchEmployee payload',payLoad);
            // Make the API call
            const response = await postData(ORG_DELETE_DOCUMENT_RACK,payLoad);
            const responseData = await response.json();
            //console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                    setKYCDownloadError('');
                    setSuccess(responseData?.info);
                    setTimeout(()=>{
                      setSuccess('')
                      onRefresh();
                    },3000);
                    // setEmployeeList(responseData?.dataJ || []);
                } else {
                    setKYCDownloadError(responseData?.info || 'Failed to delete kyc document. Please try again.');
                }
              } else {
                setKYCDownloadError(responseData?.info || 'Failed to delete kyc document.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setKYCDownloadError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setKYCDownloadError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setKYCDownloadError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setKYCDownloadError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setKYCDownloadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete kyc document. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setKYCDownloadError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setKYCDownloadError(`Failed to delete kyc document. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
            setCurrentDocDeleteIndex(null)
          }
    
        // onValueChange(teamData);
        // onClose();
    };



      return{
        downloadKycLoading,
        kycDownloadError,
        downloadKYCDocuments,
        currentDocIndex,
        setSelectedDocument,
        selectedDocument,
        handleKYCDocumentDelete,
        loading,
        success,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        currentDocDeleteIndex
      }
}

export default KYCDocumentsController