

export const validateEmail = (email) => {
  const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
  return re.test(String(email).toLowerCase());
};


export const validatePassword = (password) => {
  // Password should be at least 8 characters long and include a number and a special character
  const re = /^(?=.*[0-9])(?=.*[!@#$%^&*])(?=.*[a-z])(?=.*[A-Z]).{8,}$/;
  return re.test(password);
};

export const validateTempPassword = (tempPassword) => {
  const re = /^[A-Za-z0-9!@#$%^&*()_+-=]{6,12}$/;
  return re.test(tempPassword);
};


export const validateTime = (operatingHours) => {
  const re = /^([0-9]|[01]\d|2[0-3]):([0-5]\d)-([0-9]|[01]\d|2[0-3]):([0-5]\d)$/;
  return re.test(operatingHours)

};

export const validateMobileNumber = (mobileNumber) => {
  const re = /^[0-9]{10}$/; // Exactly 10 digits
  return re.test(mobileNumber);
};

export const validateVerifyCode = (mobileNumber) => {
  const re = /^[0-9]{6}$/; // Exactly 10 digits
  return re.test(mobileNumber);
};

export const validateText = (text) => {
  const re = /^[A-Za-z]+$/; // Only alphabets
  return re.test(text);
};

export const validateNumber = (number) => {
  const re = /^[0-9]+$/; // Only digits
  return re.test(number);
};

export const formatToLocalTime = (dateString) => {
  const date = new Date(dateString);

  const options = {
    hour: "2-digit",
    minute: "2-digit",
    // second: "2-digit",
    hour12: true,
    year: "numeric",
    month: "2-digit",
    day: "2-digit",
  };

  // Format using toLocaleString
  return date.toLocaleString(navigator?.language || "en-IN", options).replace(",", "");
};

export function convertToLocalTime(gmtDateString,option) {
  // Parse the GMT time
  const gmtDate = new Date(gmtDateString + " UTC");

  // Convert to local time
  const options = {
    weekday: "long", // Display the full day name (e.g., Monday)
    month: "short",  // Display the short month name (e.g., Dec)
    day: "2-digit",  // Display the day as two digits (e.g., 31)
    hour: "2-digit", // Display the hour (e.g., 05 or 17 for PM)
    minute: "2-digit", // Display the minutes (e.g., 55)
    hour12: true,    // Use 12-hour format (AM/PM)
    year: "numeric",
  };
    // Convert to local time
    const options2 = {
      month: "short",  // Display the short month name (e.g., Dec)
      day: "2-digit",  // Display the day as two digits (e.g., 31)
      year: "numeric",
    };

    const options3 = {
      month: "2-digit",  // Display the short month name (e.g., 12)
      day: "2-digit",  // Display the day as two digits (e.g., 31)
      hour: "2-digit", // Display the hour (e.g., 05 or 17 for PM)
      minute: "2-digit", // Display the minutes (e.g., 55)
      hour12: true,    // Use 12-hour format (AM/PM)
      year: "numeric",
    }

  // Format to local time with the specified options
  const localDate = new Intl.DateTimeFormat("en-GB",option === "full" ? options :option === "shortDateTime" ? options3 :options2).format(gmtDate);

  return localDate;
}

export function formatScheduleTime(utcDateString) {
  if (!utcDateString) return "";

  // Convert UTC string to a Date object (Ensure it's interpreted as UTC)
  const startDate = new Date(utcDateString + " UTC");

  // Format for "Monday, Oct 21"
  const dateOptions = {
    weekday: "long", // Display the full day name (e.g., Monday)
    month: "short",  // Display the short month name (e.g., Dec)
    day: "2-digit",  // Display the day as two digits (e.g., 31)
    hour: "2-digit", // Display the hour (e.g., 05 or 17 for PM)
    minute: "2-digit", // Display the minutes (e.g., 55)
    hour12: true,    // Use 12-hour format (AM/PM)
    year: "numeric",
  };

  // Convert to Local Time
  const localDateStr = new Intl.DateTimeFormat("en-US", dateOptions).format(startDate);

  return `${localDateStr}`;
}

export const formatToDMY = (dateString) => {
  if (!dateString) return '--';
  const [year, month, day] = dateString.split('-');
  return `${day}/${month}/${year}`;
};

export const splitFormatDMY = (dateString) => {
  return new Date(dateString).toLocaleDateString("en-GB"); // "17/01/2024"
};

export const getCurrentFormattedDate = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = String(today.getMonth() + 1).padStart(2, "0"); // Months are 0-based
  const day = String(today.getDate()).padStart(2, "0");
  return `${year}-${month}-${day}`;
};

export const validateEmailOrMobile = (email) => {
  if (!email) {
    return 'Please enter a valid email or mobile number';
  }

  const isNumber = /^[0-9]+$/.test(email); // Checks if the input is entirely numeric
  const isEmail = /^[^\s@]+@[^\s@]+\.[^\s@]+$/.test(email); // Checks for a valid email format

  if (isNumber) {
    if (email.length !== 10) {
      return 'Please enter a valid 10-digit mobile number';
    }
  } else if (!isEmail) {
    return 'Please enter a valid email';
  }

  return null; // No error
};


