import React, { useState, useRef, useEffect } from "react";
import CheckBox from "../CheckBox";
import closeIcon from "../../Assets/Icons/close.png";
import { postData } from "../../ApiServices/ApiService";
import { ORG_EMP_SPECIALIZATION_UPDATE } from '../../ApiServices/BaseURL';
import Loader from "../Loader";

const UserSpecializationModal = ({ isOpen, onClose, userInfo, selService, onValueChange }) => {
    const [subServiceList, setSubServiceList] = useState(selService?.sub_service_list || []);
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState('');
    const [updateSuccess, setupdateSuccess] = useState('');

    useEffect(() => {
        //console.log('selService @@@', selService);
        if (selService?.sub_service_list) {
            setSubServiceList(selService?.sub_service_list);
        }
    },[selService])

    const handleCheckboxChange = (subService, isChecked) => {
        //console.log('handleCheckboxChange subService @@@', subService, isChecked);
        const updatedList = subServiceList.map((item) => 
            item?.service_sub_id === subService?.service_sub_id ?
            { ...item, is_active: isChecked ? 1 : 0 } : item
        );

        setSubServiceList(updatedList);
    };

    useEffect(() => {
      if (!isOpen) {
          resetForm();
      }
    }, [isOpen]);

    const handleUpdate = async () => {
        //console.log("subServiceList:", subServiceList, 'userInfo: ', userInfo);
  
        try {
            setupdateSuccess('');
            setUpdateError('');
            setUpdateLoading(true);
            // if (!selectedClient) {
            //   setUpdateError('Please select a client to add to the group');
            //   return;
            // }
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
              emp_id_auth: storedUserInfo?.emp_id,
              emp_id: userInfo?.emp_id,
              service_id: selService?.service_id,
              sub_service_list: subServiceList
            }
      
            //console.log('handleUpdate service payload', payLoad);
            // Make the API call
            const response = await postData(ORG_EMP_SPECIALIZATION_UPDATE, payLoad);
            const responseData = await response.json();
            //console.log('handleUpdate service Data', responseData);
      
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  setupdateSuccess(responseData?.info);
                  setTimeout(() => {
                    handleClose();
                    onValueChange();
                  }, 3000);
                } else {
                  setUpdateError(responseData?.info || 'Failed to manage service. Please try again.');
                }
              } else {
                setUpdateError(responseData?.info || 'Failed to manage service.');
              }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setUpdateError('Unauthorized access. Your session may have expired. Please log in again.');
              } else {
                setUpdateError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            }
            else if (response?.status === 500) {
              setUpdateError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            else {
              setUpdateError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            if (error.response) {
              setUpdateError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to manage service. Please try again.'}`);
            } else if (error.request) {
              setUpdateError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setUpdateError(`Failed to manage service. Please check your network connection and try again.`);
            }
          } finally {
            setUpdateLoading(false);
          }
    };

    if (!isOpen) return null;

    const handleClose = () => {
      setUpdateError('');
      onClose();
      setupdateSuccess('');
    }

    const resetForm = () => {
      setUpdateError('');
      setupdateSuccess('');
      setSubServiceList([]);
    }

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '664px' }}>
                <button className="close-button" onClick={handleClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Manage Specialization</h2>
                <hr className="title-divider" />

                <div style={{ marginBottom: "18px" }}>
                    <label style={styles.label}>Service</label>
                    <div style={styles.categoryValue}>{selService?.service_name}</div>
                </div>

                <div>
                    <label style={{...styles.label, color:'black', fontSize:14, paddingBottom:5}}>Select Specialization</label>
                    {subServiceList?.length > 0 && <div style={styles.specializationsGrid}>
                        {subServiceList?.map((subService, index) => (
                            <div key={index} style={styles.specializationItem}>
                                <CheckBox
                                    label={subService?.service_sub_name}
                                    checked={subService?.is_active === 1}
                                    onChange={(isChecked) =>
                                        handleCheckboxChange(subService, isChecked)
                                    }
                                    style={styles.checkbox}
                                />
                            </div>
                        ))}
                    </div>
                }  
                </div>

                <hr className="footer-divider" />
      
                <div className="button-container" style={{alignItems:'center'}}>
                    <button className="add-client-button" onClick={handleUpdate} style={{ opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer' }} disabled={updateLoading}>
                      <span style={{ paddingRight:updateLoading ? 10 : 0}}><Loader loading={updateLoading} color={'#FFFFFF'} size='small'/></span>
                        Update
                    </button>
                    <button className="cancel-button" onClick={() => { resetForm(); handleClose(); }}>
                        Cancel
                    </button>
                    {updateError && <div className="list-error-msg" style={{marginBottom:'3px'}}> {updateError} </div>}
                {updateSuccess && <div className="list-success-msg" style={{marginBottom:'3px'}}> {updateSuccess} </div>}
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    label: {
        display: "block",
        fontWeight: "400",
        marginBottom: "10px",
        color: "#767676",
        fontSize: "12px",
    },
    categoryValue: {
        color: "#060606",
        fontSize: "14px",
        fontWeight: "400",
    },
    specializationsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(143px, 1fr))",
        gap: "10px",
    },
    specializationItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#343C44",
    },
    checkbox: {
        marginRight: "10px",
        color: "#343C44",
    },
    labelName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default UserSpecializationModal;
