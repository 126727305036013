import React, { useState, useRef, useEffect } from 'react';
import UserAccessController from '../../../Controllers/AdminScreens/Users/UserAccessController';
import Toggle from '../../../../Components/Toggle';
import editIcon from '../../../../Assets/Icons/edit.png';
import ManageUserAccessModal from '../../../../Components/Modals/ManageUserAccessModal';
import Loader from '../../../../Components/Loader';

const UserAccessScreen = ({userInfo, userAccessPermissionList, userAccessPermissionLoading, userAccessPermissionError, onRefresh}) => {

    const { handleEditUserAccess,
        handleToggleChange,
        isManageAccessModalOpen,
        handleCloseManageAccessModal,
        selUserAccessService,
        getActiveSubPerNames
    } = UserAccessController();

    return (
        <div className='basic-info-container'>
            <h3 className='basic-info-header'>User Access</h3>
            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{width:'25%'}}>Module</th>
                            <th style={{width:'20%'}}>Access Level</th>
                            <th style={{width:'40%'}}>Page Access</th>
                            <th style={{width:'10%'}}></th>
                        </tr>
                    </thead>
                    {userAccessPermissionList?.length > 0 && <tbody style={{ backgroundColor: 'white' }}>
                        {userAccessPermissionList?.map((userAccess, index) => (
                            <tr key={index}
                                className="tasksScreen-main-task-row"
                                style={{ borderBottom: "1px solid #dee2e6" }}
                            >
                                <td style={{...styles.tableLink, width:'25%'}}>{userAccess?.per_name}</td>
                                <td style={{width:'20%'}}>{userAccess?.accessLevel || '--'}</td>
                                <td style={{width:'40%'}}>{getActiveSubPerNames(userAccess?.per_name_sub_list)}</td>
                                <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end', gap: 20}}>
                                    <button
                                        className="tasksScreen-edit-button"
                                        onClick={() => handleEditUserAccess(userAccess)}
                                    >
                                        <img
                                            src={editIcon}
                                            alt="Edit"
                                            style={{ width: "16px", height: "16px" }}
                                        />
                                    </button>
                                    <Toggle initialStatus={userAccess?.toggleState === 'true' ? true : false} onToggle={handleToggleChange} size='small'/>
                                </td>

                            </tr>

                        ))}

                    </tbody> }

                </table>
                {userAccessPermissionLoading && <Loader loading={userAccessPermissionLoading} color={'var(--primary-color)'} />}
                {userAccessPermissionError && <div className="list-error-msg">{userAccessPermissionError} </div>}
            </div>
            {/* Manage Access Modal */}
            {isManageAccessModalOpen && (
                <ManageUserAccessModal
                    isOpen={isManageAccessModalOpen}
                    onClose={handleCloseManageAccessModal} userInfo={userInfo}
                    selUserAccessService={selUserAccessService} onValueChange={onRefresh}
                />
            )}
        </div>

    )

};

const styles = {
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },
}

export default UserAccessScreen;