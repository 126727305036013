
import React, { useState, useRef, useEffect } from 'react';
const InvoiceBillController = () => {

  const invoiceBill = [
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "3,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "10,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "500.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "7,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "9,000.00"
    },
    {
      billNo: "DLS24-0001",
      clientName: "DLS INDIA",
      taskType: "GST Tax Filing",
      generatedDate: "23/10/2024",
      groupName: "Company Name/Group Name",
      generatedBy: "John Doe",
      amount: "22,000.00"
    }
  ];

  const ItemsPerPage = 10;
  const [currentPage, setCurrentPage] = useState(1);
  const totalPages = Math.ceil(invoiceBill.length / ItemsPerPage);

  // Function to handle page change
  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
  }
  };

  // Calculate the index of the first and last items on the current page
  const indexOfLastItem = currentPage * ItemsPerPage;
  const indexOfFirstItem = indexOfLastItem - ItemsPerPage;

  // Slice the data for the current page
  const currentTasks = invoiceBill.slice(indexOfFirstItem, indexOfLastItem);

  const [filterData, setFilterData] = useState({
    clientName: "",
    services:"",
    groupName:"",
    generatedDate: "",
    generatedBy:""
  });

  const handleFilterChange = (field, value) => {
    setFilterData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };
  return {
    invoiceBill,
    handlePageChange,
    currentTasks,
    totalPages,
    currentPage,
    filterData,
    setFilterData,
    handleFilterChange

  }
}

export default InvoiceBillController