import React from 'react';
// import './App.css';
import notifIcon from '../Assets/Icons/notificationIcon.png'
import AddClientModal from './AddClientModal';
import { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import searchIcon from '../Assets/Icons/search-icon.png';
import quickActionIcon from '../Assets/Icons/bitcoin-convert.png';
import plusIcon from '../Assets/Icons/Pluse.png'
import CreateEntryModal from './Modals/CreateEntryModal';

const Header = ({ title, onValueChange }) => {
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isActionModalOpen, setIsActionModalOpen] = useState(false);
  const [activeModal, setActiveModal] = useState(null);

  const quickActions = [
    { label: 'Add Client', modal: 'addClient' },
    // { label: 'Post Time Sheet', modal: 'postTimeSheet' },
    // { label: 'Leave/Attendance', modal: 'leaveAttendance' },
    // { label: 'Add Expense', modal: 'addExpense' },
    { label: 'Document In/Out', modal: 'documentInOut' },
    // { label: 'Add Notes', modal: 'addNotes' },
    // { label: 'Create Task', isActive: true },
    { label: 'Add To-Do', modal: 'addToDoModal' },
    { label: 'Leave/ Attendance', modal: 'leaveAttendance' },
    { label: 'Add Expense', modal: 'addExpense' },
    { label: 'Approvals', modal: 'approvals'},
  ];
  const location = useLocation();
  const navigator = useNavigate();

  const handleAddClientClick = () => {
    setIsModalOpen(true);
  };
  const handleAddTaskClick = () => {
    navigator('/create-task')
  };
  const handleShowQuickActionClick = () => {
    setIsActionModalOpen(true);
  };

  const handleCloseActionModal = () => {
    setIsActionModalOpen(false);
  }

  const handleQuickActionClick = (modalType) => {
    setActiveModal(modalType); // Open specific modal
    setIsActionModalOpen(false); // Close Quick Actions modal
  };


  const handleCloseModal = () => {
    setActiveModal(null);
    setIsModalOpen(false);
  };

  const hadleNotification =()=>{
    navigator('/resourceslist')
  }
  const handleSearch = ()=>{
    navigator('/clientlist')
  }
  // //console.log('titte', title)
  return (
    <div className="header-div">
      <div className="header">
        <div className="header-greeting">
          {title}
        </div>
        <div className="header-controls">
          {/* Search Bar */}
          <div onClick={handleSearch} style={styles.searchBar}>
            <img src={searchIcon} alt="Search" style={styles.searchIcon} onClick={handleSearch} />
            <span onClick={handleSearch} className='search-input' style={styles.searchInput}>Search Something...</span>
            {/* <input type="text" placeholder="Search Something..." style={styles.searchInput} className='search-input' /> */}
          </div>

          {/* Notification Button */}
          <button style={styles.notificationButton} onClick={hadleNotification}>
            <img src={notifIcon} alt="Notification" style={styles.notificationIcon} />
          </button>

          <button className="add-client-button-header" onClick={handleAddTaskClick}>
            +&emsp; Add Task
          </button>

          <button className="add-client-button-header" onClick={handleAddClientClick}>
            +&emsp; Add Client
          </button>

          <div style={styles.quickActiondiv} onClick={handleShowQuickActionClick}>
            <img src={quickActionIcon} alt="Search" style={styles.quickActionIcon} />
          </div>

          {isActionModalOpen && (
            <div 
            style={styles.modalOverlay} 
            // className='modal-overlay-m'
            onClick={handleCloseActionModal}>
              <div
                style={{
                  ...styles.modalContent,
                }}
                className='modal-content-m'
              >
                <h3 style={styles.quickActionsTitle}>
                  <img src={quickActionIcon} alt="Quick Actions" style={styles.quickActionIcon} />
                  Quick Links
                </h3>

                <div style={styles.quickActionsContainer}>
                  {quickActions?.map((action, index) => (
                    <div
                      key={index}
                      style={{
                        ...styles.quickActionCard,
                        ...(action.isActive ? styles.activeCard : {}),
                      }}
                      onClick={() => handleQuickActionClick(action.modal)}
                    >
                      <div style={styles.quickActionContent}>
                        <div style={styles.quickActionLabel}>{action.label}</div>
                        
                      </div>
                      <div style={styles.quickActionIconContainer}>
                          <img src={plusIcon} alt="Plus" style={styles.plusIcon} />
                        </div>
                    </div>
                  ))}
                </div>
              </div>
            </div>
          )}

          {activeModal === 'addClient' && (
            <AddClientModal isOpen={!!activeModal} onClose={handleCloseModal} onValueChange={onValueChange} />
          )}
          {/* {activeModal === 'postTimeSheet' && <div>Post Time Sheet Modal</div>}
          {activeModal === 'leaveAttendance' && <div>Leave/Attendance Modal</div>}
          {activeModal === 'addExpense' && <div>Add Expense Modal</div>}
          {activeModal === 'documentInOut' && <div>Document In/Out Modal</div>}
          {activeModal === 'addNotes' && <div>Add Notes Modal</div>} */}

          {activeModal === 'documentInOut' && (
            <CreateEntryModal isOpen={!!activeModal} onClose={handleCloseModal} onValueChange={onValueChange} />
          )}

        </div>
        {isModalOpen &&
        <AddClientModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={onValueChange} />
        }
      </div>
    </div>

  );
};


const styles = {
  searchBar: {
    display: 'flex',
    alignItems: 'center',
    backgroundColor: '#FFFFFF',
    border: 'none',
    borderRadius: '10px',
    padding: '8px 10px',
    width: '100%',
    maxWidth: 300,
    cursor:'pointer'
  },
  searchIcon: {
    width: '15px',
    height: '15px',
    marginRight: '10px',
  },
  searchInput: {
    border: 'none',
    backgroundColor: 'transparent',
    outline: 'none',
    fontSize: '12px',
    color: '#A0A0A0',
    width: '100%',
  },
  notificationButton: {
    backgroundColor: '#FFFFFF',
    border: '1px solid #858585',
    borderRadius: '50%',
    width: '35px',
    height: '35px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    cursor: 'pointer',
  },
  notificationIcon: {
    width: '20px',
    height: '20px',
  },
  quickActionIcon: {
    width: '20px',
    height: '20px',
    marginRight: '10px',
  },
  quickActiondiv: {
    backgroundColor: 'var(--secondary-color)',
    padding: '10px',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    borderTopLeftRadius: '20px',
    borderBottomLeftRadius: '20px',
    cursor: 'pointer'
  },
  modalOverlay: {
    position: 'fixed',
    top: 0,
    right: 0,
    width: '100%',
    height: '100%',
    backgroundColor: 'rgba(0, 0, 0, 0.5)', // Semi-transparent background
    display: 'flex',
    justifyContent: 'flex-end', // Align modal to the right
    alignItems: 'flex-start', // Align modal to the top
    zIndex: 1000,
    padding: '10px'
  },
  modalContent: {
    backgroundColor: '#609966',
    borderRadius: '35px',
    padding: '10px',
    maxWidth: '1200px',
    display: 'flex',
    flexDirection: 'row',
    alignItems: 'center',
    boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
    gap: '20px',
    justifyContent: 'flex-end'
  },
  quickActionsTitle: {
    display: 'flex',
    alignItems: 'center',
    fontSize: '12px',
    fontWeight: '400',
    color: '#F2F2F2',
    // marginBottom: '10px',
    fontFamily:'Roboto',
  },
  quickActionIcon: {
    width: '24px',
    height: '24px',
    marginRight: '10px',
  },
  quickActionsContainer: {
    display: 'flex',
    gap: '16px',
    flexWrap: 'wrap',
    backgroundColor: '#EDF1D6',
    padding: '15px',
    borderRadius: '25px',
  },
  quickActionCard: {
    backgroundColor: '#FFFFFF',
    borderRadius: '12px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'space-between',
    width: '72px',
    height: '72px',
    boxShadow: '0 2px 4px rgba(0, 0, 0, 0.1)',
    transition: 'transform 0.2s ease',
    padding:'5px',
    cursor:'pointer',
  },
  activeCard: {
    border: '2px solid #62C462',
    transform: 'translateY(-5px)',
  },
  quickActionContent: {
    // textAlign: 'center',
    // padding:'5px',
  },
  quickActionLabel: {
    fontSize: '14px',
    fontWeight: 400,
    color: '#060606',
    marginBottom: '8px',
    wordWrap:'anywhere',
    fontFamily:'Roboto',
  },
  quickActionIconContainer: {
    width: '21px',
    height: '21px',
    backgroundColor: '#40513B',
    borderRadius: '4px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    boxShadow: '0px 4px 16px rgba(90, 85, 210, 0.4)',
    // marginTop:'15px'
    cursor:'pointer',
    marginLeft:'2px',
    marginBottom:'2px',
  },
  plusIcon: {
    width: '12px',
    height: '12px',
  },
}

export default Header;
