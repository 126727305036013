import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import AdminPersonalProfileScreen from './AdminPersonalProfileScreen';
import NotificationRemindersScreen from './NotificationRemindersScreen';

const AdminSettingsScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:10}}>
                <h2 className='title'>Settings</h2>
            </div>

            <div >
                <Tabs tabs={['Personal Profile', 'Notifications & Reminders']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <AdminPersonalProfileScreen />
                    <NotificationRemindersScreen />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default AdminSettingsScreen;