import React, { useState, useRef, useEffect} from "react";
import Input from "../../../../../Components/Input";
import Dropdown from "../../../../../Components/Dropdown";
import WhatsAppConfigurationController from "../../../../Controllers/AdminScreens/Configuration/ConfigurationCenter/WhatsAppConfigurationController";

const WhatsAppConfiguration = () => {
  const {
    bussinessAccountName,
    setBusinessAccountName,
    bussinessEmail,
    setBusinessEmail,
    bussinessWhatsAppNumber,
    setBussinessWhatsAppNumber,
    webHookUrl,
    setWebHookUrl,
    otherParameters,
    setOtherParameters,
    passKey,
    setPassKey
  } = WhatsAppConfigurationController()

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <div className='basic-info-container' >
      <h3 style={styles.categoryHeader}>WhatsApp </h3>
      <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '50px', rowGap: '15px' }}>
        <Dropdown
          ref={(el) => (dropdownRefs.current[" "] = el)}
          isOpen={openDropdown === " "}
          toggleDropdown={() => toggleDropdown(" ")}
          label="Business Account Name"
          options={["Option 1", "Option 2"]}
          placeholder="Business Account Name"
          value={bussinessAccountName}
          onChange={setBusinessAccountName}
        />
        <Input
          label="Business Email"
          placeholder="business Email"
          value={bussinessEmail}
          onChange={(e) => setBusinessEmail(e.target.value)}
        />
        <Input
          label="WhatsApp Business Number"
          placeholder="WhatsApp Business Number"
          value={bussinessWhatsAppNumber}
          onChange={(e) => setBussinessWhatsAppNumber(e.target.value)}
        />
      </div>
      <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '50px', marginTop: '15px', rowGap: '15px' }}>
        <div
          style={{
            width: "472px", // Default width for desktop
            maxWidth: "90%", // Ensure it doesn't exceed the screen width
            marginRight: '20px'
            // marginBottom: "15px", // Add spacing between rows
          }}
        >
          <Input
            label="Web Hook URL"
            placeholder="Web Hook URL"
            width="100%" // Pass width as needed
            value={webHookUrl}
            onChange={(e) => setWebHookUrl(e.target.value)}
          />
        </div>

        {/* <div> */}
        <Input
          label="Other Parameters"
          placeholder="Other Parameters"
          value={otherParameters}
          onChange={(e) => setOtherParameters(e.target.value)}
        />
        {/* </div> */}
        {/* <div> */}
        <Input
          label="Passkey"
          placeholder="Passkey"
          value={passKey}
          onChange={(e) => setPassKey(e.target.value)}
        />
        {/* </div> */}
      </div>
      <div className="button-container" style={{ marginTop: '25px', marginBottom: '30px' }}>
        <button className="add-client-button" style={{ marginRight: '8px', paddingLeft: '30px', paddingRight: '30px' }}>Update</button>
        <button className="cancel-button"  >Cancel</button>
      </div>
    </div>
  )

}

export default WhatsAppConfiguration;

const styles = {
  categoryHeader: {
    color: '#1F384C',
    fontSize: 18,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  fullWidth: {
    gridColumn: "span 2",
    width: "100%", // Full width
  },
}