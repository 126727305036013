import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import pluseIcon from '../../Assets/Icons/Pluse.png';
import uploadIcon from '../../Assets/Icons/upload.png';
import { ORG_ADD_KYC_DOCUMENT, ORG_UPDATE_KYC_DOCUMENT, ORG_ADD_DOCUMENT_RACK, ORG_UPDATE_DOCUMENT_RACK } from '../../ApiServices/BaseURL';
import { postData } from '../../ApiServices/ApiService';
import Loader from '../Loader';
import errorIcon from '../../Assets/Icons/error_message.png';

const AddDocumentModal = ({ isOpen, onClose, clientId, documentData, onValueChange, modalMode }) => {
  const documentTypeOptions = [
    { label: "Internal", value: 1 },
    { label: "Client Documents", value: 2 },
  ];
  const sourceOptions = [
    { label: "Inward (Sent by Client)", value: 1 },
    { label: "Outward (Sent by Employee)", value: 2 },
  ];
  const deliveryTypeOptions = [
    { label: "Email", value: "EMAIL" },
    { label: "Whatsapp", value: "WATSAPP" },
    { label: "Physical", value: "PHYSICAL" },
    { label: "Others", value: "OTHERS" },
  ];
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [showUpload, setShowUpload] = useState(false);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [base64File, setBase64File] = useState('');
  const [fileExtension, setFileExtension] = useState('');
  const [updateKycError, setUpdateKycError] = useState('');
  const [documentNameError, setDocumentNameError] = useState('')
  const [documentDescriptionError, setDocumentDescriptionError] = useState('')
  const [documentUploadError, setDocumentUploadError] = useState('');
  const [documentType, setDocumentType] = useState();
  const [source, setSource] = useState();
  const [deliveryType, setDeliveryType] = useState();
  const [documentNumber, setDocumentNumber] = useState('');
  const [uploadedFileName, setUploadedFileName] = useState('');
  const [documentTypeError, setDocumentTypeError] = useState('')
  const [deliveryTypeError, setDeliveryTypeError] = useState('')
  const [documentNumberError, setDocumentNUmberError] = useState('')
  const [sourceError, setSourceError] = useState('')
  const fileInputRef = useRef(null);

  useEffect(() => {
    if (isOpen && documentData) {
      //console.log("documentData", documentData);

      setDocumentName(documentData.doc_name || '');
      setDescription(documentData.doc_description || '');
      setDocumentNumber(documentData.doc_number || '');

      // Initialize document type based on doc_is_internal
      const selectedDocumentType = documentTypeOptions.find(option => option.value === documentData.doc_is_internal);
      setDocumentType(selectedDocumentType?.value || documentTypeOptions[0]?.value);

      // Initialize source based on doc_source_id
      const selectedSource = sourceOptions.find(option => option.value === documentData.doc_source_id);
      setSource(selectedSource?.value || sourceOptions[0]?.value);

      // Initialize delivery type based on doc_upload_via
      const selectedDeliveryType = deliveryTypeOptions.find(option => option.value === documentData.doc_upload_via);
      setDeliveryType(selectedDeliveryType?.value || deliveryTypeOptions[0]?.value);
    }
  }, [isOpen, documentData]);


  const handleUploadKYCDocument = async () => {

    try {
      setError('');
      setDocumentDescriptionError('');
      setDocumentNameError('');
      setDocumentUploadError('');
      setDocumentNUmberError('');
      setDeliveryTypeError('');
      setDocumentTypeError('');
      setSourceError('')
      // if (!documentName) {
      //   setDocumentNameError("Please enter document name");
      //   return
      // }
      // if(!description){
      //   setDocumentDescriptionError("Please enter document description");
      //   return
      // }
      if (!documentType) {
        setDocumentTypeError("Please select document type");
        return
      }
      // if(!documentNumber){
      //   setDocumentNUmberError("Please enter document number");
      //   return
      // }
      // if (!source) {
      //   setSourceError("Please select source");
      //   return
      // }
      // if (!deliveryType) {
      //   setDeliveryTypeError("Please select delivery type");
      //   return
      // }
      if (!base64File) {
        setDocumentUploadError("Please upload document");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, client_id: clientId, doc_name: documentName, doc_name_with_ext: `${documentName}.${fileExtension}`, doc_upload_via: deliveryType, doc_description: description, doc_base_64: base64File, doc_is_internal: documentType, doc_source_id: source, doc_class: 'KYC' }

      //console.log('handleSearchEmployee payload', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_DOCUMENT_RACK, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to upload kyc document. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to upload kyc document.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to upload kyc document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to upload kyc document. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const handleUpdateKYCDocument = async () => {

    try {
      setUpdateKycError('');
      setError('');
      setDocumentDescriptionError('');
      setDocumentNameError('');
      setDocumentUploadError('');
      setDocumentNUmberError('');
      setDeliveryTypeError('');
      setDocumentTypeError('');
      setSourceError('')
      if (!documentName) {
        setDocumentNameError("Please enter document name");
        return
      }
      // if(!description){
      //   setDocumentDescriptionError("Please enter document description");
      //   return
      // }
      if (!documentType) {
        setDocumentTypeError("Please select document type");
        return
      }
      // if(!documentNumber){
      //   setDocumentNUmberError("Please enter document number");
      //   return
      // }
      if (!source) {
        setSourceError("Please select source");
        return
      }
      if (!deliveryType) {
        setDeliveryTypeError("Please select delivery type");
        return
      }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, doc_id: documentData.doc_id, doc_name: documentName, doc_description: description, doc_upload_via: deliveryType, doc_is_internal: documentType, doc_source_id: source }

      //console.log('handleSearchEmployee payload', payLoad);
      // Make the API call
      const response = await postData(ORG_UPDATE_DOCUMENT_RACK, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setUpdateKycError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setUpdateKycError(responseData?.info || 'Failed to update kyc document. Please try again.');
          }
        } else {
          setUpdateKycError(responseData?.info || 'Failed to update kyc document.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setUpdateKycError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setUpdateKycError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setUpdateKycError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setUpdateKycError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setUpdateKycError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update kyc document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setUpdateKycError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setUpdateKycError(`Failed to update kyc document. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const handleClose = () => {
    setError('');
    onClose();
    setSuccess('');
    setBase64File('');
    setDocumentName('');
    setDescription('');
    setUpdateKycError('');
    setDeliveryType();
    setDocumentType();
    setDocumentNumber('')
    setSource();
    setUploadedFileName('')
    setDocumentDescriptionError('');
    setDocumentNameError('');
    setDocumentUploadError('');
    setDocumentNUmberError('');
    setDeliveryTypeError('');
    setDocumentTypeError('');
    setSourceError('')
  }

  // // Reset form fields to initial values
  // const resetForm = () => {
  //     setDocumentName('');
  //     setDescription('');
  // };

  // // Run resetForm when modal closes
  // useEffect(() => {
  //   if (!isOpen) {
  //     resetForm();
  //   }
  // }, [isOpen]);


  // Handle file input change
  const handleFileChange = (event) => {
    setDocumentUploadError('');
    const files = event.target.files;

    if (files && files.length > 0) {
      const file = files[0];
      const fileName = file.name;
      const fileSize = file.size; // File size in bytes
      const maxFileSize = 2 * 1024 * 1024;
      const fileExtension = fileName.split('.').pop(); // Extract the file extension

      //console.log("Uploaded File Name:", fileName);
      //console.log("File Extension:", fileExtension);
      setUploadedFileName(fileName);

      if (fileSize > maxFileSize) {
        setDocumentUploadError("File size exceeds the maximum limit of 2MB.");
        return;
      }

      const reader = new FileReader();

      try {
        reader.onload = () => {
          try {
            const base64String = reader.result.split(',')[1]; // Extract Base64 string from result
            //console.log("Base64 Encoded File:", base64String);

            setBase64File(base64String); // Add the Base64 string to the payload
            // Optionally, save the file extension in state or payload
            setFileExtension(fileExtension);
          } catch (parseError) {
            console.error("Error parsing file to Base64:", parseError);
            setDocumentUploadError("Failed to process the file. Please try again.");
          }
        };

        reader.onerror = (error) => {
          console.error("Error reading file:", error);
          setDocumentUploadError("Failed to read the file. Please try again.");
        };

        reader.readAsDataURL(file); // Convert file to Base64 string
      } catch (readError) {
        console.error("File upload error:", readError);
        setDocumentUploadError("An unexpected error occurred during the file upload. Please try again.");
      }
    } else {
      setDocumentUploadError("No file selected. Please choose a file to upload.");
    }
  };

  const handleDrop = (e) => {
    const files = e.dataTransfer.files; // Get the files from the drop event
    if (files && files.length > 0) {
      //console.log("Dropped files:", files);
      handleFileChange({ target: { files } }); // Reuse handleFileChange for processing
    }
  };





  // Trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => { handleClose(); }}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        <h2 className="modal-title">{documentData ? `Update Document` : 'Add Document'}</h2>
        <hr className="title-divider" style={{ color: '#d1e3ed' }} />

        <div className="form-grid">
          {/* <div style={styles.halfWidth}> */}
          <Input
            label="Document Name"
            placeholder="Enter Document Name"
            value={documentName}
            onChange={(e) => setDocumentName(e.target.value)}
            errorMessage={documentNameError}
            width={250}
          />
          <Dropdown
            ref={(el) => (dropdownRefs.current["documentType"] = el)}
            isOpen={openDropdown === "documentType"}
            toggleDropdown={() => toggleDropdown("documentType")}
            label="Document Type"
            options={documentTypeOptions}
            value={documentTypeOptions.find((option) => option.value === documentType)}
            onChange={(selectedOption) => setDocumentType(selectedOption.value)}
            width={250}
            errorMessage={documentTypeError}
          />
          <Dropdown
            ref={(el) => (dropdownRefs.current["sourceOptions"] = el)}
            isOpen={openDropdown === "sourceOptions"}
            toggleDropdown={() => toggleDropdown("sourceOptions")}
            label="Source"
            options={sourceOptions}
            value={sourceOptions.find((option) => option.value === source)}
            onChange={(selectedOption) => setSource(selectedOption.value)}
            width={250}
            errorMessage={sourceError}
          />
          <Dropdown
            ref={(el) => (dropdownRefs.current["deliveryTypeOptions"] = el)}
            isOpen={openDropdown === "deliveryTypeOptions"}
            toggleDropdown={() => toggleDropdown("deliveryTypeOptions")}
            label="Delivery Type"
            options={deliveryTypeOptions}
            value={deliveryTypeOptions.find((option) => option.value === deliveryType)}
            onChange={(selectedOption) => setDeliveryType(selectedOption.value)}
            width={250}
            errorMessage={deliveryTypeError}
          />
          {/* {modalMode === "edit" && (
                <Input
                type="number"
                label="Document No"
                placeholder="Enter Document Number"
                value={documentNumber}
                // onChange={(e) => setDocumentNumber(e.target.value)}
              //   onChange={(e) => {
              //     const value = e.target.value;
              //     if (/^\d*$/.test(value)) { // Allow only numbers
              //         setDocumentNumber(value);
              //     }
              // }}
              readOnly={modalMode === "edit"} 
                width={250}
                // errorMessage={documentNumberError}
                />
              )} */}

          {/* </div> */}
          <div style={styles.fullWidth}>
            <Input
              label="Description"
              placeholder="Enter Description"
              value={description}
              onChange={(e) => setDescription(e.target.value)}
              width={'100%'}
            // errorMessage={documentDescriptionError}
            />
          </div>
        </div>

        {/* {showUpload && ( */}
        {!documentData && (
          <div
            style={styles.uploadContainer}
            onDragOver={(e) => e.preventDefault()} // Prevent default drag behavior
            onDrop={(e) => {
              e.preventDefault();
              e.stopPropagation();
              handleDrop(e);
            }}
            onClick={(e) => {
              e.stopPropagation(); // Prevent event bubbling
              fileInputRef.current?.click(); // Programmatically trigger file input click
            }}
          >
            <div style={styles.uploadBox}>
              <span style={styles.uploadCircle}>
                <img src={pluseIcon} alt="Plus Icon" style={{ ...styles.iconImage, filter: 'brightness(0) invert(0)' }} />
              </span>
              <p style={uploadedFileName ? styles.uploadedFileName : styles.uploadText}>
                {uploadedFileName || // Show file name if uploaded
                  "Upload Document, Drop here or click on upload"}
              </p>
              <img src={uploadIcon} alt="Upload Icon" style={styles.uploadIconImage} />

              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                onChange={handleFileChange} // Handle file selection
                onClick={(e) => e.stopPropagation()} // Prevent double triggering
              />
            </div>
          </div>
        )}
        {documentUploadError && <div className="list-error-msg" style={{ display: 'flex', padding: '5px' }}><img
          src={errorIcon}
          alt="Error"
          style={{ width: "12px", height: "12px", marginRight: '4px' }}
        />{documentUploadError} </div>}

        {/* )} */}
        {/* <div className="existing-client-checkbox">
              <label style={styles.exisitingHeading}>Primary Contact?</label>
              <input type="checkbox" checked={primaryContact} onChange={(e) => setPrimartContact(e.target.checked)} />
            </div> */}
        <hr className="footer-divider" style={{ color: '#d1e3ed' }} />

        <div className="button-container" style={{ alignItems: 'center' }}>
          <button
            className="add-client-button"
            onClick={documentData ? handleUpdateKYCDocument : handleUploadKYCDocument}
            disabled={loading}
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px', // Add spacing between text and loader
              position: 'relative',
              opacity: loading ? 0.5 : 1, // Reduce opacity when loading
              cursor: loading ? 'not-allowed' : 'pointer',
            }}
          >
            <span>{documentData ? 'Update Document' : 'Add Document'}</span>
            {loading && (
              <Loader loading={loading} color={'var(--background-color)'} size="small" />
            )}
          </button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
          {success && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{success} </div>}
          {error && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{error} </div>}
          {updateKycError && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{updateKycError} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  uploadContainer: {
    marginTop: '20px',
    width: '100%',
    // marginBottom:'20px',
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #609966',
    borderRadius: '10px',
    padding: '15px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '35px',
    height: '35px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  iconImage: {
    width: '12px',
    height: '12px',
  },
  uploadIconImage: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr", // Single-column layout by default
    gap: "20px", // Space between rows
  },
  halfWidth: {
    gridColumn: "span 1",
    width: "50%", // Half width
    marginRight: "auto", // Aligns left
  },
  fullWidth: {
    gridColumn: "span 2",
    width: "100%", // Full width
  },
  uploadedFileName: {
    flex: 1,
    color: "#262626", // Highlight the uploaded file name with a distinct color
    fontSize: "15px",
    fontWeight: 500,
    overflow: "hidden",
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
  },
}

export default AddDocumentModal;