import { API_URL } from "./BaseURL";

async function postDataLogin(endpoint, data) {
    try {
        const response = await fetch(`${API_URL}${endpoint}`, {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: JSON.stringify(data),
            // body: data,
        });

        const responseData = await response.json();
        return responseData;
    } catch (error) {
        console.error('Error posting data:', error);
    }
}
async function getData(endpoint, data ) {
    try {
      const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getIDToken()}`
        },
        body: data,
      });
      return response;
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  }



async function postData(endpoint, data) {
    try {
      const response = await fetch(`${API_URL}${endpoint}`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${getIDToken()}`
        },
        body: JSON.stringify(data),
        // body: data,
      });
  
      // const responseData = await response.json();
      return response;
    } catch (error) {
      console.error('Error posting data:', error);
    }
  }

async function getParamsData(endpoint, params) {
    try {
        // Convert params object to a query string
        
        const queryString = new URLSearchParams(params).toString();
        //console.log("`${API_URL}${endpoint}?${queryString}",`${API_URL}${endpoint}?${queryString}`)
        
        const response = await fetch(`${API_URL}${endpoint}?${queryString}`, {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': getIDToken()
            },
            // body: JSON.stringify(params),
        });

        return response;
    } catch (error) {
        console.error('Error fetching data:', error);
    }
}

async function getData2(endpoint) {
  try {
    
      
      const response = await fetch(`${API_URL}${endpoint}`, {
          method: 'GET',
          headers: {
              'Content-Type': 'application/json',
              // 'Authorization': getIDToken()
          },
          // body: JSON.stringify(params),
      });

      return response;
  } catch (error) {
      console.error('Error fetching data:', error);
  }
}



function getIDToken(){
    return JSON.parse(localStorage.getItem('_userInfo_'))?.emp_tkn_id;
  }
export { postDataLogin, getData, postData,getParamsData,getData2};