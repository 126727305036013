import { useState, useEffect, useRef } from 'react';
import { ORG_DELETE_DOCUMENT_RACK, ORG_DOWNLOAD_DOCUMENT_RACK, ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_GET_EMPLOYEE_LIST, ORG_DOCUMENT_SEARCH_RACK } from '../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../ApiServices/ApiService";

const InwardOutwardListController = (onRefresh, kycDocumentList, setKycListLoading, setKYCListError, setKycDocumentList) => {
  const [tasks, setTasks] = useState([]);
  const [filteredTasks, setFilteredTasks] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10; // Number of items per page
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [currentDocDeleteIndex, setCurrentDocDeleteIndex] = useState(null);
  const [success, setSuccess] = useState({});
  const [loading, setLoading] = useState({});
  const [kycDownloadError, setKYCDownloadError] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [downloadKycLoading, setDownloadKycLoading] = useState(false);
  const [currentDocIndex, setCurrentDocIndex] = useState(null);
  const [documentNumber, setDOcumentNumber] = useState('');
  const docInfoRef = useRef(null);
  const [docFiltterError,setDocFiltterError] = useState('')
  const sourceOptions = [
    { label: "Inward (Sent by Client)", value: 1 },
    { label: "Outward (Sent by Employee)", value: 2 },
  ];
  const deliveryTypeOptions = [
    { label: "Email", value: "EMAIL" },
    { label: "Whatsapp", value: "WATSAPP" },
    { label: "Physical", value: "PHYSICAL" },
    { label: "Others", value: "OTHERS" },
  ];
  const [source, setSource] = useState();
  const [deliveryType, setDeliveryType] = useState();
  // const [selectedEmployee, setSelectedEmployee] = useState(null)
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");

  const handleStartDateChange = (value) => {
    setStartDate(value);
  };

  const handleEndtDateChange = (value) => {
    setEndDate(value);
  };


  useEffect(() => {
    // Fetch or load the data from an API or local file
    const data = [
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },
      {
        documentName: 'Form 16',
        source: 'Sent by Client',
        deliveryType: 'WhatsApp',
        clientName: 'DLS India',
        documentNumber: '123456',
        createdBy: 'John Doe',
        date: '11/11/2024',
      },
      {
        documentName: 'Invoice 001',
        source: 'Sent by Staff',
        deliveryType: 'Email',
        clientName: 'ABC Corp',
        documentNumber: '987654',
        createdBy: 'Jane Doe',
        date: '10/11/2024',
      },
      {
        documentName: 'Form 15',
        source: 'Sent by Client',
        deliveryType: 'Email',
        clientName: 'XYZ Ltd.',
        documentNumber: '654321',
        createdBy: 'John Doe',
        date: '09/11/2024',
      },

      // Add more sample data as needed
    ];
    setTasks(data);
    setFilteredTasks(data); // Initially, all tasks are displayed
  }, []);

  // Handle filtering tasks
  const filterTasks = (filters) => {
    const { source, deliveryType } = filters;
    const filtered = tasks.filter((task) => {
      const matchesSource = source ? task.source === source : true;
      const matchesDeliveryType = deliveryType ? task.deliveryType === deliveryType : true;
      return matchesSource && matchesDeliveryType;
    });
    setFilteredTasks(filtered);
    setCurrentPage(1); // Reset to the first page after filtering
  };

  // Handle pagination
  const totalPages = Math.ceil(kycDocumentList.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = kycDocumentList.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDelete = (doc, index) => {
    // setSelectedDocument({ doc, index }); // Store the credential to delete
    docInfoRef.current = doc;
    setIsDeleteModalOpen(true); // Open the confirmation modal
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true); // Open the modal
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  };

  const handleDocumentDelete = async (docId) => {

    try {
      // const { doc, index } = selectedDocument;
      // //console.log("index", index)
      //   setKYCDownloadError('');  
      //   setSuccess('')
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      // setLoading(true);
      // setCurrentDocDeleteIndex(index)
      setKYCDownloadError({});
      setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: '' }));
      setLoading((prev) => ({ ...prev, [docId?.doc_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, doc_id: docId?.doc_id }

      //console.log('handleSearchEmployee payload',payLoad);
      // Make the API call
      const response = await postData(ORG_DELETE_DOCUMENT_RACK, payLoad);
      const responseData = await response.json();
      //console.log('response Data',responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess((prev) => ({ ...prev, [docId?.doc_id]: responseData?.info }));
            // setEmployeeList(responseData?.dataJ || []);
            setTimeout(() => {
              setSuccess((prev) => ({ ...prev, [docId?.doc_id]: '' }));
              onRefresh();
              setCurrentPage(1);
            }, 3000);
          } else {
            setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: responseData?.info || 'Failed to delete kyc document. Please try again.' }));
          }
        } else {
          setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: responseData?.info || 'Failed to delete kyc document.' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete kyc document. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setKYCDownloadError((prev) => ({ ...prev, [docId?.doc_id]: `Failed to delete kyc document. Please check your network connection and try again.` }));
      }
    } finally {
      setLoading((prev) => ({ ...prev, [docId?.doc_id]: false }));
      // setCurrentDocDeleteIndex(null)
    }

    // onValueChange(teamData);
    // onClose();
  };

  const downloadDocuments = async (docId, index, clientId) => {
    try {
      setCurrentDocIndex(index);
      setDownloadKycLoading(true);
      setKYCDownloadError('');
      // setTeamInfo({});
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_DOWNLOAD_DOCUMENT_RACK}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}&doc_id=${docId}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const downloadUrl = responseData?.dataJ?.[0]?.download_url;
              if (downloadUrl) {
                try {
                  // Fetch the file as a blob
                  const fileResponse = await fetch(downloadUrl);
                  const blob = await fileResponse.blob();

                  // Create a temporary object URL for the blob
                  const url = window.URL.createObjectURL(blob);

                  // Create a link and trigger download
                  const link = document.createElement('a');
                  link.href = url;

                  // Extract the file extension from the download URL or set a default
                  const fileExtension = downloadUrl.includes('.')
                    ? downloadUrl.split('.').pop().split('?')[0]
                    : ''; // Default to 'pdf' if no extension found

                  const filename = `Document_${docId}.${fileExtension}`;
                  link.setAttribute('download', filename);

                  document.body.appendChild(link);
                  link.click();

                  // Clean up
                  document.body.removeChild(link);
                  window.URL.revokeObjectURL(url);
                } catch (error) {
                  console.error('Error downloading file:', error);
                  setKYCDownloadError('An error occurred while attempting to download the file. Please try again.');
                }
              } else {
                setKYCDownloadError('Download URL not available.');
              }
            } else {
              setKYCDownloadError(responseData?.info || 'Data Not Found');
            }
          } else {
            setKYCDownloadError(responseData?.info || 'Failed to fetch KYC document. Please try again.');
          }
        } else {
          setKYCDownloadError(responseData?.info || 'Failed to fetch KYC document.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setKYCDownloadError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setKYCDownloadError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setKYCDownloadError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setKYCDownloadError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setKYCDownloadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch KYC document. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setKYCDownloadError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setKYCDownloadError(`Failed to fetch KYC document. Please check your network connection and try again.`);
      }
    } finally {
      setCurrentDocIndex(null);
      setDownloadKycLoading(false);
    }
  }

  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [receivedBy, setReceived] = useState(null);
  const [searchUser, setSearchUser] = useState('');
  const [clientError, setClientError] = useState('');
  const [clientLoading, setClientLoading] = useState(false);
  const [employeeList, setEmployeeList] = useState([]);
  const [flineNumber, setFileNumber] = useState('');

  const handleSelectedEmployee = (employee) => {
    setSelectedEmployee(employee); // Set selected client data
    setSearchUser(employee?.client_name); // Update TextInput with the selected client's name
    // Clear the dropdown
    setEmployeeList([]);
  };

  const handleSelectedFileEmployee = (employee) => {
    setSelectedEmployee(employee); // Set selected client data
    setFileNumber(employee?.client_file_id)
    setFileEmployeeList([]); // Clear the dropdown
  };

  const handleSearchInputText = (value, type) => {
    // console.log('handleSearchInputText value : ',value, 'type', type);
    if (type === 'GENERIC') {
      setSearchUser(value);
      setClientError('');
      setEmployeeList([]);
      if (!value) {
        setSelectedEmployee(null);
      }
    } else if (type === 'FILEID') {
      setFileNumber(value);
      setFileError('');
      setFileEmployeeList([]);
      if (!value) {
        setSelectedEmployee(null);
      }
    }
  }


  const [fileLoading, setFileLoading] = useState(false); // Loader for "File Number"
  const [fileError, setFileError] = useState('');
  const [fileEmployeeList, setFileEmployeeList] = useState([]);
  const [activeDropdown, setActiveDropdown] = useState(null);

  const handleSearchEmployee = async (type) => {

    try {
      if (type === 'GENERIC') {
        setClientLoading(true);
        setClientError('');
        setActiveDropdown('GENERIC');
        setEmployeeList([])
      } else if (type === 'FILEID') {
        setFileLoading(true);
        setFileError('');
        setActiveDropdown('FILEID');
        setFileEmployeeList([])
      }
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const searchWord = type === 'GENERIC' ? searchUser : flineNumber
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: type, search_word: searchWord }

      //console.log('handleSearchEmployee payload',payLoad);
      // Make the API call
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data',responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            // setAddEmployeeError('')
            // setEmployeeList(responseData?.dataJ || []);
            if (type === 'GENERIC') {
              setEmployeeList(responseData?.dataJ || []); // Update Client Employee List
            } else if (type === 'FILEID') {
              setFileEmployeeList(responseData?.dataJ || []); // Update File Employee List
            }

          } else {
            if (type === 'GENERIC') {
              setClientError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            } else if (type === 'FILEID') {
              setFileError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          }
        } else {
          if (type === 'GENERIC') {
            setClientError(responseData?.info || 'Failed to fetch Task Categorie data.');
          } else if (type === 'FILEID') {
            setFileError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          if (type === 'GENERIC') {
            setClientError('Unauthorized access. Your session may have expired. Please log in again.');
          } else if (type === 'FILEID') {
            setFileError('Unauthorized access. Your session may have expired. Please log in again.');
          }
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          if (type === 'GENERIC') {
            setClientError(responseData?.info || 'Bad request. Please check the request parameters.');
          } else if (type === 'FILEID') {
            setFileError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        if (type === 'GENERIC') {
          setClientError(responseData?.info || 'Internal Server Error. Please try again later.');
        } else if (type === 'FILEID') {
          setFileError(responseData?.info || 'Internal Server Error. Please try again later.');
        }
      }
      // Unexpected or network-related errors
      else {
        if (type === 'GENERIC') {
          setClientError(responseData?.info || 'Unexpected error occurred. Please try again later.');
        } else if (type === 'FILEID') {
          setFileError(responseData?.info || 'Unexpected error occurred. Please try again later.');
        }
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        // setClientError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        if (type === 'GENERIC') {
          setClientError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (type === 'FILEID') {
          setFileError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        }
      } else if (error.request) {
        // Request was made but no response was received
        if (type === 'GENERIC') {
          setClientError('No response received from the server. Please check your network connection and try again.');
        } else if (type === 'FILEID') {
          setFileError('No response received from the server. Please check your network connection and try again.');
        }
      } else {
        // An error occurred in setting up the request
        if (type === 'GENERIC') {
          setClientError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        } else if (type === 'FILEID') {
          setFileError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      }
    } finally {
      if (type === 'GENERIC') {
        setClientLoading(false);
      } else if (type === 'FILEID') {
        setFileLoading(false)
      }
    }

  };

  useEffect(() => {
    fetchUserList()
  }, [])

  const [empLoading, setEmpLoading] = useState(false);
  const [filterError, setFilterError] = useState('');
  const [listOfEmployees, setListOfEmployee] = useState([]);
  const fetchUserList = async () => {
    try {
      setEmpLoading(true);
      setFilterError('');
      setListOfEmployee([]);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const filterData = responseData?.dataJ.map(service => ({
                label: service?.emp_name,
                value: service?.emp_id
              }));
              setListOfEmployee(filterData);
            } else {
              setFilterError(responseData?.info || 'Data Not Found');
            }
          } else {
            setFilterError(responseData?.info || 'Failed to fetch User data. Please try again.');
          }
        } else {
          setFilterError(responseData?.info || 'Failed to fetch User data.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setFilterError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setFilterError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else if (response?.status === 500) {
        setFilterError(responseData?.info || 'Internal Server Error. Please try again later.');
      } else {
        setFilterError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setFilterError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
      } else if (error.request) {
        setFilterError('No response received from the server. Please check your network connection and try again.');
      } else {
        setFilterError(`Failed to fetch User data. Please check your network connection and try again.`);
      }
    } finally {
      setEmpLoading(false);
    }
  }

  // const [error,setError] = useState('');
  // const [docSearchLoading, setDocSearchLoading] = useState(false);

  const handleSubmitClientSearch = async () => {
    //console.log("apply clicked")
    try {
      setDocFiltterError('')
      if (!searchUser && !flineNumber && !deliveryType && !source && !documentNumber && !startDate && !endDate && !receivedBy) {
        setDocFiltterError('Please fill at least one field to search.');
        return; // Stop execution if all fields are empty
      }

      const startDateObj = new Date(startDate);
      const endDateObj = new Date(endDate);
         // Validation: Start date should not be greater than the end date
    if (startDate && endDate && startDateObj > endDateObj) {
      setDocFiltterError("To date cannot be earlier than the from date.");return
    }

      setKYCListError('');
      setKycDocumentList([]);
      setKycListLoading(true);
      setCurrentPage(1);

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      const payLoad = {
        emp_id_auth: storedUserInfo?.emp_id,
        client_name: searchUser || '', // Default to empty string
        client_file_id: flineNumber || '',
        doc_upload_via: deliveryType || '',
        doc_source_id: source || 0,
        doc_number: documentNumber || 0,
        start_date: startDate || '',
        end_date: endDate || '',
        emp_id: receivedBy?.value || 0,
        client_id: selectedEmployee?.client_id
      };
      //console.log("apply clicked 6 ")


      //console.log('handleSearchEmployee payload',payLoad);
      // Make the API call
      const response = await postData(ORG_DOCUMENT_SEARCH_RACK, payLoad);
      const responseData = await response.json();
      //console.log('response Data',responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            // setEmployeeSearchList('')
            setKycDocumentList(responseData?.dataJ || []);
            // setFilteredTasks(responseData?.dataJ || [])
            //   setError(responseData?.info);
            // setTimeout(() => {
            //   // handleClose();
            //   // onValueChange();
            // }, 3000);
          } else {
            setKYCListError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setKYCListError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setKYCListError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setKYCListError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setKYCListError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setKYCListError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setKYCListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setKYCListError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setKYCListError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setKycListLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  return {
    currentTasks, // Only return tasks for the current page
    currentPage,
    totalPages,
    handlePageChange,
    filterTasks, // Expose the filter function
    setSelectedDocument,
    selectedDocument,
    handleDocumentDelete,
    loading,
    success,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
    handleDelete,
    isDeleteModalOpen,
    currentDocDeleteIndex,
    downloadDocuments,
    currentDocIndex,
    downloadKycLoading,
    kycDownloadError,

    handleSearchEmployee,
    handleSelectedEmployee,
    clientLoading,
    employeeList,
    clientError,

    listOfEmployees,
    filterError,
    empLoading,

    deliveryType,
    source,
    documentNumber,
    selectedEmployee,
    deliveryTypeOptions,
    setDeliveryType,
    sourceOptions,
    setSource,
    setDOcumentNumber,
    setSelectedEmployee,
    handleEndtDateChange,
    handleStartDateChange,
    setEndDate,
    endDate,
    setStartDate,
    startDate,
    setFileNumber,
    flineNumber,
    fileError,
    fileLoading,
    handleSubmitClientSearch,
    fileEmployeeList,
    handleSelectedFileEmployee,
    searchUser,
    setSearchUser,
    activeDropdown,
    setClientError,
    setFileError,
    setReceived,
    receivedBy,
    setFilterError,
    docInfoRef,
    handleSearchInputText,docFiltterError,setDocFiltterError
  };
};

export default InwardOutwardListController;
