import { useState, useRef, useEffect } from 'react';
import { getData, postData } from "../../../../../ApiServices/ApiService";
import { ORG_NOTICE_BOARD_MSG_LIST} from '../../../../../ApiServices/BaseURL';

const NoticeBoardController = () => {
    const [tasks, setTasks] = useState([]);
    const [filteredTasks, setFilteredTasks] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10; // Number of items per page

    useEffect(() => {
        // Fetch or load the data from an API or local file
        const data = [
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Team Name',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Team Name,User Name,User Name',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name',
                recipient: 'Global  ',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },
            {
                dateCreated: '11/11/2024',
                published: '11/11/2024',
                message: 'Holiday announced today, Company Name/Group Name ,Holiday announced today, Company Name/Group Name, Holiday announced today, Company Name/Group Name Holiday announced today, Company Name/Group Name',
                recipient: 'Team Name, Team NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam NameTeam Name',
                createdBy: 'John Doe',
                expiryDate: '11/11/2024',
            },

            // Add more sample data as needed
        ];
        setTasks(data);
        setFilteredTasks(data); // Initially, all tasks are displayed
    }, []);

    const [noticeBoardMessagesLoading, setNoticeBoardMessagesLoading] = useState(false);
    const [noticeBoardMessagesError, setNoticeBoardMessagesError] = useState('');
    const [noticeBoardExpiredMessagesError, setNoticeBoardExpiredMessagesError] = useState('');
    const [noticeBoardActiveMessagesError, setNoticeBoardActiveMessagesError] = useState('');
    const [noticeBoardMessagesList, setNoticeBoardMessagesList] = useState([]);
    const [noticeBoardExpiredMessagesList, setNoticeBoardExpiredMessagesList] = useState([]);

    useEffect(()=>{
        fetchNoticeBoardMessagesList()
    },[])

    const fetchNoticeBoardMessagesList =async ()=>{
        
        try {
            setNoticeBoardMessagesList([]);
            setNoticeBoardExpiredMessagesList([])
          setNoticeBoardMessagesLoading(true);
          setNoticeBoardMessagesError('');
          setNoticeBoardExpiredMessagesError('')
          setNoticeBoardActiveMessagesError("")
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_NOTICE_BOARD_MSG_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const currentDate = new Date().toISOString().split("T")[0]
                    console.log("currentDate", currentDate)
                    const activeRecords = [];
                    const expiredRecords = [];
                    responseData.dataJ.forEach((record) => {
                      const expireDate = record.expire_at;
                      console.log("expireDate", expireDate)
                      if (expireDate >= currentDate) {
                        activeRecords.push(record);
                      } else {
                        expiredRecords.push(record);
                      }
                    });
                    console.log("activeRecords", activeRecords);
                    console.log("expiredRecords", expiredRecords);
                    setNoticeBoardMessagesList(activeRecords);
                    setNoticeBoardExpiredMessagesList(expiredRecords);
                    if (activeRecords.length === 0) {
                      setNoticeBoardActiveMessagesError('No active messages found.');
                    }
                  
                    if (expiredRecords.length === 0) {
                      setNoticeBoardExpiredMessagesError('No expired messages found.');
                    }
                  } else {
                    setNoticeBoardMessagesError(responseData?.info || 'Data Not Found');
                  }
                } else {
                    setNoticeBoardMessagesError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                }
              } else {
                setNoticeBoardMessagesError(responseData?.info || 'Failed to fetch Team Information.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setNoticeBoardMessagesError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setNoticeBoardMessagesError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setNoticeBoardMessagesError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setNoticeBoardMessagesError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setNoticeBoardMessagesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setNoticeBoardMessagesError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setNoticeBoardMessagesError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
          } finally {
            setNoticeBoardMessagesLoading(false);
          }
      }

    // Handle filtering tasks
    const filterTasks = (filters) => {
        const { published, message } = filters;
        const filtered = tasks.filter((task) => {
            const matchesSource = published ? task.published === published : true;
            const matchesDeliveryType = message ? task.message === message : true;
            return matchesSource && matchesDeliveryType;
        });
        setFilteredTasks(filtered);
        setCurrentPage(1); // Reset to the first page after filtering
    };

    // Handle pagination
    const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
        if (pageNumber > 0 && pageNumber <= totalPages) {
            setCurrentPage(pageNumber);
        }
    };

    return {
        tasks: currentTasks, // Only return tasks for the current page
        currentPage,
        totalPages,
        handlePageChange,
        filterTasks, // Expose the filter function

        noticeBoardMessagesList,
        noticeBoardMessagesError,
        noticeBoardMessagesLoading,
        setNoticeBoardMessagesError,
        setNoticeBoardMessagesList,
        setNoticeBoardMessagesLoading,
        fetchNoticeBoardMessagesList,
        noticeBoardExpiredMessagesList,
        setNoticeBoardExpiredMessagesList,
        noticeBoardExpiredMessagesError,
        noticeBoardActiveMessagesError
    };
};

export default NoticeBoardController;
