import React, { useState, useRef, useEffect } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskCategoryModal from '../../../../Components/Modals/AddTaskCategoryModal';
import Input from '../../../../Components/Input';
import MobileInput from '../../../../Components/MobileInput';
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import AddUserModal from '../../../../Components/AddUserModal';
import ManageUsersController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageUsersController';
import Loader from '../../../../Components/Loader';
import Dropdown from '../../../../Components/Dropdown';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';

const ManageUsersScreen = ({ userList, error, loading, onRefresh,refreshTeamList }) => {

  const { userListLoading, userListError,
    username, setUsername, userNameError,
    mobileNumber, setMobileNumber, mobileNumberError,
    countryCode, setCountryCode,
    email, setEmail, emailError,
    teamManager, setTeamManager, teamManagerError,
    teamLead, setTeamLead, teamLeadError,
    employeeList, teamLeaderLoading,
    manageUserList,
    isModalOpen,
    handleAddDocument,
    handleCloseModal,
    getStatusStyle,
    handleEditUser, isUserEditable,
    onRefreshList,
    currentPage,
    totalPages,
    handlePageChange,
    currentPageUsers,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
    handleDelete,
    setSelectedEmployee,
    selectedEmployee,
    isDeleteModalOpen,
    handleEmployeeDelete,
    success,
    currentDocDeleteIndex,
    employeeDeleteLoading,
    employeedDeleteError,
    showFilters,
    filters, filterError,
    handleFilterChange, toggleFilters,
    applyFilters, resetFilters, cancelFilters,
    categoryInfoRef,
    handleAddUser,

    managerFiletrError,
    managerList,
    managerLoading,
    deletedMessages
  } = ManageUsersController(onRefresh, userList, error,refreshTeamList);

  //console.log('userListLoading @@@@', userListLoading)

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <>
      <div className='basic-info-container-border'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>Search User</h3>
          <div style={{ alignSelf: 'center' }}>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleAddUser}>
              Add User
            </button>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} alt="Filter" />  <span>
              {showFilters ? 'Filters' : 'Filters'}
            </span></button>
            <div >
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div className='taskScreen-filters-grid-container'>
              <Input
                label="Username"
                placeholder="Enter Username"
                value={filters?.user_name}
                onChange={(e) => handleFilterChange('user_name', e.target.value)}
                errorMessage={userNameError}
              // searchBoxWidth={230}
              />
              {/* <Input
          label="Mobile No."
          placeholder="------"
          value={mobileNumber}
          onChange={(e) => setMobileNumber(e.target.value)}
        /> */}
              <MobileInput
                label="Mobile No."
                countryCode={countryCode}
                mobileNumber={mobileNumber}
                onCountryChange={setCountryCode}
                onMobileChange={setMobileNumber}
                placeholder="Enter mobile number"
                countries={[
                  { code: "IN", dialCode: "+91" },
                  { code: "US", dialCode: "+1" },
                  { code: "UK", dialCode: "+44" },
                ]}
                width='100%'
                errorMessage={mobileNumberError}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Team Leader"] = el)}
                isOpen={openDropdown === "Team Leader"}
                toggleDropdown={() => toggleDropdown("Team Leader")}
                label="Team Leader"
                options={employeeList}
                value={filters?.team_leader}
                onChange={(value) => handleFilterChange('team_leader', value)}
                errorMessage={teamLeadError}
                loading={teamLeaderLoading}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Manager"] = el)}
                isOpen={openDropdown === "Manager"}
                toggleDropdown={() => toggleDropdown("Manager")}
                label="Manager"
                options={managerList}
                value={filters?.team_manager}
                onChange={(value) => handleFilterChange('team_manager', value)}
                errorMessage={managerFiletrError}
                loading={managerLoading}
              />
              {/* <Input
                label="Manager"
                placeholder="------"
                value={manager}
                onChange={(e) => setManager(e.target.value)}
              /> */}
              <Input
                label="Email ID"
                placeholder="Enter Email ID"
                value={filters?.email_id}
                onChange={(e) => handleFilterChange('email_id', e.target.value)}
                errorMessage={emailError}
              // searchBoxWidth={230}
              />
            </div>
            {filterError && <div className="list-error-msg" style={{ textAlign: 'left', paddingBottom: 5 }}> {filterError}</div>}
            <div className="tasksScreen-filter-buttons">
              <button onClick={applyFilters} className="tasksScreen-apply-button" style={{ cursor: userListLoading ? 'not-allowed' : 'pointer', opacity: userListLoading ? 0.5 : 1 }} disabled={userListLoading}>Apply</button>
              <button onClick={cancelFilters} className="tasksScreen-cancel-button">Cancel</button>
              <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
            </div>
          </div>
        )}

      </div>

      <div className='basic-info-card' style={{ border: 'none' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>User Name</th>
                <th style={{ width: "14%" }}>Mobile#</th>
                <th style={{ width: "20%" }}>Email ID</th>
                <th style={{ width: "16%" }}>Team Name</th>
                <th style={{ width: "10%" }}>Team Leader</th>
                <th style={{ width: "10%" }}>Manager</th>
                <th style={{ width: "5%" }}>Status</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            {currentPageUsers?.length > 0 &&
              <tbody style={{ backgroundColor: "white" }}>
                {currentPageUsers?.map((category, index) => (
                  <tr key={index}>
                    <td style={styles.tableLink} onClick={() => handleEditUser('userName', category)}>{category?.emp_name}</td>
                    <td style={styles.tableValue}>{category?.emp_mobile_code}{category?.emp_mobile_no || '--'}</td>
                    <td style={styles.tableValue}>{category?.emp_email || '--'}</td>
                    <td style={styles.tableValue}>{category?.team_name || '--'}
                      {success[category?.emp_id] &&
                        <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{success[category?.emp_id]}</div>}
                      {employeedDeleteError[category?.emp_id] &&
                        <div style={{ color: 'red', paddingTop: 10 }} >{employeedDeleteError[category?.emp_id]}</div>}
                    </td>
                    <td style={styles.tableValue}>{category?.emp_id_team_lead_name || '--'}</td>
                    <td style={styles.tableValue}>{category?.emp_id_team_manager_name || '--'}</td>
                    <td style={styles.tableValue}>
                      {/* Status Label */}
                      <div
                        style={{
                          display: "inline-block", // Makes the div wrap content and have its own width
                          padding: "5px 5px", // Padding for the button-like appearance
                          borderRadius: "15px",
                          ...getStatusStyle(Number(category?.emp_status)), // Dynamic styles based on the status
                          width: "auto", // Width adjusts automatically to content
                        }}
                      >
                        {category?.emp_status === 0 ? 'InActive' : 'Active'}
                      </div>

                      {/* Action Buttons */}
                    </td>
                    <td>
                      <div
                        style={{
                          display: "flex",
                          gap: "2px", // Space between edit and delete buttons
                        }}
                      >
                        {/* Edit Button */}
                        <button
                          className="tasksScreen-edit-button"
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: employeeDeleteLoading[category?.emp_id] || deletedMessages.includes(category?.emp_id) ? 'not-allowed' : 'pointer',
                            pointerEvents: employeeDeleteLoading[category?.emp_id] || deletedMessages.includes(category?.emp_id) ? 'none' : 'auto',
                            opacity: employeeDeleteLoading[category?.emp_id] ? 0.3 : 1
                          }}
                          onClick={() => handleEditUser('editUser', category)}
                        >
                          <img
                            src={editIcon}
                            alt="Edit"
                            style={{ width: "16px", height: "16px" }}
                          />
                        </button>

                        {/* Delete Button */}
                        <button
                          className="tasksScreen-delete-button"
                          style={{
                            border: "none",
                            background: "transparent",
                            cursor: employeeDeleteLoading[category?.emp_id] || deletedMessages.includes(category?.emp_id) ? 'not-allowed' : 'pointer',
                            pointerEvents: employeeDeleteLoading[category?.emp_id] || deletedMessages.includes(category?.emp_id) ? 'none' : 'auto',
                            opacity: employeeDeleteLoading[category?.emp_id] ? 0.5 : 1,
                          }}
                          onClick={() => { handleDelete(category) }}
                        >
                          <img
                            src={deleteIcon}
                            alt="Delete"
                            style={{ width: "16px", height: "16px" }}
                          />
                        </button>
                        {employeeDeleteLoading[category?.emp_id] &&
                          <span><Loader loading={employeeDeleteLoading[category?.emp_id]} color={'var(--primary-color)'} size='small' /></span>
                        }
                        {/* {employeeDeleteLoading &&  currentDocDeleteIndex=== index && (
                        <span style={{marginLeft:'5px'}}>
                          <Loader loading={employeeDeleteLoading} color={'var(--primary-color)'} size="small" />
                        </span>
                      )} */}
                      </div>
                    </td>

                  </tr>
                ))}
              </tbody>
            }
          </table>
          {isModalOpen &&
            <AddUserModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={onRefreshList} />}
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            message={`Are you sure you want to delete ${categoryInfoRef?.current?.emp_name}?`}
            onClose={handleCloseDeleteModal}
            onConfirm={() => {
              handleCloseDeleteModal(); // Close modal
              handleEmployeeDelete(categoryInfoRef?.current); // Perform delete operation
            }}
          />
          {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
          {userListLoading && <Loader loading={userListLoading} color={'var(--primary-color)'} />}
          {userListError && <div className="list-error-msg">{userListError} </div>}
          {/* {employeedDeleteError && <div className="list-error-msg">{employeedDeleteError} </div>}
          {success && <div className="list-success-msg">{success} </div>} */}
        </div>

        {/* Pagination */}
        <div className="tasksScreen-table-footer" >
          <span className="tasksScreen-pagination-info">
            {`${currentPage}-${totalPages} of items`}
          </span>
          <div className="tasksScreen-pagination">
            <button
              className="tasksScreen-pagination-button"
              onClick={() => handlePageChange(currentPage - 1)}
              disabled={currentPage === 1 || totalPages === 0}
            >
              <img
                src={downArrow}
                alt="Previous"
                className="tasksScreen-arrow-left tasksScreen-icon-image"
              />
            </button>
            {[...Array(totalPages)].map((_, index) => (
              <button
                key={index}
                className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                onClick={() => handlePageChange(index + 1)}
              >
                {index + 1}
              </button>
            ))}
            <button
              className="tasksScreen-pagination-button"
              onClick={() => handlePageChange(currentPage + 1)}
              disabled={currentPage === totalPages || totalPages === 0}
            >
              <img
                src={downArrow}
                alt="Next"
                className="tasksScreen-arrow-right tasksScreen-icon-image"
              />
            </button>
          </div>
        </div>

      </div>
    </>

  )

};

const styles = {
  categoryHeader: {
    color: '#1F384C',
    fontSize: 28,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2',
    cursor: 'pointer'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },


}

export default ManageUsersScreen;