
import React, {useState, useEffect} from "react";
import { useLocation } from 'react-router-dom';
import { ORG_GET_KYC_DOCUMENTS_LIST,ORG_GET_USER_CREDENTIAL_LIST, ORG_GET_CLIENT_TASK_LIST} from '../../../../ApiServices/BaseURL';
import { getData } from "../../../../ApiServices/ApiService";

const ManageClientController =(clientId)=>{
    const [kycListLoading, setKycListLoading] = useState(false);
    const [kycListError, setKYCListError] = useState('');
    const [success, setSuccess] = useState('');
    const [kycDocumentList, setKycDocumentList] = useState([]);
    const [userCredentials, setUserCredentials] = useState([]);
    const [userCredentialLoading, setUserCredentialLoading] = useState(false);
    const [userCredentailError, setUserCredentailError] = useState("");

    const [taskList, setTaskList] = useState([]);
    const [taskListLoading,setTaskListLoading]=useState(false);
    const [taskListError,setTaskListError]=useState('');
    const [oneTimeUseEffectCallForChiled,setOneTimeUseEffectCallForChiled] = useState(true);

    useEffect(()=>{
        fetchKYCDocumentsList();
        fetchUserCredentials();
        fetchClientTaskList();
    },[])

    const fetchUserCredentials = async () => {
      //console.log('Fetching user credentials');
      try {
          setUserCredentials([]); // Reset credentials list
          setUserCredentialLoading(true); // Start loading
          setUserCredentailError(''); // Clear any existing error

          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

          // Make the API call
          const response = await getData(`${ORG_GET_USER_CREDENTIAL_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
          const responseData = await response.json();

          // Check for success (200) and internal status code
          if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                  if (responseData?.message.toLowerCase() === 'success') {
                      if (responseData?.dataJ?.length > 0) {
                          const accounts = responseData?.dataJ || [];
                          const formattedCredentials = accounts.map((account) => ({
                              portalName: account.account_name,
                              username: account.account_user_name,
                              password: account.account_user_password,
                              showPassword: false,
                              url: account.account_web_url,
                              account_id: account.account_id,
                          }));
                          setUserCredentials(formattedCredentials); // Update credentials
                      } else {
                          setUserCredentailError(responseData?.info || 'No credentials found.');
                      }
                  } else {
                      setUserCredentailError(responseData?.info || 'Failed to fetch credentials.');
                  }
              } else {
                  setUserCredentailError(responseData?.info || 'Failed to fetch credentials.');
              }
          }
          // Handle specific token-related errors
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setUserCredentailError('Unauthorized access. Your session may have expired. Please log in again.');
              } else {
                  setUserCredentailError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
          }
          // Internal Server Error
          else if (response?.status === 500) {
              setUserCredentailError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Handle unexpected errors
          else {
              setUserCredentailError('Unexpected error occurred. Please try again later.');
          }
      } catch (error) {
          // Handle various error scenarios
          if (error.response) {
              setUserCredentailError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch credentials. Please try again.'}`);
          } else if (error.request) {
              setUserCredentailError('No response received from the server. Please check your network connection and try again.');
          } else {
              setUserCredentailError(`Failed to fetch credentials. Please check your network connection and try again.`);
          }
      } finally {
          setUserCredentialLoading(false); // Stop loading
      }
  };

    const fetchKYCDocumentsList =async ()=>{
        try {
          setKycDocumentList([]);
            setKycListLoading(true);
            setKYCListError('');
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_KYC_DOCUMENTS_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setKycDocumentList(responseData?.dataJ);
                    // selTeamInfo.current = responseData?.dataJ[0];
                  } else {
                    setKYCListError(responseData?.info || 'Data Not Found');
                  }
                } else {
                    setKYCListError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                }
              } else {
                setKYCListError(responseData?.info || 'Failed to fetch Team Information.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setKYCListError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setKYCListError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setKYCListError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setKYCListError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setKYCListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setKYCListError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setKYCListError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
          } finally {
            setKycListLoading(false);
          }
      }

      const fetchClientTaskList = async () => {
        //console.log('Fetching user credentials');
        try {
          setTaskList([]); // Reset credentials list
          setTaskListLoading(true); // Start loading
          setTaskListError(''); // Clear any existing error
  
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
  
            // Make the API call
            const response = await getData(`${ORG_GET_CLIENT_TASK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
            const responseData = await response.json();
  
            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {                            
                          setTaskList(responseData?.dataJ); 
                        } else {
                          setTaskListError(responseData?.info || 'No credentials found.');
                        }
                    } else {
                      setTaskListError(responseData?.info || 'Failed to fetch credentials.');
                    }
                } else {
                  setTaskListError(responseData?.info || 'Failed to fetch credentials.');
                }
            }
            // Handle specific token-related errors
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setTaskListError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                  setTaskListError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error
            else if (response?.status === 500) {
              setTaskListError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Handle unexpected errors
            else {
              setTaskListError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              setTaskListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch credentials. Please try again.'}`);
            } else if (error.request) {
              setTaskListError('No response received from the server. Please check your network connection and try again.');
            } else {
              setTaskListError(`Failed to fetch credentials. Please check your network connection and try again.`);
            }
        } finally {
            setTaskListLoading(false)
        }
    };

      const fetchKYCDocumentsRefresh =()=>{
        //console.log('call the refresh')
        fetchKYCDocumentsList();
      }

      return{
        kycListLoading,
        kycListError,
        kycDocumentList,
        fetchKYCDocumentsRefresh,
        userCredentials,
        userCredentialLoading,
        userCredentailError,
        fetchUserCredentials,
        setUserCredentials,
        taskList,
        taskListLoading,
        taskListError,
        fetchClientTaskList,
        oneTimeUseEffectCallForChiled,setOneTimeUseEffectCallForChiled
      }
}

export default ManageClientController
