import { useState, useRef, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { getData } from "../../../../ApiServices/ApiService";
import { ORG_GET_COMMENTS_LIST } from "../../../../ApiServices/BaseURL";

const CommentsController = () => {
  const [comments, setComments] = useState([]);
  const [expandedTasks, setExpandedTasks] = useState({});
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  const location = useLocation();
  const clientId = location.state?.clientId || "";

  useEffect(() => {
    if (!clientId) {
      setError("Client ID is missing.");
      setLoading(false);
      return;
    }

    const fetchComments = async () => {
      try {
        setLoading(true);
        setError("");
        const storedUserInfo = JSON.parse(localStorage.getItem("_userInfo_"));
        if (!storedUserInfo?.emp_id) {
          setError("User information is missing.");
          setLoading(false);
          return;
        }

        const url = `${ORG_GET_COMMENTS_LIST}?emp_id_auth=${storedUserInfo.emp_id}&client_id=${clientId}`;
        const response = await getData(url);
        const responseData = await response.json();

        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setComments(responseData?.dataJ || []);
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch Comments. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Comments.');
          }
        }
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        }
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        }
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Comments. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Comments. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchComments();
  }, [clientId]);

  const handleToggleDetails = (taskId) => {
    setExpandedTasks((prev) => ({
        ...prev,
        [taskId]: !prev[taskId],
    }));
};

  return {
    comments,
    expandedTasks,
    handleToggleDetails,
    loading,
    error,
  };
};

export default CommentsController;
