import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import ResourcesListController from "../../Controllers/AdminScreens/ResourcesListController";
import AddResourceModal from "../../../Components/AddResourceModal";
import downArrow from "../../../Assets/Icons/chevron-down.png";
import searchIcon from "../../../Assets/Icons/search.png";

const ResourcesListScreen = () => {
    const {
        currentResources,
        searchTerm,
        setSearchTerm,
        currentPage,
        totalPages,
        handlePageChange,
    } = ResourcesListController();

    const [isModalOpen, setIsModalOpen] = useState(false);

    const navigate = useNavigate();

    const handleAddUserClick = () => {
        setIsModalOpen(true);
    };

    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleRowClick = (resource) => {
        //console.log("Navigating to details with resource:", resource);
        navigate("/resources-details", { state: { resource } });
    };

    return (
        <div className="tasksScreen-main-content">
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <h2 style={styles.title}>Resources</h2>
                <button
                    className="add-client-button"
                    style={{ marginBottom: 10 }}
                    onClick={handleAddUserClick}
                >
                    Add Resources
                </button>
            </div>
            <div className="tasksScreen-tasks-container">
                <h3 style={styles.searchTitle}>Search Resources</h3>
                <div className="tasksScreen-task-table-wrapper">
                    <table className="tasksScreen-task-table">
                        <thead>
                            <tr>
                                <th colSpan="3" style={styles.theadContainer}>
                                    <div style={styles.searchBarContainer}>
                                        <input
                                            type="text"
                                            placeholder="Search Something on Resources"
                                            value={searchTerm}
                                            onChange={(e) => setSearchTerm(e.target.value)}
                                            style={styles.searchInput}
                                        />
                                        <img
                                            src={searchIcon}
                                            alt="Search"
                                            style={styles.searchIcon}
                                        />
                                        <button
                                            className="tasksScreen-apply-button"
                                            style={styles.searchButton}
                                            onClick={() => console.log("Searching:", searchTerm)}
                                        >
                                            Search
                                        </button>
                                        <button
                                            className="tasksScreen-reset-button"
                                            style={styles.resetButton}
                                            onClick={() => setSearchTerm("")}
                                        >
                                            Reset
                                        </button>
                                    </div>
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            <div style={styles.resourcesList}>
                                {currentResources.map((resource, index) => (
                                    <div key={index} style={styles.resourceItem} onClick={() => handleRowClick(resource)}>
                                        <div style={styles.resourceImage}></div>
                                        <div style={styles.resourceDetails}>
                                            <div style={styles.resourceTitle}>
                                                {resource.title}
                                            </div>
                                            <p style={styles.resourceDescription}>
                                                {resource.description}
                                            </p>
                                        </div>
                                    </div>
                                ))}
                            </div>
                        </tbody>
                    </table>
                </div>

                {/* Pagination */}
                <div className="tasksScreen-table-footer">
                    <span className="tasksScreen-pagination-info">
                        {`${currentPage}-${totalPages} of items`}
                    </span>
                    <div className="tasksScreen-pagination">
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage - 1)}
                            disabled={currentPage === 1}
                        >
                            <img
                                src={downArrow}
                                alt="Previous"
                                className="tasksScreen-arrow-left tasksScreen-icon-image"
                            />
                        </button>
                        {[...Array(totalPages)].map((_, index) => (
                            <button
                                key={index}
                                className={`tasksScreen-pagination-button ${currentPage ===
                                    index + 1
                                    ? "active"
                                    : ""
                                    }`}
                                onClick={() => handlePageChange(index + 1)}
                            >
                                {index + 1}
                            </button>
                        ))}
                        <button
                            className="tasksScreen-pagination-button"
                            onClick={() => handlePageChange(currentPage + 1)}
                            disabled={currentPage === totalPages}
                        >
                            <img
                                src={downArrow}
                                alt="Next"
                                className="tasksScreen-arrow-right tasksScreen-icon-image"
                            />
                        </button>
                    </div>
                </div>

                {/* Add Resource Modal */}
                <AddResourceModal isOpen={isModalOpen} onClose={handleCloseModal} />
            </div>
        </div>
    );
};

const styles = {
    headerContainer: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "5px",
    },
    title: {
        fontSize: "22px",
        fontWeight: "500",
        color: "#060606",
    },
    searchContainer: {
        marginBottom: "20px",
    },
    searchTitle: {
        fontSize: "28px",
        fontWeight: "500",
        marginBottom: "10px",
        color: "#1F384C"
    },
    theadContainer: {
        textAlign: "center",
        padding: "20px",
        backgroundColor: "#f9f9f9",
    },
    searchBar: {
        display: "flex",
        alignItems: "center",
        gap: "10px",
        justifyContent: "center",
    },
    searchBarContainer: {
        position: "relative",
        display: "flex",
        alignItems: "center",
        gap: "10px",
        justifyContent: "center",
        color: "#343C44",
        fontSize: "12px",

    },
    searchIcon: {
        position: "absolute",
        right: "23%",
        top: "50%",
        transform: "translateY(-50%)",
        width: "20px",
        height: "20px",
        pointerEvents: "none",
    },
    searchInput: {
        width: "60%",
        padding: "10px",
        border: "1px solid #d1d1d1",
        borderRadius: "5px",
        fontSize: "14px",
        marginLeft: "120px",
        paddingRight: "40px",
    },
    resourcesList: {
        flexDirection: "column",
        gap: "20px",
    },
    resourceItem: {
        display: "flex",
        alignItems:'center',
        gap: "15px",
        backgroundColor: "#fff",
        borderBottom: "1px solid #d1d1d1",
        cursor: "pointer",
        padding:'10px'
    },
    resourceImage: {
        width: "40px",
        height: "40px",
        backgroundColor: "#D9D9D9",
        borderRadius: "50%",
        // marginTop: "20px",
        // marginLeft: "10px"
    },
    resourceDetails: {
        flex: 1,
    },
    resourceTitle: {
        fontSize: "16px",
        fontWeight: "400",
        marginBottom: "5px",
        color: "#060606",
    },
    resourceDescription: {
        fontSize: "12px",
        color: "#767676",
        fontWeight: 400,
    },
};

export default ResourcesListScreen;