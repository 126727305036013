import React ,{ useEffect,useState, useRef } from "react";
import { ORG_GET_SERVICE_LIST, ORG_GET_SUB_SERVICE_LIST,ORG_GET_SUB_SERVICE_CHECK_LIST,ORG_GET_SERVICE_LIST_SEARCH } from "../../../../ApiServices/BaseURL";
import { postData,getData } from "../../../../ApiServices/ApiService";

const TaskManagementController = () => {
   
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');  

  const [subCategories, setSubCategories] = useState([]);
  const [subCatLoading, setSubCatLoading] = useState(false);
  const [subCatError, setSubCatError] = useState('');
  
  const [typeCheckList, setTypeCheckList] = useState([]);
  const [typeCheckLoading, setTypeCheckLoading] = useState(false);
  const [typeCheckError, setTypeCheckError] = useState('');

  const [servicesSearchList, setServicesSearchList] = useState([]);
  const [searchLoading, setSearchLoading] = useState(false);
  const [searchError, setSearchError] = useState('');

  useEffect(()=>{
    fetchTaskCategorieList();
    fetchTaskSubCategorieList();
    fetchTaskCheckList();
    fetchTaskServicesSearchList();
  },[])

  const fetchTaskCategorieList =async ()=>{
    try {
        //console.log('get categoty')
        setLoading(true);
        setCategories([]);
        setError('');
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const response = await getData(`${ORG_GET_SERVICE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setCategories(responseData?.dataJ);
              } else {
                setError(responseData?.info || 'Data Not Found');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
      }
  }


  const fetchTaskSubCategorieList =async ()=>{
    try {
        setSubCatError('')
        setSubCatLoading(true);
        setSubCategories([]);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call service_id=10006&
        const response = await getData(`${ORG_GET_SUB_SERVICE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setSubCategories(responseData?.dataJ);
              } else {
                setSubCatError(responseData?.info || 'Data Not Found');
              }
            } else {
              setSubCatError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setSubCatError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setSubCatError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setSubCatError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setSubCatError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setSubCatError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setSubCatError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setSubCatError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setSubCatError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setSubCatLoading(false);
      }
  }

  const fetchTaskCheckList =async ()=>{
    try {
        setTypeCheckError('')
        setTypeCheckLoading(true);
        setTypeCheckList([]);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call service_id=10006&
        const response = await getData(`${ORG_GET_SUB_SERVICE_CHECK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
        // const response = await getData(`${ORG_GET_SUB_SERVICE_CHECK_LIST}?emp_id_auth=10001&service_sub_id=100001`);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                setTypeCheckList(responseData?.dataJ);
              } else {
                setTypeCheckError(responseData?.info || 'Data Not Found');
              }
            } else {
                setTypeCheckError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setTypeCheckError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setTypeCheckError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setTypeCheckError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
            setTypeCheckError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
            setTypeCheckError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setTypeCheckError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setTypeCheckError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setTypeCheckError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setTypeCheckLoading(false);
      }
  }

  const fetchTaskServicesSearchList =async ()=>{
    try {
        setSearchError('')
        //console.log('fetchTaskServicesSearchList')
        setSearchLoading(true);
        setServicesSearchList([]);
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {
          "emp_id_auth": storedUserInfo?.emp_id
        }
          
        //console.log('fetchTaskServicesSearchList payload',payLoad);
    
        // Make the API call
        const response = await postData(ORG_GET_SERVICE_LIST_SEARCH,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              if (responseData?.dataJ?.length > 0) {
                const filterData = await responseData?.dataJ.map(service => ({
                  label: service.service_name,
                  value: service.service_id
                }));
                setServicesSearchList(filterData);
              } else {
                setSearchError(responseData?.info || 'Data Not Found');
              }
            } else {
              setSearchError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
            }
          } else {
            setSearchError(responseData?.info || 'Failed to fetch Task Categorie data.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setSearchError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setSearchError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setSearchError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setSearchError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setSearchError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setSearchError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setSearchError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
        }
      } finally {
        setSearchLoading(false);
      }
  }

  const categoryRefresh =()=>{
    //console.log('call the refresh')
    fetchTaskCategorieList();
    fetchTaskServicesSearchList();
    fetchTaskSubCategorieList();
    fetchTaskCheckList();
  }

  const subCategoryRefresh =()=>{
    //console.log('call the sub refresh')
    fetchTaskSubCategorieList();
    fetchTaskCheckList();
  }

  const typeCheckRefresh =()=>{
    //console.log('call the typeCheck List')
    fetchTaskCheckList();
  }






  return {
    categories,
    loading,
    error,   
    servicesSearchList,
    searchLoading,
    searchError,
    categoryRefresh,
    subCategories,
    subCatError,
    subCatLoading,
    subCategoryRefresh,
    typeCheckList,
    typeCheckLoading,
    typeCheckError,
    typeCheckRefresh
  };
};

export default TaskManagementController;
