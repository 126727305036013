import React, { useState, useEffect, useRef } from "react";
import Input from "../Input";
import TextInput from "../TextInput";
import Dropdown from "../Dropdown";
import DateInput from "../DateInput";
import MobileInput from "../MobileInput";
import closeIcon from "../../Assets/Icons/close.png";
import search from "../../Assets/Icons/search.png";
import uploadIcon from "../../Assets/Icons/upload.png";

const AddDigitalSignatureModal = ({ isOpen, onClose }) => {
    const [clientFileNo, setClientFileNo] = useState("");
    const [physicalTokenNo, setPhysicalTokenNo] = useState("");
    const [nameOfClient, setNameOfClient] = useState("");
    const [organisationName, setOrganisationName] = useState("");
    const [panNo, setPanNo] = useState("");
    const [emailId, setEmailId] = useState("");
    const [phoneNo, setPhoneNo] = useState("");
    const [countryCode, setCountryCode] = useState('+91');
    const [tokenPassword, setTokenPassword] = useState("");
    const [status, setStatus] = useState("");
    const [fileUpload, setFileUpload] = useState("");
    const [validFrom, setValidFrom] = useState("");
    const [remark, setRemark] = useState("");
    const fileInputRef = useRef(null);

    const statusOptions = [
        { label: "Downloaded", value: "downloaded" },
        { label: "Pending", value: "pending" },
        { label: "Rejected", value: "rejected" },
    ];

    const fileUploadFrom = [
        { label: "USB", value: "SUB" },
        { label: "Mail", value: "Mail" },
        { label: "WhatsApp", value: "WhatsApp" },
        { label: "Drive", value: "Drive" },
    ];

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const resetForm = () => {
        setClientFileNo("");
        setPhysicalTokenNo("");
        setNameOfClient("");
        setOrganisationName("");
        setPanNo("");
        setEmailId("");
        setPhoneNo("");
        setTokenPassword("");
        setStatus("");
        setValidFrom("");
        setRemark("");
    };

    const handleAddSignature = () => {
        const newSignature = {
            clientFileNo,
            physicalTokenNo,
            nameOfClient,
            organisationName,
            panNo,
            emailId,
            phoneNo,
            tokenPassword,
            status,
            validFrom,
            remark,
        };
        //console.log("New Digital Signature:", newSignature);
        onClose();
    };

    const handleFileUploadClick = () => {
        fileInputRef.current.click();
    };

    const handleFileChange = (event) => {
        const file = event.target.files[0];
        if (file) {
            //console.log("Uploaded file:", file.name);
        }
    };
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '750px' }}>
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Add New Digital Signature</h2>
                <hr className="footer-divider" />
                <div className="form-grid">
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Upload Certificate</label>
                        <div style={styles.uploadContainer}>
                            <div style={styles.uploadBox} onClick={handleFileUploadClick}>
                                <span style={styles.uploadText}><img src={uploadIcon} alt="Upload" style={styles.uploadIcon} />Upload (Valid file format Only)</span>
                            </div>
                            <input
                                type="file"
                                ref={fileInputRef}
                                style={styles.fileInput}
                                onChange={handleFileChange}
                            />
                        </div>
                    </div>
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Select Client or File No.</label>
                        <TextInput
                            type="text"
                            placeholder="Enter File No."
                            value={clientFileNo}
                            onChange={(e) => setClientFileNo(e.target.value)}
                            right={<img src={search} alt="Search" />}
                        />
                    </div>
                    <Input
                        label="Physical Token No."
                        value={physicalTokenNo}
                        onChange={(e) => setPhysicalTokenNo(e.target.value)}
                        placeholder="Enter Token No."
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["fileUpload"] = el)}
                        isOpen={openDropdown === "fileUpload"}
                        toggleDropdown={() => toggleDropdown("fileUpload")}
                        label="USB/Mail/WhatsApp/Drive"
                        options={fileUploadFrom}
                        value={fileUpload}
                        onChange={(value) => setFileUpload(value)}
                    />
                    <Input
                        label="Name of Client"
                        value={nameOfClient}
                        onChange={(e) => setNameOfClient(e.target.value)}
                        placeholder="Enter Client Name"
                    />
                    <Input
                        label="Organisation Name"
                        value={organisationName}
                        onChange={(e) => setOrganisationName(e.target.value)}
                        placeholder="Enter Organisation Name"
                    />
                    <Input
                        label="PAN No"
                        value={panNo}
                        onChange={(e) => setPanNo(e.target.value)}
                        placeholder="Enter PAN No."
                    />
                    <Input
                        label="Email ID"
                        value={emailId}
                        onChange={(e) => setEmailId(e.target.value)}
                        placeholder="Enter Email ID"
                    />
                    {/* <Input
                        label="Phone No."
                        value={phoneNo}
                        onChange={(e) => setPhoneNo(e.target.value)}
                        placeholder="Enter Phone No."
                    /> */}
                    <MobileInput
                        label="Phone No."
                        countryCode={countryCode}
                        mobileNumber={phoneNo}
                        onCountryChange={setCountryCode}
                        onMobileChange={setPhoneNo}
                        placeholder="Enter mobile number"
                        countries={[
                            { code: "IN", dialCode: "+91" },
                            { code: "US", dialCode: "+1" },
                            { code: "UK", dialCode: "+44" },
                        ]}
                        width="95%"
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["status"] = el)}
                        isOpen={openDropdown === "status"}
                        toggleDropdown={() => toggleDropdown("status")}
                        label="Status"
                        options={statusOptions}
                        value={status}
                        onChange={(value) => setStatus(value)}
                    />
                    <div>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Valid From</label>
                        <DateInput
                            label="Valid From"
                            value={validFrom}
                            onChange={(date) => setValidFrom(date)}
                        />
                    </div>
                    <Input
                        label="Token Password"
                        value={tokenPassword}
                        onChange={(e) => setTokenPassword(e.target.value)}
                        placeholder="Enter Token Password"
                    />
                </div>
                <div style={styles.actionField}>
                    <label style={styles.label}>Remark</label>
                    <textarea placeholder="Add Remark" style={styles.textarea}></textarea>
                </div>
                <hr className="footer-divider" />
                <div style={styles.buttonContainer}>
                    <button className="add-client-button" onClick={handleAddSignature}>
                        Add
                    </button>
                    <button className="cancel-button" onClick={onClose}>
                        Cancel
                    </button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 2fr 2fr",
        gap: "20px",
        marginTop: "20px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
        marginTop: '15px'
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "87px",
        fontSize: "14px",
        color: "#333",
        width: '500px'
    },
    uploadContainer: {
        marginTop: "10px",
    },
    uploadBox: {
        display: "flex",
        alignItems: "center",
        border: "1px solid #858585",
        borderRadius: "50px",
        padding: "10px",
        cursor: "pointer",
        justifyContent: 'space-between'
    },
    uploadIcon: {
        width: "20px",
        height: "20px",
        marginRight: "10px",
    },
    uploadText: {
        display: 'flex',
        color: "#40513B ",
        fontSize: "12px",
        fontWeight: 500,
        gap: "1px",
        alignItems: 'center',
    },
    fileInput: {
        display: "none",
    },
};

export default AddDigitalSignatureModal;
