import { useState, useRef, useEffect } from "react"

const UserAccessController = () => {

    const [isManageAccessModalOpen, setIsManageAccessModalOpen] = useState(false);
    const [selUserAccessService, setSelUserAccessService] = useState(null);

    const handleEditUserAccess = (userAccess) => {
        //console.log('handleEditUserAccess userAccess @', userAccess)
        setSelUserAccessService(userAccess);
        setIsManageAccessModalOpen(true); 
    }

    const handleToggleChange = (newState) => {
        //console.log('Toggle userAccess:', newState);
    }

    const handleCloseManageAccessModal = () => {
        setSelUserAccessService(null);
        setIsManageAccessModalOpen(false); 
    }

    const getActiveSubPerNames = (list) => {
        const activeSubPerNames = list
          ?.filter((perNameSub) => perNameSub.is_active === 1)
          ?.map((perNameSub) => perNameSub.per_name_sub);
      
        return activeSubPerNames?.length > 0 ? activeSubPerNames.join(", ") : "--";
    };

    return {
        handleEditUserAccess,
        handleToggleChange,
        isManageAccessModalOpen,
        handleCloseManageAccessModal,
        selUserAccessService,
        getActiveSubPerNames
    };

}

export default UserAccessController;