import React, { useState, useRef, useEffect } from 'react';
import '../App.css';
import error from '../Assets/Icons/error_message.png';

const TextInput = ({
  placeholder,
  inputType = 'text',
  style,
  right,
  value,
  onChange,
  onRightClick,
  errorMessage,
  width = 221,
  searchBoxWidth = null,
  maxLength = null,
  ...props
}) => {
  const [isSmallScreen, setIsSmallScreen] = useState(window.innerWidth <= 550);

  useEffect(() => {
    const handleResize = () => {
      setIsSmallScreen(window.innerWidth <= 550);
    };

    window.addEventListener('resize', handleResize);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  const handleInputChange = (e) => {
    const inputValue = e.target.value;
    if (inputType === 'number') {
      if (/^\d*$/.test(inputValue)) {
        onChange(e);
      }
    } else {
      onChange(e);
    }
  };

  return (
    <div style={{ width: isSmallScreen ? '100%' : null }}>
      <div
        className="input-container2"
        style={{
          position: 'relative',
          width: '100%',
          border: '1px solid #d6e4ec',
          borderColor: errorMessage ? "red" : "#D6E4EC",
          borderRadius: '6px',
          marginTop: '5px',
          maxWidth: isSmallScreen ? null : searchBoxWidth,
          minWidth: isSmallScreen ? null : searchBoxWidth,
          display:'flex',alignItems:'center'
        }}
      >
        <input
          type="text"
          className="input-field2"
          placeholder={placeholder}
          style={{
            ...style,
            border: 'none',
            minWidth: '70%',
            fontSize: '14px',
            flex:'auto',
            padding:'0px 10px'
          }}
          value={value || ''} // Ensure value is a string or an empty string
          onChange={handleInputChange}
          maxLength={maxLength}
          {...props}
        />
        {right && (
          <div
            className="" //right-element
            style={{ display:'flex',alignItems:'center',padding:5}} //top: '24px', right: '8px'
            onClick={onRightClick}
          >
            {right}
          </div>
        )}
      </div>
      {errorMessage && (
        <div style={{ ...styles.errorMessage, maxWidth: searchBoxWidth }}>
          <span>
            <img
              src={error}
              alt="Error"
              style={{ width: "12px", height: "12px" }}
            />
          </span>{" "}
          {errorMessage}
        </div>
      )}
    </div>
  );
};

const styles = {
  errorMessage: {
    color: "#EE201C",
    fontSize: "10px",
    marginTop: "4px",
    display: "flex",
    gap: "4px",
    fontWeight: '400',
  },
};

export default TextInput;
