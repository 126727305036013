import React, { useState, useRef, useEffect } from 'react';
import DateInput from '../../../../../Components/DateInput';
import Dropdown from '../../../../../Components/Dropdown';
import editImg from '../../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import filter from '../../../../../Assets/Icons/filter.png';
import AddNoticeBoardMsgModal from '../../../../../Components/Modals/AddNoticeBoardMsgModal';
import { useNavigate } from 'react-router-dom';
import NoticeBoardActiveMsgListController from '../../../../Controllers/AdminScreens/AddOns/NoticeBoard/NoticeBoardActiveMsgListController';
import Loader from '../../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../../Components/Modals/DeleteConformationModal';

const NoticeBoardActiveMsgList = ({  noticeBoardMessagesList, noticeBoardMessagesError, noticeBoardMessagesLoading, onRefresh,noticeBoardActiveMessagesError }) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);
    const [showFilters, setShowFilters] = useState(false);

    const{
        handlePageChange,
        currentNoticeMsg,
        currentPage,
        totalPages,

        loading,
        success,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        selectedDocument,
        setSelectedDocument,
        noticeBoardMsgDeleteError,
        handleNoticeBoardMessageDelete,
        currentNoticeDeleteIndex,
        deletedMessages
    } = NoticeBoardActiveMsgListController(noticeBoardMessagesList, noticeBoardMessagesError, noticeBoardMessagesLoading, onRefresh, noticeBoardActiveMessagesError)

    console.log("noticeBoardMessagesList", noticeBoardMessagesList)
    const createAddNewMessage = () => {
        setSelectedNoticeMessage(null)
        setModalVisible(true)
    }

    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const [filters, setFilters] = useState({
        createdBy: '',
        recipient: '',
        datePublished: '',
        expiryDate: '',
    });

    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        //console.log('Applying filters:', filters);
    };

    const resetFilters = () => {
        setFilters({
            createdBy: '',
            recipient: '',
            datePublished: '',
            expiryDate: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const formatDate = (dateString) => {
        if (!dateString || dateString === "--" || typeof dateString !== "string" || dateString.trim() === "") {
            return "--";
        }
        const [year, month, day] = dateString.split("-");
        return `${day}/${month}/${year}`;
    };

    const [selectedNoticeMessage, setSelectedNoticeMessage] = useState(null);
    const handleEditDocument = (noticeMsg) => {
        setSelectedNoticeMessage(noticeMsg);
        setModalVisible(true);
    };

    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 style={{ color: '#1F384C', fontSize: '18px', fontWeight: '500', lineHeight: '19.08px', wordWrap: 'break-word' }}>Notice Board Messages</h2>
                <button className="tasksScreen-apply-button" style={{ marginBottom: '10px' }} onClick={createAddNewMessage}>Add New Notice Board Message</button>
            </div>

            <div className="tasksScreen-filters-row" style={{ position: 'relative' }}>
                <div className="tasksScreen-filters-button-wrapper">
                    <button
                        className="tasksScreen-filters-button"
                        onClick={toggleFilters}
                    >
                        <img src={filter} alt="Filter" />
                        {showFilters ? 'Filters' : 'Filters'}
                    </button>
                </div>
                {showFilters && (
                    <div className="tasksScreen-filters-container-overlay">
                        <div className="tasksScreen-filters-flex" style={{ display: 'flex', flexWrap: 'wrap', gap: '15px' }}>
                            {/* Created By Dropdown */}
                            <div className="filter-field">
                                <Dropdown
                                    ref={(el) => (dropdownRefs.current["Created By"] = el)}
                                    isOpen={openDropdown === "Created By"}
                                    toggleDropdown={() => toggleDropdown("Created By")}
                                    label="Created By"
                                    options={[
                                        { label: "--", value: "" },
                                        { label: "User1", value: "User1" },
                                        { label: "User2", value: "User2" },
                                        // Add more options as needed
                                    ]}
                                    value={filters.createdBy || ""}
                                    onChange={(value) => handleFilterChange("createdBy", value)}
                                    searchBoxWidth={235}
                                />
                            </div>

                            {/* Recipient Dropdown */}
                            <div className="filter-field">
                                <Dropdown
                                    ref={(el) => (dropdownRefs.current["Recipient"] = el)}
                                    isOpen={openDropdown === "Recipient"}
                                    toggleDropdown={() => toggleDropdown("Recipient")}
                                    label="Recipient"
                                    options={[
                                        { label: "--", value: "" },
                                        { label: "Recipient1", value: "Recipient1" },
                                        { label: "Recipient2", value: "Recipient2" },
                                        // Add more options as needed
                                    ]}
                                    value={filters.recipient || ""}
                                    onChange={(value) => handleFilterChange("recipient", value)}
                                    searchBoxWidth={235}
                                />
                            </div>

                            {/* Date Published Date Picker */}
                            <div className="filter-field">
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Date Published</label>
                                <DateInput
                                    value={filters.datePublished || ''}
                                    onChange={(e) => handleFilterChange('datePublished', e.target.value)}
                                    width={240}
                                />
                            </div>

                            {/* Expiry Date Date Picker */}
                            <div className="filter-field">
                                <label style={{ fontSize: '12px', color: '#343C44' }}>Expiry Date</label>
                                <DateInput
                                    value={filters.expiryDate || ''}
                                    onChange={(e) => handleFilterChange('expiryDate', e.target.value)}
                                    width={240}
                                />
                            </div>
                        </div>

                        {/* Filter Buttons */}
                        <div className="tasksScreen-filter-buttons">
                            <button
                                onClick={applyFilters}
                                className="tasksScreen-apply-button"
                            >
                                Apply
                            </button>
                            <button
                                onClick={toggleFilters}
                                className="tasksScreen-cancel-button"
                            >
                                Cancel
                            </button>
                            <button
                                onClick={resetFilters}
                                className="tasksScreen-reset-button"
                            >
                                Reset
                            </button>
                        </div>
                    </div>
                )}
            </div>

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th style={{ width: "10%" }}>Date Created</th>
                            <th style={{ width: "10%" }}>Published</th>
                            <th style={{ width: "24%" }}>Message</th>
                            <th style={{ width: "24%" }}>Recipient</th>
                            <th style={{ width: "12%" }}>Created By</th>
                            <th style={{ width: "10%" }}>Expiry</th>
                            <th style={{ width: "10%" }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {currentNoticeMsg?.map((noticeMsg, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${noticeMsg.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td>{formatDate(noticeMsg?.create_at || '--')}</td>
                                    <td>{formatDate(noticeMsg?.publish_at || '--')}</td>
                                    <td>{noticeMsg?.nb_message || '--'}</td>
                                    <td>{noticeMsg?.recipient_members || '--'}</td>
                                    <td>{noticeMsg?.created_by || '--'}</td>
                                    <td>{formatDate(noticeMsg?.expire_at || '--')}
                                    {noticeBoardMsgDeleteError && currentNoticeDeleteIndex=== index &&
                                            <div style={{ color: 'red', textDecoration: 'none' }}>{noticeBoardMsgDeleteError}</div>}
                                        {success && currentNoticeDeleteIndex=== index &&
                                            <div style={{ color: 'green', textDecoration: 'none' }}>{success}</div>}
                                    </td>
                                    <td>
                                    <div style={{ display: "flex", alignItems: "center", gap: "5px",justifyContent: "end", }}>
                                        <button className="tasksScreen-edit-button" style={{
                                            opacity: (loading && currentNoticeDeleteIndex === index) || deletedMessages.includes(noticeMsg.nb_id) ? 0.5 : 1,
                                            cursor: (loading && currentNoticeDeleteIndex === index) || deletedMessages.includes(noticeMsg.nb_id) ? 'not-allowed' : 'pointer',
                                            pointerEvents: (loading && currentNoticeDeleteIndex === index) || deletedMessages.includes(noticeMsg.nb_id) ? 'none' : 'auto',
                                        }}
                                        >
                                            <img src={editImg} alt="Edit" onClick={() => handleEditDocument(noticeMsg)}/>
                                        </button>
                                        <button className="tasksScreen-delete-button" style={{
                                            opacity: (loading && currentNoticeDeleteIndex === index) || deletedMessages.includes(noticeMsg.nb_id) ? 0.5 : 1,
                                            cursor: (loading && currentNoticeDeleteIndex === index) || deletedMessages.includes(noticeMsg.nb_id) ? 'not-allowed' : 'pointer',
                                            pointerEvents: (loading && currentNoticeDeleteIndex === index) || deletedMessages.includes(noticeMsg.nb_id) ? 'none' : 'auto',
                                        }}
                                        >
                                            <img src={deleteImg} alt="Delete" onClick={() => { handleDelete(noticeMsg, index) }}/>
                                        </button>
                                        {loading && currentNoticeDeleteIndex=== index && (
                                                <span style={{ marginLeft: '5px' }}>
                                                    <Loader loading={loading} color={'var(--primary-color)'} size="small" />
                                                </span>
                                            )}
                                    </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {noticeBoardMessagesError && <div className="list-error-msg">{noticeBoardMessagesError} </div>}
                {noticeBoardActiveMessagesError && <div className="list-error-msg">{noticeBoardActiveMessagesError} </div>}
                {noticeBoardMessagesLoading && <Loader loading={noticeBoardMessagesLoading} color={'var(--primary-color)'} />}
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <AddNoticeBoardMsgModal isOpen={modalVisible} onClose={handleCloseModal} onValueChange={onRefresh} documentData={selectedNoticeMessage} />
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    message={`Are you sure you want to delete ${selectedDocument?.noticeMsg?.nb_message}?`}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => {
                    handleCloseDeleteModal();
                    handleNoticeBoardMessageDelete();
                    }}
                />
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default NoticeBoardActiveMsgList;
