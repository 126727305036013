
import React,{useState,useEffect} from "react";

import { ORG_NATURE_OF_CALLS_LIST} from '../../../../../ApiServices/BaseURL';
import { getData, postData } from "../../../../../ApiServices/ApiService";

const OtherSettingsConteoller = ()=>{
    const[loading,setLoading]= useState(false);
  const[error,setError] = useState('');
  const[callsList,setCallsList] = useState([]);

    useEffect(()=>{
    fetchNatureOfCallsList();
},[])

    const fetchNatureOfCallsList =async ()=>{
        try {
          setLoading(true);
          setCallsList([]);
            setError('');
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_NATURE_OF_CALLS_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const records = responseData?.dataJ || [];
                    setCallsList(records)
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch nature of calls. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch nature of calls.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch nature of calls. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch nature of calls. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
          }
      }

      return{
        loading,
    error,
    callsList,
    fetchNatureOfCallsList
      }
}

export default OtherSettingsConteoller