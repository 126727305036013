import React, { useState, useRef, useEffect } from 'react';
import InfoIcon from '../../../../Assets/Icons/Info.png';
import AddUserPrimaryInformationController from '../../../Controllers/AdminScreens/Users/AddUserPrimaryInformationController';
import Input from '../../../../Components/Input';
import Dropdown from '../../../../Components/Dropdown';
import DateInput from '../../../../Components/DateInput';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';
import MobileInput from '../../../../Components/MobileInput';

const AddUserPrimaryInformationScreen = () => {

    const { handleCancel,
        fieldConfigs,
        primaryData,
        handleInputChange,
        handleAddUser,
        addLoading,
        success,
        addError,
    } = AddUserPrimaryInformationController();

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };
    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='basic-info-container'>
            <h3 className='basic-info-header'>Basic Information</h3>
            <div className='basic-info-card'>
                <div className='form-grid'>
                    {fieldConfigs.map((field) => (
                        <div className="basic-info-item" key={field.key}>
                            {field.type === "dropdown" ? (
                                <Dropdown
                                    ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                                    isOpen={openDropdown === `${field.label}`}
                                    toggleDropdown={() => toggleDropdown(`${field.label}`)}
                                    label={field.label} // Dropdown already renders the label internally
                                    options={field.options}
                                    value={primaryData[field.key]}
                                    onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}
                                    loading={field?.loading}
                                    errorMessage={field?.error}
                                />
                            ) : field.type === "date" ? (
                                <div className="basic-info-label">
                                    <label style={styles.label}>{field.label}</label>
                                    <DateInput
                                        value={primaryData[field.key] || ""}
                                        onChange={(e) => handleInputChange(field.key, e.target.value)}
                                        errorMessage={field?.error}
                                    />
                                </div>
                            ) : field.type === "display" ? (
                                <><span className="basic-info-label">{field.label}</span>
                                    {field.key === 'emp_mobile_no' ? <span> {primaryData?.emp_mobile_code}{primaryData[field.key]}{<LoadingSkeleton width="40%" height="10px" />} </span> :
                                        <span className="basic-info-value"> {primaryData[field.key]} {!primaryData[field.key] && <span>--</span>} {<LoadingSkeleton width="40%" height="10px" />}</span>}</>
                            ) : field.type === "mobile" ? (
                                <MobileInput
                                    label={field.label}
                                    countryCode={primaryData?.emp_mobile_code || ""}
                                    mobileNumber={primaryData[field.key] || ""}
                                    onCountryChange={(value) => handleInputChange("emp_mobile_code", value)}
                                    onMobileChange={(value) => handleInputChange(field.key, value)}
                                    placeholder={`Enter ${field.label}`}
                                    countries={[
                                        { code: "IN", dialCode: "+91" },
                                        { code: "US", dialCode: "+1" },
                                        { code: "UK", dialCode: "+44" },
                                    ]}
                                    errorMessage={field?.error}
                                />
                            ) : (
                                <Input
                                    label={field.label} // Input already renders the label internally
                                    placeholder={`Enter ${field.label}`}
                                    value={primaryData[field.key]}
                                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                                    isEdit={!(field.label === 'Team Leader' || field.label === 'Manager')}
                                    errorMessage={field?.error}

                                />
                            )
                            }
                        </div>
                    ))}
                </div>

            </div>
            <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 17 }}>
                <h3 className='basic-info-header'>Recent Activity</h3>
                <div className="basic-info-outer-card">
                    <table className='tasksScreen-task-table'>
                        <thead>
                            <tr>
                                <th>Task Type</th>
                                <th>Client</th>
                                <th>Current Status</th>
                            </tr>
                        </thead>
                        <tbody style={{ backgroundColor: 'white' }}>
                            <tr>
                                <td >
                                    <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', gap: '10px' }}>
                                        <img
                                            src={InfoIcon}
                                            alt="No Data"
                                            style={{ width: '25px', height: '25px', objectFit: 'contain' }}
                                        />
                                        <span style={styles.recentData}>No Data to Display</span>
                                    </div>
                                </td>
                            </tr>
                        </tbody>

                    </table>
                </div>
            </div>
            {addError && <div className="list-error-msg"> {addError} </div>}
            {success && <div className="list-success-msg"> {success} </div>}
            <div className="button-container" style={{ gap: 20, flexWrap: 'wrap' }}>
                <button onClick={handleAddUser} className="add-client-button" style={{ opacity: addLoading ? 0.5 : 1, cursor: addLoading ? 'not-allowed' : 'pointer' }}>
                    {addLoading && (
                        <span style={{ paddingRight: 10 }}><Loader loading={addLoading} color={'var(--background-color)'} size="small" /> </span>
                    )} Add User</button>
                <button onClick={handleCancel} className="tasksScreen-cancel-button" style={{ opacity: addLoading ? 0.5 : 1, cursor: addLoading ? 'not-allowed' : 'pointer' }}>Cancel</button>
            </div>

        </div>
    )

};

const styles = {
    label: {
        fontSize: "12px",
        marginBottom: "5px",
        fontWeight: 400,
        fontFamily: "Roboto",
        color: "#343C44",
    },
    recentData: {
        color: '#B3261E',
        fontWeight: 400,
        fontSize: 12,
        fontFamily: 'Roboto'
    }
}

export default AddUserPrimaryInformationScreen;