import React, { useState, useRef, useEffect } from 'react';
import backIcon from '../../../../Assets/Icons/back.png';
import Toggle from '../../../../Components/Toggle';
import Dropdown from '../../../../Components/Dropdown';
import Input from '../../../../Components/Input';
import ManageGroupDetailsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageGroupDetailsController';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddGroupMemberModal from '../../../../Components/Modals/AddGroupMemberModal';
import TextInput from '../../../../Components/TextInput';
import Loader from '../../../../Components/Loader';
import searchIcon from '../../../../Assets/Icons/search.png';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';

const ManageGroupDetails = () => {

    const { handleBack,
        userToggleState,
        handleUserToggleChange,
        isGroupInfoEdit,
        loading,
        error,
        primaryData,
        editPrimaryData,
        fieldConfigs,
        handleEditGroupMember,
        handleDeleteGroupMember,
        handleUpdateGroup,
        handleDeleteGroup,
        handleCancelGroup,
        handleAddGroupMembers,
        groupMemberModalOpen,
        handleCloseGroupMemberModal,
        onValueChangeGroupMember,
        handleInputChange,
        groupMembers,
        groupMembersError,
        groupMembersLoading,
        groupStatusLoading,
        groupStatusError,
        searchClient,
        setSearchClient,
        handleSearchClient,
        clientList,
        selectedClient,
        handleSelectClient,
        clientListLoading,
        updateGroupLoading,
        deleteGroupLoading,
        isGroupMemberDeleteModalOpen,
        selGroupMemberRef, groupMemDeleteLoading,
        groupMemDeleteSuccess, groupMemDeleteError,
        callDeleteGroupMember,
        handleCloseGroupMemberDeleteModal,
        updateDeleteGroupErrorMsg,
        updateDeleteGroupSuccessMsg,
        isGroupAdd,
        groupName, setGroupName,
        description, setDescription,
        addLoading,
        addError,
        addSuccessMsg,
        descriptionError,
        groupClientError,
        groupNameError,
        groupClient, setGroupClient,
        groupClientOptions,
        handleAddGroup,
        mobileNo,
        isGroupDeleteModalOpen,
        handleCloseDeleteGroup,
        callDeleteGroup,groupMasterError,
        groupStatusSuccess,
        handleSearchInputText
    } = ManageGroupDetailsController();

    //console.log('clientList @@@', clientList, 'is edit', isGroupInfoEdit.current);

    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Manage Group</span>
            </div>

            {/* Group Section */}
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                        {isGroupAdd.current ? <div className='teamdetails-title'>Group Add</div> :
                            <div className='teamdetails-title'>{primaryData?.group_name}</div>}
                        {isGroupAdd.current ? <Toggle initialStatus={true} size='big' disabled={true} /> :
                            <Toggle initialStatus={primaryData?.group_status === 1 ? true : false} onToggle={(value) => handleUserToggleChange(value, primaryData?.group_id)} size='big' />}
                        {groupStatusLoading && <Loader loading={groupStatusLoading} color={'#000'} size='small' />}
                        {groupStatusError && <div className="list-error-msg"> {groupStatusError} </div>}
                        {groupStatusSuccess && <div className="list-success-msg"> {groupStatusSuccess} </div>}
                    </div>

                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {primaryData?.client_id_primary_email &&
                            <span>Contact User : {primaryData?.client_id_primary_email}</span>}
                        <span style={{ alignSelf: 'end', paddingTop: 4 }}>{primaryData?.client_id_primary_mobile_code} {primaryData?.client_id_primary_mobile_no}</span>
                    </div>
                </div>
                <div className="company-details">{primaryData?.group_desc}</div>
            </div>
            {/* {groupStatusError && <div className="list-error-msg"> {groupStatusError} </div>} */}
            {/* Group Information */}
            <div className='teamdetails-outer-card' style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
                    <h3 className='basic-info-header'>Group Information</h3>
                    <button onClick={handleAddGroupMembers} className="add-client-button" style={{ opacity: isGroupAdd.current ? 0.5 : 1,cursor: isGroupAdd.current ? 'not-allowed' : 'pointer' }} disabled={isGroupAdd.current}>Add Group Members</button>
                </div>

                <div className='basic-info-card' style={{ marginTop: 20 }}>
                    {isGroupAdd.current ? <>
                        <div className="basic-info-row" style={{gap:50}}>
                            <Input
                                label="Group Name"
                                placeholder="Enter Group Name"
                                value={groupName}
                                onChange={(e) => setGroupName(e.target.value)}
                                // width={'99%'}
                                errorMessage={groupNameError}
                                searchBoxWidth={230}
                            />
                            {/* <Input
                label="Description"
                placeholder="Enter Description"
                value={description}
                onChange={(e) => setDescription(e.target.value)}
                errorMessage={descriptionError}
                /> */}
                            <div className="filter-field" style={{ width: loading ? '100%' : '' }}>
                                <label
                                    style={{
                                        ...styles.labaleStyle,
                                        display: 'block',
                                        paddingBottom: loading ? 12 : 0
                                    }}
                                >
                                    Group Master
                                </label>
                                {!loading && <TextInput
                                    type="text"
                                    placeholder="Client Name"
                                    value={searchClient}
                                    onChange={(e) => handleSearchInputText(e.target.value)}
                                    onRightClick={() => handleSearchClient(searchClient)}
                                    right={
                                        clientListLoading ? (
                                            <Loader loading={clientListLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                        ) : (
                                            <img src={searchIcon} alt="Search" style={{ cursor: 'pointer' }} />
                                        )
                                    }
                                    style={{ padding: '20px 10px' }}
                                    searchBoxWidth={230}
                                    errorMessage={groupMasterError}
                                />}
                                {loading && <LoadingSkeleton width="40%" height="10px" />}
                                {(clientList.length > 0) && (
                                    <ul style={styles.dropdownList}>
                                        {clientList.map((client, index) => (
                                            <li
                                                key={client?.client_id}
                                                onClick={() => handleSelectClient(client)}
                                                style={{
                                                    ...styles.dropdownItem,
                                                    borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                                }}
                                            >
                                                {client?.client_name}
                                            </li>
                                        ))}
                                    </ul>
                                )}
                            </div>
                            <Input
                                label="Primary Contact"
                                placeholder="--"
                                value={mobileNo}
                                isEdit={false}
                                searchBoxWidth={230}
                            />
                                 
                        </div>
                        {error && <div className="list-error-msg">{error} </div>}
                    </> : <>
                        <div className='basic-info-row' style={{gap:'50px',flexBasis:200}}>
                            {fieldConfigs.map((field) => (
                                <div className="basic-info-row" key={field.key}>
                                    {isGroupInfoEdit.current ? (
                                     
                                        field.type === "search" ? (
                                            <div className="filter-field" style={{ width: loading ? '100%' : '' }}>
                                                <label
                                                    style={{
                                                        ...styles.labaleStyle,
                                                        display: 'block',
                                                        paddingBottom: loading ? 12 : 0
                                                    }}
                                                >
                                                    Group Master
                                                </label>
                                                {!loading && <TextInput
                                                    type="text"
                                                    placeholder="Client Name"
                                                    value={editPrimaryData[field.key]}
                                                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                                                    onRightClick={() => handleSearchClient(editPrimaryData?.client_id_primary_name)}
                                                    right={
                                                        clientListLoading ? (
                                                            <Loader loading={clientListLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                                        ) : (
                                                            <img src={searchIcon} alt="Search" style={{ cursor: 'pointer' }} />
                                                        )
                                                    }
                                                    style={{ padding: '20px 10px' }}
                                                    errorMessage={field?.error}
                                                    searchBoxWidth={230}
                                                />}
                                                {loading && <LoadingSkeleton width="40%" height="10px" />}
                                                {(clientList.length > 0) && (
                                                    <ul style={styles.dropdownList}>
                                                        {clientList.map((client, index) => (
                                                            <li
                                                                key={client?.client_id}
                                                                onClick={() => handleSelectClient(client)}
                                                                style={{
                                                                    ...styles.dropdownItem,
                                                                    borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                                                }}
                                                            >
                                                                {client?.client_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}
                                            </div>
                                        ) : field.type === "inputtext" ? (
                                            <div>
                                                {/* <span className="basic-info-label" style={{ paddingBottom: 10 }}>{field.label}</span>
                                                {loading && <LoadingSkeleton width="40%" height="10px" />}
                                                {!loading && <span className="basic-info-value">{field.key === 'client_id_primary_mobile_no' && <span>{editPrimaryData?.client_id_primary_mobile_code} </span>} {editPrimaryData[field.key]} </span>} */}
                                                <>
                                            {!loading && <Input
                                                label={field.label} // Input already renders the label internally
                                                placeholder={field.key === 'client_id_primary_mobile_no' ? '--':`Enter ${field.label}`}
                                                value={editPrimaryData[field.key]}
                                                onChange={(e) => handleInputChange(field.key, e.target.value)}
                                                errorMessage={field.error}
                                                searchBoxWidth={230}
                                                isEdit={false}
                                            />}
                                            {loading &&
                                                <div>
                                                    <span className="basic-info-label" style={{ paddingBottom: 10 }}>{field.label}</span>
                                                    <LoadingSkeleton width="40%" height="10px" />
                                                </div>
                                            }</>
                                            </div>
                                        ) : (<>
                                            {!loading && <Input
                                                label={field.label} // Input already renders the label internally
                                                placeholder={`Enter ${field.label}`}
                                                value={editPrimaryData[field.key]}
                                                onChange={(e) => handleInputChange(field.key, e.target.value)}
                                                errorMessage={field.error}
                                                searchBoxWidth={230}
                                            />}
                                            {loading &&
                                                <div>
                                                    <span className="basic-info-label" style={{ paddingBottom: 10 }}>{field.label}</span>
                                                    <LoadingSkeleton width="40%" height="10px" />
                                                </div>
                                            }</>
                                        )
                                    ) : (
                                        <div className="basic-info-item"><span className="basic-info-label">{field.label}</span>
                                            <span className="basic-info-value">{field.key === 'client_id_primary_mobile_no' && <span>{primaryData?.client_id_primary_mobile_code} </span>} {primaryData[field.key]}
                                                {loading && <LoadingSkeleton width="40%" height="10px" />}</span></div>
                                    )}
                                </div>
                            ))}
                        </div>
                        {error && <div className="list-error-msg">{error} </div>}
                    </>}

                </div>

                <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 20 }}>
                    <h3 className='basic-info-header'>Group Members & Contacts</h3>
                    <div className="basic-info-outer-card">
                        <table className='tasksScreen-task-table'>
                            <thead>
                                <tr>
                                    <th style={{ width: '25%' }}>Username</th>
                                    <th style={{ width: '30%' }}>Email ID</th>
                                    <th style={{ width: '40%' }}>Phone No.</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {groupMembers.map((groupMember, index) => (
                                    <tr key={index}
                                        className="tasksScreen-main-task-row"
                                        style={{ borderBottom: "1px solid #dee2e6", cursor: 'pointer' }} >
                                        <td style={{ ...styles.tableLink, width: '25%' }}>{groupMember?.client_name}</td>
                                        <td style={{ width: '30%' }}>{groupMember?.client_email}</td>
                                        <td style={{ width: '40%' }}>
                                            <div style={{display:'flex',flexDirection:'row',justifyContent:'space-between'}}>{groupMember?.client_mobile_code} {groupMember?.client_mobile_no}
                                            {groupMemDeleteSuccess[groupMember?.client_id] &&
                                                <div style={{ color: 'green', }} >{groupMemDeleteSuccess[groupMember?.client_id]}</div>}
                                            {groupMemDeleteError[groupMember?.client_id] &&
                                                <div style={{ color: 'red', }} >{groupMemDeleteError[groupMember?.client_id]}</div>}
                                                </div>
                                        </td>
                                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'left' }}>
                                            <button
                                                className="tasksScreen-edit-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleEditGroupMember(groupMember)}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            <button
                                                className="tasksScreen-delete-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: groupMemDeleteLoading[groupMember?.client_id] ? 'not-allowed' : 'pointer',
                                                    opacity: groupMemDeleteLoading[groupMember?.client_id] ? 0.3 : 1
                                                }}
                                                onClick={() => handleDeleteGroupMember(groupMember)}
                                                disabled={groupMemDeleteLoading[groupMember?.client_id]}
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="Delete"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            {groupMemDeleteLoading[groupMember?.client_id] &&
                                                <span><Loader loading={groupMemDeleteLoading[groupMember?.client_id]} color={'var(--primary-color)'} size='small' /></span>
                                            }
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {groupMembersLoading && <Loader loading={groupMembersLoading} color={'var(--primary-color)'} />}
                        {groupMembersError && <div className="list-error-msg">{groupMembersError} </div>}
                    </div>
                </div>

                {isGroupAdd.current ? <>
                    <div style={{ marginTop: 20 }}>
                        <h3 className='basic-info-header'>Notes</h3>
                        <div className="basic-info-outer-card">
                            <label
                                style={{
                                    ...styles.labaleStyle,
                                    display: 'block',
                                }}
                            >
                                Group Notes
                            </label>
                            <textarea
                                id="groupNotes"
                                placeholder="Enter your notes here..."
                                style={styles.textarea}
                                value={description}
                                onChange={(e) => setDescription(e.target.value)}
                            ></textarea>

                        </div>

                    </div>
                </> :
                    <div style={{ marginTop: 20 }}>
                        <h3 className='basic-info-header'>Notes</h3>
                        <div className="basic-info-outer-card">
                            {isGroupInfoEdit.current ? <>
                                <label
                                    style={{
                                        ...styles.labaleStyle,
                                        display: 'block',
                                    }}
                                >
                                    Group Notes
                                </label>
                                <textarea
                                    id="groupNotes"
                                    placeholder="Enter your notes here..."
                                    style={styles.textarea}
                                    value={editPrimaryData?.group_desc || ''}
                                    onChange={(e) => handleInputChange('group_desc', e.target.value)}
                                ></textarea></> :
                                <div className='basic-info-value'>
                                    {editPrimaryData?.group_desc}
                                </div>
                            }

                        </div>

                    </div>}



                {updateDeleteGroupErrorMsg && <div className="list-error-msg">{updateDeleteGroupErrorMsg} </div>}
                {updateDeleteGroupSuccessMsg && <div className="list-success-msg">{updateDeleteGroupSuccessMsg} </div>}
                {addError && <div className="list-error-msg">{addError}</div>}
                {addSuccessMsg && <div className="list-success-msg">{addSuccessMsg}</div>}

                <div className="button-container" style={{ gap: 20, flexWrap: 'wrap' }}>

                    {isGroupInfoEdit.current &&
                        <button onClick={handleUpdateGroup} className="add-client-button" style={{ opacity: updateGroupLoading ? 0.5 : 1, cursor: updateGroupLoading ? 'not-allowed' : 'pointer', }} disabled={updateGroupLoading} >
                            <span style={{ paddingRight: updateGroupLoading ? 10 : 0 }}><Loader loading={updateGroupLoading} color={'#FFFFFF'} size='small' /></span>Update Group</button>
                    }
                    {isGroupAdd.current &&
                        <button onClick={handleAddGroup} className="add-client-button" style={{ opacity: addLoading ? 0.5 : 1, cursor: addLoading ? 'not-allowed' : 'pointer', }} disabled={addLoading} >
                            <span style={{ paddingRight: addLoading ? 10 : 0 }}><Loader loading={addLoading} color={'#FFFFFF'} size='small' /></span>Add Group</button>}

                    {isGroupAdd.current && <button onClick={handleCancelGroup} className="tasksScreen-cancel-button">Clear</button>}
                    {isGroupInfoEdit.current && <button onClick={handleCancelGroup} className="tasksScreen-cancel-button">Cancel</button>}
                    <button onClick={handleDeleteGroup} className="tasksScreen-cancel-button" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', opacity: deleteGroupLoading || isGroupAdd.current ? 0.5 : 1, cursor: deleteGroupLoading || isGroupAdd.current ? 'not-allowed' : 'pointer', }} disabled={deleteGroupLoading || isGroupAdd.current}>
                        <span style={{ paddingRight: deleteGroupLoading ? 10 : 0 }}><Loader loading={deleteGroupLoading} color={'#575252'} size='small' /></span>Delete Group</button>

                </div>

            </div>

            {groupMemberModalOpen && <AddGroupMemberModal isOpen={groupMemberModalOpen} onClose={handleCloseGroupMemberModal} onValueChange={onValueChangeGroupMember} groupID={primaryData?.group_id} />}
            {isGroupMemberDeleteModalOpen && <DeleteConfirmationModal isOpen={isGroupMemberDeleteModalOpen} message={`Are you sure you want to delete ${selGroupMemberRef?.current?.client_name}?`}
                onClose={handleCloseGroupMemberDeleteModal}
                onConfirm={() => {
                    handleCloseGroupMemberDeleteModal(); // Close modal
                    callDeleteGroupMember(selGroupMemberRef.current); // Perform delete operation
                }} />}
                {isGroupDeleteModalOpen && <DeleteConfirmationModal isOpen={isGroupDeleteModalOpen} message={`Are you sure you want to delete group?`}
                onClose={handleCloseDeleteGroup}
                onConfirm={() => {
                    handleCloseDeleteGroup(); // Close modal
                    callDeleteGroup(); // Perform delete operation
                }} />}
        </div>
    )

}

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        letterSpacing: 0.22
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },
    labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)',
    },
    textarea: {
        width: "98%",
        height: "100px",
        border: "1px solid #ccc",
        borderRadius: "5px",
        padding: "10px",
        fontSize: "12px",
        resize: "none",
        // backgroundColor: "#f9f9f9",
        outline: "none",
        fontFamily: 'Roboto',
        color:'#343C44',
        fontWeight:'400',
    },
    label: {
        display: "block",
        marginBottom: "5px",
        fontSize: "14px",
        fontWeight: "bold",
        color: "#333",
    },
    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },
    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
    fullWidth: {
        gridColumn: "span 2",
        width: "97%", // Full width
    },
}

export default ManageGroupDetails;