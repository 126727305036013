import { useState, useEffect, useRef } from 'react';
import { postData, getData } from '../../../../ApiServices/ApiService';
import { ORG_GET_TASK_LIST } from '../../../../ApiServices/BaseURL';
import { useNavigate } from 'react-router-dom';
import { ORG_DELETE_CLIENT_TASK } from '../../../../ApiServices/BaseURL';

const ClientTaskListController = (taskList, responseError, responseLoading, fetchTaskList) => {
  console.log("responseLoading", responseLoading);
  console.log("taskList", taskList);

  const [tasks, setTasks] = useState(taskList || []);
  const [filteredTasks, setFilteredTasks] = useState(taskList || []);
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 10;

  const [loading, setLoading] = useState(responseLoading || false)
  const [error, setError] = useState(responseError || '');
  const navigate = useNavigate();
  const [deleteTaskLoading, setDeleteTaskLoading] = useState({});
  const [deleteTaskError, setDeleteTaskError] = useState({});
  const [deleteTaskSuccess, setDeleteTaskSuccess] = useState({});
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const taskInfoRef = useRef(null)

  // const [taskList, setTaskList] = useState({
  //   task_id: null, // Number set to null
  //   client_id: null, // Number set to null
  //   service_sub_id: null, // Number set to null
  //   task_start_date: "", // String set to empty
  //   task_due_date: "", // String set to empty
  //   task_end_date: null, // Number or date set to null
  //   financial_year: "", // String set to empty
  //   financial_period: "", // String set to empty
  //   task_description: "", // String set to empty
  //   employee_id_assigned: null, // Number set to null
  //   task_status: null, // Number set to null
  //   task_priority: null, // Number set to null
  //   service_sub_name: "", // String set to empty
  //   emp_id_assigned_name: "", // String set to empty
  //   client_name: "" // String set to empty
  // });

  // const [taskListLoading,setTaskListLoading]=useState(false);
  // const [taskListError,setTaskListError]=useState('');



  useEffect(() => {
    // Sample data with status and showDetails property

    // setTasks(data);
    // setFilteredTasks(data);
    // fetchTaskList();
    // setLoading(responseLoading)
    setFilteredTasks(taskList);
  }, [taskList]);

  useEffect(() => {
    
    console.log("responseLoading in useEffect", responseLoading);
    setLoading(responseLoading);
    setError(responseError);
  }, [responseLoading, responseError]);

  const fetchTaskList2 = async () => {
    //console.log('get client')
    try {
      setFilteredTasks([])
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id
      }
      const response = await postData(ORG_GET_TASK_LIST, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              setTasks(responseData?.dataJ);
              setFilteredTasks(responseData?.dataJ);
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  }

  const toggleDetails = (index) => {
    setFilteredTasks((prevTasks) =>
      prevTasks.map((task, i) =>
        i === index ? { ...task, showDetails: !task.showDetails } : task
      )
    );
  };

  const totalPages = Math.ceil(filteredTasks.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentTasks = filteredTasks.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const getStatusStyle = (status) => {
    switch (true) {
      case status?.task_priority === 1:
        // If priority is 1, set red color regardless of the task status
        return { backgroundColor: "#f8d7da", color: "#721c24" }; // Red

      case status?.task_status === 1:
        return { backgroundColor: "#fff3cd", color: "#DFA510" }; // Yellow

      case status?.task_status === 2:
        return { backgroundColor: "#d4edda", color: "#155724" }; // Green

      case status?.task_status === 3:
        return { backgroundColor: "#d4edda", color: "#155724" }; // Green

      case status?.task_status === 11:
        return { backgroundColor: "#fff3cd", color: "#DFA510" }; // Yellow

      case status?.task_status === 12:
        return { backgroundColor: "#f8d7da", color: "#721c24" }; // Red

      case status?.task_status === 13:
        return { backgroundColor: "#f8d7da", color: "#721c24" }; // Red

      default:
        return {};
    }
  };


  const handleGenerateInvoive = () => {
    navigate("/generate-invoice");
  };

  const onTaskDelete = async (taskInfo) => {
    //console.log('taskInfo:-', taskInfo?.task_id);
    taskInfoRef.current = taskInfo;
    setIsDeleteModalOpen(true);

  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  }
  const callDeleteTask = async (taskInfo) => {
    try {
      setDeleteTaskError({});
      setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: '' }));
      setDeleteTaskLoading((prev) => ({ ...prev, [taskInfo?.task_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "task_id": taskInfo?.task_id
      }

      //console.log('callDeleteTask payload', payLoad);

      // Make the API call
      const response = await postData(ORG_DELETE_CLIENT_TASK, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeleteTaskSuccess((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info }));
            setTimeout(() => {
              setDeleteTaskSuccess((prev) => ({ ...prev, [taskInfo?.task_id]: '' }));
              fetchTaskList();
            }, 3000);
          } else {
            setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Failed to delete task. Please try again.' }));
          }
        } else {
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Failed to delete task. Please try again...' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete task. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setDeleteTaskError((prev) => ({ ...prev, [taskInfo?.task_id]: `Failed to delete task. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteTaskLoading((prev) => ({ ...prev, [taskInfo?.task_id]: false }));
    }
  }

  return {
    tasks: currentTasks,
    currentPage,
    totalPages,
    handlePageChange,
    toggleDetails,
    getStatusStyle,
    loading,
    error,
    handleGenerateInvoive,
    onTaskDelete,
    deleteTaskError, deleteTaskLoading, deleteTaskSuccess,
    isDeleteModalOpen, handleCloseDeleteModal,
    callDeleteTask,
    taskInfoRef
  };
};

export default ClientTaskListController;
