import { useState, useRef, useEffect } from 'react';
import { validateEmail } from '../../../Utils/Validation';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate } from 'react-router-dom';
import { postDataLogin } from '../../../ApiServices/ApiService';
import { SIGNUP_API } from '../../../ApiServices/BaseURL';
import { validateEmailOrMobile } from '../../../Utils/Validation';

const LoginController = () => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [emailError, setEmailError] = useState('');
  const [passwordError, setPasswordError] = useState('');
  const [error, setError] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [loading, setLoading] = useState(false);
  const screenName = 'LoginPage'

  const navigate = useNavigate();
  const {theme, setCompanyId} = useTheme();

  const handleEmailChange = (text) => {
    // //console.log('text to enter')
    setEmail(text);
    // if (!validateEmail(text)) {
    //   setEmailError('Please enter a valid email Id');
    // } else {
    //   setEmailError('');
    // }
  };

  const handlePasswordChange = (text) => {
    setPassword(text);
  };

  const toggleShowPassword = () => {
    setShowPassword(!showPassword);
  };



  const login = async () => {
    try {
      setEmailError('');
      setError('');
      setPasswordError('')
          // Validate email or mobile
    const emailValidationError = validateEmailOrMobile(email);
    if (emailValidationError) {
      setEmailError(emailValidationError);
      return;
    }
      if (!password) {
        setPasswordError('Please enter password');
        return;
      }
      const payLoad = {
        "user_id": email,
        "user_password": password
      }
      // //console.log('Login Payload:', payLoad);  
      // return       
      setLoading(true);
      await postDataLogin(SIGNUP_API,payLoad).then(response => {
        // //console.log('Login response:', response); 
        setLoading(false);
        if (response.statusCode === 200) {
          if (response.message === 'success') {
            if (response.dataJ[0]?.is_mfa_enabled === 1) {
              console.log("mfa Screesn");
              navigate('/mfa', { state: { email , sessionTkn: response.dataJ[0]?.session_token || ''} });
            }
            else {
            var themeId = 6
            setCompanyId(themeId);
            localStorage.setItem(`themeId`, themeId);
            const role = 'SUPER-ADMIN'
            localStorage.setItem(`userRole`, role); //need to change dynamic
            // localStorage.setItem(`userRole`, response?.dataJ[0]?.emp_role);
            let userData = JSON.stringify(response?.dataJ[0]);
            localStorage.setItem('_userInfo_', userData);
            navigate('/dashboard');
            // navigate('/mfa');
            }
          } else {
            setError(response.info);
          //   ExceptionSendService(screenName, 'log-101', response.info)
          }
  
        } else if (response.statusCode === 500) {
          setError(response.info);
          // ExceptionSendService(screenName, 'log-102', response.info)
  
        } else {
          setError("No Internet Connection Detected Or Connection Is Slow");
          // ExceptionSendService(screenName, 'log-103', 'No Responce')
        }
      })
   
    } catch (error) {
      setLoading(false);
      let err = String(error)
    //   ExceptionSendService(screenName, 'log-104', err)
      setError("No Internet Connection Detected Or Connection Is Slow !");
    }


  };

  return {
    email,
    password,
    emailError,
    error,
    showPassword,
    loading,
    setEmail,
    setPassword,
    setEmailError,
    setError,
    toggleShowPassword,
    handleEmailChange,
    handlePasswordChange,
    login,
    passwordError,
    setPasswordError,
  };
};

export default LoginController;
