import { useState, useEffect,useRef } from 'react';
import { validateEmail, validateNumber } from '../../../Utils/Validation';
import { useTheme } from '../../../GlobalContext/GlobalContext';
import { useNavigate,useLocation } from 'react-router-dom';
import { getData, postData } from '../../../ApiServices/ApiService';
import {
  ORG_GET_SERVICE_LIST, ORG_GET_SUB_SERVICE_LIST, ORG_GET_GROUP_LIST, ORG_GET_EMPLOYEE_LIST, ORG_GET_SUB_SERVICE_LIST_SEARCH,
  ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_CLIENT_TO_DO_LIST_DD, ORG_GET_ALL_CODES_LIST, ORG_ADD_TASK, ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER
} from '../../../ApiServices/BaseURL';

const CreateTaskController = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [fileName, setFileName] = useState("");
  const [searchUser, setSearchUser] = useState('');
  const clintInfo = useRef(location.state?.clintInfo);
  
  const [formData, setFormData] = useState({
    clientName: '', // Text input for Client Name
    services: {}, // Multiselect dropdown for Services
    taskCategory: {}, // Dropdown for Task Category
    group: '', // Dropdown for Group
    primaryAssignedUser: null, // Dropdown for Primary Assigned User
    taskLeader: null, // Dropdown for Task Leader
    taskManager: null, // Dropdown for Task Manager
    financialYear: {}, // Dropdown for Financial Year
    period: {}, // Text input for Period
    billAmount: '', // Text input for Bill Amount
    agreedBillAmount: '',
    fileNo: '', // Text input for File Number
    taskDescription: '', // Textarea for Task Description
    startDate: '', // Date Picker for Start Date
    dueDate: '', // Date Picker for Due Date
    taskPriority: { label: 'Not Priority', value: 0 }, // Dropdown for Task Priority
    toDoList: null, // Multiselect dropdown for To-Do List
    completedBy: '', // Dropdown for Completed By
    allowTimePosting: false, // Checkbox for Time Posting
    documents: null
  });

  const [clientList1, setClientList1] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [serviceList, setServiceList] = useState([]);
  const [categoryList, setCategoryList] = useState([])
  const [groupList, setGroupList] = useState([]);
  const [yearList, setYearList] = useState([]);
  const [periodList, setPeriodList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [groupLoading, setGroupLoading] = useState(false);
  const [groupError, setGroupError] = useState(false);
  const [yearLoading, setYearLoading] = useState(false);
  const [yearError, setYearError] = useState('');
  const [serviceLoading, setServiceLoading] = useState(false);
  const [clientLoading, setClientLoading] = useState(false);
  const [categoryLoading, setCategoryLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [billError, setBillError] = useState('');
  const [agreedBillError, setAgreedBillError] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [serviceError, setServiceError] = useState('');
  const [taskCategoryError, setTaskCategoryError] = useState('');
  const [financialYearError, setFinancialYearError] = useState('');
  const [financialPeriodError, setFinancialPeriodError] = useState('');
  const [startDateError, setStartDateError] = useState('');
  const [dueDateError, setDueDateError] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [empLoading, setEmpLoading] = useState(false);
  const [empError, setEmpError] = useState('');
  const [taskAssignedError, setTaskAssignedError] = useState('');
  const [toDoList,setToDoList] = useState([])
  const [toDoLoading, setToDoLoading] = useState(false);
  const [toDoError, setToDoError] = useState('');
 
  useEffect(() => {
    const populateFormData = () => {
      setFormData((prev) => ({
        ...prev,
        clientName: clintInfo.current.client_name || '', // Populate Client Name
        group: clintInfo.current.group_name || '', // Populate Group Name
        primaryAssignedUser: clintInfo.current.emp_id_incharge_name || null, // Populate Primary Assigned User
        fileNo: clintInfo.current.client_file_id || '', // Populate File Number
        taskDescription: '', // Populate Task Description (example)
        startDate: '', // Populate Start Date
        taskLeader: null, // Add mapping if Task Leader is available
        taskManager: null, // Add mapping if Task Manager is available
        financialYear: {}, // Add mapping if Financial Year is available
        period: '', // Populate Period if applicable
        billAmount: '', // Add mapping if Bill Amount is available
        agreedBillAmount: '', // Add mapping if Agreed Bill Amount is available
        clientEmail: clintInfo.current.client_email || '',
        taskPriority: { label: 'Not Priority', value: 0 }, // Default Task Priority
        toDoList: {label: '', value: 0 }, // Add mapping if To-Do List is available
        completedBy: '', // Add mapping if Completed By is available
        allowTimePosting: false, // Default Checkbox
        documents: null, // Add mapping for Documents
      }));
      setSearchUser(clintInfo.current.client_name);
      setSelectedClient(clintInfo.current);
      fetchToDOList(clintInfo.current?.client_id)
    };
    if(clintInfo.current){
      populateFormData();
    } 
  }, [clintInfo.current]);
  

  useEffect(() => {
    setError("");
    fetchServiceList();
    fetchGroupList();
    fetchYearList();
    fetchUserList();
    setBillError("");
    setAgreedBillError("");
    setClientNameError("");
    setServiceError("");
    setTaskCategoryError("");
    setFinancialYearError("");
    setStartDateError("");
    setDueDateError("");
    setFinancialPeriodError("");
    setToDoError('');
  }, []);

  const fetchServiceList = async () => {
    try {
      setServiceError('')
      setServiceLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id
      }

      // Make the API call
      // const response = await getData(`${ORG_GET_SERVICE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const serviceOptions = await responseData?.dataJ.map(service => ({
                label: service.service_name,
                value: service.service_id
              }));
              setServiceList(serviceOptions);
            } else {
              setServiceError(responseData?.info || 'Data Not Found');
            }
          } else {
            setServiceError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setServiceError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setServiceError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setServiceError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setServiceError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setServiceError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setServiceError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setServiceLoading(false);
    }
  }

  const fetchCategoryList = async (serviceId) => {
    try {
      setTaskCategoryError('')
      setCategoryList([])
      setCategoryLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": serviceId
      }
      //console.log('fetchTaskSubCategorieList payload', payLoad);
      // Make the API call
      // const response = await getData(`${ORG_GET_SUB_SERVICE_LIST}?service_id=${serviceId}&emp_id_auth=${storedUserInfo?.emp_id}`);
      const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const serviceOptions = await responseData?.dataJ.map(service => ({
                label: service.service_sub_name,
                value: service.service_sub_id
              }));
              setCategoryList(serviceOptions);
            } else {
              setTaskCategoryError(responseData?.info || 'Data Not Found');
            }
          } else {
            setTaskCategoryError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setTaskCategoryError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setTaskCategoryError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setTaskCategoryError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setTaskCategoryError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setTaskCategoryError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setTaskCategoryError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setTaskCategoryError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setTaskCategoryError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setCategoryLoading(false);
    }
  }

  const fetchGroupList = async () => {
    try {
      setGroupLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const groupOptions = await responseData?.dataJ.map(service => ({
                label: service.group_name,
                value: service.group_id
              }));
              setGroupList(groupOptions);
            } else {
              setGroupError(responseData?.info || 'Data Not Found');
            }
          } else {
            setGroupError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setGroupError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setGroupError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setGroupError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setGroupError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setGroupError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setGroupError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setGroupError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setGroupError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setGroupLoading(false);
    }
  }


  const fetchYearList = async () => {
    try {
      setYearLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const payLoad = { "code_id": "org_all_codes" }
      const response = await postData(ORG_GET_ALL_CODES_LIST, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ) {
              // Transforming the data
              const yerarListOption = await responseData?.dataJ?.financial_year_codes_list.map(service => ({
                label: service.year_name,
                value: service.year_id
              }));

              setYearList(yerarListOption)
              // Transforming the data
              const periodOption = await responseData?.dataJ?.financial_period_codes_list.map(service => ({
                label: service.pc_name,
                value: service.pc_id
              }));
              setPeriodList(periodOption)
            } else {
              setYearError(responseData?.info || 'Data Not Found');
            }
          } else {
            setYearError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setYearError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setYearError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setYearError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setYearError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setYearError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setYearError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setYearError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setYearError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setYearLoading(false);
    }
  }

  const fetchUserList = async () => {
    try {
      setEmpLoading(true);
      setEmpError('');
      setEmployeeList([]);


      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const filterData = responseData?.dataJ.map(service => ({
                label: service?.emp_name,
                value: service?.emp_id
              }));
              setEmployeeList(filterData);
            } else {
              setEmpError(responseData?.info || 'Data Not Found');
            }
          } else {
            setEmpError(responseData?.info || 'Failed to fetch User data. Please try again.');
          }
        } else {
          setEmpError(responseData?.info || 'Failed to fetch User data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setEmpError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setEmpError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setEmpError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setEmpError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setEmpError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setEmpError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setEmpError(`Failed to fetch User data. Please check your network connection and try again.`);
      }
    } finally {
      setEmpLoading(false);
    }
  }

  const fetchToDOList = async (clientId) => {
    try {
      setToDoError('')
      setToDoLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_GET_CLIENT_TO_DO_LIST_DD}?emp_id_auth=${storedUserInfo?.emp_id}&emp_id=${storedUserInfo?.emp_id}&client_id=${clientId}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              const toDoOptions = await responseData?.dataJ.map(service => ({
                label: service.sc_desc,
                value: service.sc_id
              }));
              setToDoList(toDoOptions);
            } else {
              setToDoError(responseData?.info || 'Data Not Found');
            }
          } else {
            setToDoError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setToDoError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setToDoError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setToDoError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setToDoError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setToDoError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setToDoError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setToDoError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setToDoError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setToDoLoading(false);
    }
  }


  const handleChange = (name, value) => {
    // const { name, value } = e.target;
    //console.log('name:----', name, 'value:-----', value)
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));

    if (name === 'services' && value) {
      fetchCategoryList(value.value);
    } else {
      //console.log('name not valid')
    }
  };

  const handleFileChange = (e) => {
    setFormData((prevData) => ({
      ...prevData,
      documents: e.target.files[0],
    }));
    const file = e.target.files[0];
    if (file) {
      setFileName(file.name); // Set the selected file name
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    // Submit form data to your backend API
    //console.log('Form Data Submitted:', formData);
    try {
      setSuccess('')
      setError('');
      setBillError("");
      setAgreedBillError("");
      setClientNameError("");
      setServiceError("");
      setTaskCategoryError("");
      setFinancialYearError("");
      setStartDateError("");
      setDueDateError("");
      setFinancialPeriodError("");
      setTaskAssignedError("");
      setToDoError('');

      setLoading(true);

      if (!searchUser) {
        setClientNameError("Please select the client name")
        return false;
      }

      if (!formData.services?.value) {
        setServiceError("Please select the service")
        return;
      }

      if (!formData.taskCategory?.value) {
        setTaskCategoryError("Please select the task")
        return;
      }

      // if(!formData.primaryAssignedUser?.value){
      //   setTaskAssignedError("Please select the user")
      //   return;
      // }

      if (!formData.financialYear?.value) {
        setFinancialYearError("Please select the financial year")
        return;
      }

      if (!formData.period?.value) {
        setFinancialPeriodError("Please select the financial period")
        return;
      }

      if (!formData.startDate) {
        setStartDateError("Please select the start date")
        return;
      }

      if (!formData.dueDate) {
        setDueDateError("Please select the due date")
        return;
      }
      const startDateObj = new Date(formData.startDate);
      const endDateObj = new Date(formData.dueDate);
         // Validation: Start date should not be greater than the end date
    if (formData?.startDate && formData?.dueDate && startDateObj > endDateObj) {
      setError("Due date cannot be earlier than the start date.");return
    }

      // if (!validateNumber(formData.billAmount)) {
      //   setBillError("Invalid bill amount.");
      //   return false;
      // }

      // if (!validateNumber(formData.agreedBillAmount)) {
      //   setAgreedBillError("Invalid agreed bill amount.");
      //   return false;
      // }
      
      //console.log('before',formData?.toDoList)
      let valueArray = [];
      // Validation to ensure formData?.toDoList is an array and not null or undefined
      if (Array.isArray(formData?.toDoList) && formData?.toDoList.length > 0) {
        valueArray = formData.toDoList.map((item) => item.value);
      }
      //console.log('after',formData?.toDoList)
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "client_id": selectedClient?.client_id,
        "service_id": formData?.services?.value,
        "service_sub_id": formData?.taskCategory?.value,
        "task_description": formData?.taskDescription,
        "task_start_date": formData?.startDate,
        "task_due_date": formData?.dueDate,
        "task_priority": formData?.taskPriority?.value,
        "task_bill_amount": formData?.agreedBillAmount || 0,
        "task_bill_amount_estimated": formData?.billAmount || 0,
        "financial_year": formData?.financialYear?.value,
        "financial_period": formData?.period?.value,
        "employee_id_assigned": storedUserInfo?.emp_id,
        "to_do_list_list":valueArray
      }

      //console.log('catagory Data', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_TASK, payLoad);
      const responseData = await response.json();
      //console.log('response Data12345', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              setSuccess('')
              navigate(`/manage-clients`, { state: { task:'Tasks',clientId:selectedClient?.client_id} }); // Pass task in state
            }, 3000);

          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      //console.log('error',error)
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }

  };

  const handleSelectClient = (client) => {
    setSelectedClient(client); // Set selected client data
    setSearchUser(client?.client_name); // Update TextInput with the selected client's name
    setClientList1([]); // Clear the dropdown
    setFormData((prevData) => ({
      ...prevData,
      group: client?.group_name,
      fileNo: client?.client_file_id
    }));
    fetchToDOList(client?.client_id)
  };

  const handleSearchClient = async () => {
    try {
      setClientNameError('');
      if (searchUser.trim().length <= 2) {
        setClientNameError('Min 3 letters required.'); return
      }
      setClientLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        emp_id_auth: storedUserInfo?.emp_id,
        search_type: "GENERIC",
        search_word: searchUser
      };

      //console.log('Searching Client with payload:', payLoad);

      // Make the API call
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('Search Client Response Data:', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList1(responseData?.dataJ || []); // Update client list with fetched data
          } else {
            setClientNameError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setClientNameError(responseData?.info || 'Failed to fetch Client.');
        }
      }
      // Handle token-related errors: 400, 401, 403
      else if ([400, 401, 403].includes(response?.status)) {
        if (responseData?.message === 'Unauthorized' ||
          responseData?.message === 'The incoming token has expired' ||
          responseData?.message === 'Access Denied') {
          setClientNameError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setClientNameError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Handle internal server error: 500
      else if (response?.status === 500) {
        setClientNameError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Handle unexpected errors
      else {
        setClientNameError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setClientNameError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
      } else if (error.request) {
        setClientNameError('No response received from the server. Please check your network connection and try again.');
      } else {
        setClientNameError(`An error occurred. Please check your network connection and try again.`);
      }
    } finally {
      setClientLoading(false);
    }
  };




  return {
    formData,
    fileName,
    handleFileChange,
    handleSubmit,
    handleChange,
    serviceList,
    categoryList,
    groupList,
    yearList,
    periodList,
    error,
    loading,
    billError,
    agreedBillError,
    clientNameError,
    serviceError,
    taskCategoryError,
    financialYearError,
    startDateError,
    dueDateError,
    financialPeriodError,
    handleSearchClient,
    searchUser,
    setSearchUser,
    clientList1,
    setClientList1,
    handleSelectClient,
    selectedClient,
    setSelectedClient,
    clientLoading,
    categoryLoading,
    employeeList,
    empLoading,
    empError,
    groupLoading,
    yearLoading,
    serviceLoading,
    taskAssignedError,
    yearError,
    groupError,success,
    toDoList,toDoError,toDoLoading
  };
};

export default CreateTaskController;
