import React, { useState,useEffect,useRef } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskChecksModal from '../../../../Components/Modals/AddTaskChecksModal';
import useTaskCheckController from '../../../Controllers/AdminManagementScreens/TaskManagement/TaskChecksController';
import Loader from '../../../../Components/Loader';
import filter from '../../../../Assets/Icons/filter.png';
import Dropdown from '../../../../Components/Dropdown';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';

const TaskCheckScreen = ({ servicesSearchList, typeCheckList, loading, error, onRefresh, searchLoading, searchError }) => {
    const {
        // subCategories,
        isModalOpen,
        handleAddDocument,
        handleCloseModal,
        getStatusStyle,
        handleEditService,
        categorieInfo,
        categoryCheckInfoRef,
        OnAddUpdateSucess,
        handleDeleteTaskCheck,
        // loading,
        deleteLoading,
        deleteError,
        // error,
        showFilters,
        filters,
        handleFilterChange,
        applyFilters, resetFilters, cancelFilters,
        toggleFilters,
        categoryListRef,
        subCategoryListRef,
        subError,
        subLoading,
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        isDeleteModalOpen,
        handleCloseDeleteModal,
        callDeleteTaskCheck,
        taskInfoRef,
        searchTaskCheckListError, searchTaskCheckListLoading,
        deleteSuccess,
        serviceErrorMessage, serviceCategoryErrorMessage
    } = useTaskCheckController(onRefresh, servicesSearchList, typeCheckList, error);

    //console.log('TaskCheckScreen @@@ deleteLoading ', deleteLoading)
    //console.log('TaskCheckScreen @@@ deleteError ', deleteError)
    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    return (
        <div className='basic-info-container'>
            <div style={{ display: 'flex', justifyContent: 'space-between', paddingBottom: 10, alignItems: 'center' }}>
                <h3 style={styles.categoryHeader}>Task Checks</h3>
                <div>
                    <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleAddDocument}>
                        Add Checks
                    </button>
                </div>
            </div>
            <div className="tasksScreen-filters-row">
                <div className="tasksScreen-filters-button-wrapper">
                    <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                        <img src={filter} alt="Filter" />  <span>
                            {showFilters ? 'Filters' : 'Filters'}
                        </span>

                    </button>
                </div>
            </div>

            {showFilters && (
                <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                    <div className="tasksScreen-filters" style={{ display: 'flex' }}>
                        {/* Replace each filter select with Dropdown component */}
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Service"] = el)}
                            isOpen={openDropdown === "Service"}
                            toggleDropdown={() => toggleDropdown("Service")}
                            label="Service "
                            options={servicesSearchList || []}
                            value={filters?.service}
                            onChange={(value) => handleFilterChange('service', value)}
                            searchBoxWidth={230}
                            errorMessage={serviceErrorMessage || searchError}
                            loading={searchLoading}
                        />
                        <Dropdown
                            ref={(el) => (dropdownRefs.current["subCategory"] = el)}
                            isOpen={openDropdown === "subCategory"}
                            toggleDropdown={() => toggleDropdown("subCategory")}
                            label="Service Category"
                            options={subCategoryListRef.current || []}
                            value={filters?.service_category}
                            onChange={(value) => handleFilterChange('service_category', value)}
                            loading={subLoading}
                            searchBoxWidth={230}
                            errorMessage={serviceCategoryErrorMessage}
                        />

                    </div>
                    <div>
                        {subError && <div className='list-error-msg' style={{ textAlign: 'left', paddingBottom: 5 }}> {subError} </div>}

                        <div className="tasksScreen-filter-buttons">
                            <button onClick={applyFilters} className="tasksScreen-apply-button" style={{ cursor: searchTaskCheckListLoading ? "not-allowed" : "pointer", opacity: searchTaskCheckListLoading ? 0.5 : 1 }} disabled={searchTaskCheckListLoading}>Apply</button>
                            <button onClick={cancelFilters} className="tasksScreen-cancel-button">Cancel</button>
                            <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                        </div>
                    </div>
                </div>
            )}
            <div style={styles.tableWrapper}>
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{ width: "25%" }}>Task Check Name</th>
                            <th style={{ width: "20%" }}>Service</th>
                            <th style={{ width: "20%" }}>Service Category</th>
                            <th style={{ width: "15%" }}>Index No.</th>
                            <th style={{ width: "10%" }}>Created By</th>
                            <th style={{ width: "5%" }}>Status</th>
                            <th style={{ width: "5%" }}></th>
                        </tr>
                    </thead>
                    {/* {!searchTaskCheckListError && !searchTaskCheckListLoading&&( */}
                    {/* <> */}
                    {tasks?.length > 0 &&
                        <tbody style={{ backgroundColor: "white" }}>
                            {tasks?.map((category, index) => (
                                <tr key={index}>
                                    <td style={styles.tableValue}>{category?.service_check_name}</td>
                                    <td style={styles.tableValue}>{category?.service_name || '--'}</td>
                                    <td style={styles.tableValue}>{category?.service_sub_name || '--'}</td>
                                    <td style={styles.tableValue}>{category?.display_order || '--'}
                                        {deleteSuccess[category?.service_check_id] &&
                                            <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{deleteSuccess[category?.service_check_id]}</div>}
                                        {deleteError[category?.service_check_id] &&
                                            <div style={{ ...styles.deleteErrorMsg, paddingTop: 10 }} >{deleteError[category?.service_check_id]}</div>}
                                    </td>
                                    <td style={styles.tableValue}>{category.create_by_name || '--'}</td>
                                    <td style={styles.tableValue}>
                                        {/* Status Label */}
                                        <div
                                            style={{
                                                display: "inline-block", // Makes the div wrap content and have its own width
                                                padding: "5px 5px", // Padding for the button-like appearance
                                                borderRadius: "15px",
                                                ...getStatusStyle(category?.service_check_status), // Dynamic styles based on the status
                                                width: "auto", // Width adjusts automatically to content
                                            }}
                                        >
                                            {category?.service_check_status === 1 ? 'Active' : 'Disabled'}
                                        </div>

                                        {/* Action Buttons */}
                                    </td>
                                    <td>
                                        <div
                                            style={{
                                                display: "flex",
                                                gap: "2px", // Space between edit and delete buttons
                                            }}
                                        >
                                            {/* Edit Button */}
                                            <button
                                                className="tasksScreen-edit-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => { handleEditService(category) }}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>

                                            {/* Delete Button */}
                                            <button
                                                className="tasksScreen-delete-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                    opacity: deleteLoading[category?.service_check_id] ? 0.3 : 1
                                                }}
                                                onClick={() => { handleDeleteTaskCheck(category) }}
                                                disabled={deleteLoading[category?.service_check_id]}
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="Delete"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            {deleteLoading[category?.service_check_id] &&
                                                <span><Loader loading={deleteLoading[category?.service_check_id]} color={'var(--primary-color)'} size='small' /></span>
                                            }
                                        </div>
                                    </td>

                                </tr>
                            ))}
                        </tbody>
                    }
                    {/* </> */}
                    {/* // ) */}
                    {/* } */}

                </table>
                {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                {searchTaskCheckListLoading && <Loader loading={searchTaskCheckListLoading} color={'var(--primary-color)'} />}
                {searchTaskCheckListError && <div className="list-error-msg">{searchTaskCheckListError} </div>}
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    message={`Are you sure you want to delete ${taskInfoRef.current?.service_check_name}?`}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => {
                        handleCloseDeleteModal(); // Close modal
                        callDeleteTaskCheck(taskInfoRef.current); // Perform delete operation
                    }}
                />
                {isModalOpen &&
                    <AddTaskChecksModal isOpen={isModalOpen} onClose={handleCloseModal} editData={categoryCheckInfoRef.current} onValueChange={OnAddUpdateSucess} />}
            </div>
            {/* Pagination */}
            <div className="tasksScreen-table-footer" >
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button
                        className="tasksScreen-pagination-button"
                        onClick={() => handlePageChange(currentPage - 1)}
                        disabled={currentPage === 1 || totalPages === 0}
                    >
                        <img
                            src={downArrow}
                            alt="Previous"
                            className="tasksScreen-arrow-left tasksScreen-icon-image"
                        />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button
                            key={index}
                            className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
                            onClick={() => handlePageChange(index + 1)}
                        >
                            {index + 1}
                        </button>
                    ))}
                    <button
                        className="tasksScreen-pagination-button"
                        onClick={() => handlePageChange(currentPage + 1)}
                        disabled={currentPage === totalPages || totalPages === 0}
                    >
                        <img
                            src={downArrow}
                            alt="Next"
                            className="tasksScreen-arrow-right tasksScreen-icon-image"
                        />
                    </button>
                </div>
            </div>

        </div>


    )

};

const styles = {
    categoryHeader: {
        color: '#1F384C',
        fontSize: 28,
        fontFamily: 'Roboto Slab',
        fontWeight: 500,
    },
    filtersContainer: {
        // display: "flex",
        flexWrap: "wrap", // Wrap inputs to the next line if needed
        gap: "20px", // Space between inputs
        marginBottom: "15px",
        // justifyContent: "space-between", // Distribute inputs evenly
        border: '1px #EFF0F4 solid',
        padding: '10px'
    },
    tableWrapper: {
        overflowX: "auto", // Horizontal scroll
        whiteSpace: "nowrap", // Prevent table from wrapping on small devices
        marginBottom: 50
    },
    tableValue: {
        colour: '#192A3E',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px'
    },
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2',
        cursor: 'pointer'
    },
    deleteErrorMsg: {
        color: 'red',
    }

}

export default TaskCheckScreen;