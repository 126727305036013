import React, { useState, useRef, useEffect} from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddTaskCategoryModal from '../../../../Components/Modals/AddTaskCategoryModal';
import Input from '../../../../Components/Input';
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import AddUserModal from '../../../../Components/AddUserModal';
import leftArrowIcon from '../../../../Assets/Icons/chevron-down.png';
import { useNavigate } from "react-router-dom";
import InvoiceBillController from '../../../Controllers/AdminScreens/Invoices/InvoiceBillController';
import TextInput from '../../../../Components/TextInput';
import DateInput from '../../../../Components/DateInput';
import search from '../../../../Assets/Icons/search.png';
import Dropdown from '../../../../Components/Dropdown';

const InvoiceBillScreen = () => {

  const {
    invoiceBill,
    handlePageChange,
    currentTasks,
    totalPages,
    currentPage,
    filterData,
    setFilterData,
    handleFilterChange
  } = InvoiceBillController();

  const navigate = useNavigate();
  const handleGenerateInvoive = () => {
    navigate("/generate-invoice");
  };
  const handleEditInvoive = () => {
    navigate("/generate-edit-invoice");
  };


  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => {
    setShowFilters((prev) => !prev); // Toggle the filter visibility
  };
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);



  return (
    <>
      <div className='basic-info-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>Invoices</h3>
          <div>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleGenerateInvoive}>
              Generate Invoices
            </button>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} style={styles.filterIcon} /> Filters</button>
            <div >
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <div className="filter-field" > {/* Add margin-bottom here */}
                <label style={{ ...styles.labaleStyle, display: "block" }}>
                  Client Name
                </label>
                <TextInput
                  type="text"
                  placeholder="Search"
                  value={filterData?.clientName}
                  onChange={(e) => handleFilterChange('clientName', e.target.value)}
                  right={<img src={search} alt="Search" className="search-icon" />}
                  style={{ padding: "20px 10px" }} // Add padding inside the TextInput
                />
              </div>
              <Dropdown
                ref={(el) => (dropdownRefs.current["Services"] = el)}
                isOpen={openDropdown === "Services"}
                toggleDropdown={() => toggleDropdown("Services")}
                label="Services"
                options={["Option 1", "Option 2"]}
                placeholder="------"
                value={filterData?.services}
                onChange={(value) => handleFilterChange('services', value)}
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current["Group Name"] = el)}
                isOpen={openDropdown === "Group Name"}
                toggleDropdown={() => toggleDropdown("Group Name")}
                label="Group Name"
                options={["Option 1", "Option 2"]}
                placeholder="------"
                value={filterData?.groupName}
                onChange={(value) => handleFilterChange('groupName', value)}
              />
              <div className="filter-field" > {/* Add margin-bottom here */}
                <label style={{ ...styles.labaleStyle, display: "block" }}>
                  Generated Date
                </label>
                <DateInput
                  value={filterData?.generatedDate}
                  onChange={(e) => handleFilterChange('generatedDate', e.target.value)}
                  style={{ padding: "20px 10px" }}
                />
              </div>
              <Dropdown
                ref={(el) => (dropdownRefs.current["Generated By"] = el)}
                isOpen={openDropdown === "Generated By"}
                toggleDropdown={() => toggleDropdown("Generated By")}
                label="Generated By"
                options={["Option 1", "Option 2"]}
                placeholder="------"
                value={filterData?.generatedBy}
                onChange={(value) => handleFilterChange('generatedBy', value)}
              />
            </div>
            <div className="button-container" style={{ marginBottom: '15px' }}>
              <button className="add-client-button" style={{ marginRight: '8px', paddingLeft: '30px', paddingRight: '30px' }}>Apply</button>
              <button className="cancel-button" style={{ border: '1px #40513B solid', borderRadius: '20px' }} >Cancel</button>
              <button className="cancel-button">Reset</button>
            </div>
          </div>
        )}
      </div>

      <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "10%" }}>Invoice No.</th>
                <th style={{ width: "10%" }}>Client Name</th>
                <th style={{ width: "10%" }}>Services/Category</th>
                <th style={{ width: "10%" }}>Generated Date</th>
                <th style={{ width: "30%" }}>Group Name</th>
                <th style={{ width: "10%" }}>Generated By</th>
                <th style={{ width: "10%" }}>Amount</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {currentTasks?.map((category, index) => (
                <tr key={index}>
                  <td style={styles.tableLink}>{category.billNo}</td>
                  <td style={styles.tableValue}>{category.clientName}</td>
                  <td style={styles.tableValue}>{category.taskType}</td>
                  <td style={styles.tableValue}>{category.generatedDate}</td>
                  <td style={styles.tableValue}>{category.groupName}</td>
                  <td style={styles.tableValue}>{category.generatedBy}</td>
                  <td style={styles.tableValue}>{category.amount}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px", // Space between edit and delete buttons
                      }}
                    >
                      {/* Edit Button */}
                      <button
                        className="tasksScreen-edit-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                          onClick={handleEditInvoive}
                        />
                      </button>

                      {/* Delete Button */}
                      <button
                        className="tasksScreen-delete-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          {/* <AddUserModal isOpen={isModalOpen} onClose={handleCloseModal}/> */}
        </div>

      </div>
      <hr className="divider" style={{ marginTop: '20px' }} />
      <div className="table-footer">
        <div className="total-count"></div>
        <div className="pagination">
          <div className="pagination-info">
            {`${currentPage}-${totalPages} of items`}
          </div>
          <button
            className="pagination-button-arrow"
            onClick={() => handlePageChange(currentPage - 1)}
            disabled={currentPage === 1}
          >
            <img src={leftArrowIcon} alt="Previous" className="tasksScreen-arrow-left" style={{ width: '17px', height: '17px' }} />
          </button>
          {[...Array(totalPages)].map((_, index) => (
            <button
              key={index}
              className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`}
              onClick={() => handlePageChange(index + 1)}
            >
              {index + 1}
            </button>
          ))}
          <button
            className="pagination-button-arrow"
            onClick={() => handlePageChange(currentPage + 1)}
            disabled={currentPage === totalPages}
          >
            <img src={leftArrowIcon} alt="Next" className="tasksScreen-arrow-right" style={{ width: '17px', height: '17px' }} />
          </button>
        </div>
      </div>
    </>

  )

};

const styles = {
  categoryHeader: {
    color: '#1F384C',
    fontSize: 18,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },

}

export default InvoiceBillScreen;