import React, { useState, useRef, useEffect } from 'react';
import Tabs from '../../../../Components/Tabs';
import UserPrimaryInformationScreen from './UserPrimaryInformationScreen';
import UserAccessScreen from './UserAccessScreen';
import UserAssignmentsScreen from './UserAssignmentsScreen';
import UserSpecializationScreen from './UserSpecializationScreen';
import backIcon from '../../../../Assets/Icons/back.png';
import ManageUserController from '../../../Controllers/AdminScreens/Users/ManageUserController';
import { useLocation } from 'react-router-dom';
import Toggle from '../../../../Components/Toggle';
import playArrowIcon from '../../../../Assets/Icons/play_arrow_filled.png';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';
import pauseIcon from '../../../../Assets/Icons/pause.png';


const ManageUserScreen = () => {
    const location = useLocation();
    const { isUserEditable } = location.state || {};
    const [selectedTab, setSelectedTab] = useState(location?.selectedTab || 0);

    //console.log('isUserEditable @@@ ', isUserEditable)

    const { handleBack,
        userToggleState,
        handleUserToggleChange,
        loading,
        error,
        userData,
        userSpecializationList,
        userSpecializationLoading,
        userSpecializationError,
        refreshUserSpecializationList,
        onToggle,
        userToggleError,
        userToggleLoading,
        userToggleSuccess,

        handleTaskAssignChange,
        taskAssignSuccess,
        taskAssignLoading,
        taskAssignError,
        userAccessPermissionList, refreshUserAccessPermissionList,
        userAccessPermissionLoading, userAccessPermissionError,
        assignmentList,
        assignmentError,
        assignmentsLoading
    } = ManageUserController();

    //console.log('userToggleState @', userToggleState)

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };


    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Manage Users</span>
            </div>
            {/* User Section */}
            <div >
                <div style={{ ...styles.userInfo }}>
                    <div className="company-name" style={{ marginRight: 15 }}>{userData?.emp_name} {loading && <LoadingSkeleton width="40%" height="10px" />}</div>
                    <Toggle initialStatus={userToggleState} onToggle={onToggle} size='big' />
                    <div style={{ ...styles.busyworkborder, marginLeft: 15 }} 
                    onClick={async () => {
                        if (!taskAssignLoading) { // Prevent click during loading
                          const isSuccessful = await handleTaskAssignChange();
                          if (isSuccessful) {
                            userData.emp_task_assign_flag = userData.emp_task_assign_flag === 1 ? 0 : 1; // Toggle the flag
                            // Optionally update state or trigger a refresh
                          }
                        }
                      }}
                    >
                        {userData?.emp_task_assign_flag === 1 ? (
                            <>
                            <img
                                src={playArrowIcon}
                                alt="Free to work"
                                style={{ width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }}
                            />
                            Free to work
                            </>
                        ) : (
                            <>
                            <img
                                src={pauseIcon}
                                alt="Busy to work"
                                style={{ width: '15px', height: '15px', cursor: 'pointer', marginRight: '10px' }}
                            />
                            Busy to work
                            </>
                        )}
                        </div>

                    <div style={{display:'flex', alignItems:'center', marginLeft:'10px'}}>
                    {userToggleError && <div className="list-error-msg">{userToggleError} </div>}
                    {userToggleSuccess && <div className="list-success-msg">{userToggleSuccess} </div>}
                    {userToggleLoading && <Loader loading={userToggleLoading} color={'var(--primary-color)'} size="small"/>}
                    {taskAssignError && <div className="list-error-msg">{taskAssignError} </div>}
                    {taskAssignLoading && <Loader loading={taskAssignLoading} color={'var(--primary-color)'} size="small"/>}
                    {taskAssignSuccess && <div className="list-success-msg">{taskAssignSuccess} </div>}
                    </div>
                </div>
                <div style={{ ...styles.userInfo, width: '100%', justifyContent: 'space-between', alignItems: 'center', marginBottom: 10 }}>
                    <div className="company-details"> {userData?.role_id_name || '--'} | {userData?.emp_id_team_manager_name || '--'} | {userData?.emp_id_team_lead_name || '--'}</div>
                    <div style={{ flexDirection: 'column', display: 'flex' }}>
                        {userData?.emp_email && <span>Contact User: {userData?.emp_email}</span>}
                        <span style={{ alignSelf: 'end' }}>{userData?.emp_mobile_code} {userData?.emp_mobile_no}</span>
                    </div>
                </div>
            </div>

            {/* Tab Section */}
            <div>
                <Tabs tabs={['Primary Information', 'Specialization', 'Assignments', 'User Access']}
                    selectedIndex={selectedTab}
                    onTabChange={handleTabChange}>
                    <UserPrimaryInformationScreen isUserEditable={isUserEditable} userInfo={userData} loading={loading} error={error} />
                    <UserSpecializationScreen userInfo={userData} userSpecializationList={userSpecializationList} userSpecializationLoading={userSpecializationLoading} userSpecializationError={userSpecializationError} onRefresh={refreshUserSpecializationList} />
                    <UserAssignmentsScreen assignmentList={assignmentList} assignmentError={assignmentError} assignmentsLoading={assignmentsLoading}/>
                    <UserAccessScreen userInfo={userData} userAccessPermissionList={userAccessPermissionList} userAccessPermissionLoading={userAccessPermissionLoading} userAccessPermissionError={userAccessPermissionError} onRefresh={refreshUserAccessPermissionList}/>
                </Tabs>
            </div>

        </div>
    )

};

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        letterSpacing: 1
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    userInfo: {
        display: 'flex',
        flexDirection: 'row',
    },
    busyworkborder: {
        border: '1px #40513B solid',
        borderRadius: '20px',
        justifyContent: 'center',
        display: 'flex',
        alignItems: 'center',
        backgroundColor: "white",
        color: "#40513B",
        padding: "5px 8px",
        cursor: "pointer",
        fontSize: '12px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        height: '20px',
        alignSelf: 'center'
    }
}

export default ManageUserScreen;