import { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { getData, postData } from "../../../../ApiServices/ApiService";
import { ORG_DELETE_TEAM, ORG_GET_EMPLOYEE_LIST, ORG_SEARCH_TEAM,ORG_GET_TEAM_LEADER_MANAGER_LIST } from '../../../../ApiServices/BaseURL';

const ManageTeamsController = (teamList, onRefresh, loading, error) => {
  // //console.log('teamList:-', teamList)

  const navigate = useNavigate();
  const [teamListLoading, setTeamListLoading] = useState(false);
  const [teamListError, setTeamListError] = useState(error || '');

  const [teamName, setTeamName] = useState("");
  const [teamLead, setTeamLead] = useState(null); // Dropdown
  const [teamManager, setTeamManager] = useState(null); // Dropdown
  const [group, setGroup] = useState("");
  const teamListRef = useRef(teamList);
  const [manageTeamList, setManageTeamList] = useState([]);
  const [deleteTeamLoading, setDeleteTeamLoading] = useState({});
  const [deleteTeamError, setDeleteTeamError] = useState({});
  const [deleteTeamSuccess, setDeleteTeamSuccess] = useState({});
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [showFilters, setShowFilters] = useState(true);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const teamInfoRef = useRef(null);
  const [employeeList, setEmployeeList] = useState([]);
  const [teamLeaderLoading, setTeamLeaderLoading] = useState(false);
  const [empError, setEmpError] = useState('');
  const [teamNameError, setTeamNameError] = useState('');
  const [teamLeadError, setTeamLeadError] = useState('');
  const [teamManagerError, setTeamManagerError] = useState('');
  const [filterError, setFilterError] = useState('');
  const [currentPage, setCurrentPage] = useState(1);
    const itemsPerPage = 10;

  useEffect(() => {
    // teamListRef.current = teamList;
    setManageTeamList(teamList);

  }, [teamList])

  useEffect(() => {
    fetchTeamLeaderList()
    fetchManagersList()
  }, [])


  const fetchTeamLeaderList = async () => {
    try {
      setTeamLeaderLoading(true);
      setTeamLeadError('');
      setEmployeeList([]);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const response = await getData(`${ORG_GET_TEAM_LEADER_MANAGER_LIST}?emp_role=${'TEAM_LEAD'}&emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const filterData = responseData?.dataJ.map(service => ({
                label: service?.emp_name,
                value: service?.emp_id
              }));
              setEmployeeList(filterData);
            } else {
              setTeamLeadError(responseData?.info || 'Data Not Found');
            }
          } else {
            setTeamLeadError(responseData?.info || 'Failed to fetch User data. Please try again.');
          }
        } else {
          setTeamLeadError(responseData?.info || 'Failed to fetch User data.');
        }
      } else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setTeamLeadError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setTeamLeadError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      } else if (response?.status === 500) {
        setTeamLeadError(responseData?.info || 'Internal Server Error. Please try again later.');
      } else {
        setTeamLeadError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setTeamLeadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
      } else if (error.request) {
        setTeamLeadError('No response received from the server. Please check your network connection and try again.');
      } else {
        setTeamLeadError(`Failed to fetch User data. Please check your network connection and try again.`);
      }
    } finally {
      setTeamLeaderLoading(false);
    }
  }

  const[managerLoading, setManagerLoading]=  useState(false);
      const [managerList, setManagerList] = useState([]);
      const [managerFiletrError,setManagerFilterError] = useState('');
      const fetchManagersList = async () => {
        try {
            setManagerLoading(true);
            setEmpError('');
            setManagerList([]);
            setManagerFilterError('');
  
  
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
  
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LEADER_MANAGER_LIST}?emp_role=${'TEAM_MANAGER'}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
  
            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.emp_name,
                                value: service?.emp_id
                            }));
                            setManagerList(filterData);
                        } else {
                          setManagerFilterError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                      setManagerFilterError(responseData?.info || 'Failed to fetch User data. Please try again.');
                    }
                } else {
                  setManagerFilterError(responseData?.info || 'Failed to fetch User data. Status code error.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                  setManagerFilterError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                  setManagerFilterError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setManagerFilterError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
              setManagerFilterError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setManagerFilterError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setManagerFilterError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setManagerFilterError(`Failed to fetch User data. Please check your network connection and try again.`);
            }
        } finally {
          setManagerLoading(false);
        }
    }

  const handleCreateTeam = () => {
    //console.log('handleCreateTeam ')
    navigate('/manage-team-details', { state: { selTeamInfo: null } })
  }

  const handleEditTeam = (team, isEdit) => {
    // //console.log('handleEditTeam team editicon', team)
    // navigate('/manage-team-details', {state : { selTeamInfo:team,isEdit: isEdit} });
    navigate('/manage-team-details', { state: { selTeamInfo: team, isEdit: isEdit, selectedTab: 1 } });
  }

  const getStatusStyle = (status) => {
    switch (status) {
      // case "Incomplete":
      //   return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
      case 0:
        return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
      case 1:
        return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
      default:
        return {};
    }
  };
  const handleDeleteTeam = (team) => {
    // //console.log('handleDeleteTeam team @', team);
    // showConfirmation(team);
    teamInfoRef.current = team;
    setIsDeleteModalOpen(true);
  }

  const showConfirmation = (team) => {
    const confirmed = window.confirm("Are you sure you want to delete?");
    if (confirmed) {
      callDeleteTeam(team);
    } else {
      //console.log("Delete canceled");
    }
  }

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
};

const OnAddUpdateSucess = () => {
  onRefresh();
  setFilterError('');
  setTeamListError('');
  setFilters({
    team_name: '',
    team_leader: null,
    team_manager: null
  });
}

const [deletedMessages, setDeletedMessages] = useState([]);
  const callDeleteTeam = async (team) => {
    try {
      //console.log('callDeleteTeam team', team);
      setDeleteTeamError({});
      setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: '' }));
      setDeleteTeamLoading((prev) => ({ ...prev, [team?.team_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "team_id": team?.team_id
      }

      //console.log('callDeleteTeam payload', payLoad);

      // Make the API call
      const response = await postData(ORG_DELETE_TEAM, payLoad);
      const responseData = await response.json();
      //console.log('callDeleteTeam response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setDeleteTeamSuccess((prev) => ({ ...prev, [team?.team_id]: responseData?.info }));
            setDeletedMessages((prevDeleted) => [...prevDeleted, team?.team_id]);
            setTimeout(() => {
              setDeleteTeamSuccess((prev) => ({ ...prev, [team?.team_id]: '' }));
              OnAddUpdateSucess();
              setCurrentPage(1);
            }, 3000);
          } else {
            setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: responseData?.info || 'Failed to delete team. Please try again.' }));
          }
        } else {
          setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: responseData?.info || 'Failed to delete team. Please try again...' }));
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
        } else {
          // setError(responseData?.info || 'Bad request. Please check the request parameters.');
          setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      // Unexpected or network-related errors
      else {
        setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete team. Please try again.'}` }));
      } else if (error.request) {
        // Request was made but no response was received
        setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        // An error occurred in setting up the request
        setDeleteTeamError((prev) => ({ ...prev, [team?.team_id]: `Failed to delete team. Please check your network connection and try again.` }));
      }
    } finally {
      setDeleteTeamLoading((prev) => ({ ...prev, [team?.team_id]: false }));
    }
  }

  const handleCreateTeamModal = () => {
    navigate('/manage-team-details', { state: { selTeamInfo: null, isEdit: null, selectedTab: 1, isAddTeam: true } });
    // setIsModalOpen(true); // Open the modal
  };
  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const [filters, setFilters] = useState({
    team_name: '',
    team_leader: null,
    team_manager: null
  });

  const handleFilterChange = (name, value) => {
    //console.log('name : ', name, 'value : ', value)
    setFilterError('');
    setFilters((prevFilters) => ({
      ...prevFilters,
      [name]: value,
    }));
  };

  const applyFilters = () => {
    //console.log('Applying filters:', filters);
    // setTeamNameError('');
    // setTeamLeadError('');
    // setTeamManagerError('');
    // if(!filters?.team_name){
    //   setTeamNameError('Please enter team name');return
    // }
    // if(!filters?.team_leader){
    //   setTeamLeadError('Please select team leader');return
    // }
    // if(!filters?.team_manager){
    //   setTeamManagerError('Please select team manager');return
    // }
    fetchSearchTeamList();
  };

  const resetFilters = () => {
    if (filters?.team_name.trim() || filters?.team_leader || filters?.team_manager) {
      onRefresh();
      setCurrentPage(1);
    }
    setFilterError('');
    setTeamListError('');
    setFilters({
      team_name: '',
      team_leader: null,
      team_manager: null
    });
    setManagerFilterError('');
    setTeamLeadError('');
    setDeleteTeamError({});
  };

  const cancelFilters = () => {
    resetFilters();
    setShowFilters(!showFilters);
  }

  const toggleFilters = () => {
    setShowFilters(!showFilters);
  };

  const fetchSearchTeamList = async () => {
    try {
      setDeleteTeamError({});
      setFilterError('');
      if (!(filters?.team_name.trim() || filters?.team_leader || filters?.team_manager)) {
        setFilterError('Please enter/select any one of the above'); return
      }
      setTeamListLoading(true);
      setTeamListError('');

      // teamListRef.current = [];
      setManageTeamList([]);
      setCurrentPage(1);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      const payLoad={
        "emp_id_auth": storedUserInfo?.emp_id,
        "team_name": filters?.team_name,
        "emp_id_team_lead": filters?.team_leader?.value || 0,
        "emp_id_team_manager": filters?.team_manager?.value || 0,
      }
      //console.log('---team status:---',payLoad);

      const response = await postData(ORG_SEARCH_TEAM,payLoad);
      const responseData = await response.json();

      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // teamListRef.current = responseData?.dataJ;
              setManageTeamList(responseData?.dataJ);
            } else {
              setTeamListError(responseData?.info || 'No Teams Available');
            }
          } else {
            setTeamListError(responseData?.info || 'Failed to fetch team list. Please try again.');
          }
        } else {
          setTeamListError(responseData?.info || 'Failed to fetch team list.');
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setTeamListError('Unauthorized access. Your session may have expired. Please log in again.');
        } else {
          setTeamListError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      else if (response?.status === 500) {
        setTeamListError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      else {
        setTeamListError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      if (error.response) {
        setTeamListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        setTeamListError('No response received from the server. Please check your network connection and try again.');
      } else {
        setTeamListError(`Failed to fetch team list. Please check your network connection and try again.`);
      }
    } finally {
      setTeamListLoading(false);  
    }
  }

  // const activeTeamList = manageTeamList?.length > 0 ? manageTeamList : teamList;
  const totalPages = Math.ceil(manageTeamList?.length / itemsPerPage);
    const indexOfLastItem = currentPage * itemsPerPage;
    const indexOfFirstItem = indexOfLastItem - itemsPerPage;
    const currentPageTeams = manageTeamList?.slice(indexOfFirstItem, indexOfLastItem);

    const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
        setCurrentPage(pageNumber);
    }
    };

  return {
    teamListLoading, teamListError,
    teamName, setTeamName, teamNameError,
    teamManager, setTeamManager, teamManagerError,
    teamLead, setTeamLead, teamLeadError,
    employeeList, teamLeaderLoading,
    group, setGroup,
    teamListRef,
    manageTeamList,
    handleCreateTeam,
    handleEditTeam,
    getStatusStyle,
    handleDeleteTeam,
    deleteTeamLoading,
    deleteTeamError,
    handleCreateTeamModal,
    handleCloseModal,
    isModalOpen,
    showFilters,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteTeam,
    teamInfoRef,
    filterError,
    filters,
    handleFilterChange,
    applyFilters, resetFilters, cancelFilters,
    toggleFilters,
    currentPage,
    totalPages,
    handlePageChange,
    currentPageTeams,
    deleteTeamSuccess,

    managerFiletrError,
    managerList,
    managerLoading,
    deletedMessages
  }

}

export default ManageTeamsController;