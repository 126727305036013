import { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { postData } from "../../../../ApiServices/ApiService";
import {
    ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_SUB_SERVICE_LIST_SEARCH,
    ORG_GET_SCHEDULE_REPORT, ORG_OVERVIEW_REPORT, ORG_TASK_STATUS, ORG_DATE_STATUS, ORG_CHECKWISE_STATUS
} from "../../../../ApiServices/BaseURL";

const GstServiceController = () => {
    const [serviceOptions, setServiceOptions] = useState([]);
    const [serviceSubOptions, setServiceSubOptions] = useState([]);
    const [financialYearOptions, setFinancialYearOptions] = useState([]);
    const [statusOptions, setStatusOptions] = useState([]);
    const [taskOverview, setTaskOverview] = useState([
        { label: "Completed", key: "completed", value: "--" },
        { label: "Pending", key: "pending", value: "--" },
        { label: "Priority", key: "priority", value: "--" },
        { label: "Long Due", key: "long_due", value: "--" },
        { label: "Not Started", key: "not_started", value: "--" },
        { label: "In Progress", key: "inprogress", value: "--" },
    ]);
    const [sections, setSections] = useState([]);
    const [checkwiseGroupReport, setCheckwiseGroupReport] = useState({});
    const [loading, setLoading] = useState(true);
    const navigate = useNavigate();
    const [servicesLoading, setServicesLoading] = useState(false);
    const [servicesError, setServicesError] = useState('');
    const [serviceName, setServiceName] = useState('');
    const [subServiceName, setSubServiceName] = useState('');
    const [subServicesLoading, setSubServicesLoading] = useState(false);
    const [subServicesError, setSubServicesError] = useState('');
    const [overViewLoading, setOverViewLoading] = useState(false);
    const [overViewReportError, setOverViewReportError] = useState('');
    const [taskStatus, setTaskStatus] = useState([
        { label: "Task with Pending Issues", key: "task_with_pending_issues", value: "--" },
        { label: "Tasks kept on Hold", key: "tasks_kept_on_hold", value: "--" },
        { label: "Tasks with No Issues", key: "tasks_with_no_issues", value: "--" },
    ]);
    const [taskStatusLoading, setTaskStatusLoading] = useState(false);
    const [taskStatusError, setTaskStatusError] = useState('');
    const [dateStatus, setDatetatus] = useState([
        { label: "Due by Today", key: "due_by_today", value: "--" },
        { label: "Due within 7 Days", key: "due_within_7_days", value: "--" },
        { label: "Crossed Due Date", key: "crossed_due_date", value: "--" },
    ]);
    const [dateStatusLoading, setDateStatusLoading] = useState(false);
    const [dateStatusError, setDateStatusError] = useState('');
    const [checkwiseStatusList, setCheckwiseStatusList] = useState([])
    const [checkwiseStatus, setCheckwiseStatus] = useState([
        { label: "Info received from client for GSTR1", key: "info_received_from_clients_for_gstr1", value: "--" },
        { label: "Preparation of GSTR1", key: "preparation_of_gstr1", value: "--" },
        { label: "Checking for GSTR1", key: "checking_for_gstr1", value: "--" },
        { label: "Submission of GStr1", key: "submission_of_gstr1", value: "--" },
        { label: "Filling GSTR1", key: "filling_gstr1", value: "--" },
    ]);
    const [checkwiseStatusLoading, setCheckwiseStatusLoading] = useState(false);
    const [checkwiseStatusError, setCheckwiseStatusError] = useState('');
    const [scheduleInfoListLoading, setScheduleInfoListLoading] = useState([
        { label: "Follow Ups", key: "due_by_today", value: "--" },
        { label: "Appointments", key: "due_within_7_days", value: "--" },
        { label: "Meetings", key: "crossed_due_date", value: "--" },
    ]);
    const [scheduleInfoList, setScheduleInfoList] = useState([]);
    const [scheduleResError, setScheduleResError] = useState('');
    const [scheduleLoading, setScheduleLoading] = useState(false);

    const fetchServices = async () => {
        setServicesLoading(true);
        setServiceOptions([]);
        setServicesError('');
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
            };

            // API call to fetch GST services
            const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        const services = responseData?.dataJ.map((service) => ({
                            label: service.service_name,
                            value: service.service_id,
                        }));
                        setServiceOptions(services);
                    } else {
                        setServicesError(responseData?.info || 'Service search failed.');
                    }
                } else {
                    setServicesError(responseData?.info || 'Unexpected response code from the server.');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setServicesError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setServicesError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setServicesError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setServicesError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setServicesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get service options. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setServicesError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setServicesError(`Failed to search service options. Please check your network connection and try again.`);
            }
        } finally {
            setServicesLoading(false);
        }
    };

    const fetchSubServices = async (serviceId) => {
        setSubServicesLoading(true);
        setServiceSubOptions([]);
        setSubServiceName('');
        setSubServicesError('');
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
                service_id: serviceId,
            };

            // API call to fetch GST services
            const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        const subServices = responseData?.dataJ.map((service) => ({
                            label: service.service_sub_name,
                            value: service.service_sub_id,
                        }));
                        setServiceSubOptions(subServices);
                    } else {
                        setSubServicesError(responseData?.info || 'Subservice search failed.');
                    }
                } else {
                    setSubServicesError(responseData?.info || 'Unable to fetch services');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setSubServicesError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setSubServicesError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setSubServicesError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setSubServicesError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setSubServicesError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to search subservice options. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setSubServicesError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setSubServicesError(`Failed to search subservice options. Please check your network connection and try again.`);
            }
        } finally {
            setSubServicesLoading(false);
        }
    };

    const overViewReport = async (serviceName, subServiceName) => {
        setOverViewLoading(true);
        setOverViewReportError('');
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
                service_id: serviceName?.value,
                service_sub_id: subServiceName?.value,
            };

            // API call to fetch GST services
            const response = await postData(ORG_OVERVIEW_REPORT, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        const responseDataObject = responseData?.dataJ[0] || {};
                        const overViewReport = taskOverview.map((task) => ({
                            ...task,
                            value: responseDataObject[task.key] ?? "--",
                        }));
                        setTaskOverview(overViewReport);
                    } else {
                        setOverViewReportError(responseData?.info || 'Task overview status failed.');
                    }
                } else {
                    setOverViewReportError(responseData?.info || 'Unable to fetch task overview');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setOverViewReportError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setOverViewReportError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setOverViewReportError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setOverViewReportError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setOverViewReportError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get task overview. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setOverViewReportError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setOverViewReportError(`Failed to get task overview. Please check your network connection and try again.`);
            }
        } finally {
            setOverViewLoading(false);
        }
    }

    const taskStatusReport = async (serviceName, subServiceName) => {
        setTaskStatusLoading(true);
        setTaskStatusError('');
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
                service_id: serviceName?.value,
                service_sub_id: subServiceName?.value,
            };

            // API call to fetch GST services
            const response = await postData(ORG_TASK_STATUS, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        const responseDataObject = responseData?.dataJ[0] || {};
                        const taskStatusReport = taskStatus.map((task) => ({
                            ...task,
                            value: responseDataObject[task.key] ?? "--",
                        }));
                        setTaskStatus(taskStatusReport);
                    } else {
                        setTaskStatusError(responseData?.info || 'Task status failed.');
                    }
                } else {
                    setTaskStatusError(responseData?.info || 'Unable to fetch task status');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setTaskStatusError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setTaskStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setTaskStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setTaskStatusError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setTaskStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get task status. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setTaskStatusError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setTaskStatusError(`Failed to get task status. Please check your network connection and try again.`);
            }
        } finally {
            setTaskStatusLoading(false);
        }
    }

    const dateReport = async (serviceName, subServiceName) => {
        setDateStatusLoading(true);
        setDateStatusError('');
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
                service_id: serviceName?.value,
                service_sub_id: subServiceName?.value,
            };

            // API call to fetch GST services
            const response = await postData(ORG_DATE_STATUS, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        const responseDataObject = responseData?.dataJ[0] || {};
                        const dateStatusReport = dateStatus.map((task) => ({
                            ...task,
                            value: responseDataObject[task.key] ?? "--",
                        }));
                        setDatetatus(dateStatusReport);
                    } else {
                        setDateStatusError(responseData?.info || 'Date status failed.');
                    }
                } else {
                    setDateStatusError(responseData?.info || 'Unable to fetch task with due dates');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setDateStatusError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setDateStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setDateStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setDateStatusError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setDateStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get date status. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setDateStatusError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setDateStatusError(`Failed to get date status. Please check your network connection and try again.`);
            }
        } finally {
            setDateStatusLoading(false);
        }
    }

    const fetchScheduleInfo = async () => {
        setScheduleLoading(true);
        setScheduleResError('');
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
                // "emp_id_auth": "10001",
                // "service_id": "10006",
                // "service_sub_id": "10006"
            };

            // API call to fetch GST services
            const response = await postData(ORG_GET_SCHEDULE_REPORT, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        if (responseData?.dataJ?.length > 0) {
                            setScheduleInfoList(responseData?.dataJ);
                        } else {
                            setScheduleResError(responseData?.info || 'No schedule info available.');
                        }

                    } else {
                        setScheduleResError(responseData?.info || 'Schedule Info failed.');
                    }
                } else {
                    setScheduleResError(responseData?.info || 'Unable to fetch schedule info.');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setScheduleResError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setScheduleResError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setScheduleResError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setScheduleResError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setScheduleResError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get Schedule Info. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setScheduleResError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setScheduleResError(`Failed to get Schedule Info. Please check your network connection and try again.`);
            }
        } finally {
            setScheduleLoading(false);
        }
    }

    const checkwiseReport = async (serviceName, subServiceName) => {
        setCheckwiseStatusLoading(true);
        setCheckwiseStatusError('');
        setCheckwiseStatusList([]);
        try {
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_')); // Get user info from localStorage
            const payload = {
                emp_id_auth: storedUserInfo?.emp_id,
                service_id: serviceName?.value,
                service_sub_id: subServiceName?.value,
            };

            // API call to fetch GST services
            const response = await postData(ORG_CHECKWISE_STATUS, payload);
            const responseData = await response.json();

            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message?.toLowerCase() === "success") {
                        const responseDataObject = responseData?.dataJ[0] || {};
                        const checkwiseStatusReport = checkwiseStatus.map((task) => ({
                            ...task,
                            value: responseDataObject[task.key] ?? "--",
                        }));
                        // setCheckwiseStatus(checkwiseStatusReport);
                        setCheckwiseStatusList(responseData?.dataJ);
                    } else {
                        setCheckwiseStatusError(responseData?.info || 'Checkwise report failed.');
                    }
                } else {
                    setCheckwiseStatusError(responseData?.info || 'Unable to fetch checkwise pending tasks.');
                }
            }
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setCheckwiseStatusError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setCheckwiseStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setCheckwiseStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setCheckwiseStatusError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setCheckwiseStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to get checkwise status. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setCheckwiseStatusError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setCheckwiseStatusError(`Failed to get checkwise status. Please check your network connection and try again.`);
            }
        } finally {
            setCheckwiseStatusLoading(false);
        }
    }

    const handleSubServices = (serviceName) => {
        setServiceName(serviceName);
        fetchSubServices(serviceName?.value);
    };

    const handleData = (subServiceName) => {
        setSubServiceName(subServiceName);
        overViewReport(serviceName, subServiceName);
        taskStatusReport(serviceName, subServiceName);
        dateReport(serviceName, subServiceName);
        checkwiseReport(serviceName, subServiceName);
    }

    useEffect(() => {

        //console.log("serviceName", serviceName);

        fetchServices();
        // Simulating an API call to fetch data
        const fetchData = async () => {
            setLoading(true);
            try {

                const years = [
                    { label: "2023-24", value: "2023-24" },
                    { label: "2022-23", value: "2022-23" },
                ];

                const statuses = [
                    { label: "Completed", value: "completed" },
                    { label: "Pending", value: "pending" },
                ];

                const sectionsData = [
                    {
                        title: "Task with Status",
                        items: [
                            { label: "Tasks with Pending Issues", value: 32 },
                            { label: "Tasks kept on Hold", value: 3 },
                            { label: "Tasks with No Issues", value: 32 },
                        ],
                    },
                    {
                        title: "Task with Dates",
                        items: [
                            { label: "Due by Today", value: 21 },
                            { label: "Due within 7 Days", value: 0 },
                            { label: "Crossed Due Date", value: 1 },
                        ],
                    },
                    {
                        title: "Schedule",
                        items: [
                            { label: "Follow Ups", value: 11 },
                            { label: "Appointments", value: 0 },
                            { label: "Meetings", value: 1 },
                        ],
                    },
                    {
                        title: "Pending issues with Tasks",
                        items: [
                            { label: "No. of Demand Refunds", value: 32 },
                            { label: "No. of Digital Signature Pending", value: 3 },
                            { label: "Loan Statement Pending", value: 32 },
                            { label: "Mismatches", value: 3 },
                            { label: "Tax Payments", value: 32 },
                        ],
                    },
                ];

                const groupReport = {
                    title: "Checkwise Group Report",
                    options: [{ label: "Group Name", value: "group-name" }],
                    statusOptions: [
                        { label: "Pending", value: "pending" },
                        { label: "Completed", value: "completed" },
                    ],
                };

                await new Promise((resolve) => setTimeout(resolve, 500)); // Simulate delay

                setFinancialYearOptions(years);
                setStatusOptions(statuses);
                setSections(sectionsData);
                setCheckwiseGroupReport(groupReport);
            } catch (error) {
                console.error("Error fetching data", error);
            } finally {
                setLoading(false);
            }
        };

        fetchData();
        fetchScheduleInfo();
    }, []);

    const handlePendingTask = (taskData) => {

        navigate('/tasklist');
    }

    return {
        serviceOptions,
        serviceSubOptions,
        financialYearOptions,
        statusOptions,
        taskOverview,
        sections,
        checkwiseGroupReport,
        loading,
        handlePendingTask,
        servicesLoading,
        servicesError,
        serviceName,
        setServiceName,
        subServiceName,
        setSubServiceName,
        subServicesLoading,
        subServicesError,
        handleSubServices,
        handleData,
        overViewLoading,
        overViewReportError,
        taskStatus,
        taskStatusLoading,
        taskStatusError,
        dateStatus,
        dateStatusLoading,
        dateStatusError,
        checkwiseStatus,
        checkwiseStatusLoading,
        checkwiseStatusError,
        scheduleInfoList,
        scheduleLoading,
        scheduleResError,
        scheduleInfoListLoading,
        checkwiseStatusList
    };
};

export default GstServiceController;
