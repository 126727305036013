import React, { useState, useRef, useEffect } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import eyeIcon from '../../../../Assets/Icons/Eye-2.png'
import eyeCloseIcon from '../../../../Assets/Icons/Eye off.png'
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddUserIdPasswordModal from '../../../../Components/Modals/AddUserIdPasswordModal';
import UserIdPasswordController from '../../../Controllers/AdminScreens/Clients/UserIdPasswordController';
import Loader from '../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';

const UserIdPasswordScreen = ({ clientId, userCredentials, userCredentialLoading, userCredentailError, onRefresh, setUserCredentials }) => {

  const {
    deleteUserIdLoading,
    deleteUserIdError,
    deleteUserIdSucess,
    isModalOpen,
    togglePasswordVisibility,
    handleAddCredientailsClick,
    handleCloseModal,
    handleEditUserCredential,
    onAddUpdateSuccess,
    credentialInfoRef,
    handleCloseDeleteModal,
    isDeleteModalOpen,
    handleDelete,
    callDeleteUserId,
  } = UserIdPasswordController(onRefresh, setUserCredentials);


  return (
    <div className="tasksScreen-task-table-wrapper">
      <h3 className='basic-info-header'>User Names</h3>
      <div className="basic-info-outer-card">
        <table
          className="tasksScreen-task-table">
          <thead>
            <tr>
              <th style={{ width: "25%" }}>Portal Name</th>
              <th style={{ width: "20%" }}>Username</th>
              <th style={{ width: "15%" }}>Password</th>
              <th style={{ width: "27%" }}>URL</th>
              <th
                style={{
                  width: "15%",
                  cursor: 'pointer'
                }}
                onClick={handleAddCredientailsClick}
              >
                <span style={{ fontSize: '12px' }}>+</span> New Credential
              </th>
            </tr>
          </thead>
          <tbody style={{ backgroundColor: 'white' }}>
            {userCredentials?.map((credential, index) => (
              <tr
                key={index}
                style={{
                  borderBottom: "3px solid #f8f9fa",
                }}
              >
                <td style={styles.tableValue}>{credential.portalName}</td>
                <td style={styles.tableValue}>{credential.username}</td>
                <td style={styles.tableValue}>
                  {credential.showPassword ? credential.password : "*********"}{" "}
                  <button
                    style={{
                      background: "none",
                      border: "none",
                      cursor: "pointer",
                      marginLeft: "5px",
                    }}
                    onClick={() => togglePasswordVisibility(index)}
                  >
                    <img
                      src={credential.showPassword ? eyeCloseIcon : eyeIcon}
                      alt="Toggle Visibility"
                      style={{ width: "16px", height: "16px" }}
                    />
                  </button>
                </td>
                <td style={styles.tableLink}>
                  {/* <a href={credential?.url} target='_blank'> */}
                  <div style={{ textDecoration: 'none' }}>
                    <a
                      href={
                        credential?.url.startsWith('http://') || credential?.url.startsWith('https://')
                          ? credential?.url
                          : `http://${credential?.url}`
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      style={{ textDecoration: 'underline', }}
                    >
                      {credential?.url} <img src={forwordIcon} />
                    </a>
                    <div style={{ textAlign: 'end' }}>
                      {deleteUserIdSucess[credential?.account_id] &&
                        <div style={{ color: 'green', textDecoration: 'none' }} >{deleteUserIdSucess[credential?.account_id]}</div>}
                      {deleteUserIdError[credential?.account_id] &&
                        <div style={{ color: 'red', textDecoration: 'none' }} >{deleteUserIdError[credential?.account_id]}</div>}
                    </div>
                  </div>
                </td>
                <td style={{ display: 'flex', justifyContent: 'end' }}
                >
                  <div style={{ display: "flex", gap: "2px" }}>
                    <button className="tasksScreen-edit-button" onClick={() => { handleEditUserCredential(credential) }}>
                      <img
                        src={editIcon}
                        alt="Edit"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                    <button className="tasksScreen-delete-button" style={{ opacity: deleteUserIdLoading[credential?.account_id] ? 0.3 : 1 }}
                      onClick={() => { handleDelete(credential) }} disabled={deleteUserIdLoading[credential?.account_id]}>
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        style={{ width: "16px", height: "16px" }}
                      />
                    </button>
                    {deleteUserIdLoading[credential?.account_id] &&
                      <span><Loader loading={deleteUserIdLoading[credential?.account_id]} color={'var(--primary-color)'} size='small' /></span>
                    }
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
        </table>
        {userCredentialLoading && <Loader loading={userCredentialLoading} color={'var(--primary-color)'} />}
        {userCredentailError && <div className="list-error-msg">{userCredentailError} </div>}
      </div>
      <AddUserIdPasswordModal isOpen={isModalOpen} onClose={handleCloseModal} clientId={clientId} editData={credentialInfoRef.current} onValueChange={onAddUpdateSuccess} />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        message={`Are you sure you want to delete the credential for ${credentialInfoRef.current?.portalName}?`}
        onClose={handleCloseDeleteModal}
        onConfirm={() => {
          handleCloseDeleteModal(); // Close modal
          callDeleteUserId(credentialInfoRef.current); // Perform delete operation
        }}
      />
    </div>
  );

};

const styles = {
  header: {
    fontSize: "18px",
    color: "#609966",
    marginBottom: "10px",
    fontWeight: 500,
    fontFamily: 'Roboto Slab'
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    color: '#5A55D2',
  }
};

export default UserIdPasswordScreen;