
import React,{useState} from "react";
import { getData, postData } from "../../../../../ApiServices/ApiService";
import {ORG_NOTICE_BOARD_MSG_DELETE } from '../../../../../ApiServices/BaseURL';

const NoticeBoardActiveMsgListController = (noticeBoardMessagesList, noticeBoardMessagesError, noticeBoardMessagesLoading, onRefresh)=>{
    const [currentPage, setCurrentPage] = useState(1);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
    const [selectedDocument, setSelectedDocument] = useState(null);
      const itemsPerPage = 10;

    const totalPages = Math.ceil(noticeBoardMessagesList?.length / itemsPerPage);
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentNoticeMsg = noticeBoardMessagesList?.slice(indexOfFirstItem, indexOfLastItem);

  const handlePageChange = (pageNumber) => {
    if (pageNumber > 0 && pageNumber <= totalPages) {
      setCurrentPage(pageNumber);
    }
  };

  const handleDelete = (noticeMsg, index) => {
    console.log("Notice msg",noticeMsg )
    setSelectedDocument({ noticeMsg, index });
    setIsDeleteModalOpen(true);
  };

  const handleOpenDeleteModal = () => {
    setIsDeleteModalOpen(true); // Open the modal
  };

  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false); // Close the modal
  };

  const [loading,setLoading] = useState(false);
  const [noticeBoardMsgDeleteError, setNoticeBoardMsgDeleteError] = useState('');
  const [success, setSuccess] = useState('');
  const [currentNoticeDeleteIndex, setCurrentNoticeDeleteIndex] = useState(null);
  const [deletedMessages, setDeletedMessages] = useState([]);

  const handleNoticeBoardMessageDelete = async () => {
   
    try {
      const { noticeMsg, index } = selectedDocument;
      setCurrentNoticeDeleteIndex(null)
        setNoticeBoardMsgDeleteError('');  
        setSuccess('')      
        setLoading(true);
        setCurrentNoticeDeleteIndex(index)
        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad = {  emp_id_auth: storedUserInfo?.emp_id, nb_id:noticeMsg.nb_id}
    
        //console.log('handleSearchEmployee payload',payLoad);
        // Make the API call
        const response = await postData(ORG_NOTICE_BOARD_MSG_DELETE,payLoad);
        const responseData = await response.json();
        //console.log('response Data',responseData);
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              setNoticeBoardMsgDeleteError('');
              setDeletedMessages((prevDeleted) => [...prevDeleted, noticeMsg.nb_id]);
                setSuccess(responseData?.info);
                setTimeout(()=>{
                  setSuccess('');
                  onRefresh();
                },3000);
                // setEmployeeList(responseData?.dataJ || []);
            } else {
              setNoticeBoardMsgDeleteError(responseData?.info || 'Failed to delete notice board message. Please try again.');
            }
          } else {
            setNoticeBoardMsgDeleteError(responseData?.info || 'Failed to delete notice board message. Status code error.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setNoticeBoardMsgDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setNoticeBoardMsgDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setNoticeBoardMsgDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setNoticeBoardMsgDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setNoticeBoardMsgDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete notice board message. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setNoticeBoardMsgDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setNoticeBoardMsgDeleteError(`Failed to delete notice board message. Please check your network connection and try again.`);
        }
      } finally {
        setLoading(false);
        // setCurrentNoticeDeleteIndex(null)
      }

    // onValueChange(teamData);
    // onClose();
};

  return{
    handlePageChange,
    currentNoticeMsg,
    currentPage,
    totalPages,

    loading,
    success,
    handleCloseDeleteModal,
    handleOpenDeleteModal,
    handleDelete,
    isDeleteModalOpen,
    selectedDocument,
    setSelectedDocument,
    noticeBoardMsgDeleteError,
    handleNoticeBoardMessageDelete,
    currentNoticeDeleteIndex,
    deletedMessages
  }

}

export default NoticeBoardActiveMsgListController