import React, { useState, useRef, useEffect } from 'react';
import UserAssignmentsController from '../../../Controllers/AdminScreens/Users/UserAssignmentsController.js';
import Loader from '../../../../Components/Loader.js';

const UserAssignmentsScreen = ({assignmentList,assignmentError,assignmentsLoading}) => {

    const { userAssignmentsList,
        handleAddClients
    } = UserAssignmentsController();

    return (
        <div className='basic-info-container'>
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <h3 className='basic-info-header'>Assignments</h3>
                {/* <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleAddClients}>
                    Add Clients
                </button> */}
            </div>

            <div className="tasksScreen-task-table-wrapper" style={{ paddingTop: 10 }}>
                <table className='tasksScreen-task-table'>
                    <thead>
                        <tr>
                            <th>Clients</th>
                            <th>Group Name</th>
                            <th>Group Master</th>
                            <th>Tasks</th>
                            <th>Manager</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: 'white' }}>
                        {assignmentList?.map((assignment, index) => (
                            <tr
                                key={index}
                                className="tasksScreen-main-task-row"
                                style={{ borderBottom: "1px solid #dee2e6" }}
                            >
                                <td style={{ ...styles.tableLink, width: '20%' }} >{assignment?.client_name || '--'}</td>
                                <td style={{ width: '25%' }}>{assignment?.group_name || '--'}</td>
                                <td style={{ width: '25%' }}>{assignment?.client_id_primary_name || '--'}</td>
                                <td style={{ ...styles.tableLink, width: '15%' }}>{assignment?.task_count || '--'}</td>
                                <td style={{ width: '20%' }}>{assignment?.emp_id_incharge_name || '--'}</td>

                            </tr>
                        ))}

                    </tbody>

                </table>
                {assignmentError && <div className="list-error-msg">{assignmentError} </div>}
                {assignmentsLoading && <Loader loading={assignmentsLoading} color={'var(--primary-color)'} />}
            </div>
        </div>
    )

};

const styles = {
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },
}

export default UserAssignmentsScreen;