
import React, { useState, useRef, useEffect } from 'react';
import { postData, getData } from '../../../../ApiServices/ApiService';
import {
    ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_GET_GROUP_LIST, ORG_GET_ESTIMATE_TASK_LIST, ORG_GET_GROUP_ESTIMATE_TASK_LIST,
    ORG_GET_SUB_SERVICE_LIST_SEARCH, ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_EMPLOYEE_LIST, ORG_CREATE_ESTIMATE
} from '../../../../ApiServices/BaseURL';
import { useLocation, useNavigate } from "react-router-dom";

const CreateEditEstimateController = () => {
    const location = useLocation();
    const rowData = location?.state;
    //console.log("rowData", rowData);
    const [selectAll, setSelectAll] = useState(false);
    const [checkedStates, setCheckedStates] = useState([]);
    const [selectedTaskIds, setSelectedTaskIds] = useState([]);

    const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
    const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
    const [estimateWithoutTask, setEstimateWithoutTask] = useState(false);


    const [searchUser, setSearchUser] = useState('');
    const [clientList, setClientList] = useState([]);
    const [selectedClient, setSelectedClient] = useState(null);
    const [selectedClientError, setSelectedClientError] = useState('');
    const [loading, setLoading] = useState(false);

    const [groupClientOptionsList, setGroupClientOptionsList] = useState([]);
    const [groupLoading, setGroupLoading] = useState(false);
    const [groupClientError, setGroupClientError] = useState('');
    const [groupClient, setGroupClient] = useState(null);

    const [taskListLoading, setTaskListLoading] = useState(false);
    const [taskList, setTaskList] = useState([]);
    const [taskListError, setTaskListError] = useState('');
    const [phoneNo, setPhoneNo] = useState('');
    const [dateOfEstimate, setDateOfEstimate] = useState('');
    const [dateError, setDateError] = useState('')

    const [employeeList, setEmployeeList] = useState([]);
    const [empLoading, setEmpLoading] = useState(false);
    const [empError, setEmpError] = useState('');

    const [submitLoading, setSubmitLoading] = useState(false);
    const [submitError, setSubmitError] = useState('');
    const [submitSuccess, setSubmitSuccess] = useState('');

    const [estimateAmtError, setEstimateAmtError] = useState('')
    const [billAmtError, setBillAmtError] = useState('')
    const [serviceSelectError, setServiceSelectError] = useState('')

    const [groupTaskListLoading, setGroupTaskListLoading] = useState(false);
    const [groupTaskList, setGroupTaskList] = useState([]);
    const [groupTaskListError, setGroupTaskListError] = useState('');
    const [isShowGroupWise, setIsShowGroupWise] = useState(false);

    const [expandedClients, setExpandedClients] = useState({});

    const [rows, setRows] = useState([
        { id: 1, serviceCategory: null, serviceType: [] }, // Default row
    ]);

    const [serviceOptions, setServiceOptions] = useState([]); // Service list
    const [serviceError, setServiceError] = useState('');
    const [serviceLoading, setServiceLoading] = useState(false);
    const [serviceCategoryOptions, setServiceCategoryOptions] = useState({}); // Map of serviceId -> serviceType options
    const [serviceCategoryError, setServiceCategoryError] = useState({});
    const [serviceCategoryLoading, setServiceCategoryLoading] = useState({});


    const [estimateOptions, setEstimateOptions] = useState({
        groupEstimate: null,
        noOfClients: "",
        systemBillingAmount: 0,
        estimateAmount: 0,
        generatedBy: null,
    });

    const [selectedClientsTasks, setSelectedClientsTasks] = useState({}); // { client_id: [task_ids] }

    // Handle Task Checkbox Selection
    const handleTaskCheckboxChange = (clientId, taskId, taskEstimatedAmount, isChecked) => {
        setSelectedClientsTasks((prev) => {
            const prevSelected = prev[clientId] || [];
            return {
                ...prev,
                [clientId]: isChecked ? [...prevSelected, taskId] : prevSelected.filter((id) => id !== taskId),
            };
        });

        //  Update Estimate Amount
        setEstimateOptions((prev) => ({
            ...prev,
            estimateAmount: isChecked
                ? prev.estimateAmount + Number(taskEstimatedAmount) // Add if checked
                : prev.estimateAmount - Number(taskEstimatedAmount) // Subtract if unchecked
        }));
    };

    //  Handle Select All for Client
    const handleClientSelectAll = (clientId, isChecked) => {
        setSelectedClientsTasks((prev) => {
            const allTaskIds = groupTaskList
                .find((client) => client.client_id === clientId)
                ?.task_list.map((task) => task.task_id) || [];

            return { ...prev, [clientId]: isChecked ? allTaskIds : [] };
        });
    };
    // Toggle Client Expansion
    const toggleClient = (clientId) => {
        setExpandedClients((prev) => ({ ...prev, [clientId]: !prev[clientId] }));
    };

    const handleBasicInfoChange = (field, value) => {
        setDateOfEstimate(value)
    };

    const navigate = useNavigate()
    const handleBack = () => {
        navigate(-1); // Navigate back to the previous page
    };


    const getStatusStyle = (status) => {
        switch (status) {
            case "Not Started":
                return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
            case "Priority":
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
            case "In Progress":
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
            default:
                return {};
        }
    };

    const handleSelectAll = (isChecked) => {
        setSelectAll(isChecked);
        setCheckedStates(taskList.map(() => isChecked));
        if (isChecked) {
            // Add all task IDs to the selectedTaskIds list
            setSelectedTaskIds(taskList.map((task) => task.task_id));
        } else {
            // Clear the selectedTaskIds list
            setSelectedTaskIds([]);
        }
    };

    const handleCheckboxChange = (index, isChecked) => {
        const updatedStates = [...checkedStates];
        updatedStates[index] = isChecked;
        setCheckedStates(updatedStates);

        const taskId = taskList[index]?.task_id;
        const taskBillAmountEstimated = taskList[index]?.task_bill_amount_estimated || 0;

        if (isChecked) {
            // Add the task ID to the selectedTaskIds list
            setSelectedTaskIds((prev) => [...prev, taskId]);

            // Update the estimate options by adding selected amounts
            setEstimateOptions((prev) => ({
                ...prev,
                estimateAmount: prev.estimateAmount + Number(taskBillAmountEstimated),
            }));
        } else {
            // Remove the task ID from the selectedTaskIds list
            setSelectedTaskIds((prev) => prev.filter((id) => id !== taskId));

            // Update the estimate options by subtracting unchecked amounts
            setEstimateOptions((prev) => ({
                ...prev,
                estimateAmount: prev.estimateAmount - Number(taskBillAmountEstimated),
            }));
        }
    };


    useEffect(() => {
        fetchGroupList();
        fetchServiceList();
        fetchUserList();
    }, [])

    const handleEstimateOptionsChange = (field, value) => {
        setEstimateOptions((prevOptions) => ({
            ...prevOptions,
            [field]: value,
        }));
    };

    const handleSearchClient = async () => {
        try {
            setSelectedClientError('');
            setLoading(true);
            setClientList([]);
            if (!searchUser) {
                setSelectedClientError('Please enter client name'); return;
            }
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

            //console.log('team Data', payLoad);
            // Make the API call
            const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
            const responseData = await response.json();
            //console.log('response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setClientList(responseData?.dataJ || []);
                    } else {
                        setSelectedClientError(responseData?.info || 'Failed to fetch Client. Please try again.');
                    }
                } else {
                    setSelectedClientError(responseData?.info || 'Failed to fetch Client.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setSelectedClientError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setSelectedClientError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setSelectedClientError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setSelectedClientError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            setSelectedClientError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);

        } finally {
            setLoading(false);
        }
    };


    const handleSearchInputText = (value) => {
        //console.log('handleSearchInputText value : ',value);
        setSearchUser(value);
        setSelectedClientError('');
        setClientList([]);
        if (!value) {
            setSelectedClient(null);
            setTaskList([]);
            setSelectedTaskIds([]);
            setCheckedStates([]);
            setSelectAll(false);
            setEstimateOptions((prev) => ({
                ...prev,
                estimateAmount: 0,
            }));
            setEstimateWithoutTask(false)
        }

    }

    const handleSelectClient = (client) => {
        setSelectedClient(client); // Set selected client data
        setSearchUser(client?.client_name); // Update TextInput with the selected client's name
        fetchTaskList(client?.client_id);
        const phoneNo = client?.client_mobile_code + ' ' + client?.client_mobile_no
        setPhoneNo(phoneNo)
        setClientList([]); // Clear the dropdown
        setSelectedClientError('');
    };

    const fetchGroupList = async () => {
        try {
            setGroupClientError('')
            setGroupLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // Transforming the data
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.group_name,
                                value: service?.group_id,
                                group_clients_count: service?.group_clients_count
                            }));
                            setGroupClientOptionsList(filterData)
                        } else {
                            setGroupClientError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupClientError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupClientError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupClientError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupClientError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupClientError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupClientError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupClientError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupClientError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupClientError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setGroupLoading(false);
        }
    }

    const fetchTaskList = async (clientId) => {
        try {
            setTaskListError('');
            setTaskList([]);
            setSelectedTaskIds([]);
            setCheckedStates([]);
            setSelectAll(false);
            setTaskListLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_ESTIMATE_TASK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&client_id=${clientId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {

                            setTaskList(responseData?.dataJ)
                        } else {
                            setTaskListError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setTaskListError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setTaskListError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setTaskListError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setTaskListError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setTaskListError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setTaskListError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setTaskListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setTaskListError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setTaskListError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setTaskListLoading(false);
        }
    }




    // Function to add a new service row
    const addServiceRow = () => {
        setRows([...rows, { id: Date.now(), serviceCategory: null, serviceType: [] }]);
    };

    // Function to remove a service row
    const removeServiceRow = (id) => {
        setRows(rows.filter((row) => row.id !== id));
    };




    const fetchServiceList = async () => {
        try {
            setServiceError('');
            setServiceLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id
            }

            //console.log('fetchTaskCategorieList payload', payLoad);

            // Make the API call
            const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payLoad);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // Transforming the data
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service.service_name,
                                value: service.service_id
                            }));
                            setServiceOptions(filterData)
                        } else {
                            setServiceError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setServiceError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setServiceError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setServiceError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setServiceError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setServiceError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setServiceError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setServiceError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setServiceError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setServiceError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setServiceLoading(false);
        }
    }

    const fetchServiceCategoryList = async (serviceId, rowId) => {
        try {
            setServiceCategoryError({});
            setServiceCategoryLoading((prev) => ({ ...prev, [rowId]: true }));
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "service_id": serviceId?.value || 0
            }

            //console.log('--fetchTaskSubCategorieList payload--', payLoad);

            // Make the API call service_id=10006&
            const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH, payLoad);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            // Transforming the data
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service.service_sub_name,
                                value: service.service_sub_id
                            }));
                            setServiceCategoryOptions((prev) => ({ ...prev, [rowId]: filterData })); // Store per row
                        } else {
                            setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Data Not Found' }));
                        }
                    } else {
                        setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Failed to fetch inventory data. Please try again.' }));
                    }
                } else {
                    setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Failed to fetch Task Categorie data.' }));
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setServiceCategoryError((prev) => ({ ...prev, [rowId]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setServiceCategoryError((prev) => ({ ...prev, [rowId]: responseData?.info || 'Internal Server Error. Please try again later.' }));
            }
            // Unexpected or network-related errors
            else {
                setServiceCategoryError((prev) => ({ ...prev, [rowId]: 'Unexpected error occurred. Please try again later.' }));
            }
        } catch (error) {

            setServiceCategoryError((prev) => ({ ...prev, [rowId]: 'Failed to fetch Task Categorie data. Please check your network connection and try again.' }));

        } finally {
            setServiceCategoryLoading((prev) => ({ ...prev, [rowId]: false }));
        }
    }

    const fetchUserList = async () => {
        try {
            setEmpLoading(true);
            setEmpError('');
            setEmployeeList([]);


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            const filterData = responseData?.dataJ.map(service => ({
                                label: service?.emp_name,
                                value: service?.emp_id
                            }));
                            setEmployeeList(filterData);
                        } else {
                            setEmpError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setEmpError(responseData?.info || 'Failed to fetch User data. Please try again.');
                    }
                } else {
                    setEmpError(responseData?.info || 'Failed to fetch User data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setEmpError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setEmpError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setEmpError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setEmpError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setEmpError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setEmpError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setEmpError(`Failed to fetch User data. Please check your network connection and try again.`);
            }
        } finally {
            setEmpLoading(false);
        }
    }

    const handleSubmitEstimate = async () => {
        try {
            setSubmitError('');
            setGroupClientError('');
            setSelectedClientError('');
            setBillAmtError('');
            setEstimateAmtError('');
            setTaskListError('');
            setServiceSelectError('');

            //  Extract all Clents task IDs into a single list
            const allTaskIds = Object.values(selectedClientsTasks).flat();

            const servicesListSelected = rows
                .filter(item => item.serviceCategory?.value) // Filter out items without serviceCategory value
                .map(item => ({
                    service_id: item.serviceCategory.value, // Use serviceCategory value as service_id
                    service_sub_list: item.serviceType ? item.serviceType.map(type => type.value) : [] // Map serviceType values
                }));

            if (isShowGroupWise) {
                if (!groupClient?.value) {
                    setGroupClientError('Select group name.'); return
                }
                if (estimateWithoutTask) {
                    if (allTaskIds.length <= 0) {
                        setTaskListError('Please select at least one task.'); return
                    }

                } else {
                    if (servicesListSelected.length <= 0) {
                        setServiceSelectError('Please select requested services'); return
                    }
                }
            } else {
                if (!selectedClient?.client_id) {
                    setSelectedClientError('Enter client name, search, and select.'); return
                }
                if (estimateWithoutTask) {
                    if (selectedTaskIds.length <= 0) {
                        setTaskListError('Please select at least one task.'); return
                    }

                } else {
                    if (servicesListSelected.length <= 0) {
                        setServiceSelectError('Please select requested services'); return
                    }
                }
            }

            if (!dateOfEstimate) {
                setDateError('Please select date'); return
            }

            if (!estimateOptions?.systemBillingAmount) {
                setBillAmtError('Please enter bill amount'); return
            }
            if (!estimateOptions?.estimateAmount) {
                setEstimateAmtError('Please enter estimate amount'); return
            }

            setSubmitLoading(true)
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "client_id": selectedClient?.client_id || 0,
                "estimate_date": dateOfEstimate.replace('T', ' ') + ':00',
                "group_id": groupClient?.value || 0,
                "estimate_gen_emp_id": estimateOptions?.generatedBy?.value || 0,
                "is_group_estimate": estimateOptions?.groupEstimate?.value || 0,
                // "is_task_estimate": estimateWithoutTask ? 1 : 0,
                "task_bill_amount_m": estimateOptions?.systemBillingAmount || 0,
                "task_bill_amount_estimated_m": estimateOptions?.estimateAmount || 0,
                "send_update_client": sendUpdateToClient ? 1 : 0,
                "send_update_team": sendUpdateToTeam ? 1 : 0,
                "tasks_list_selected": !estimateWithoutTask ? [] : isShowGroupWise ? allTaskIds : selectedTaskIds,
                "services_list_selected": !estimateWithoutTask ? servicesListSelected : [],
                "estimate_for": isShowGroupWise ? 2 : 1,
                "estimate_type": estimateWithoutTask ? 2 : 1
            }

            console.log('payload:--', payLoad);
            // Make the API call service_id=10006&
            const response = await postData(ORG_CREATE_ESTIMATE, payLoad);
            const responseData = await response.json();
            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setSubmitSuccess(responseData?.info || '');
                        setTimeout(() => {
                            setSubmitSuccess('');
                            navigate('/bills-receipts');
                        }, 3000);
                    } else {
                        setSubmitError(responseData?.info || 'Failed to create estimate. Please try again.');
                    }
                } else {
                    setSubmitError(responseData?.info || 'Failed to create estimate.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setSubmitError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setSubmitError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setSubmitError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setSubmitError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            setEmpError('No response received from the server. Please check your network connection and try again.');
        } finally {
            setSubmitLoading(false)
        }


    }

    const showGroupEstimateList = () => {
        setGroupClientError('');
        if (groupClient?.value) {
            fetchGroupClientTaskList(groupClient?.value);
        } else {
            setGroupClientError('Select group')
        }
    }

    const fetchGroupClientTaskList = async (groupId) => {
        try {
            setIsShowGroupWise(true)
            setGroupTaskListError('');
            setGroupTaskList([]);
            setGroupTaskListLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

            // Make the API call
            const response = await getData(`${ORG_GET_GROUP_ESTIMATE_TASK_LIST}?emp_id_auth=${storedUserInfo?.emp_id}&group_id=${groupId}`);
            const responseData = await response.json();

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                            setGroupTaskList(responseData?.dataJ);
                            setEstimateWithoutTask(true)
                        } else {
                            setGroupTaskListError(responseData?.info || 'Data Not Found');
                        }
                    } else {
                        setGroupTaskListError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                    }
                } else {
                    setGroupTaskListError(responseData?.info || 'Failed to fetch Task Categorie data.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setGroupTaskListError('Unauthorized access. Your session may have expired. Please log in again.');
                    // Optionally, redirect to the login page or refresh the token
                    // Example: redirectToLogin();
                } else {
                    setGroupTaskListError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setGroupTaskListError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            // Unexpected or network-related errors
            else {
                setGroupTaskListError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                // Server responded with a status outside the 2xx range
                setGroupTaskListError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
                // Request was made but no response was received
                setGroupTaskListError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setGroupTaskListError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
        } finally {
            setGroupTaskListLoading(false);
        }
    }

    const handleSetGroupClient = (value) => {
        setGroupClient(value);
        setGroupClientError('')
    }

    const handleSelectServiceOrTask = (value) => {
        if (isShowGroupWise) {
            if (value) {
                if (groupTaskList?.length > 0) {
                    setEstimateWithoutTask(true)
                } else {
                    setGroupClientError('Select group and click on group estimate.');
                    setEstimateWithoutTask(false)
                }
            } else {
                setEstimateWithoutTask(false)
            }
        } else {
            if (value) {
                if (selectedClient?.client_id !== undefined) {
                    setEstimateWithoutTask(true)
                } else {
                    setSelectedClientError('Enter client name, search, and select.');
                    setEstimateWithoutTask(false)
                }
            } else {
                setEstimateWithoutTask(false)
            }
        }


    }

    const handleSelectGroupOrClient = (value) => {
        setIsShowGroupWise(value);
        clearAllFileds()

    }

    const clearAllFileds = () => {
        setSelectAll(false);
        setCheckedStates([]);
        setSelectedTaskIds([]);
        setSendUpdateToClient(false);
        setSendUpdateToTeam(false);
        setEstimateWithoutTask(false);
        setSearchUser('');
        setClientList([]);
        setSelectedClient(null);
        setSelectedClientError('');
        setLoading(false);
        setGroupLoading(false);
        setGroupClientError('');
        setGroupClient(null);
        setTaskListLoading(false);
        setTaskList([]);
        setTaskListError('');
        setPhoneNo('');
        setDateOfEstimate('');
        setDateError('');
        setEmpLoading(false);
        setEmpError('');
        setSubmitLoading(false);
        setSubmitError('');
        setSubmitSuccess('');
        setEstimateAmtError('');
        setBillAmtError('');
        setServiceSelectError('');
        setGroupTaskListLoading(false);
        setGroupTaskListError('');
        setExpandedClients({});
        setRows([{ id: 1, serviceCategory: null, serviceType: [] }]);
        setServiceError('');
        setServiceLoading(false);
        setServiceCategoryOptions({});
        setServiceCategoryError({});
        setServiceCategoryLoading({});
        setEstimateOptions({
            groupEstimate: null,
            noOfClients: "",
            systemBillingAmount: 0,
            estimateAmount: 0,
            generatedBy: null,
        });
        setSelectedClientsTasks({});
        setGroupTaskList([]);

    }


    return {
        handleBasicInfoChange,
        estimateOptions,
        handleEstimateOptionsChange,
        sendUpdateToClient,
        setSendUpdateToClient,
        sendUpdateToTeam,
        setSendUpdateToTeam,
        estimateWithoutTask,
        setEstimateWithoutTask,
        handleSelectClient,
        handleSearchInputText,
        handleSearchClient,
        searchUser,
        selectedClientError,
        clientList,
        loading,
        groupClientOptionsList,
        groupLoading,
        groupClientError,
        groupClient, setGroupClient,
        taskList, taskListError, taskListLoading,
        addServiceRow, removeServiceRow, rows, setRows,
        serviceCategoryOptions, serviceOptions,
        serviceCategoryError, serviceCategoryLoading,
        serviceError, serviceLoading, fetchServiceCategoryList,
        phoneNo, dateOfEstimate, handleSubmitEstimate,
        rowData, selectAll, checkedStates, handleBack, getStatusStyle, handleSelectAll,
        handleCheckboxChange, employeeList, empLoading, empError, dateError,
        submitLoading, submitError, submitSuccess, estimateAmtError, billAmtError, serviceSelectError,
        showGroupEstimateList, groupTaskListLoading, groupTaskList, groupTaskListError, isShowGroupWise,
        expandedClients, toggleClient, handleSetGroupClient, handleSelectGroupOrClient, handleSelectServiceOrTask,
        handleClientSelectAll, handleTaskCheckboxChange, selectedClientsTasks
    }

}

export default CreateEditEstimateController