import React, { useState, useRef, useEffect} from "react";
import Input from "../../../../Components/Input"; // Import Input Component
import Dropdown from "../../../../Components/Dropdown"; // Import Dropdown Component
import CheckBox from "../../../../Components/CheckBox";
import MobileInput from "../../../../Components/MobileInput";
import closeIcon from '../../../../Assets/Icons/X circle.png'
import DateInput from '../../../../Components/DateInput';
import backIcon from '../../../../Assets/Icons/back.png';
import search from '../../../../Assets/Icons/search.png';
import TextInput from '../../../../Components/TextInput';
import { useNavigate } from "react-router-dom";
import GenerateEditInvoiceController from "../../../Controllers/AdminScreens/Invoices/GenerateEditInvoiceController";

const GenerateEditInvoiceScreen = () => {

  const {
    setBasicInformation,
    basicInformation,
    handleBasicInformationChange,
    invoiceOptions,
    setInvoiceOptions,
    handleInvoiceOptionsChange,
    sendUpdateToClient,
    setSendUpdateToClient,
    sendUpdateToTeam,
    setSendUpdateToTeam,
    invoiceWithoutTask,
    setInvoiceWithoutTask,
    organizationDetails,
    setOrganizationDetails,
    handleOrganizationDetailsChange
  } = GenerateEditInvoiceController();

  const navigate = useNavigate()
  const handleBack = () => {
    navigate(-1); // Navigate back to the previous page
  };


  const [serviceCategoryRow1, setServiceCategoryRow1] = useState("");
  const [serviceTypeRow1, setServiceTypeRow1] = useState("");

  const [serviceCategoryRow2, setServiceCategoryRow2] = useState("");
  const [serviceTypeRow2, setServiceTypeRow2] = useState("");

  const [serviceCategoryRow3, setServiceCategoryRow3] = useState("");
  const [serviceTypeRow3, setServiceTypeRow3] = useState("");

  const [organization, setOrganization] = useState("");
  const [receiptBook, setReceiptBook] = useState("");
  const [followUpDate, setFollowUpDate] = useState("");

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  return (
    <>
      <div style={{ width: '93%', margin: 'auto' }}>
        <h3 style={styles.header}>
          <div style={styles.headerContainer} >
            <img
              src={backIcon}
              alt="Back"
              style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
              onClick={handleBack}
            />
            <span>Generate Invoice</span>
          </div>
        </h3>
      </div>

      <div style={styles.container}>
        <h2 style={styles.title}>Generate Invoice</h2>

        {/* Basic Information */}
        <div style={styles.section}>
          <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Basic Information</h3>
          <div style={styles.row}>
            {/* <Input label="Client Name" placeholder="Client Name" value={clientName}
            onChange={(e) => setClientName(e.target.value)} /> */}
            <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
              <label style={{ ...styles.labaleStyle, marginBottom: "8px", display: "block" }}>
                Client Name
              </label>
              <TextInput
                type="text"
                placeholder="Search"
                value={basicInformation?.clientName}
                onChange={(e) => handleBasicInformationChange("clientName", e.target.value)}
                right={<img src={search} alt="Search" className="search-icon" />}
                style={{ padding: "20px 10px" }} // Add padding inside the TextInput
              />
            </div>
            <Dropdown
            ref={(el) => (dropdownRefs.current["Group Name"] = el)}
              isOpen={openDropdown === "Group Name"}
              toggleDropdown={() => toggleDropdown("Group Name")} label="Group Name" options={["Option 1", "Option 2"]} value={basicInformation?.groupName}
              onChange={(e) => handleBasicInformationChange("groupName", e.target.value)} />
            {/* <Input label="Phone No." placeholder="Barchasi" value={basicInformation?.phoneNo}
              onChange={(e) => handleBasicInformationChange("phoneNo", e.target.value)} /> */}

            <MobileInput
              label="Phone No."
              countryCode={basicInformation?.countryCode}
              mobileNumber={basicInformation?.phoneNo}
              onMobileChange={(value) => handleBasicInformationChange('phoneNo', value)}
              onCountryChange={(value) => handleBasicInformationChange('countryCode', value)}
              placeholder="Enter mobile number"
              countries={[
                { code: "IN", dialCode: "+91" },
                { code: "US", dialCode: "+1" },
                { code: "UK", dialCode: "+44" },
              ]}
              width="100%"
            />
            <div>
              {/* <Input label="Date of Invoicing" placeholder="11/11/2024" value={dateOfEstimate}
            onChange={(e) => setDateOfEstimate(e.target.value)} /> */}
              <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
                <label style={{ ...styles.labaleStyle, marginBottom: "8px", display: "block" }}>
                  Date of Invoicing
                </label>
                <DateInput
                  value={basicInformation?.dateOfEstimate}
                  onChange={(e) => handleBasicInformationChange("dateOfEstimate", e.target.value)}
                  style={{ padding: "20px 10px" }}
                />
              </div>
            </div>
          </div>
        </div>

        {/* Requested Services */}
        <div style={styles.mainContainer}>
          <div >
            <div style={{ display: 'flex', gap: '10px', marginBottom: '12px' }}>
              <h3 style={styles.sectionTitle}>Requested Services</h3>
              <div style={styles.checkboxContainer}>
                {/* <input type="checkbox" id="invoiceWithoutTask" checked={invoiceWithoutTask}
                  onChange={(e) => setInvoiceWithoutTask(e.target.checked)} />
                <label htmlFor="invoiceWithoutTask" style={styles.checkboxLabel}>
                  Invoice without Task
                </label> */}
                <CheckBox
                  label="Invoice without Task"
                  checked={invoiceWithoutTask}
                  onChange={(isChecked) => setInvoiceWithoutTask(isChecked)}
                />
              </div>
            </div>
            <div style={{ ...styles.servicesContainer, paddingRight: '28px', paddingBottom: '146px' }}>
              {/* Row 1 */}
              <div style={styles.serviceRow}>
                <Dropdown
            ref={(el) => (dropdownRefs.current["Service Category"] = el)}
                  isOpen={openDropdown === "Service Category"}
                  toggleDropdown={() => toggleDropdown("Service Category")} label="Service Category" options={["GST", "Income Tax", "Audit"]} value={serviceCategoryRow1}
                  onChange={(value) => setServiceCategoryRow1(value)} />
                <Dropdown
            ref={(el) => (dropdownRefs.current["Service Type"] = el)}
                  isOpen={openDropdown === "Service Type"}
                  toggleDropdown={() => toggleDropdown("Service Type")} label="Service Type" options={["Option 1", "Option 2"]} value={serviceTypeRow1}
                  onChange={(value) => setServiceTypeRow1(value)} />
              </div>

              {/* Row 2 */}
              <div style={styles.serviceRow}>
                <Dropdown
            ref={(el) => (dropdownRefs.current["serviceCategoryRow2"] = el)}
                  isOpen={openDropdown === "serviceCategoryRow2"}
                  toggleDropdown={() => toggleDropdown("serviceCategoryRow2")} label="Service Category" options={["Option 1", "Option 2"]} value={serviceCategoryRow2}
                  onChange={(value) => setServiceCategoryRow2(value)} />
                <div style={styles.dropdownWithClose}>
                  <Dropdown
            ref={(el) => (dropdownRefs.current["serviceTypeRow2"] = el)}
                    isOpen={openDropdown === "serviceTypeRow2"}
                    toggleDropdown={() => toggleDropdown("serviceTypeRow2")}
                    label="Service Type"
                    options={["Option 1", "Option 2"]}
                    value={serviceTypeRow2}
                    onChange={(value) => setServiceTypeRow2(value)}
                  />
                  {/* <button style={styles.closeButton} >✖</button> */}
                  <img src={closeIcon} style={styles.closeButton} />
                </div>

              </div>

              {/* Row 3 */}
              <div style={styles.serviceRow}>
                <Dropdown
            ref={(el) => (dropdownRefs.current["serviceCategoryRow3"] = el)}
                  isOpen={openDropdown === "serviceCategoryRow3"}
                  toggleDropdown={() => toggleDropdown("serviceCategoryRow3")} label="Service Category" options={["Option 1", "Option 2"]} value={serviceCategoryRow3}
                  onChange={(value) => setServiceCategoryRow3(value)} />
                <div style={styles.dropdownWithClose}>
                  <Dropdown
            ref={(el) => (dropdownRefs.current["serviceTypeRow3"] = el)}
                    isOpen={openDropdown === "serviceTypeRow3"}
                    toggleDropdown={() => toggleDropdown("serviceTypeRow3")} label="Service Type" options={["Option 1", "Option 2"]} value={serviceTypeRow3}
                    onChange={(value) => setServiceTypeRow3(value)} />
                  <img src={closeIcon} style={styles.closeButton} />
                </div>
              </div>

              <button style={styles.addServiceButton} >
                <span style={{ marginRight: '10px' }}>+</span> Add More Services
              </button>
            </div>
          </div>

          {/* Estimate Options */}
          <div >
            <h3 style={{ ...styles.sectionTitle, marginBottom: "12px" }}>Invoice Options</h3>
            <div style={{ ...styles.servicesContainer }}>
              <div style={styles.serviceRow}>
                <Dropdown
            ref={(el) => (dropdownRefs.current["Group Estimation"] = el)}
                  isOpen={openDropdown === "Group Estimation"}
                  toggleDropdown={() => toggleDropdown("Group Estimation")} label="Group Estimation" options={["GST", "Income Tax", "Audit"]} value={invoiceOptions?.groupEstimate}
                  onChange={(e) => handleInvoiceOptionsChange("groupEstimate", e.target.value)} />
                <Dropdown
            ref={(el) => (dropdownRefs.current["invoiceOptions"] = el)}
                  isOpen={openDropdown === "invoiceOptions"}
                  toggleDropdown={() => toggleDropdown("invoiceOptions")} label="No. Of Clients" options={["Option 1", "Option 2"]} value={invoiceOptions?.noOfClients}
                  onChange={(e) => handleInvoiceOptionsChange("noOfClients", e.target.value)} />
              </div>
              <div style={styles.serviceRow}>
                <Dropdown
            ref={(el) => (dropdownRefs.current["invoiceOptions2"] = el)}
                  isOpen={openDropdown === "invoiceOptions2"}
                  toggleDropdown={() => toggleDropdown("invoiceOptions2")} label="No Tax Invoice" options={["Yes", "No"]} value={invoiceOptions?.noTaxBill}
                  onChange={(e) => handleInvoiceOptionsChange("noTaxBill", e.target.value)} />
                <Dropdown
            ref={(el) => (dropdownRefs.current["generateSeperateBill"] = el)}
                  isOpen={openDropdown === "generateSeperateBill"}
                  toggleDropdown={() => toggleDropdown("generateSeperateBill")} label="Generate Seperate Invoices" options={["Yes", "No"]} value={invoiceOptions?.generateSeperateBill}
                  onChange={(e) => handleInvoiceOptionsChange("generateSeperateBill", e.target.value)} />
              </div>
              <div style={styles.serviceRow}>
                <Input label="Estimate Number" placeholder="4,000.00" value={invoiceOptions?.estimateNumber}
                  onChange={(e) => handleInvoiceOptionsChange("estimateNumber", e.target.value)} />
                <Input label="Advance Payment" placeholder="2,000.00" value={invoiceOptions?.advancePayment}
                  onChange={(e) => handleInvoiceOptionsChange("advancePayment", e.target.value)} />
              </div>
              <div style={styles.serviceRow}>
                <Input label="Invoicing Amount" placeholder="4,000.00" value={invoiceOptions?.systemBillingAmount}
                  onChange={(e) => handleInvoiceOptionsChange("systemBillingAmount", e.target.value)} />
                <Input label="Estimate Amount" placeholder="2,000.00" value={invoiceOptions?.invoiceAmount}
                  onChange={(e) => handleInvoiceOptionsChange("invoiceAmount", e.target.value)} />
              </div>
              <div style={styles.serviceRow}>
                <Dropdown
            ref={(el) => (dropdownRefs.current["taskCompletedBy"] = el)}
                  isOpen={openDropdown === "taskCompletedBy"}
                  toggleDropdown={() => toggleDropdown("taskCompletedBy")} label="Task Completed By" options={["Option 1", "Option 2"]} value={invoiceOptions?.taskCompletedBy}
                  onChange={(e) => handleInvoiceOptionsChange("taskCompletedBy", e.target.value)} />
                <Dropdown
            ref={(el) => (dropdownRefs.current["generatedBy"] = el)}
                  isOpen={openDropdown === "generatedBy"}
                  toggleDropdown={() => toggleDropdown("generatedBy")} label="Generated By" options={["Option 1", "Option 2"]} value={invoiceOptions?.generatedBy}
                  onChange={(e) => handleInvoiceOptionsChange("generatedBy", e.target.value)} />
              </div>
            </div>
          </div>
        </div>
        {/* Footer Options */}
        <div style={{ ...styles.mainContainer, display: 'flex', justifyContent: 'space-between', marginTop: '18px' }}>
          <div>
            <div style={{ marginBottom: '15px', display: "flex", alignItems: "center", }}>
              {/* <input type="checkbox" id="sendUpdateClient" checked={sendUpdateToClient}
                onChange={(e) => setSendUpdateToClient(e.target.checked)} />
              <label htmlFor="sendUpdateClient" style={styles.checkboxLabel}>
                Send Update to Client
              </label> */}
              <CheckBox
                label="Send Update to Client"
                checked={sendUpdateToClient}
                onChange={(isChecked) => setSendUpdateToClient(isChecked)}
              />
            </div>
            <div style={{ display: "flex", alignItems: "center", }}>
              {/* <input type="checkbox" id="sendUpdateTeam" checked={sendUpdateToTeam}
                onChange={(e) => setSendUpdateToTeam(e.target.checked)} />
              <label htmlFor="sendUpdateTeam" style={styles.checkboxLabel}>
                Send Update to Team
              </label> */}
              <CheckBox
                label="Send Update to Team"
                checked={sendUpdateToTeam}
                onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
              />
            </div>
          </div>
          <div style={{ display: 'flex', flexWrap: 'wrap', columnGap: '14px', rowGap: '5px' }}>
            <Dropdown
            ref={(el) => (dropdownRefs.current["Organization"] = el)}
              isOpen={openDropdown === "Organization"}
              toggleDropdown={() => toggleDropdown("Organization")} label="Organization" options={["Option 1", "Option 2"]} value={organizationDetails?.organization}
              onChange={(e) => handleOrganizationDetailsChange("organization", e.target.value)} />
            <Dropdown
            ref={(el) => (dropdownRefs.current["receiptBook"] = el)}
              isOpen={openDropdown === "receiptBook"}
              toggleDropdown={() => toggleDropdown("receiptBook")} label="Receipt Book" options={["Option 1", "Option 2"]} value={organizationDetails?.receiptBook}
              onChange={(e) => handleOrganizationDetailsChange("receiptBook", e.target.value)} />
            <div className="filter-field" style={{ marginBottom: "15px" }}> {/* Add margin-bottom here */}
              <label style={{ ...styles.labaleStyle, marginBottom: "8px", display: "block" }}>
                Follow Up Date
              </label>
              <DateInput
                value={organizationDetails?.followUpDate}
                onChange={(e) => handleOrganizationDetailsChange("followUpDate", e.target.value)}
                style={{ padding: "20px 10px" }}
              />
            </div>
          </div>
        </div>

        {/* Footer Buttons */}
        <div className="button-container" style={{ marginTop: '32px' }}>
          <button className="add-client-button" style={{ paddingLeft: '30px', paddingRight: '30px', marginRight: '10px', }}>Generate Invoice</button>
          <button style={{ ...styles.addServiceButton, paddingLeft: '30px', paddingRight: '30px' }}>Generate Receipt For Invoice</button>
          <button className="cancel-button" >Cancel</button>
        </div>
      </div>
    </>
  );
};

const styles = {
  container: {
    padding: "20px",
    maxWidth: "90%",
    margin: "auto",
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "10px",
    boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
  },
  title: {
    fontSize: "28px",
    marginBottom: "23px",
    fontWeight: "500",
    color: "#1F384C",
    // fontFamily: "Roboto Slab"
  },
  section: {
    marginBottom: "21px",
  },
  sectionTitle: {
    fontSize: "18px",
    fontWeight: "500",
    color: "#609966",
    fontFamily: 'Roboto Slab'
  },
  row: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid'
  },
  estimatedRow: {
    padding: '16px 11px',
    display: "flex",
    flexWrap: "wrap",
    columnGap: "70px",
    rowGap: "15px",
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
    paddingBottom: '63px'
  },
  checkboxContainer: {
    display: "flex",
    alignItems: "center",
    gap: "2px",
  },
  checkboxLabel: {
    fontSize: "12px",
    color: "#343C44",
    fontFamily: 'Roboto',
    fontWeight: '400'
  },
  serviceRow: {
    display: "flex",
    justifyContent: "flex-start",
    gap: "15px 70px", // Gap between two elements in the same row
    marginBottom: "15px", // Space between rows
    flexWrap: "wrap",
  },
  dropdownWithClose: {
    display: "flex",
    alignItems: "center", // Vertically aligns the dropdown and close icon
    gap: "26px", // Gap between dropdown and close icon
  },
  closeButton: {
    width: '15px',
    height: '15px',
    cursor: "pointer",
    verticalAlign: "middle",
    objectFit: "contain",
  },
  addServiceButton: {
    backgroundColor: "transparent",
    border: "1px #858585 solid",
    borderRadius: "5px",
    padding: "8px 12px",
    color: "#40513B",
    cursor: "pointer",
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: '400',
    borderRadius: '28px',
    // marginBottom:'16px'
  },
  removeButton: {
    backgroundColor: "transparent",
    border: "none",
    color: "#f00",
    fontSize: "16px",
    cursor: "pointer",
  },
  footer: {
    display: "flex",
    justifyContent: "space-between",
    marginTop: "20px",
  },
  createButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "10px 20px",
    cursor: "pointer",
  },
  servicesContainer: {
    // display: "flex",
    // flexWrap: "wrap", // Allows items to wrap to the next row if needed
    // gap: "20px", // Space between rows and items
    padding: '16px 11px',
    border: '1px #EFF0F4 solid',
    borderBottom: '2px #060606 solid',
  },
  mainContainer: {
    display: "flex",
    gap: "15px",
    flexWrap: "wrap",
    // overflowX: "auto",
    // whiteSpace: "nowrap",
  },
  "@media (max-width: 768px)": {
    // For tablets and smaller screens
    mainContainer: {
      display: 'flex',
      flexDirection: 'column',

    }
  },
  "@media (max-width: 768px)": {
    serviceRow: {
      flexDirection: "column", // Stack items vertically
      gap: "15px", // Reduce the gap for smaller screens
    },
  },
  headerContainer: {
    display: 'flex',
    alignItems: 'center', // Vertically align items
  },
  header: {
    fontSize: "22px",
    color: "#060606",
    marginBottom: "10px",
    fontWeight: 500,
    // fontFamily: 'Montserrat',
    //   marginLeft:'20px'
  },
  leftHeader: {
    marginTop: '-5px'
  },
  labaleStyle: {
    fontSize: '12px',
    marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  }
};

export default GenerateEditInvoiceScreen;
