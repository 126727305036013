import { useState, useRef } from "react";
import { postData } from "../../../../ApiServices/ApiService";
import { ORG_DELETE_USER_CREDENTIAL } from '../../../../ApiServices/BaseURL';

const UserIdPasswordController = (onRefresh, setUserCredentials) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [deleteUserIdLoading, setDeleteUserIdLoading] = useState({});
    const [deleteUserIdError, setDeleteUserIdError] = useState({});
    const [deleteUserIdSucess, setDeleteUserIdSucess] = useState({});
    const credentialInfoRef = useRef(null);
    const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

    const callDeleteUserId = async (credential) => {
        try {
            //console.log('callDeleteUserId')
            //console.log("credential", credential);
            setDeleteUserIdError({});
            setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: '' }));
            setDeleteUserIdSucess((prev) => ({ ...prev, [credential?.account_id]: '' }));
            setDeleteUserIdLoading((prev) => ({ ...prev, [credential?.account_id]: true }));
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                "emp_id_auth": storedUserInfo?.emp_id,
                "account_id": credential?.account_id
            }

            //console.log('callDeleteUserId payload', payLoad);

            // Make the API call
            const response = await postData(ORG_DELETE_USER_CREDENTIAL, payLoad);
            const responseData = await response.json();
            //console.log('callDeleteClientContact response Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setDeleteUserIdSucess((prev) => ({ ...prev, [credential?.account_id]: responseData?.info }));
                        setTimeout(() => {
                            setDeleteUserIdSucess((prev) => ({ ...prev, [credential?.account_id]: '' }));
                            onRefresh();
                        }, 3000);
                    } else {
                        setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: responseData?.info || 'Failed to delete client credential. Please try again.' }));
                    }
                } else {
                    setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: responseData?.info || 'Failed to delete client credential. Please try again...' }));
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
                } else {
                    setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
                }
            }
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
            }
            // Unexpected or network-related errors
            else {
                setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: 'Unexpected error occurred. Please try again later.' }));
            }
        } catch (error) {
            // Handle various error scenarios
            if (error.response) {
                setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete client credential. Please try again.'}` }));
            } else if (error.request) {
                setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: 'No response received from the server. Please check your network connection and try again.' }));
            } else {
                setDeleteUserIdError((prev) => ({ ...prev, [credential?.account_id]: `Failed to delete client credential. Please check your network connection and try again.` }));
            }
        } finally {
            setDeleteUserIdLoading((prev) => ({ ...prev, [credential?.account_id]: false }));
        }
    }

    // Toggle password visibility
    const togglePasswordVisibility = (index) => {
        setUserCredentials((prevCredentials) =>
            prevCredentials.map((credential, i) =>
                i === index ? { ...credential, showPassword: !credential.showPassword } : credential
            )
        );
    };

    // Open modal for adding credentials
    const handleAddCredientailsClick = () => {
        credentialInfoRef.current = null;
        setIsModalOpen(true);
    };

    // Close modal
    const handleCloseModal = () => {
        setIsModalOpen(false);
    };

    const handleEditUserCredential = (credential) => {
        credentialInfoRef.current = credential;
        setIsModalOpen(true);
    }

    const onAddUpdateSuccess = () => {
        //console.log('onAddUpdateSuccess')
        onRefresh();
    }

    const handleDelete = (credential) => {
        credentialInfoRef.current = credential; // Store the credential to delete
        setIsDeleteModalOpen(true); // Open the confirmation modal
    };

    const handleOpenDeleteModal = () => {
        setIsDeleteModalOpen(true); // Open the modal
    };

    const handleCloseDeleteModal = () => {
        setIsDeleteModalOpen(false); // Close the modal
    };


    return {
        isModalOpen,
        togglePasswordVisibility,
        handleAddCredientailsClick,
        handleCloseModal,
        deleteUserIdLoading,
        deleteUserIdError,
        deleteUserIdSucess,
        handleEditUserCredential,
        onAddUpdateSuccess,
        credentialInfoRef,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        isDeleteModalOpen,
        handleDelete,
        callDeleteUserId,
    };
};

export default UserIdPasswordController;
