
import React, { useState, useRef, useEffect } from "react";

const WhatsAppContactListController = () => {
  const [filterData, setFilterData] = useState({
    contactName: "",
    phoneNo: "",
    optOut: "",
    countryCode: "",
  });

  const handleInputChange = (field, value) => {
    setFilterData((prevData) => ({
      ...prevData,
      [field]: value,
    }));
  };

  const emailContactData = [
    {
      contactName: "Contact Name",
      emailId: "example@example.com",
      listName: "Group Name",
      addedOn: "11/11/2024",
      addedBy: "John Doe",
      optOut: "Yes",
      contactNo: '9876543223'
    },
    {
      contactName: "Contact Name",
      emailId: "example@example.com",
      listName: "Group Name",
      addedOn: "11/11/2024",
      addedBy: "John Doe",
      optOut: "Yes",
      contactNo: '9876543223'
    },
    {
      contactName: "Contact Name",
      emailId: "example@example.com",
      listName: "Group Name",
      addedOn: "11/11/2024",
      addedBy: "John Doe",
      optOut: "Yes",
      contactNo: '9876543223'
    },
    {
      contactName: "Contact Name",
      emailId: "example@example.com",
      listName: "Group Name",
      addedOn: "11/11/2024",
      addedBy: "John Doe",
      optOut: "No",
      contactNo: '9876543223'
    }
  ]


  return {
    filterData,
    setFilterData,
    emailContactData,
    handleInputChange
  }

}

export default WhatsAppContactListController