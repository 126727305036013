import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { postDataLogin } from '../../../ApiServices/ApiService';
import { ORG_MFA_LOGIN } from '../../../ApiServices/BaseURL';
import { useLocation } from 'react-router-dom';
import { useTheme } from '../../../GlobalContext/GlobalContext';

const MFAScreenController = () => {
    const [mfa, setMfa] = useState(''); // State to store the OTP entered by the user
    const [error, setError] = useState(''); // State for error messages
    const [loading, setLoading] = useState(false); // State to track loading
    const [resendTimer, setResendTimer] = useState(15); // Timer for resend functionality
    const { theme, setCompanyId } = useTheme();

    const location = useLocation();
    const email = location.state?.email;
    const sessionTkn = location.state?.sessionTkn || '';

    console.log("email", email);


    const navigate = useNavigate();

    // Function to handle input changes for MFA code
    const handleMfaChange = (text) => {
        // Only allow numbers and limit the input to 6 characters
        const value = text.replace(/[^0-9]/g, '').slice(0, 6);
        setMfa(value);
    };

    // Function to verify the MFA code
    const verify = async () => {
        setError('');

        // Validation for the MFA code
        if (mfa.length !== 6) {
            setError('Please enter a valid 6-digit code.');
            return;
        }

        setLoading(true); // Show the loader before making the request

        try {
            const payload = {
                session_token:sessionTkn,
                username: email,
                mfa_code: mfa,
            };

            const response = await postDataLogin(ORG_MFA_LOGIN, payload);

            // Handle response based on status code
            if (response.statusCode === 200) {
                if (response.message === 'success') {
                    const themeId = 6; // Assuming themeId is constant here
                    setCompanyId(themeId);
                    // Save data to localStorage
                    localStorage.setItem('themeId', themeId);
                    const role = 'SUPER-ADMIN'
                    localStorage.setItem(`userRole`, role); //need to change dynamic
                    // localStorage.setItem(`userRole`, response?.dataJ[0]?.emp_role);
                    let userData = JSON.stringify(response?.dataJ[0]);
                    localStorage.setItem('_userInfo_', userData);
                    navigate('/dashboard'); // Navigate to dashboard
                } else {
                    setError(response?.info || 'Failed to verify the code.');
                }
            } else if (response.statusCode === 500) {
                setError(response?.info || 'Internal Server Error. Please try again later.');
            } else {
                setError('No Internet Connection Detected or Connection Is Slow.');
            }
        } catch (error) {
            // Handle exceptions
            console.error('Error during MFA verification:', error);
            setError('An error occurred. Please check your connection and try again.');
        } finally {
            setLoading(false); // Hide the loader after request is completed
        }
    };


    // Function to handle resend logic
    const resendCode = async () => {
        setError('');
        if (resendTimer > 0) return;

        try {
            setLoading(true);

            // Add API call to resend the MFA code here
            const response = await postDataLogin(ORG_MFA_LOGIN, { resend: true });
            const responseData = await response.json();

            if (response.status === 200 && responseData.statusCode === 200) {
                setResendTimer(15); // Reset the timer
            } else {
                setError(responseData?.message || 'Failed to resend the code. Please try again.');
            }
        } catch (err) {
            setError('An error occurred while resending the code. Please try again later.');
        } finally {
            setLoading(false);
        }
    };

    // Countdown for resend timer
    useState(() => {
        const timer = setInterval(() => {
            setResendTimer((prev) => (prev > 0 ? prev - 1 : 0));
        }, 1000);

        return () => clearInterval(timer);
    }, []);

    return {
        mfa,
        setMfa,
        handleMfaChange,
        verify,
        resendCode,
        loading,
        error,
        resendTimer,
    };
};

export default MFAScreenController;
