import React, { useState, useEffect, useRef } from 'react';
import Input from './Input';
import CheckBox from './CheckBox';
import Dropdown from './Dropdown';
import closeIcon from '../Assets/Icons/close.png';

const AddBillingModal = ({ isOpen, onClose, onValueChange }) => {
    const [serviceName, setServiceName] = useState('');
    const [frequency, setFrequency] = useState('');
    const [remarks, setRemarks] = useState('');
    const [type, setType] = useState('');
    const [billAmount, setBillAmount] = useState('');
    const [billDate, setBillDate] = useState('');
    const [sendToClient, setSendToClient] = useState(false);
    const [sendToTeam, setSendToTeam] = useState(false);

    const serviceOptions = ['GST Filing', 'Accounting', 'Auditing'];
    const frequencyOptions = ['Monthly', 'Quarterly', 'Yearly'];
    const typeOptions = ['Bill', 'Receipt'];


    const handleAddBilling = () => {
        return
        const billingData = {
            serviceName,
            frequency,
            remarks,
            type,
            billAmount,
            billDate,
            sendToClient,
            sendToTeam,
        };
        onValueChange(billingData);
        onClose();
    };

    const resetForm = () => {
        setServiceName('');
        setFrequency('');
        setRemarks('');
        setType('');
        setBillAmount('');
        setBillDate('');
        setSendToClient(false);
        setSendToTeam(false);
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={onClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Billing/Receipt</h2>
                <hr className="title-divider" />

                <div className="form-grid">
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["serviceName"] = el)}
                        isOpen={openDropdown === "serviceName"}
                        toggleDropdown={() => toggleDropdown("serviceName")}
                        label="Service Name"
                        options={serviceOptions}
                        value={serviceName}
                        onChange={setServiceName}
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["frequency"] = el)}
                        isOpen={openDropdown === "frequency"}
                        toggleDropdown={() => toggleDropdown("frequency")}
                        label="Frequency"
                        options={frequencyOptions}
                        value={frequency}
                        onChange={setFrequency}
                    />
                    <Input
                        label="Remarks"
                        placeholder="Enter Remarks"
                        value={remarks}
                        onChange={(e) => setRemarks(e.target.value)}
                    />
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["typeOptions"] = el)}
                        isOpen={openDropdown === "typeOptions"}
                        toggleDropdown={() => toggleDropdown("typeOptions")}
                        label="Type"
                        options={typeOptions}
                        value={type}
                        onChange={setType}
                    />
                    <Input
                        label="Bill Amount"
                        placeholder="Enter Bill Amount"
                        value={billAmount}
                        onChange={(e) => setBillAmount(e.target.value)}
                    />
                    <Input
                        label="Bill Date"
                        type="date"
                        value={billDate}
                        onChange={(e) => setBillDate(e.target.value)}
                    />
                </div>
                <div className="existing-client-checkbox">
                    {/* <input
                        type="checkbox"
                        checked={sendToClient}
                        onChange={(e) => setSendToClient(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update to Client</label> */}
                    <CheckBox
                        label="Send Update to Client"
                        checked={sendToClient}
                        onChange={(isChecked) => setSendToClient(isChecked)}
                    />
                </div>
                <div className="existing-client-checkbox">
                    {/* <input
                        type="checkbox"
                        checked={sendToTeam}
                        onChange={(e) => setSendToTeam(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update to Team</label> */}
                    <CheckBox
                        label="Send Update to Team"
                        checked={sendToClient}
                        onChange={(isChecked) => setSendToTeam(isChecked)}
                    />
                </div>
                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleAddBilling}>Add Bill</button>
                    <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
                </div>
            </div>
        </div>
    );
};

const styles = {
    uploadContainer: {
        marginTop: '20px',
        width: '97%',
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #8FC7F2',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    iconImage: {
        width: '15px',
        height: '15px',
    },
    uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight: '5px'
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    }
}

export default AddBillingModal;
