import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import TextInput from '../TextInput';
import search from '../../Assets/Icons/search.png';
import { postData } from '../../ApiServices/ApiService';
import { ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER, ORG_ADD_EMP_TEAM } from '../../ApiServices/BaseURL';
import Loader from '../Loader';


const AddTeamMemberModal = ({ isOpen, onClose, onValueChange,teamId }) => {

    //console.log("teamId modal", teamId);
    const [loading, setLoading] = useState(false)
    const [addEmployeeLoading, setAddEmployeeLoading] = useState(false)
    const [error, setError] = useState('');
    const [addEmployeeError, setAddEmployeeError] = useState('');
  const [employeeList, setEmployeeList] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [success, setSuccess] = useState('');

    const handleSearchEmployee = async () => {
   
        try {
            setError('');
            setEmployeeList([]); 
            if (!searchUser) {
              setError('Please enter valid text'); return;
            }         
            setLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  search_type: "GENERIC", search_word: searchUser}
        
            //console.log('handleSearchEmployee payload',payLoad);
            // Make the API call
            const response = await postData(ORG_EMP_SEARCH_TO_ADD_TEAMMEMBER,payLoad);
            const responseData = await response.json();
            //console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                    setAddEmployeeError('')
                    setEmployeeList(responseData?.dataJ || []);
                //   setError(responseData?.info);
                  setTimeout(() => {
                    // handleClose();
                    // onValueChange();
                  }, 3000);
                } else {
                  setError(responseData?.info || 'Failed to fetch user. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch user.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch user. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch user. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
          }

        // onValueChange(teamData);
        // onClose();
    };

    const handleClose=()=>{
        setError('');
        setAddEmployeeError('')
        onClose();
        setSuccess('');
        setSearchUser('');
        setEmployeeList([]);
        setSelectedEmployee(null)
      }

    const handleAddEmployeeToTeam = async () => {
   
        try {
          setError('');
          setAddEmployeeError(''); 
          setSuccess('')       
            if(!selectedEmployee){
                setError('Please enter search user');
                return
            }   
            setAddEmployeeLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {  emp_id_auth: storedUserInfo?.emp_id,  team_id: teamId?.current || teamId,emp_id:selectedEmployee?.emp_id }
        
            //console.log('team Data',payLoad);
            // Make the API call
            const response = await postData(ORG_ADD_EMP_TEAM,payLoad);
            const responseData = await response.json();
            //console.log('response Data',responseData);
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                    setSuccess(responseData?.info);
                  setTimeout(() => {
                    handleClose();
                    onValueChange();
                  }, 3000);
                } else {
                    setAddEmployeeError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
                }
              } else {
                setAddEmployeeError(responseData?.info || 'Failed to fetch Task Categorie data.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setAddEmployeeError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setAddEmployeeError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
                setAddEmployeeError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
                setAddEmployeeError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setAddEmployeeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setAddEmployeeError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setAddEmployeeError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
            }
          } finally {
            setAddEmployeeLoading(false);
          }

        // onValueChange(teamData);
        // onClose();
    };

    const handleSearchInputText = (value) => {
      //console.log('handleSearchInputText value : ',value);
      setSearchUser(value);
      setError('');
      setEmployeeList([]);
    }

    const handleSelectedEmployee = (employee) => {
        setSelectedEmployee(employee); // Set selected client data
        setSearchUser(employee?.emp_name); // Update TextInput with the selected client's name
        setEmployeeList([]); // Clear the dropdown
        setError('');
      };

    const [searchUser, setSearchUser] = useState('');


    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={handleClose}>
                    <img src={closeIcon} alt="Close" style={styles.iconImage} />
                </button>
                <h2 className="modal-title">Add Team Member</h2>
                <hr className="title-divider" />

                <div>
                    <div className="filter-field" style={{ marginBottom: '15px' }}>
                        <label
                            style={{
                                ...styles.labaleStyle,
                                // marginBottom: '8px',
                                display: 'block',
                            }}
                        >
                            Search User
                        </label>
                        <TextInput
                            type="text"
                            placeholder="Search User"
                            value={searchUser}
                            onChange={(e) => handleSearchInputText(e.target.value)}
                            onRightClick={handleSearchEmployee}
                            right={
                                loading ? (
                                  <Loader loading={loading} color={'var(--primary-color)'} size='small'/> // Show loader when loading
                                ) : (
                                  <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                )
                              }
                            style={{ padding: '20px 10px' }}
                            errorMessage={error}
                        />
                        {employeeList.length > 0 && (
                            <ul style={styles.dropdownList}>
                                {employeeList.map((employee, index) => (
                                <li
                                    key={employee?.emp_id}
                                    onClick={() => handleSelectedEmployee(employee)}
                                    style={{
                                    ...styles.dropdownItem,
                                    borderBottom: index !== employeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                    }}
                                >
                                    {employee?.emp_name}
                                </li>
                                ))}
                            </ul>
                            )}

                    </div>

                    <div className="basic-info-row">
                        <div className="basic-info-item">
                            <span className="basic-info-label">User Name</span>
                            <span className="basic-info-value">{selectedEmployee?.emp_name || '--'}</span>
                        </div>
                        <div className="basic-info-item">
                            <span className="basic-info-label">Manager</span>
                            <span className="basic-info-value">{selectedEmployee?.emp_id_team_manager_name || '--'}</span>
                        </div>
                        <div className="basic-info-item">
                            <span className="basic-info-label">Email ID</span>
                            <span className="basic-info-value">{selectedEmployee?.emp_email || '--'}</span>
                        </div>
                        <div className="basic-info-item">
                            <span className="basic-info-label">Mobile No.</span>
                            <span className="basic-info-value">
                            {selectedEmployee?.emp_mobile_code ? `${selectedEmployee.emp_mobile_code} ` : ''} 
                            {selectedEmployee?.emp_mobile_no || '--'}
                          </span>
                        </div>
                        </div>
                </div>


                <hr className="footer-divider" />
                <div className="button-container" style={{alignItems:'center'}}>
                <button
                    className="add-client-button"
                    onClick={handleAddEmployeeToTeam}
                    disabled={addEmployeeLoading}
                    style={{
                        paddingLeft: '30px',
                        paddingRight: '30px',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center',
                        gap: '10px', // Add spacing between text and loader
                        position: 'relative',
                        opacity: addEmployeeLoading ? 0.5 : 1, // Reduce opacity when loading
                        cursor: addEmployeeLoading ? 'not-allowed' : 'pointer',
                    }}
                    >
                    <span>Add</span>
                    {addEmployeeLoading && (
                        <Loader loading={addEmployeeLoading} color={'var(--background-color)'} size="small" />
                    )}
                    </button>

                    <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
                    {/* {error && <div className="list-error-msg" style={{marginBottom:'3px'}}>{error} </div>} */}
                {addEmployeeError && <div className="list-error-msg">{addEmployeeError} </div>}
                {success && <div className="list-success-msg" style={{marginBottom:'3px'}}>{success} </div>}
                </div>
            </div>
        </div>
    )

}

const styles = {
    uploadContainer: {
        marginTop: '20px',
        width: '97%',
    },
    uploadBox: {
        width: '100%',
        border: '1px dashed #8FC7F2',
        borderRadius: '10px',
        padding: '10px',
        display: 'flex',
        alignItems: 'center',
        gap: '10px',
        cursor: 'pointer',
        boxSizing: 'border-box',
        position: 'relative', // Position relative to contain the hidden input
    },
    uploadCircle: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        width: '30px',
        height: '30px',
        borderRadius: '50%',
        backgroundColor: '#f0f0f0',
    },
    plusIcon: {
        fontSize: '16px',
        color: '#333',
    },
    uploadText: {
        flex: 1,
        color: '#262626',
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400
    },
    uploadArrow: {
        fontSize: '18px',
        color: '#555',
    },
    fileInput: {
        position: 'absolute',
        top: 0,
        left: 0,
        width: '100%',
        height: '100%',
        opacity: 0,
        cursor: 'pointer',
    },
    iconImage: {
        width: '15px',
        height: '15px',
    },
    uploadIconImage: {
        width: '25px',
        height: '25px',
        marginRight: '5px'
    },
    exisitingHeading: {
        color: '#343C44',
        fontSize: '10px',
        fontWeight: 500,
        fontFamily: 'Roboto'
    },
    labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)',
    },

    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
      },
      dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
          backgroundColor: '#f0f0f0',
        },
      },
      loaderStyle: {
        width: '20px',
        height: '20px',
      },
}

export default AddTeamMemberModal;