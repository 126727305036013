import React, { useState, useRef, useEffect } from 'react';
import NatureCallTypeListController from '../../../../Controllers/AdminScreens/Services/Settings/NatureCallTypeListController';
import AddCallTypeModal from '../../../../../Components/Modals/AddCallTypeModal';
import editImg from '../../../../../Assets/Icons/edit.png';
import deleteImg from '../../../../../Assets/Icons/delete.png';
import downArrow from '../../../../../Assets/Icons/chevron-down.png';
import { useNavigate } from 'react-router-dom';
import Loader from '../../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../../Components/Modals/DeleteConformationModal';

const NatureCallTypeListScreen = ({ callsList, error, loading, onRefresh }) => {
    const navigate = useNavigate();
    const [modalVisible, setModalVisible] = useState(false);

    const handleNav = (pageName, task) => {
        //console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } }); // Pass task in state
    };
    const {
        tasks,
        currentPage,
        totalPages,
        handlePageChange,
        toggleDetails,
        // loading,
        // error,
        // callsList,
        fetchNatureOfCallsList,
        selectedDocument,
        setSelectedDocument,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        natureOfCallsDeleteError,
        natureOfCallsDeleteLoading,
        currentCallDeleteIndex,
        success,
        handleNatureOfCallsDelete,
        currentTasks,
        deletedRows
    } = NatureCallTypeListController(onRefresh, callsList);

    const getStatusStyle = (status) => {
        switch (status) {
            case 0:
                return { backgroundColor: "rgba(226, 177, 51, 0.18)", color: "rgba(223, 165, 16, 1)" }; // Green
            case 2:
                return { backgroundColor: "rgba(238, 32, 28, 0.18)", color: "rgba(238, 32, 28, 1)" }; // Yellow
            case 1:
                return { backgroundColor: "rgba(26, 147, 46, 0.18)", color: "rgba(26, 147, 46, 1)" }; // Red
            default:
                return {};
        }
    };

    const addCallTypeModalVisible = () => {
        setModalVisible(true);
        setSelectedDocument(null)
    }
    const handleCloseModal = () => {
        setModalVisible(false);
    };

    const handleEditDocument = (doc) => {
        setSelectedDocument(doc);
        setModalVisible(true);
    };

    return (
        <>
            <div className="tasksScreen-tasks-header" style={{ marginBottom: '15px' }}>
                <h2 className="tasksScreen-tasks-title" style={{ fontSize: '28px' }}>Call Types</h2>
                <button className="tasksScreen-apply-button" onClick={addCallTypeModalVisible} style={{ paddingTop: '10px', paddingBottom: '10px' }}>Add New</button>
            </div>

            <div className="tasksScreen-task-table-wrapper">
                <table className="tasksScreen-task-table">
                    <thead>

                        {/* Table Headers */}
                        <tr>
                            <th style={{ width: "30%", }}>Nature of Call</th>
                            <th style={{ width: "40%", }}>Description</th>
                            <th style={{ width: "10%", }}>Created By</th>
                            <th style={{ width: "12%", }}>Status</th>
                            <th style={{ width: "8%", }}></th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: '#fff' }}>
                        {currentTasks?.map((task, index) => (
                            <React.Fragment key={index}>
                                <tr className={`tasksScreen-main-task-row ${task.showDetails ? 'tasksScreen-show-border' : ''}`}>
                                    <td style={{ textDecoration: 'underline', color: '#5A55D2', cursor: 'pointer' }}>{task.sc_type_name}</td>
                                    <td>{task.sc_type_desc || "--"}</td>
                                    <td>{task.create_by_name}</td>
                                    <td>
                                        <div
                                            style={{
                                                display: "inline-block",
                                                padding: "5px 5px",
                                                borderRadius: "15px",
                                                ...getStatusStyle(task.sc_type_status),
                                                width: "auto",
                                            }}
                                        >
                                            {task.sc_type_status === 1
                                                ? "Active"
                                                : task.sc_type_status === 0
                                                    ? "InActive"
                                                    : "---"}
                                        </div>
                                        {success && currentCallDeleteIndex === index&&selectedDocument?.page === currentPage  && (
                                            <div style={{ color: 'green', textDecoration: 'none' }}>{success}</div>
                                        )}
                                        {natureOfCallsDeleteError && currentCallDeleteIndex === index && selectedDocument?.page === currentPage &&(
                                            <div style={{ color: 'red', textDecoration: 'none' }}>{natureOfCallsDeleteError}</div>
                                        )}
                                    </td>
                                    <td>
                                        <div style={{ display: "flex", alignItems: "center", gap: "5px", justifyContent: "end", }}>
                                            <button className="tasksScreen-edit-button" disabled={deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index)}
                                            style={{
                                                opacity: deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index) ? 0.5 : 1,
                                                pointerEvents: deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index) ? 'none' : 'auto',
                                            }}>
                                                <img src={editImg} alt="Edit" onClick={() => handleEditDocument(task)} />
                                            </button>
                                            <button className="tasksScreen-delete-button" onClick={() => { handleDelete(task, index) }}
                                            disabled={deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index)}
                                            style={{
                                                opacity: deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index) ? 0.5 : 1,
                                                pointerEvents: deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index) ? 'none' : 'auto',
                                                cursor: deletedRows.includes(task.sc_type_id) || (natureOfCallsDeleteLoading && currentCallDeleteIndex === index) ? 'not-allowed' : 'pointer',
                                            }}
                                            >
                                                <img src={deleteImg} alt="Delete" />
                                            </button>
                                            {natureOfCallsDeleteLoading && currentCallDeleteIndex === index && (
                                                <span style={{ marginLeft: '5px' }}>
                                                    <Loader loading={natureOfCallsDeleteLoading} color={'var(--primary-color)'} size="small" />
                                                </span>
                                            )}
                                        </div>
                                    </td>
                                </tr>
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {error && <div className="list-error-msg">{error} </div>}
                {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                {/* {success && <div className="list-success-msg">{success} </div>} */}
                {/* {natureOfCallsDeleteError && <div className="list-error-msg">{natureOfCallsDeleteError} </div>} */}
            </div>

            <div className="tasksScreen-table-footer">
                <span className="tasksScreen-pagination-info">
                    {`${currentPage}-${totalPages} of items`}
                </span>
                <div className="tasksScreen-pagination">
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || totalPages === 0}>
                        <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
                    </button>
                    {[...Array(totalPages)].map((_, index) => (
                        <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
                    ))}
                    <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}>
                        <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
                    </button>
                </div>
                <AddCallTypeModal isOpen={modalVisible} onClose={handleCloseModal} fetchNatureOfCallsList={fetchNatureOfCallsList} documentData={selectedDocument} onValueChange={onRefresh} />
                <DeleteConfirmationModal
                    isOpen={isDeleteModalOpen}
                    message={`Are you sure you want to delete ${selectedDocument?.call?.sc_type_name}?`}
                    onClose={handleCloseDeleteModal}
                    onConfirm={() => {
                        handleCloseDeleteModal(); // Close modal
                        handleNatureOfCallsDelete(selectedDocument.sc_type_id, selectedDocument.index); // Perform delete operation
                    }}
                />
            </div>
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default NatureCallTypeListScreen;
