import React,{useState} from "react";
import Tabs from "../../../../Components/Tabs";
import editIcon from "../../../../Assets/Icons/edit.png";
import deleteIcon from "../../../../Assets/Icons/delete.png";
import AddTaskCategoryModal from "../../../../Components/Modals/AddTaskCategoryModal";
import useTaskCategoryController from "../../../Controllers/AdminManagementScreens/TaskManagement/TaskCategoryController";
import Loader from "../../../../Components/Loader";
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import DeleteConfirmationModal from "../../../../Components/Modals/DeleteConformationModal";

const TaskCategoryScreen = ({categories,loading,error,onRefresh}) => {
  const {
    // categories,
    isModalOpen,
    handleAddCategory,
    handleCloseModal,
    getStatusStyle,
    handleEditService,
    categorieInfo,
    categoryInfoRef,
    OnAddUpdateSucess,
    handleDeleteTaskCategory,
    // loading,
    deleteLoading,
    deleteError,
    // error,
    currentPage,
        totalPages,
        handlePageChange,
        currentCategories,
        isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteTaskCategory,
    deleteSuccess,
  } = useTaskCategoryController(onRefresh, categories);

  // //console.log('TaskCategoryScreen @@@ deleteLoading ', deleteLoading)
  // //console.log('TaskCategoryScreen @@@ deleteError ', deleteError)

  const navSubServiceList = ()=>{
    //console.log('service info')
  }


  return (
    <div className="basic-info-container">
      <div style={{ display:'flex', justifyContent:'space-between',paddingBottom:10,alignItems:'center'}}>
        <h3 style={styles.categoryHeader}>Services</h3>
        <div>
        <button className="add-client-button" style={{paddingLeft:30,paddingRight:30}} onClick={handleAddCategory}>
              Add Service
            </button>
      </div>
      </div>

      <div style={styles.tableWrapper}>
        <table className="tasksScreen-task-table">
          <thead>
            <tr>
              <th style={{ width: "30%" }}>Service Name</th>
              <th style={{ width: "50%" }}>Description</th>
              <th style={{ width: "10%" }}>Created By</th>
              <th style={{ width: "10%" }}>Status</th>
              <th style={{ width: "10%" }}></th>
            </tr>
          </thead>
          {currentCategories.length > 0 &&
          <tbody style={{ backgroundColor: "white" }}>
            {currentCategories?.map((category, index) => (
              <tr key={index}>
                <td style={styles.tableLink} onClick={navSubServiceList}>{category?.service_name}</td>
                <td style={styles.tableValue}>{category?.service_desc || '--'}
                {deleteSuccess[category?.service_id] &&
                        <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{deleteSuccess[category?.service_id]}</div>}
                  {deleteError[category?.service_id] && 
                  <div style={{...styles.deleteErrorMsg, paddingTop: 10}} >{deleteError[category?.service_id]}</div>}
                </td>
                <td style={styles.tableValue}>{category?.create_by_name || '--'}</td>
                {/* {/* <td style={styles.tableValue}>{category?.org_id}</td> */}
                <td style={styles.tableValue}>
                  <div
                    style={{
                      display: "inline-block",
                      padding: "5px 10px",
                      borderRadius: "15px",
                      ...getStatusStyle(category?.service_status),
                      width: "auto",
                    }}
                  >
                    {category?.service_status === 1 ? 'Active':'Disabled'}
                  </div>
                </td>
                <td>
                  <div style={{ display: "flex", gap: "2px" }}>
                    <button
                      className="tasksScreen-edit-button"
                      style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                      }}
                      onClick={() => {handleEditService(category)}}
                    >
                      <img src={editIcon} alt="Edit" style={{ width: "16px", height: "16px" }} />
                    </button>
                    <button
                      className="tasksScreen-delete-button"
                      style={{
                        border: "none",
                        background: "transparent",
                        cursor: "pointer",
                        opacity: deleteLoading[category?.service_id] ? 0.3 : 1
                      }}
                      onClick={() => {handleDeleteTaskCategory(category)}}
                      disabled={deleteLoading[category?.service_id]}
                    >
                      <img src={deleteIcon} alt="Delete" style={{ width: "16px", height: "16px" }} />
                    </button>
                    {deleteLoading[category?.service_id] &&
                      <span><Loader loading={deleteLoading[category?.service_id]} color={'var(--primary-color)'} size='small' /></span> 
                    }
                    
                  </div>
                </td>
              </tr>
            ))}
          </tbody>
          }
        </table>
        {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
        {error && <div className="list-error-msg">{error} </div>}
        <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        message={`Are you sure you want to delete ${categoryInfoRef.current?.service_name}?`}
        onClose={handleCloseDeleteModal}
        onConfirm={() => {
          handleCloseDeleteModal(); // Close modal
          callDeleteTaskCategory(categoryInfoRef.current); // Perform delete operation
        }}
      />
        <AddTaskCategoryModal isOpen={isModalOpen} onClose={handleCloseModal} editData={categoryInfoRef.current} onValueChange={OnAddUpdateSucess}/>
        <div className="tasksScreen-table-footer">
            <span className="tasksScreen-pagination-info">
              {`${currentPage}-${totalPages} of items`}
            </span>
            <div className="tasksScreen-pagination">
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || totalPages === 0}>
                <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
              ))}
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}>
                <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
              </button>
            </div>
          </div>
      </div>
    </div>
  );
};

const styles = {
  categoryHeader: {
    color: "#1F384C",
    fontSize: 28,
    fontFamily: "Roboto Slab",
    fontWeight: 500,
  },
  tableWrapper: {
    overflowX: "auto",
    whiteSpace: "nowrap",
    marginBottom: 50,
  },
  tableValue: {
    color: "#192A3E",
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
  },
  tableLink: {
    fontFamily: "Roboto",
    fontWeight: 400,
    fontSize: "12px",
    textDecoration: "underline",
    color: "#5A55D2",
    cursor:'pointer'
  },
  deleteErrorMsg: {
    color: 'red',
  }
};

export default TaskCategoryScreen;
