
import React, { useState, useRef, useEffect } from "react";
import Dropdown from "../Dropdown";
import DateInput from "../DateInput";
import closeIcon from "../../Assets/Icons/close.png";
import { postData } from "../../ApiServices/ApiService";
import { ORG_UPDATE_TO_DO_STATUS } from "../../ApiServices/BaseURL";
import Loader from "../Loader";

const ToDoCompleteModal = ({ isOpen, onClose, selectedToDoInfo, fetchToDoList }) => {

  const handleClose = () => {
    onClose();
    setError('');
  }

  const dropDownOptions = [
    { "label": "IN-Progress", "value": 2 },
    { "label": "Done", "value": 3 },
    { "label": "Cancelled", "value": 4 }
  ]
  const [dropdownValue, setDropdownOptions] = useState({
    "label": "",
    "value": 0
  });

  const [dateTime, setDateTime] = useState("");

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

      const dropdownRefs = useRef({}); // Ref for dropdown
  
      // Handle clicks outside dropdowns
      useEffect(() => {
          const handleClickOutside = (event) => {
              const isOutside = Object.keys(dropdownRefs.current).every(
                  (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
              );
              if (isOutside) {
                  setOpenDropdown(null);
              }
          };
  
          document.addEventListener("mousedown", handleClickOutside);
          return () => {
              document.removeEventListener("mousedown", handleClickOutside);
          };
      }, []);

  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('')

  useEffect(() => {
    //console.log('selectedToDoInfo:--',selectedToDoInfo);
    if (selectedToDoInfo) {
      setDropdownOptions({
        label: selectedToDoInfo?.sc_status_name,
        value: selectedToDoInfo?.sc_status
      })
      setDateTime(selectedToDoInfo?.sc_time.replace(' ','T'))
    }


  }, [selectedToDoInfo]);

  const handleUpdate = async () => {
    try {
      setError('');
      setSuccess('');
      if (!dateTime) {
        setError('Date is required'); return
      }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      // const locationValue = location === 'Online' ? 2 : 1;
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "emp_id": storedUserInfo?.emp_id,
        "sc_time": dateTime.replace('T',' '),
        "sc_id": selectedToDoInfo?.sc_id,
        "sc_status": dropdownValue?.value
      }
      //console.log('ToDo status payload', payLoad);
      // Make the API call
      const response = await postData(ORG_UPDATE_TO_DO_STATUS, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            setTimeout(() => {
              fetchToDoList();
              handleClose();
              setSuccess('');

            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to update ToDo status. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to update ToDo status.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update ToDo status. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to update ToDo status. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  }

  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content" style={{ width: 200, borderRadius: '10px 0px 0px 10px' }}>
        <button className="close-button" onClick={() => { handleClose(); }}>
          <img src={closeIcon} alt="Close" style={styles.closeIcon} />
        </button>
        <h2 className="modal-title">To-Do Complete</h2>
        <hr className="title-divider" />

        <div className="form-grid" style={{ display: 'flex', flexDirection: 'column' }}>
          <Dropdown
            ref={(el) => (dropdownRefs.current[" "] = el)}
            isOpen={openDropdown === " "}
            toggleDropdown={() => toggleDropdown(" ")}
            label="To-Do Status"
            options={dropDownOptions}
            value={dropdownValue}
            onChange={setDropdownOptions}
          />
          <div>
            <label style={{ fontSize: '12px', color: '#343C44' }}>Update Due Date</label>
            <DateInput
              value={dateTime}
              onChange={(e) => setDateTime(e.target.value)}
              isDateTime={true}
            />
          </div>

        </div>
        <hr className="footer-divider" />
        {error && <div className="list-error-msg" style={{ padding: 0 }}> {error}</div>}
        {success && <div className="list-success-msg" style={{ padding: 0 }}> {success}</div>}

        <div className="button-container">
          <button className="add-client-button" onClick={handleUpdate} style={{ opacity: loading ? 0.5 : 1 }} disabled={loading}> {loading &&
            <span style={{ paddingRight: 5 }} ><Loader loading={loading} color={'var(--background-color)'} size="small" /> </span>
          } Update</button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
        </div>
      </div>
    </div>
  )

}

export default ToDoCompleteModal

const styles = {
  closeIcon: {
    width: "12px",
    height: "12px",
  },
}