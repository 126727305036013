import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../../Components/Tabs';
import DSCActiveCertificate from './DSCActiveCertificate';
import DSCExpiredCertificate from './DSCExpiredCertificate';
import DSCCenterController from '../../../../Controllers/AdminScreens/AddOns/DSCCenter/DSCCenterController';


const DSCCenterScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const {
        tasks: currentTasks, // Only return tasks for the current page
        currentPage,
        totalPages,
        handlePageChange,
        filterTasks, // Expose the filter function
    } = DSCCenterController();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{ paddingBottom: 10 }}>
                <h2 className='title'>Notice Board</h2>
            </div>

            <div >
                <Tabs tabs={['Active Certificate', 'Expired Certificate']}
                selectedIndex={selectedTab}
                onTabChange={handleTabChange}>
                    <DSCActiveCertificate currentTasks={currentTasks} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                    <DSCExpiredCertificate currentTasks={currentTasks} currentPage={currentPage} totalPages={totalPages} handlePageChange={handlePageChange} />
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default DSCCenterScreen;