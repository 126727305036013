import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import { postData, getData } from '../../ApiServices/ApiService';
import { ORG_ADD_SUB_SERVICE_CHECK, ORG_UPDATE_SUB_SERVICE_CHECK, ORG_GET_SERVICE_LIST_SEARCH, ORG_GET_SUB_SERVICE_LIST_SEARCH } from '../../ApiServices/BaseURL';
import Loader from '../Loader';

const AddTaskChecksModal = ({ isOpen, onClose, onValueChange, editData }) => {
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [indexValue, setIndexValue] = useState(null)

  const fileInputRef = useRef(null);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const taskCategoryOptions = useRef([]);
  const [taskCategoryValue, setTaskCategoryValue] = useState(null)
  const taskSubCategoryOptions = useRef([]);
  const [taskSubCategoryValue, setTaskSubCategoryValue] = useState(null);
  const [taskNameError, setTaskNameError] = useState('');
  const [serviceError, setServiceError] = useState('');
  const [serviceCategoryError, setServiceCategoryError] = useState('');
  const [indexValueError, setIndexValueError] = useState('');
  const [statusError, setStatusError] = useState('');

  const [catListloading, setCatListLoading] = useState(false);
  const [subCatListloading, setSubCatListLoading] = useState(false);
  const [servicesName, setServicesName] = useState('')
  const [servicesCategory, setServicesCategory] = useState('')
  const [success, setSuccess] = useState('');

  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  useEffect(() => {
    if (!editData) {
      fetchTaskCategorieList();
    }
    // fetchTaskSubCategorieList();
    // setError('');
  }, [])

  useEffect(() => {
    //console.log('taskCheck ', editData)
    if (editData) {
      //console.log('@@@@taskCheck if @@@@', editData)
      setDocumentName(editData?.service_check_name)
      setIndexValue(editData?.display_order)
      setTaskCategoryValue({ label: editData?.service_name, value: editData?.service_id })
      setTaskSubCategoryValue({ label: editData?.service_sub_name, value: editData?.service_sub_id })
      setGroupClient(editData?.service_check_status === 1 ? 'Active' : 'Disabled')
    }
  }, [editData])

  const fetchTaskCategorieList = async () => {
    try {
      setError('');
      taskSubCategoryOptions.current = []
      setCatListLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id
      }

      //console.log('fetchTaskCategorieList payload', payLoad);

      // Make the API call
      const response = await postData(ORG_GET_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              taskCategoryOptions.current = responseData?.dataJ.map(service => ({
                label: service.service_name,
                value: service.service_id
              }));
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setCatListLoading(false);
    }
  }

  const fetchTaskSubCategorieList = async (serviceId) => {
    try {
      setError('');
      taskSubCategoryOptions.current = [];
      setTaskSubCategoryValue(null)
      setSubCatListLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": serviceId
      }

      //console.log('--fetchTaskSubCategorieList payload--', payLoad);

      // Make the API call service_id=10006&
      const response = await postData(ORG_GET_SUB_SERVICE_LIST_SEARCH, payLoad);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              // Transforming the data
              taskSubCategoryOptions.current = responseData?.dataJ.map(service => ({
                label: service.service_sub_name,
                value: service.service_sub_id
              }));
            } else {
              setError(responseData?.info || 'Data Not Found');
            }
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setSubCatListLoading(false);
    }
  }

  // useEffect(() => {
  //   if (clientName || clientType || identificationType || identificationNumber || groupClient || mobileNumber) {
  //     setShowUpload(true);
  //   } else {
  //     setShowUpload(false);
  //   }
  // }, [clientName, clientType, identificationType, identificationNumber, groupClient, mobileNumber]);

  // Reset form fields to initial values
  const resetForm = () => {
    setDocumentName('');
    setDescription('');
    setTaskCategoryValue(null);
    setTaskSubCategoryValue(null);
    setIndexValue(null);
    setTaskCategoryValue(null);
    setTaskSubCategoryValue(null);
    setError('');
    setTaskNameError('');
    setServiceError('');
    setServiceCategoryError('');
    setIndexValueError('');
    setStatusError('');
    setSuccess('')
  };

  // Run resetForm when modal closes
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleAddClient = async () => {

    // return
    try {
      setError('');
      setSuccess('')
      setTaskNameError('');
      setServiceError('');
      setServiceCategoryError('');
      setIndexValueError('');
      setStatusError('');

      if (!documentName) {
        setTaskNameError("Please enter the task check name");
        return;
      }

      if (!taskCategoryValue) {
        setServiceError("Please select the service");
        return;
      }

      if (!taskSubCategoryValue) {
        setServiceCategoryError("Please select the service category");
        return;
      }

      if (!indexValue) {
        setIndexValueError("Please enter the index number");
        return;
      }

      if (!groupClient) {
        setStatusError("Please select the status");
        return;
      }

      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_check_name": documentName,
        "service_id": taskCategoryValue?.value,
        "service_sub_id": taskSubCategoryValue?.value,
        "service_check_status": groupClient === 'Active' ? 1 : 0,
        "display_order": Number(indexValue)
      }

      //console.log('catagory Data', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_SUB_SERVICE_CHECK, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }


    // onValueChange(clientData); // Send data to Dashboard
    // onClose(); // Close modal
  };
  const handleClose = () => {
    setError('');
    onClose();
    setSuccess('')
  }
  // Handle file input change
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      //console.log("Uploaded file:", files[0]);
      // Handle file upload here (e.g., upload to server or process locally)
    }
  };

  // Trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };

  const groupClientOptions = ["Active", "Disabled"];
  const [groupClient, setGroupClient] = useState('');

  if (!isOpen) return null;

  const handleUpdateTaskCheck = async () => {

    try {
      setError('');
      setSuccess('')
      setTaskNameError('');
      setServiceError('');
      setServiceCategoryError('');
      setIndexValueError('');
      setStatusError('');

      if (!documentName) {
        setTaskNameError("Please enter the task check name");
        return;
      }

      if (!taskCategoryValue) {
        setServiceError("Please select the service");
        return;
      }

      if (!taskSubCategoryValue) {
        setServiceCategoryError("Please select the service category");
        return;
      }

      if (!indexValue) {
        setIndexValueError("Please enter the index number");
        return;
      }

      if (!groupClient) {
        setStatusError("Please select the status");
        return;
      }

      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_check_name": documentName,
        "service_id": taskCategoryValue?.value || '',
        "service_sub_id": taskSubCategoryValue?.value || '',
        "service_check_status": groupClient === 'Active' ? 1 : 0,
        "display_order": Number(indexValue),
        "service_check_id": editData?.service_check_id
      }

      //console.log('handleUpdateTaskCheck Data', payLoad);
      // Make the API call
      const response = await postData(ORG_UPDATE_SUB_SERVICE_CHECK, payLoad);
      const responseData = await response.json();
      //console.log('handleUpdateTaskCheck Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }


    // onValueChange(clientData); // Send data to Dashboard
    // onClose(); // Close modal
  }

  const handleTaskCategoryValue = (value) => {
    //console.log('value:', value);
    setTaskCategoryValue(value);
    if (value) {
      fetchTaskSubCategorieList(value?.value)
    }
  }



  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        {!editData ? <h2 className="modal-title">Add Task Checks</h2> :
          <h2 className="modal-title">Update Task Checks</h2>}
        <hr className="title-divider" />

        <div className="form-grid" style={{ marginBottom: "15px", }}>
          <div style={styles.fullWidth}>
            <Input
              label="Task Check Name"
              placeholder="Enter Check Name"
              value={documentName || ''}
              onChange={(e) => {setDocumentName(e.target.value);setTaskNameError('')}}
              width={'99%'}
              errorMessage={taskNameError}
            />
          </div>
        </div>
        <div className="form-grid">
          {editData && (
            <Input
              label="Service"
              placeholder="Enter Service Name"
              value={editData?.service_name || ''}
              onChange={(e) => setServicesName(e.target.value)}
              width={'99%'}
              // errorMessage={taskNameError}
              readOnly={!!editData}
            />
          )}
          {!editData && (

            <Dropdown
            ref={(el) => (dropdownRefs.current["Service"] = el)}
              isOpen={openDropdown === "Service"}
              toggleDropdown={() => toggleDropdown("Service")}
              label="Service"
              options={taskCategoryOptions.current}
              value={taskCategoryValue}
              onChange={(value) =>{handleTaskCategoryValue(value);setServiceError('')}}
              width={'250px'}
              loading={catListloading}
              errorMessage={serviceError}
            />
          )}
          {editData && (
            <Input
              label="Service Category"
              placeholder="Enter Service Category"
              value={editData?.service_sub_name || ''}
              onChange={(e) => setServicesCategory(e.target.value)}
              width={'99%'}
              // errorMessage={taskNameError}
              readOnly={!!editData}
            />
          )}
          {!editData && (
            <Dropdown
            ref={(el) => (dropdownRefs.current["taskSubCategory"] = el)}
              isOpen={openDropdown === "taskSubCategory"}
              toggleDropdown={() => toggleDropdown("taskSubCategory")}
              label="Service Category"
              options={taskSubCategoryOptions.current}
              value={taskSubCategoryValue}
              onChange={(value)=>{setTaskSubCategoryValue(value);setServiceCategoryError('')}}
              width={'250px'}
              loading={subCatListloading}
              errorMessage={serviceCategoryError}
            />
          )}
          {/* </div> */}
          {/* <div style={styles.row}> */}

          <div >
            <Input
              label="Index No."
              placeholder="Enter Index Number"
              value={indexValue || ''}
              onChange={(e) => {setIndexValue(e.target.value);setIndexValueError('')}}
              width={'250px'}
              errorMessage={indexValueError}
              inputType='number'
            />
          </div>
          {/* </div> */}

          <Dropdown
            ref={(el) => (dropdownRefs.current["groupClient"] = el)}
            isOpen={openDropdown === "groupClient"}
            toggleDropdown={() => toggleDropdown("groupClient")}
            label="Status"
            options={groupClientOptions}
            value={groupClient}
            onChange={(value)=>{setGroupClient(value);setStatusError('')}}
            errorMessage={statusError}
            width={'250px'}
          />
        </div>
        {/* {showUpload && ( */}
        {/* <div style={styles.uploadContainer} onClick={handleUploadClick} onDrop={(e) => e.preventDefault()}>
            <div style={styles.uploadBox} onDragOver={(e) => e.preventDefault()}>
              <span style={styles.uploadCircle}>
              <img src={pluseIcon} alt="Plus Icon" style={styles.iconImage} />
              </span>
              <p style={styles.uploadText}>Upload Form 16 for faster onboarding, Drop here or click on upload</p>
              <img src={uploadIcon} alt="Upload Icon" style={styles.uploadIconImage} />
  
             
              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                onChange={handleFileChange}
              />
            </div>
          </div> */}
        {/* )} */}
        {/* <div className="existing-client-checkbox">
              <label style={styles.exisitingHeading}>Primary Contact?</label>
              <input type="checkbox" checked={primaryContact} onChange={(e) => setPrimartContact(e.target.checked)} />
            </div> */}

        <hr className="footer-divider" />

        <div className="button-container" style={{ alignItems: 'center' }}>
          {!editData ? <button className="add-client-button" onClick={handleAddClient} style={{ paddingLeft: 30, paddingRight: 30, opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add</button> :
            <button className="add-client-button" onClick={handleUpdateTaskCheck} style={{ paddingLeft: 30, paddingRight: 30, opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Update</button>}
          <button className="cancel-button" onClick={() => { resetForm(); onClose(); }}>Cancel</button>
          {error && <div className="list-error-msg" style={{ marginBottom: '3px' }}> {error} </div>}
          {success && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{success} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  uploadContainer: {
    marginTop: '20px',
    width: '97%',
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #609966',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  iconImage: {
    width: '11px',
    height: '11px',
  },
  uploadIconImage: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr", // Single-column layout by default
    gap: "20px", // Space between rows
    marginBottom: "15px",
  },
  halfWidth: {
    gridColumn: "span 1",
    width: "48%", // Half width
    marginRight: "auto", // Aligns left
    marginBottom: '17px'
  },
  fullWidth: {
    gridColumn: "span 2",
    // width: "97%", // Full width
  },
  row: {
    display: "flex",
    justifyContent: "space-between",
    gap: "20px", // Space between dropdowns
    marginBottom: "15px",
    marginRight: "auto",
  },
}

export default AddTaskChecksModal;