import React, { useState, useRef, useEffect } from 'react';
import Input from '../Input';
import closeIcon from '../../Assets/Icons/close.png';
import { postData } from '../../ApiServices/ApiService';
import Loader from '../Loader';
import { ORG_ADD_USER_CREDENTIAL, ORG_UPDATE_USER_CREDENTIAL } from '../../ApiServices/BaseURL';
import { validatePassword } from '../../Utils/Validation';

const AddUserIdPasswordModal = ({ isOpen, onClose, clientId, editData, onValueChange }) => {
  const [portalName, setPortalName] = useState(editData?.portalName || '');
  const [portalUrl, setPortalUrl] = useState(editData?.url || '');
  const [userName, setUserName] = useState(editData?.username || '');
  const [password, setPassword] = useState(editData?.password || '');
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [success, setSuccess] = useState('');
  const [portalNameError, setPortalNameError] = useState('');
  const [userNameError, setUserNameError] = useState('');
  const [userPasswordError, setUserPasswordError] = useState('');
  const [urlError, setUrlError] = useState('');

  useEffect(() => {
    //console.log('addupdate contact ', editData);
    if (editData) {
      //console.log('addupdate contact if @@@@', editData)
      setPortalName(editData?.portalName)
      setPortalUrl(editData?.url)
      setUserName(editData?.username)
      setPassword(editData?.password)
    } else {
      resetForm();
    }

  }, [isOpen, editData])

  const resetForm = () => {
    setSuccess('');
    setError('');
    setPortalName('');
    setPortalUrl('');
    setUserName('');
    setPassword('');
    setPortalNameError('');
    setUserNameError('');
    setUserPasswordError('');
    setUrlError('');
  };

  // Run resetForm when modal closes
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleClose = () => {
    setError('');
    resetForm();
    onClose();
    setSuccess('')
  }

  const handleAddCredential = async () => {
    try {
      setPortalNameError('');
      setUserNameError('');
      setUserPasswordError('');
      setUrlError('');
      setSuccess('')
      if (!portalName) {
        setPortalNameError("Please enter the portal name");
        return;
      }
      if (!portalUrl) {
        setUrlError("Please enter the portal url");
        return;
      }
      if (!userName) {
        setUserNameError("Please enter the user name");
        return;
      }
      if (!password) {
        setUserPasswordError("Please enter the valid password");
        return;
      }

      setError('');
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "client_id": clientId,
        "account_name": portalName,
        "account_user_name": userName,
        "account_user_password": password,
        "account_web_url": portalUrl,
        "emp_id_auth": storedUserInfo?.emp_id,
      }

      //console.log('handleAddCredential payload', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_USER_CREDENTIAL, payLoad);
      const responseData = await response.json();
      //console.log('handleAddCredential Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to add new contact. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to add new contact.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add new contact. Please try again.'}`);
      } else {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      }
      // else {
      //   // An error occurred in setting up the request
      //   setError(`Failed to add new contact. Please check your network connection and try again.`);
      // }
    } finally {
      setLoading(false);
    }
  };

  const handleUpdateCredential = async () => {
    try {
      setPortalNameError('');
      setUserNameError('');
      setUserPasswordError('');
      setUrlError('');
      setSuccess('')
      if (!portalName) {
        setPortalNameError("Please enter the portal name");
        return;
      }
      if (!portalUrl) {
        setUrlError("Please enter the portal URL");
        return;
      }
      if (!userName) {
        setUserNameError("Please enter the username");
        return;
      }
      if (!password) {
        setUserPasswordError("Please enter a valid password");
        return;
      }

      setError('');
      setLoading(true);

      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "client_id": clientId,
        "account_name": portalName,
        "account_user_name": userName,
        "account_user_password": password,
        "account_web_url": portalUrl,
        "emp_id_auth": storedUserInfo?.emp_id,
        "account_id": editData?.account_id,
      };

      //console.log('handleUpdateCredential payload', payLoad);

      const response = await postData(ORG_UPDATE_USER_CREDENTIAL, payLoad);
      const responseData = await response.json();

      //console.log('handleUpdateCredential Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200 && responseData?.statusCode === 200) {
        if (responseData?.message.toLowerCase() === 'success') {
          setSuccess(responseData?.info || 'Credential updated successfully.');
          setTimeout(() => {
            handleClose();
            onValueChange(); // Trigger parent callback for updated data
          }, 3000);
        } else {
          setError(responseData?.info || 'Failed to update credential. Please try again.');
        }
      } else {
        setError(responseData?.info || 'Error occurred while updating credential.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update credential. Please try again.'}`);
      } else {
        setError('No response received from the server. Please check your network connection and try again.');
      }
      // else {
      //   setError(`Failed to update credential. Please check your network connection and try again.`);
      // }
    } finally {
      setLoading(false);
    }
  };

  if (!isOpen) return null;

  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        {!editData ? <h2 className="modal-title">Add User ID and Password</h2> :
          <h2 className="modal-title">Update User ID and Password</h2>}
        <hr className="title-divider" />

        <div className="form-grid">
          <Input label="Portal Name" placeholder="Enter Portal Name" value={portalName} onChange={(e) => setPortalName(e.target.value)} errorMessage={portalNameError} />
          <Input label="Portal URL" placeholder="Portal URL" value={portalUrl} onChange={(e) => setPortalUrl(e.target.value)} errorMessage={urlError} />
          <Input label="Username" placeholder="Enter Username" value={userName} onChange={(e) => setUserName(e.target.value)} errorMessage={userNameError} />
          <Input label="Password" placeholder="Enter Password" value={password} onChange={(e) => setPassword(e.target.value)} errorMessage={userPasswordError} />

        </div>
        <hr className="footer-divider" style={{ marginTop: '50px' }} />
        

        <div className="button-container" style={{alignItems:'center'}}>
          <button className="add-client-button" onClick={editData ? handleUpdateCredential : handleAddCredential} style={{ opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>{editData ? 'Update Credentials' : 'Add Credentials'}</button>
          <button className="cancel-button" onClick={() => { handleClose(); }}>Cancel</button>
          {error && <div className="list-error-msg" style={{marginBottom:'3px'}}>{error} </div>}
        {success && <div className="list-success-msg" style={{marginBottom:'3px'}}>{success} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  uploadContainer: {
    marginTop: '20px',
    width: '97%',
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #8FC7F2',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  iconImage: {
    width: '15px',
    height: '15px',
  },
  uploadIconImage: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  }
}

export default AddUserIdPasswordModal;