import React, { useState, useRef, useEffect } from 'react';
import Tabs from '../../../../Components/Tabs';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png';
import AddGroupModal from '../../../../Components/Modals/AddGroupModal';
import Input from '../../../../Components/Input';
import MobileInput from '../../../../Components/MobileInput';
import filterIcon from '../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../Assets/Icons/back.png'
import useManageGroupsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageGroupsController';
import Loader from '../../../../Components/Loader';
import { validateMobileNumber } from '../../../../Utils/Validation';
import downArrow from '../../../../Assets/Icons/chevron-down.png';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import TextInput from '../../../../Components/TextInput';
import searchIcon from '../../../../Assets/Icons/search.png';

const ManageGroupsScreen = () => {

  const {
    groupsList,
    isModalOpen,
    username,
    mobileNumber,
    countryCode,
    handleAddGroup,
    handleCloseModal,
    getStatusStyle,
    setUsername,
    setMobileNumber,
    setCountryCode,
    OnAddUpdateSucess,
    loading,
    error,
    handleEditGroup,
    handleDeleteGroup,
    deleteGroupLoading,
    deleteGroupError,
    currentGroupsList,
    currentPage,
    totalPages,
    handlePageChange,
    isDeleteModalOpen,
    handleCloseDeleteModal,
    callDeleteGroup,
    groupInfoRef,
    applyLoading,
    groupMasterError, mobileNumberError,
    clientList, clientListLoading,
    handleSearchClient, selectedClient, handleSearchInputText,
    searchClient, setSearchClient, handleSelectClient,
    filterError, filters, showFilters,
    handleFilterChange,
    applyFilters, resetFilters, cancelFilters,
    toggleFilters,
    deleteGroupSuccess,
    deletedMessages
  } = useManageGroupsController();

  return (
    <div style={{ padding: '0px 40px 20px 40px' }}>
      <div style={{ fontSize: 26, paddingBottom: 10 }}>Groups</div>
      <div className='basic-info-container ' style={styles.searchFilterDiv} >
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>Search Groups</h3>
          <div style={{alignSelf:'center'}}>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30 }} onClick={handleAddGroup}>
              Add Groups
            </button>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} alt="Filter" />  <span>
              {showFilters ? 'Filters' : 'Filters'}
            </span></button>
            <div >
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div style={{ display: 'flex', justifyContent: 'flex-start', flexWrap: 'wrap', gap: 40 }}>
              <Input
                label="Client Name"
                placeholder="Enter Client Name"
                value={filters?.client_name}
                onChange={(e) => handleFilterChange('client_name', e.target.value)}
                // errorMessage={clientNameError}
                searchBoxWidth={230}
              />
              <div className="filter-field">
                <label
                  style={{
                    ...styles.labaleStyle,
                    display: 'block'
                  }}
                >
                  Group Master
                </label>
                <TextInput
                  type="text"
                  placeholder="Group Master"
                  value={searchClient}
                  onChange={(e) => handleSearchInputText(e.target.value)}
                  onRightClick={() => handleSearchClient(searchClient)}
                  right={
                    clientListLoading ? (
                      <Loader loading={clientListLoading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                    ) : (
                      <img src={searchIcon} alt="Search" style={{ cursor: 'pointer' }} />
                    )
                  }
                  searchBoxWidth={240}
                  style={{ padding: '20px 10px' }}
                  errorMessage={groupMasterError}
                />
                {/* {loading && <LoadingSkeleton width="40%" height="10px" />} */}
                {(clientList?.length > 0) && (
                  <ul style={styles.dropdownList}>
                    {clientList?.map((client, index) => (
                      <li
                        key={client?.client_id}
                        onClick={() => handleSelectClient(client)}
                        style={{
                          ...styles.dropdownItem,
                          borderBottom: index !== clientList?.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                        }}
                      >
                        {client?.client_name}
                      </li>
                    ))}
                  </ul>
                )}
              </div>
              {/* <Input
                label="Mobile No."
                placeholder="Enter Mobile Number"
                value={filters?.mobile_number}
                onChange={(e) => handleFilterChange('mobile_number', e.target.value)}
                searchBoxWidth={230}
                // isEdit={false}
              /> */}
              <MobileInput
                label="Mobile No."
                countryCode={countryCode}
                mobileNumber={mobileNumber}
                onCountryChange={setCountryCode}
                onMobileChange={setMobileNumber}
                placeholder="Enter mobile number"
                countries={[
                  { code: "IN", dialCode: "+91" },
                  { code: "US", dialCode: "+1" },
                  { code: "UK", dialCode: "+44" },
                ]}
                width={280}
                errorMessage={mobileNumberError}
              />

              {/* <Input
          label="Email ID"
          placeholder="------"
          value={email}
          onChange={(e) => setEmail(e.target.value)}
        /> */}
            </div>
            {filterError && <div className="list-error-msg" style={{ textAlign: 'left', paddingBottom: 5 }}> {filterError}</div>}
            <div className="tasksScreen-filter-buttons">
              <button onClick={applyFilters} className="tasksScreen-apply-button" style={{cursor: applyLoading ? "not-allowed" : "pointer", opacity:applyLoading ? 0.5 : 1}} disabled={applyLoading}>Apply</button>
              <button onClick={cancelFilters} className="tasksScreen-cancel-button">Cancel</button>
              <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
            </div>
          </div>
        )}

      </div>

      <div className='basic-info-card' style={{ border: 'none' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "25%" }}>Group Name</th>
                <th style={{ width: "15%" }}>Group Master</th>
                <th style={{ width: "45%" }}>Notes</th>
                <th style={{ width: "10%" }}>Created By</th>
                <th style={{ width: "5%" }}>Status</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {currentGroupsList?.map((category, index) => (
                <tr key={index}>
                  <td style={styles.tableLink} onClick={() => handleEditGroup(category, true)}>{category?.group_name || '--'}</td>
                  <td style={styles.tableValue}>{category?.client_id_primary_name || '--'}</td>
                  <td style={styles.tableValue}>{category?.group_desc || '--'}
                    {deleteGroupSuccess[category?.group_id] &&
                        <div style={{ color: 'green', textDecoration: 'none', paddingTop: 10 }} >{deleteGroupSuccess[category?.group_id]}</div>}
                    {deleteGroupError[category?.group_id] &&
                      <div style={{ ...styles.deleteErrorMsg, paddingTop: 10 }} >{deleteGroupError[category?.group_id]}</div>}
                  </td>
                  <td style={styles.tableValue}>{category?.create_by_name || '--'}</td>
                  <td style={styles.tableValue}>
                    {/* Status Label */}
                    <div
                      style={{
                        display: "inline-block", // Makes the div wrap content and have its own width
                        padding: "5px 5px", // Padding for the button-like appearance
                        borderRadius: "15px",
                        ...getStatusStyle(category?.group_status), // Dynamic styles based on the status
                        width: "auto", // Width adjusts automatically to content
                      }}
                    >
                      {category?.group_status === 1 ? 'Active' : 'Disabled'}
                    </div>

                    {/* Action Buttons */}
                  </td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px", // Space between edit and delete buttons
                      }}
                    >
                      {/* Edit Button */}
                      <button
                        className="tasksScreen-edit-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: deleteGroupLoading[category?.group_id] || deletedMessages.includes(category?.group_id) ? 'not-allowed' : 'pointer',
                          pointerEvents: deleteGroupLoading[category?.group_id] || deletedMessages.includes(category?.group_id) ? 'none' : 'auto',
                            opacity: deleteGroupLoading[category?.group_id] ? 0.3 : 1
                        }}
                        onClick={() => handleEditGroup(category, true)}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>

                      {/* Delete Button */}
                      <button
                        className="tasksScreen-delete-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                          cursor: deleteGroupLoading[category?.group_id] ? 'not-allowed' : 'pointer',
                          pointerEvents: deleteGroupLoading[category?.group_id] || deletedMessages.includes(category?.group_id) ? 'none' : 'auto',
                          opacity: deleteGroupLoading[category?.group_id] ? 0.3 : 1
                        }}
                        onClick={() => { handleDeleteGroup(category) }}
                        disabled={deleteGroupLoading[category?.group_id]}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>
                      {deleteGroupLoading[category?.group_id] &&
                        <span><Loader loading={deleteGroupLoading[category?.group_id]} color={'var(--primary-color)'} size='small' /></span>
                      }
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
          {(loading || applyLoading) && <Loader loading={(loading || applyLoading)} color={'var(--primary-color)'} />}
          {error && <div className="list-error-msg">{error} </div>}
          <DeleteConfirmationModal
            isOpen={isDeleteModalOpen}
            message={`Are you sure you want to delete ${groupInfoRef.current?.group_name}?`}
            onClose={handleCloseDeleteModal}
            onConfirm={() => {
              handleCloseDeleteModal(); // Close modal
              callDeleteGroup(groupInfoRef.current); // Perform delete operation
            }}
          />
          <AddGroupModal isOpen={isModalOpen} onClose={handleCloseModal} onValueChange={OnAddUpdateSucess} />
          <div className="tasksScreen-table-footer">
            <span className="tasksScreen-pagination-info">
              {`${currentPage}-${totalPages} of items`}
            </span>
            <div className="tasksScreen-pagination">
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage - 1)} disabled={currentPage === 1 || totalPages === 0}>
                <img src={downArrow} alt="Previous" className="tasksScreen-arrow-left tasksScreen-icon-image " />
              </button>
              {[...Array(totalPages)].map((_, index) => (
                <button key={index} className={`tasksScreen-pagination-button ${currentPage === index + 1 ? 'active' : ''}`} onClick={() => handlePageChange(index + 1)}>{index + 1}</button>
              ))}
              <button className="tasksScreen-pagination-button" onClick={() => handlePageChange(currentPage + 1)} disabled={currentPage === totalPages || totalPages === 0}>
                <img src={downArrow} alt="Next" className="tasksScreen-arrow-right tasksScreen-icon-image " />
              </button>
            </div>
          </div>
        </div>

      </div>
    </div>

  )

};

const styles = {
  searchFilterDiv: {
    padding: '15px 20px',
    marginBottom: '20px',
    borderBottomRightRadius: '10px',
    borderBottomLeftRadius: '10px',
    borderLeft: '1px solid #9DC08B'
  },
  categoryHeader: {
    color: '#1F384C',
    fontSize: 28,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2',
    cursor: 'pointer'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },
  deleteErrorMsg: {
    color: 'red',
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },


}

export default ManageGroupsScreen;