import React, { useEffect, useState, useRef } from "react";
import closeIcon from '../../Assets/Icons/close.png';
import Dropdown from '../Dropdown';
import CheckBox from "../CheckBox";
import { postData } from "../../ApiServices/ApiService";
import { ORG_EMP_ACCESS_PERMISSION_UPDATE } from '../../ApiServices/BaseURL';
import Loader from "../Loader";

const ManageUserAccessModal = ({ isOpen, onClose, userInfo, selUserAccessService, onValueChange }) => {
    const [subPerAccessList, setSubPerAccessList] = useState(selUserAccessService?.per_name_sub_list || [])
    const [updateLoading, setUpdateLoading] = useState(false);
    const [updateError, setUpdateError] = useState('');
    const [updateSuccess, setupdateSuccess] = useState('');

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    useEffect(() => {
        //console.log('selUserAccessService @@@', selUserAccessService);
        if (selUserAccessService?.per_name_sub_list) {
            setSubPerAccessList(selUserAccessService?.per_name_sub_list);
        }
    }, [selUserAccessService])

    const accessLevelOptions = ["Manager A", "Manager B", "Manager C"];
    const [accessLevel, setAccessLevel] = useState('');

    const handleCheckboxChange = (subPerAccess, isChecked) => {
        //console.log('handleCheckboxChange subPerAccess @@@', subPerAccess, isChecked);
        const updatedList = subPerAccessList.map((item) =>
            item?.per_id === subPerAccess?.per_id ?
                { ...item, is_active: isChecked ? 1 : 0 } : item
        );

        setSubPerAccessList(updatedList);
    };

    useEffect(() => {
        if (!isOpen) {
            resetForm();
        }
    }, [isOpen]);

    const handleUpdate = async () => {
        //console.log("subPerAccessList:", subPerAccessList, 'userInfo: ', userInfo);

        try {
            setupdateSuccess('');
            setUpdateError('');
            setUpdateLoading(true);
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
            const payLoad = {
                emp_id_auth: storedUserInfo?.emp_id,
                emp_id: userInfo?.emp_id,
                per_name_code: selUserAccessService?.per_name_code,
                per_name_sub_list: subPerAccessList
            }

            //console.log('handleUpdate service payload', payLoad);
            // Make the API call
            const response = await postData(ORG_EMP_ACCESS_PERMISSION_UPDATE, payLoad);
            const responseData = await response.json();
            //console.log('handleUpdate service Data', responseData);

            // Check for success (200) and internal status code
            if (response?.status === 200) {
                if (responseData?.statusCode === 200) {
                    if (responseData?.message.toLowerCase() === 'success') {
                        setupdateSuccess(responseData?.info);
                        setTimeout(() => {
                            handleClose();
                            onValueChange();
                        }, 3000);
                    } else {
                        setUpdateError(responseData?.info || 'Failed to manage access. Please try again.');
                    }
                } else {
                    setUpdateError(responseData?.info || 'Failed to manage access.');
                }
            }
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                    setUpdateError('Unauthorized access. Your session may have expired. Please log in again.');
                } else {
                    setUpdateError(responseData?.info || 'Bad request. Please check the request parameters.');
                }
            }
            else if (response?.status === 500) {
                setUpdateError(responseData?.info || 'Internal Server Error. Please try again later.');
            }
            else {
                setUpdateError('Unexpected error occurred. Please try again later.');
            }
        } catch (error) {
            if (error.response) {
                setUpdateError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to manage access. Please try again.'}`);
            } else if (error.request) {
                setUpdateError('No response received from the server. Please check your network connection and try again.');
            } else {
                // An error occurred in setting up the request
                setUpdateError(`Failed to manage access. Please check your network connection and try again.`);
            }
        } finally {
            setUpdateLoading(false);
        }
    };

    if (!isOpen) return null;

    const handleClose = () => {
        setUpdateError('');
        onClose();
        setupdateSuccess('');
    }

    const resetForm = () => {
        setUpdateError('');
        setupdateSuccess('');
        setSubPerAccessList([]);
    }



    return (
        <div className="modal-overlay">
            <div className="modal-content" style={{ width: '664px' }}>
                <button className="close-button" onClick={handleClose}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">Manage Access</h2>
                <hr className="title-divider" />

                <div style={{ marginBottom: "18px" }}>
                    <label style={styles.label}>Module Name</label>
                    <div style={styles.categoryValue}>{selUserAccessService?.per_name}</div>
                </div>

                <div style={{ marginBottom: "20px" }}>
                    <label style={{ ...styles.label, color: 'black', fontSize: 14, paddingBottom: 5 }}>Select Sub Modules</label>
                    {subPerAccessList?.length > 0 && <div style={styles.specializationsGrid}>
                        {subPerAccessList?.map((subPerAccess, index) => (
                            <div key={index} style={styles.specializationItem}>
                                {/* <label style={styles.labelName}> */}
                                {/* <input
                                        type="checkbox"
                                        checked={selSubModules.includes(subModule)}
                                        onChange={() => handleCheckboxChange(subModule)}
                                        style={styles.checkbox}
                                    />
                                    <span>{subModule}</span> */}
                                <CheckBox
                                    label={subPerAccess?.per_name_sub}
                                    checked={subPerAccess?.is_active === 1}
                                    onChange={(isChecked) =>
                                        handleCheckboxChange(subPerAccess, isChecked)
                                    }
                                    style={styles.checkbox}
                                />
                                {/* </label> */}
                            </div>
                        ))}
                    </div>}
                </div>

                <div>
                    <Dropdown
                        ref={(el) => (dropdownRefs.current[" "] = el)}
                        isOpen={openDropdown === " "}
                        toggleDropdown={() => toggleDropdown(" ")}
                        label={'Access Level'}
                        options={accessLevelOptions}
                        value={accessLevel}
                        onChange={setAccessLevel}
                    />
                </div>

                <hr className="footer-divider" />

                <div className="button-container">
                    <button className="add-client-button" onClick={handleUpdate} style={{ opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer' }} disabled={updateLoading}>
                        <span style={{ paddingRight: updateLoading ? 10 : 0 }}><Loader loading={updateLoading} color={'#FFFFFF'} size='small' /></span>
                        Update
                    </button>
                    <button className="cancel-button" onClick={() => { resetForm(); handleClose(); }}>
                        Cancel
                    </button>
                    {updateError && <div className="list-error-msg" style={{ marginBottom: '3px' }}> {updateError} </div>}
                    {updateSuccess && <div className="list-success-msg" style={{ marginBottom: '3px' }}> {updateSuccess} </div>}
                </div>
            </div>
        </div>
    )

};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    label: {
        display: "block",
        fontWeight: "400",
        marginBottom: "5px",
        color: "#767676",
        fontSize: "12px",
    },
    categoryValue: {
        color: "#060606",
        fontSize: "14px",
        fontWeight: "400"
    },
    specializationsGrid: {
        display: "grid",
        gridTemplateColumns: "repeat(auto-fit, minmax(143px, 1fr))",
        gap: "10px",
    },
    specializationItem: {
        display: "flex",
        alignItems: "center",
        fontSize: "12px",
        color: "#343C44",
    },
    checkbox: {
        marginRight: "10px",
        color: "#343C44",
    },
    labelName: {
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center'
    }
};

export default ManageUserAccessModal;