import React, { useState, useRef, useEffect } from "react";
import Input from "../Input";
import Dropdown from "../Dropdown";
import DateInput from "../DateInput";
import closeIcon from "../../Assets/Icons/close.png";
import { ORG_NOTICE_BOARD_MSG_ADD,ORG_GET_TEAM_LIST,ORG_GET_EMPLOYEE_LIST,ORG_NOTICE_BOARD_MSG_UPDATE } from '../../ApiServices/BaseURL';
import { postData,getData } from '../../ApiServices/ApiService';
import Loader from '../Loader';
import errorIcon from '../../Assets/Icons/error_message.png';

const AddNoticeBoardMsgModal = ({ isOpen, onClose, onValueChange,documentData,expired }) => {
  // console.log("expired", expired);
    const [noticeMessage, setNoticeMessage] = useState("");
    const [recipientType, setRecipientType] = useState("");
    const [recipients, setRecipients] = useState([]);
    const [publishedDate, setPublishedDate] = useState("");
    const [scheduleDate, setScheduleDate] = useState("");
    const [expiryDate, setExpiryDate] = useState("");

    useEffect(() => {
        if (isOpen && documentData) {
          console.log("documentData in Notice", documentData);
          setNoticeMessage(documentData?.nb_message || '')
          setPublishedDate(documentData?.publish_at);
          setScheduleDate(documentData?.schedule_at)
          setExpiryDate(documentData?.expire_at)
          setRecipientType(documentData?.recipient_type);
          const members = documentData?.recipient_members
            ?.split(", ")
            .map(member => ({ label: member, value: member })) || [];

        setRecipients(members);
        }
      }, [isOpen, documentData]);

    const handlePublishedDateChange = (value) => {
        setPublishedDate(value);
      };
    
      const handleScheduleDateChange = (value) => {
        setScheduleDate(value);
      };
      const handleExpireDateChange = (value) => {
        setExpiryDate(value);
      };

    const recipientTypeOptions = [
        { label: "Everyone", value: 1 },
        { label: "Teamwise", value: 2 },
        { label: "Userwise", value: 3 },
        { label: "Others", value: 4 },
    ];

    // let recipientOptions = [];

    useEffect(() => {
        if (!isOpen) {
            handleClose();
        }
    }, [isOpen]);

    const handleClose = () => {
        onClose();
        setNoticeMessage("");
        setRecipientType("");
        setRecipients([]);
        setPublishedDate("");
        setScheduleDate("");
        setExpiryDate("");
        setNoticeBoardMessageAddError('');
        setNoticeBoardMessageError('')
        setNoticeBoardRecipientError('');
        setNoticeBoardRecipientTypeError('');
        setNoticeBoardPublishedDateError('');
        setNoticeBoardScheduledDateError('');
        setNoticeBoardExpireDateError('');
        setNoticeBoardMessageAddSuccess('');
        setUserError('');
        setError('');
        setRecipientOptions([]);
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);

    const [noticeBoardMessageAddError, setNoticeBoardMessageAddError] = useState('')
    const [noticeBoardMessageAddSuccess, setNoticeBoardMessageAddSuccess] = useState('');
    const [noticeBoardMessageAddLoading, setNoticeBoardMessageAddLoading] = useState('');
    const [noticeBoardMessageError, setNoticeBoardMessageError] = useState('')
    const [noticeBoardRecipientError, setNoticeBoardRecipientError] = useState('')
    const [noticeBoardRecipientTypeError, setNoticeBoardRecipientTypeError] = useState('')
    const [noticeBoardPublishedDateError, setNoticeBoardPublishedDateError] = useState('')
    const [noticeBoardScheduledDateError, setNoticeBoardScheduledDateError] = useState('');
    const [noticeBoardExpireDateError, setNoticeBoardExpireDateError] = useState('');

    const handleAddNoticeBoardMessages = async () => {

        try {
            setNoticeBoardMessageAddError('');
            setNoticeBoardMessageError('')
            setNoticeBoardRecipientError('');
            setNoticeBoardRecipientTypeError('');
            setNoticeBoardPublishedDateError('');
            setNoticeBoardScheduledDateError('');
            setNoticeBoardExpireDateError('');
            setError('');
            setUserError('')
          const MAX_NOTICE_MESSAGE_LENGTH = 500;

          if (!noticeMessage) {
            setNoticeBoardMessageError("Please enter notice board message");
            return
          }
          if (noticeMessage.length > MAX_NOTICE_MESSAGE_LENGTH) {
            setNoticeBoardMessageError(`Notice board message cannot exceed ${MAX_NOTICE_MESSAGE_LENGTH} characters.`);
            return;
        }
          if(!recipientType){
            setNoticeBoardRecipientTypeError("Please select recipient type");
            return
          }
          if ((recipientType === 2 || recipientType === 3) && recipients.length === 0) {
            setNoticeBoardRecipientError("Please select recipient");
            return
          }
          if (!publishedDate) {
            setNoticeBoardPublishedDateError("Please select published date");
            return
          }
          // if (!scheduleDate) {
          //   setNoticeBoardScheduledDateError("Please select schedule date");
          //   return
          // }
          if (!expiryDate) {
            setNoticeBoardExpireDateError("Please select expiry date");
            return
          }      
          if (expiryDate < publishedDate) {
            setNoticeBoardExpireDateError("Expiry date can't be before published date");
            return;
        }
          setNoticeBoardMessageAddLoading(true);
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
          const payLoad = { emp_id_auth: storedUserInfo?.emp_id, nb_message: noticeMessage, recipient_type: recipientType, recipient_members: recipients, publish_at:publishedDate,  expire_at:expiryDate }
    
          console.log('handlea Add Notice Board Messages payload', payLoad);
        //   return
          // Make the API call
          const response = await postData(ORG_NOTICE_BOARD_MSG_ADD, payLoad);
          const responseData = await response.json();
          //console.log('response Data', responseData);
    
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                setNoticeBoardMessageAddError('')
                setNoticeBoardMessageAddSuccess(responseData?.info);
                setTimeout(() => {
                  handleClose();
                  onValueChange();
                }, 3000);
              } else {
                setNoticeBoardMessageAddError(responseData?.info || 'Failed to add notice board message. Please try again.');
              }
            } else {
                setNoticeBoardMessageAddError(responseData?.info || 'Failed to add notice board message. Status code error.');
            }
          }
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setNoticeBoardMessageAddError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
                setNoticeBoardMessageAddError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          }
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setNoticeBoardMessageAddError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Unexpected or network-related errors
          else {
            setNoticeBoardMessageAddError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setNoticeBoardMessageAddError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add notice board message. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setNoticeBoardMessageAddError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setNoticeBoardMessageAddError(`Failed to add notice board message. Please check your network connection and try again.`);
          }
        } finally {
            setNoticeBoardMessageAddLoading(false);
        }
    
        // onValueChange(teamData);
        // onClose();
      };

      const handleEditNoticeBoardMessages = async () => {

        try {
            setNoticeBoardMessageAddError('');
            setNoticeBoardMessageError('');
            setNoticeBoardRecipientError('');
            setNoticeBoardRecipientTypeError('');
            setNoticeBoardPublishedDateError('');
            setNoticeBoardScheduledDateError('');
            setNoticeBoardExpireDateError('');
            setError('');
            setUserError('')

          const MAX_NOTICE_MESSAGE_LENGTH = 500;

          if (!noticeMessage) {
            setNoticeBoardMessageError("Please enter notice board message");
            return
          }
          if (noticeMessage.length > MAX_NOTICE_MESSAGE_LENGTH) {
            setNoticeBoardMessageError(`Notice board message cannot exceed ${MAX_NOTICE_MESSAGE_LENGTH} characters.`);
            return;
        }
          // if(!recipientType){
          //   setNoticeBoardRecipientTypeError("Please select recipient type");
          //   return
          // }
          // if (recipients.length === 0) {
          //   setNoticeBoardRecipientError("Please select recipient");
          //   return
          // }
          if (!publishedDate) {
            setNoticeBoardPublishedDateError("Please select published date");
            return
          }
          // if (!scheduleDate) {
          //   setNoticeBoardScheduledDateError("Please select schedule date");
          //   return
          // }
          if (!expiryDate) {
            setNoticeBoardExpireDateError("Please select expiry date");
            return
          }   
          if (expiryDate < publishedDate) {
            setNoticeBoardExpireDateError("Expiry date can't be before published date");
            return;
        }   
          setNoticeBoardMessageAddLoading(true);
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
          const payLoad = { emp_id_auth: storedUserInfo?.emp_id,nb_id:documentData.nb_id, nb_message: noticeMessage, publish_at:publishedDate,  expire_at:expiryDate }
    
          console.log('handlea Add Notice Board Messages payload', payLoad);
        //   return
          // Make the API call
          const response = await postData(ORG_NOTICE_BOARD_MSG_UPDATE, payLoad);
          const responseData = await response.json();
          //console.log('response Data', responseData);
    
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                setNoticeBoardMessageAddError('')
                setNoticeBoardMessageAddSuccess(responseData?.info);
                setTimeout(() => {
                  handleClose();
                  onValueChange();
                }, 3000);
              } else {
                setNoticeBoardMessageAddError(responseData?.info || 'Failed to add notice board message. Please try again.');
              }
            } else {
                setNoticeBoardMessageAddError(responseData?.info || 'Failed to add notice board message.');
            }
          }
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setNoticeBoardMessageAddError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
                setNoticeBoardMessageAddError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          }
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setNoticeBoardMessageAddError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Unexpected or network-related errors
          else {
            setNoticeBoardMessageAddError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setNoticeBoardMessageAddError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add notice board message. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setNoticeBoardMessageAddError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setNoticeBoardMessageAddError(`Failed to add notice board message. Please check your network connection and try again.`);
          }
        } finally {
            setNoticeBoardMessageAddLoading(false);
        }
    
        // onValueChange(teamData);
        // onClose();
      };

const [loading, setLoading] = useState(false)
const [error, setError] = useState('');
const [recipientOptions, setRecipientOptions] = useState([]);
      const fetchTeamList = async () => {
        try {
          setLoading(true);
          setError('');
          setRecipientOptions([])
          setRecipients([])
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
          // Make the API call
          const response = await getData(`${ORG_GET_TEAM_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
          const responseData = await response.json();
    
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                if (responseData?.dataJ?.length > 0) {
    
                  const formattedTeams = responseData.dataJ.map(service => ({
                    label: service.team_name,
                    value: service.team_id
                  }));

                  setRecipientOptions(formattedTeams);
                  console.log("recipientOptions", recipientOptions)
                } else {
                  setError(responseData?.info || 'Data Not Found');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch team data. Please try again.');
              }
            } else {
              setError(responseData?.info || 'Failed to fetch team data.');
            }
          }
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          }
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setError(responseData?.info || 'Internal Server Error. Please try again later.');
          }
          // Unexpected or network-related errors
          else {
            setError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch team data. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setError(`Failed to fetch team data. Please check your network connection and try again.`);
          }
        } finally {
          setLoading(false);
        }
      }

    const [userLoading, setUserLoading] = useState(false);
    const [userError, setUserError] = useState('');  
      const fetchUserList =async ()=>{
              try {
                setUserLoading(true);
                setUserError('');
                setRecipientOptions([])
                setRecipients([]);
                  const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
              
                  // Make the API call
                  const response = await getData(`${ORG_GET_EMPLOYEE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
                  const responseData = await response.json();
              
                  // Check for success (200) and internal status code
                  if (response?.status === 200) {
                    if (responseData?.statusCode === 200) {
                      if (responseData?.message.toLowerCase() === 'success') {
                        if (responseData?.dataJ?.length > 0) {
                          const formattedTeams = responseData.dataJ.map(service => ({
                            label: service.emp_name,
                            value: service.emp_id
                          }));
                          setRecipientOptions(formattedTeams);
                        } else {
                          setUserError(responseData?.info || 'Data Not Found');
                        }
                      } else {
                        setUserError(responseData?.info || 'Failed to fetch User data. Please try again.');
                      }
                    } else {
                      setUserError(responseData?.info || 'Failed to fetch User data.');
                    }
                  } 
                  // Token-related errors: 400, 401, 403
                  else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
                    if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                      setUserError('Unauthorized access. Your session may have expired. Please log in again.');
                      // Optionally, redirect to the login page or refresh the token
                      // Example: redirectToLogin();
                    } else {
                      setUserError(responseData?.info || 'Bad request. Please check the request parameters.');
                    }
                  } 
                  // Internal Server Error: 500
                  else if (response?.status === 500) {
                    setUserError(responseData?.info || 'Internal Server Error. Please try again later.');
                  } 
                  // Unexpected or network-related errors
                  else {
                    setUserError('Unexpected error occurred. Please try again later.');
                  }
                } catch (error) {
                  // Handle various error scenarios
                  if (error.response) {
                    // Server responded with a status outside the 2xx range
                    setUserError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
                  } else if (error.request) {
                    // Request was made but no response was received
                    setUserError('No response received from the server. Please check your network connection and try again.');
                  } else {
                    // An error occurred in setting up the request
                    setUserError(`Failed to fetch User data. Please check your network connection and try again.`);
                  }
                } finally {
                  setUserLoading(false);
                }
            }

    if (!isOpen) return null;

    return (
        <div className="modal-overlay">
            <div className="modal-content">
                <button className="close-button" onClick={() => { handleClose(); }}>
                    <img src={closeIcon} alt="Close" style={styles.closeIcon} />
                </button>
                <h2 className="modal-title">{documentData ? 'Update' : 'Add'} Notice Board Message</h2>
                <hr className="footer-divider" />
                <div style={styles.actionField}>
                    <label style={styles.label}>Notice Board Message</label>
                    <textarea placeholder="Enter your notice message" style={styles.textarea} value={noticeMessage} onChange={(e)=>setNoticeMessage(e.target.value)} disabled={expired && documentData}></textarea>
                    {noticeBoardMessageError && <div className="list-error-msg" style={{ display: 'flex', padding: '5px' }}><img
                    src={errorIcon}
                    alt="Error"
                    style={{ width: "12px", height: "12px", marginRight: '4px' }}
                    />{noticeBoardMessageError} </div>}
                </div>
                <div className="form-grid" style={{ marginTop: "20px", }}>
                  {documentData ?(
                    <Input
                    label="Recipient Type"
                        value={recipientTypeOptions.find(option => option.value === recipientType)?.label || ""}
                        isEdit={false}
                        searchBoxWidth={262}
                    />
                   ) : (
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["recipientType"] = el)}
                        isOpen={openDropdown === "recipientType"}
                        toggleDropdown={() => toggleDropdown("recipientType")}
                        label="Recipient Type"
                        options={recipientTypeOptions}
                        value={recipientTypeOptions.find((option) => option.value === recipientType)}
                        // onChange={(selectedOption) => setRecipientType(selectedOption.value)}

                        onChange={(selectedOption) => {
                          setRecipientType(selectedOption.value);
                          if (selectedOption.value === 2) {
                              fetchTeamList();
                          }else if(selectedOption.value === 3){
                            fetchUserList();
                          }
                          else {
                            setRecipientOptions([]);
                            setRecipients([]);
                        }
                      }}
                        searchBoxWidth={262}
                        errorMessage={noticeBoardRecipientTypeError}
                    />
                    )}
                    
                    {documentData ? (
                    <Input
                        label="Select Recipient"
                        value={recipients.map(recipient => recipient.label).join(", ")}
                        isEdit={false}
                        searchBoxWidth={262}
                    />
                ) : (
                    <Dropdown
                        ref={(el) => (dropdownRefs.current["recipients"] = el)}
                        isOpen={openDropdown === "recipients"}
                        toggleDropdown={() => toggleDropdown("recipients")}
                        label="Select Recipient"
                        options={recipientOptions}
                        value={recipients}
                        onChange={setRecipients}
                        searchBoxWidth={262}
                        errorMessage={noticeBoardRecipientError || error || userError}
                        loading={loading || userLoading}
                        isMulti
                    />
                )}

                    <div style={{width:'285px'}}>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Published Date</label>
                        <DateInput
                            label="Published Date"
                            value={publishedDate}
                            onChange={(e) => handlePublishedDateChange(e.target.value)}
                            minWidth={140}
                            errorMessage={noticeBoardPublishedDateError}
                            min={new Date().toISOString().split("T")[0]}
                        />
                    </div>
                    {/* <div style={{width:'285px'}}>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Scheduled Date</label>
                        <DateInput
                            label="Schedule Date"
                            value={scheduleDate}
                            onChange={(e) => handleScheduleDateChange(e.target.value)}
                            errorMessage={noticeBoardScheduledDateError}
                            min={new Date().toISOString().split("T")[0]}
                        />
                    </div> */}
                    <div style={{width:'285px'}}>
                        <label style={{ fontSize: '12px', color: '#343C44' }}>Expiry Date</label>
                        <DateInput
                            label="Expiry Date"
                            value={expiryDate}
                            onChange={(e) => handleExpireDateChange(e.target.value)}
                            errorMessage={noticeBoardExpireDateError}
                            min={new Date().toISOString().split("T")[0]}
                        />
                    </div>
                </div>
                <hr className="footer-divider" />
                <div className="button-container" style={{ alignItems: 'center' }}>
                    <button className="add-client-button" onClick={documentData? handleEditNoticeBoardMessages: handleAddNoticeBoardMessages} style={{
                        paddingLeft:'30px', 
                        paddingRight:'30px',
                        gap: '10px',
                        opacity: noticeBoardMessageAddLoading ? 0.5 : 1,
                        cursor: noticeBoardMessageAddLoading ? 'not-allowed' : 'pointer',
                    }}>
                        <span>{documentData ? 'Update' : 'Add'}</span>
                        {noticeBoardMessageAddLoading && (
                            <Loader loading={noticeBoardMessageAddLoading} color={'var(--background-color)'}size="small" />
                        )}
                    </button>
                    <button className="cancel-button" onClick={() => { handleClose(); }}>
                        Cancel
                    </button>
                    {noticeBoardMessageAddSuccess && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{noticeBoardMessageAddSuccess} </div>}
                    {noticeBoardMessageAddError && <div className="list-error-msg" style={{ marginBottom: '3px' }}>{noticeBoardMessageAddError} </div>}
                </div>
            </div>
        </div>
    );
};

const styles = {
    closeIcon: {
        width: "16px",
        height: "16px",
    },
    formGrid: {
        display: "grid",
        gridTemplateColumns: "1fr 1fr",
        gap: "20px",
        marginTop: "20px",
    },
    buttonContainer: {
        display: "flex",
        justifyContent: "flex-start",
        gap: "10px",
        marginTop: "20px",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
        marginTop: '15px'
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "87px",
        fontSize: "14px",
        color: "#333",
        fontFamily: 'Roboto',
        width: '575px'
    },
};

export default AddNoticeBoardMsgModal;
