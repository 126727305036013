import React, { useState, useRef, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import Tabs from '../../../../Components/Tabs';
import ManageUsersScreen from './ManageUsers';
import ManageTeamsScreen from './ManageTeams';
import ManageGroupsScreen from './ManageGroups';
import TeamsUsersManagementController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/TeamsUsersManagementController';


const TeamsUsersManagementScreen = () => {

    const { state } = useLocation();
    const [selectedTab, setSelectedTab] = useState(state?.selectedTab || 0);

    const{
        teamList,
        error,
        loading,
        refreshTeamList,
        userList,
        userError,
        userLoading,
        refreshUserList
    }=TeamsUsersManagementController();

    const handleTabChange = (index) => {
        setSelectedTab(index);
    };

    return (
        <div style={styles.mainContent}>
            <div className='title-container' style={{paddingBottom:20}}>
                <h2 className='title'>Add/Edit Employees</h2>
            </div>

            <div >
                {/* <Tabs tabs={['Manage Employee','Manage Teams']}> */}
                <Tabs
                    tabs={['Manage Employee', 'Manage Teams']}
                    selectedIndex={selectedTab}
                    onTabChange={handleTabChange}
                    divBorder={false}
                >
                    <ManageUsersScreen userList={userList} error={userError} loading={userLoading} onRefresh={refreshUserList} refreshTeamList={refreshTeamList}/>
                    <ManageTeamsScreen teamList={teamList} error={error} loading={loading} onRefresh={refreshTeamList}/>
                    {/* <ManageGroupsScreen /> */}
                </Tabs>
            </div>

        </div>


    )

};

const styles = {
    mainContent: {
        width: '95%',
        margin: 'auto'
    }

}

export default TeamsUsersManagementScreen;