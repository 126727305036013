import React, { useState, useRef, useEffect} from 'react';
import TextInput from '../../../../Components/TextInput';
import Input from '../../../../Components/Input';
import DateInput from '../../../../Components/DateInput';
import filter from '../../../../Assets/Icons/filter.png';
import search from '../../../../Assets/Icons/search.png';
import Dropdown from '../../../../Components/Dropdown';
import { useNavigate } from 'react-router-dom';

const DocumentListScreen = () => {
    const navigate = useNavigate();

    const handleNav = (pageName, task) => {
        //console.log('page', pageName, 'task', task);
        navigate(`/${pageName}`, { state: { task } }); // Pass task in state
    };

    const [showFilters, setShowFilters] = useState(true); // Control the visibility of filters
    const [filters, setFilters] = useState({
        clientName: "",
        fileNumber: "",
        documentNumber: "",
        receivedBy: "",
        source: "",
        deliveryType: "",
        startDate: "",
        endDate: "",
    });


    const handleFilterChange = (name, value) => {
        setFilters((prevFilters) => ({
            ...prevFilters,
            [name]: value,
        }));
    };

    const applyFilters = () => {
        //console.log('Applying filters:', filters);
        setShowFilters(false);
    };

    const resetFilters = () => {
        setFilters({
            taskType: '',
            financialYear: '',
            period: '',
            status: '',
        });
    };

    const toggleFilters = () => {
        setShowFilters(!showFilters);
        setFilters({
            taskType: '',
            financialYear: '',
            period: '',
            status: '',
        });
    };

    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <>
            <div className="tasksScreen-tasks-header">
                <h2 className="tasksScreen-tasks-title">Search Documents</h2>
            </div>

            {/* Filters Row */}
            <div className="tasksScreen-filters-row">
                <div className="tasksScreen-filters-button-wrapper">
                    <button className="tasksScreen-filters-button" onClick={toggleFilters}>
                        <img src={filter} alt="Filter" />
                        {showFilters ? 'Filters' : 'Filters'}
                    </button>
                </div>
            </div>

            {showFilters && (
                <div className={`tasksScreen-filters-container ${showFilters ? 'tasksScreen-open-filters' : ''}`}>
                    <div className="tasksScreen-filters">
                        {/* Replace each filter select with Dropdown component */}

                        <div className="filter-field">
                            <label>Client Name</label>
                            <TextInput
                                type="text"
                                placeholder="Client Name"
                                value={filters.clientName || ''}
                                onChange={(e) => handleFilterChange('clientName', e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>

                        <div className="filter-field">
                            <label>File Number</label>
                            <TextInput
                                type="text"
                                placeholder="Client Name"
                                value={filters.clientName || ''}
                                onChange={(e) => handleFilterChange('clientName', e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>

                        <div className="filter-field">
                            <label>Document Number</label>
                            <TextInput
                                type="text"
                                placeholder="Client Name"
                                value={filters.clientName || ''}
                                onChange={(e) => handleFilterChange('clientName', e.target.value)}
                                right={<img src={search} alt="Search" className="search-icon" />}
                            />
                        </div>

                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Received By"] = el)}
                            isOpen={openDropdown === "Received By"}
                            toggleDropdown={() => toggleDropdown("Received By")}
                            label="Received By"
                            options={['Type 1', 'Type 2']}
                            value={filters.taskType}
                            onChange={(value) => handleFilterChange('taskType', value)}
                        />

                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Source"] = el)}
                            isOpen={openDropdown === "Source"}
                            toggleDropdown={() => toggleDropdown("Source")}
                            label="Source"
                            options={['2023-24', '2024-25']}
                            value={filters.financialYear}
                            onChange={(value) => handleFilterChange('financialYear', value)}
                        />

                        <Dropdown
                            ref={(el) => (dropdownRefs.current["Delivery Type"] = el)}
                            isOpen={openDropdown === "Delivery Type"}
                            toggleDropdown={() => toggleDropdown("Delivery Type")}
                            label="Delivery Type"
                            options={['Q1', 'Q2', 'Q3', 'Q4']}
                            value={filters.period}
                            onChange={(value) => handleFilterChange('period', value)}
                        />

                        <div className="filter-field">
                            <label>Period</label>
                            <DateInput
                                value={filters.startDate || ""}
                                onChange={(e) => handleFilterChange('startDate', e.target.value)}
                            />
                        </div>

                        <div className="filter-field">
                            <label>Period</label>
                            <DateInput
                                value={filters.endDate || ""}
                                onChange={(e) => handleFilterChange('endDate', e.target.value)}
                            />
                        </div>

                    </div>
                    <div className="tasksScreen-filter-buttons">
                        <button onClick={applyFilters} className="tasksScreen-apply-button">Apply</button>
                        <button onClick={toggleFilters} className="tasksScreen-cancel-button">Cancel</button>
                        <button onClick={resetFilters} className="tasksScreen-reset-button">Reset</button>
                    </div>
                </div>
            )}
        </>
    );
};

const styles = {
    header: {
        fontSize: "22px",
        color: "#060606",
        marginBottom: "10px",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader: {
        marginTop: '-5px'
    }
};

export default DocumentListScreen;
