import React, { useState, useRef, useEffect } from 'react';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import forwordIcon from '../../../../Assets/Icons/forward.png'
import AddDocumentModal from '../../../../Components/Modals/AddDocumentModal';
import KYCDocumentsController from '../../../Controllers/AdminScreens/Clients/KYCDocumentsController';
import Loader from '../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';
import addIcon from '../../../../Assets/Icons/add.png'
const KYCDocumentsScreen = ({clientId, kycListLoading, kycListError, kycDocumentList, onRefresh}) => {
  const{
    downloadKycLoading,
        kycDownloadError,
        downloadKYCDocuments,
        currentDocIndex,
        setSelectedDocument,
        selectedDocument,
        handleKYCDocumentDelete,
        loading,
        success,
        handleCloseDeleteModal,
        handleOpenDeleteModal,
        handleDelete,
        isDeleteModalOpen,
        currentDocDeleteIndex
  }= KYCDocumentsController(clientId, onRefresh);

  //console.log('clientId in KYC Screen:----------',clientId)

    const documents = [
        {
          documentName: "Form 16",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/form16",
        },
        {
          documentName: "GST Registration",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/gst",
        },
        {
          documentName: "Company Registration",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/company",
        },
        {
          documentName: "ITR",
          description: "Period 2024 - 2025",
          downloadLink: "https://example.com/itr2024",
        },
        {
          documentName: "ITR",
          description: "Period 2023 - 2024",
          downloadLink: "https://example.com/itr2023",
        },
      ];
      const [isModalOpen, setIsModalOpen] = useState(false);
      const [modalMode, setModalMode] = useState("create");

        const handleAddDocument = () => {
            setSelectedDocument(null);
            setIsModalOpen(true);
            setModalMode('create')
        };

        const handleCloseModal = () => {
            setIsModalOpen(false);
        };
        const handleEditDocument = (doc) => {
          setSelectedDocument(doc);
          setIsModalOpen(true);
          setModalMode('edit')
        };
    
      return (
        <div className="tasksScreen-task-table-wrapper">
          <h3 className='basic-info-header'>Available Documents</h3>
          <div className="basic-info-outer-card">
          <table className="tasksScreen-task-table" >
            <thead>
              <tr>
                <th style={{ width: "25%", }}>Document Name</th>
                <th style={{ width: "21%" }}>Description</th>
                <th style={{ width: "15%" }}>Source</th>
                <th style={{ width: "10%" }}>Type</th>
                <th style={{ width: "15%" }}>Download</th>
                <th
                  style={{
                    width: "14%",
                    cursor: 'pointer',
                  }}
                  onClick={handleAddDocument}
                >
                  <div style={{ display: 'flex', alignItems: 'center',justifyContent: "end", }}>
                    <img src={addIcon} alt="Add" style={{ width: '17px', height: '17px',marginRight:'10px',marginBottom:'4px' }} />
                    <span style={{color:'#40513B',fontWeight:'500'}}>New Document</span>
                  </div>
                </th>

              </tr>
            </thead>
            <tbody style={{backgroundColor:'white'}}>
              {kycDocumentList?.map((doc, index) => (
                <tr
                  key={index}
                  style={{
                    borderBottom: "3px solid #f8f9fa",
                  }}
                >
                  <td style={styles.tableValue}>{doc?.doc_name}</td>
                  <td style={styles.tableValue}>{doc?.doc_description || '--'}</td>
                  <td style={styles.tableValue}>{doc?.doc_source_id === 1 ? 'Sent by Client' : doc?.doc_source_id === 2 ? 'Sent by Employee' : 'Unknown'}</td>
                  <td style={styles.tableValue}>{doc?.doc_is_internal === 1 ? "Internal" : doc?.doc_is_internal === 2 ? "Client Documents" : "Unknown"}</td>
                  <td style={styles.tableLink}>
                    <a
                      onClick={() => !downloadKycLoading && downloadKYCDocuments(doc.doc_id, index)}
                      style={{ display: "flex", alignItems: "center", gap: "1px",
                        opacity: downloadKycLoading && currentDocIndex === index ? 0.5 : 1, // Reduce opacity while loading
                        cursor: downloadKycLoading && currentDocIndex === index ? "not-allowed" : "pointer", // Change cursor
                       }} // Flexbox to align items horizontally
                    >
                      <span>Download</span>
                      <img
                        src={forwordIcon}
                        alt="Forward"
                        style={{ width: "14px", height: "14px",marginLeft:'5px' }}
                      />
                      {downloadKycLoading && currentDocIndex === index && (
                        <span style={{marginLeft:'5px'}}>
                          <Loader loading={downloadKycLoading} color={'var(--primary-color)'} size="small" />
                        </span>
                      )}
                    </a>
                  </td>

                  <td
                  >
                    <div style={{ display: "flex", alignItems: "center", gap: "5px",justifyContent: "end", }}>
                    <button className="tasksScreen-edit-button">
                      <img
                        src={editIcon}
                        alt="Edit"
                        style={{ width: "16px", height: "16px" }}
                        onClick={() => handleEditDocument(doc)}
                      />
                    </button>
                    <button className="tasksScreen-delete-button"
                    style={{
                      opacity: loading && currentDocDeleteIndex === index ? 0.5 : 1, // Reduce opacity when loading
                      pointerEvents: loading && currentDocDeleteIndex === index ? 'none' : 'auto', // Disable click
                      cursor: loading && currentDocDeleteIndex === index ? 'not-allowed' : 'pointer', // Change cursor
                    }}
                     >
                      <img
                        src={deleteIcon}
                        alt="Delete"
                        style={{ width: "16px", height: "16px" }}
                        onClick={() => { handleDelete(doc, index) }}
                      />
                    </button>
                    {loading &&  currentDocDeleteIndex=== index && (
                        <span style={{marginLeft:'5px'}}>
                          <Loader loading={loading} color={'var(--primary-color)'} size="small" />
                        </span>
                      )}
                      </div>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
          {kycListLoading && <Loader loading={kycListLoading} color={'var(--primary-color)'} />}
          {/* {downloadKycLoading && <Loader loading={downloadKycLoading} color={'var(--primary-color)'} />} */}
          {kycListError && <div className="list-error-msg">{kycListError} </div>}
          {kycDownloadError && <div className="list-error-msg">{kycDownloadError} </div>}
          {success && <div className="list-success-msg">{success} </div>}
          </div>
          <AddDocumentModal isOpen={isModalOpen} onClose={handleCloseModal} clientId={clientId} documentData={selectedDocument} onValueChange={onRefresh} modalMode={modalMode}/>
          <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        message={`Are you sure you want to delete ${selectedDocument?.doc?.doc_name}?`}
        onClose={handleCloseDeleteModal}
        onConfirm={() => {
          handleCloseDeleteModal(); // Close modal
          handleKYCDocumentDelete(selectedDocument.doc_id,selectedDocument.index); // Perform delete operation
        }}
      />
        </div>
      );

};

const styles = {
    header: {
      fontSize: "18px",
      color: "#609966",
      marginBottom: "10px",
      fontWeight:500,
      fontFamily:'Roboto Slab'
    },
    tableValue:{
        colour:'#192A3E',
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px'
    },
    tableLink:{
        fontFamily:'Roboto',
        fontWeight:400,
        fontSize:'12px',
        textDecoration:'underline',
        color: '#5A55D2',
        cursor:'pointer'
    }
  };

export default KYCDocumentsScreen;