
import React, { useState, useRef, useEffect} from "react";
import Input from "../../../../../Components/Input";
import Dropdown from "../../../../../Components/Dropdown";
import MobileInput from "../../../../../Components/MobileInput";
import editIcon from '../../../../../Assets/Icons/edit.png'
import deleteIcon from '../../../../../Assets/Icons/delete.png';
import filterIcon from '../../../../../Assets/Icons/filter.png'
import leftBtuIcon from '../../../../../Assets/Icons/back.png'
import SMSContactController from "../../../../Controllers/AdminScreens/Configuration/ContactsCenter/SMSContactController";
import ConfigurationEmailConfigurationModal from "../../../../../Components/Modals/ConfigurationEmailConfigurationModal";
import ConfigurationAddNewContactModal from "../../../../../Components/Modals/ConfigurationAddNewContactModal";

const SMSContactList = () => {

  const {
    filterData,
    setFilterData,
    emailContactData,
    handleInputChange
  } = SMSContactController();

  const [showFilters, setShowFilters] = useState(false);
  const toggleFilters = () => {
    setShowFilters((prev) => !prev); // Toggle the filter visibility
  };

  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddContactModalOpen, setIsAddContactModalOpen] = useState(false);

  const handleSMSConfiguration = () => {
    setIsModalOpen(true); // Open the modal
  };

  const handleCloseModal = () => {
    setIsModalOpen(false); // Close the modal
  };

  const handleAddContactModal = () => {
    setIsAddContactModalOpen(true)
  }

  const handleAddContactCloseModal = () => {
    setIsAddContactModalOpen(false)
  }
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='basic-info-container'>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h3 style={styles.categoryHeader}>SMS Contacts</h3>
          <div style={{ display: 'flex', marginBottom: '10px' }}>
            <button className="add-client-button" style={{ paddingLeft: 30, paddingRight: 30, marginRight: '15px' }} onClick={handleSMSConfiguration}>
              Add Contact
            </button>
            <button className="cancel-button" style={{ border: '1px #40513B solid', borderRadius: '20px', paddingLeft: '30px', paddingRight: '30px' }} onClick={handleAddContactModal}>
              Upload Contacts
            </button>
          </div>
        </div>
        <div style={styles.container}>
          <div style={styles.filtersHeader}>
            <button style={styles.filterButton} onClick={toggleFilters}> <img src={filterIcon} style={styles.filterIcon} /> Filters</button>
            <div>
              <img src={leftBtuIcon} style={{ marginRight: '5px', cursor: 'pointer', width: '28px', height: '28px' }} />
              <img src={leftBtuIcon} style={{ transform: 'rotate(180deg)', transition: 'transform 0.3s ease', cursor: 'pointer', width: '28px', height: '28px' }} />
            </div>
          </div>
        </div>
        {/* Filters using Flexbox */}
        {showFilters && (
          <div style={styles.filtersContainer}>
            <div style={{ display: 'flex', justifyContent: 'space-between', flexWrap: 'wrap' }}>
              <Input
                label="Contact Name"
                placeholder="Contact Name"
                value={filterData?.contactName}
                onChange={(e) => handleInputChange('contactName', e.target.value)}
              />
              {/* <Input
                label="Phone No."
                placeholder="Phone No."
                value={filterData?.phoneNo}
                onChange={(e) => handleInputChange('phoneNo', e.target.value)}
              /> */}
              <MobileInput
                label="Phone No."
                countryCode={filterData?.countryCode}
                mobileNumber={filterData?.phoneNo}
                onMobileChange={(value) => handleInputChange('phoneNo', value)}
                onCountryChange={(value) => handleInputChange('countryCode', value)}
                placeholder="Enter mobile number"
                countries={[
                  { code: "IN", dialCode: "+91" },
                  { code: "US", dialCode: "+1" },
                  { code: "UK", dialCode: "+44" },
                ]}
                width="100%"
              />
              <Dropdown
                ref={(el) => (dropdownRefs.current[" "] = el)}
                isOpen={openDropdown === " "}
                toggleDropdown={() => toggleDropdown(" ")}
                label="Otp Out"
                options={["Option 1", "Option 2"]}
                placeholder="------"
                value={filterData?.optOut}
                onChange={(value) => handleInputChange('optOut', value)}
              />
            </div>
            <div className="button-container" style={{ marginBottom: '15px' }}>
              <button className="add-client-button" style={{ marginRight: '8px', paddingLeft: '30px', paddingRight: '30px' }}>Apply</button>
              <button className="cancel-button" style={{ border: '1px #40513B solid', borderRadius: '20px' }} >Cancel</button>
              <button className="cancel-button">Reset</button>
            </div>
          </div>
        )}
      </div>

      <div className='basic-info-card' style={{ border: 'none', padding: '0px' }}>
        <div style={styles.tableWrapper}>
          <table className="tasksScreen-task-table">
            <thead>
              <tr>
                <th style={{ width: "20%" }}>Contact Name</th>
                <th style={{ width: "20%" }}>Contact No.</th>
                <th style={{ width: "15%" }}>List Name</th>
                <th style={{ width: "15%" }}>Added No</th>
                <th style={{ width: "15%" }}>Added By</th>
                <th style={{ width: "10%" }}>Opt Out</th>
                <th style={{ width: "5%" }}></th>
              </tr>
            </thead>
            <tbody style={{ backgroundColor: "white" }}>
              {emailContactData?.map((category, index) => (
                <tr key={index}>
                  <td style={styles.tableValue}>{category.contactName}</td>
                  <td style={styles.tableValue}>{category.contactNo}</td>
                  <td style={styles.tableValue}>{category.listName}</td>
                  <td style={styles.tableValue}>{category.addedOn}</td>
                  <td style={styles.tableValue}>{category.addedBy}</td>
                  <td style={styles.tableValue}>{category.optOut}</td>
                  <td>
                    <div
                      style={{
                        display: "flex",
                        gap: "2px", // Space between edit and delete buttons
                      }}
                    >
                      {/* Edit Button */}
                      <button
                        className="tasksScreen-edit-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={editIcon}
                          alt="Edit"
                          style={{ width: "16px", height: "16px" }}
                          onClick={handleAddContactModal}
                        />
                      </button>

                      {/* Delete Button */}
                      <button
                        className="tasksScreen-delete-button"
                        style={{
                          border: "none",
                          background: "transparent",
                          cursor: "pointer",
                        }}
                      >
                        <img
                          src={deleteIcon}
                          alt="Delete"
                          style={{ width: "16px", height: "16px" }}
                        />
                      </button>
                    </div>
                  </td>

                </tr>
              ))}
            </tbody>
          </table>
        </div>
        {isModalOpen && (
          <ConfigurationEmailConfigurationModal isOpen={isModalOpen} onClose={handleCloseModal} />
        )}
        {isAddContactModalOpen && (
          <ConfigurationAddNewContactModal isOpen={isAddContactModalOpen} onClose={handleAddContactCloseModal} />
        )}
      </div>
      {/* <hr className="divider" style={{ marginTop: '20px' }} /> */}
    </>
  )

}

const styles = {
  categoryHeader: {
    color: '#1F384C',
    fontSize: 18,
    fontFamily: 'Roboto Slab',
    fontWeight: 500,
    paddingBottom: 20
  },
  tableWrapper: {
    overflowX: "auto", // Horizontal scroll
    whiteSpace: "nowrap", // Prevent table from wrapping on small devices
    // marginBottom:50
  },
  tableValue: {
    colour: '#192A3E',
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px'
  },
  tableLink: {
    fontFamily: 'Roboto',
    fontWeight: 400,
    fontSize: '12px',
    textDecoration: 'underline',
    color: '#5A55D2'
  },
  container: {
    padding: "5px",
    backgroundColor: "#EFF0F4",
    border: "none",
    // borderRadius: "5px",
    border: '1px #EFF0F4 solid'
  },
  filtersHeader: {
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    // marginBottom: "15px",
  },
  filterButton: {
    backgroundColor: "white",
    color: "#40513B",
    border: "none",
    borderRadius: "5px",
    padding: "5px 8px",
    cursor: "pointer",
    borderRadius: '20px',
    fontSize: '12px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    border: '1px #40513B solid'

  },
  filterIcon: {
    width: '10px',
    height: '10px',
    paddingRight: '5px'
  },

  paginationButtons: {
    display: "flex",
    gap: "5px",
  },
  paginationButton: {
    backgroundColor: "#fff",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "5px 10px",
    cursor: "pointer",
  },
  filtersContainer: {
    // display: "flex",
    flexWrap: "wrap", // Wrap inputs to the next line if needed
    gap: "20px", // Space between inputs
    marginBottom: "15px",
    // justifyContent: "space-between", // Distribute inputs evenly
    border: '1px #EFF0F4 solid',
    padding: '10px'
  },
  buttonsContainer: {
    display: "flex",
    gap: "10px",
    alignItems: "center",
  },
  applyButton: {
    backgroundColor: "#609966",
    color: "#fff",
    border: "none",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  cancelButton: {
    backgroundColor: "transparent",
    border: "1px solid #ccc",
    borderRadius: "5px",
    padding: "8px 15px",
    cursor: "pointer",
  },
  resetButton: {
    backgroundColor: "transparent",
    color: "#555",
    border: "none",
    cursor: "pointer",
  },
  labaleStyle: {
    fontSize: '12px',
    // marginBottom: '5px',
    fontWeight: '400',
    fontFamily: 'Roboto',
    color: 'rgb(52, 60, 68)'
  },


}

export default SMSContactList