import React, { useState, useRef, useEffect } from 'react';
import CommentsController from '../../../Controllers/AdminScreens/Clients/CommentsController';
import Loader from '../../../../Components/Loader';
import dropDownIcon from '../../../../Assets/Icons/chevron-down.png';
import dotImage from '../../../../Assets/Icons/comment-dot.png';
import { formatToLocalTime } from '../../../../Utils/Validation';

const CommentsScreen = ({ clientId }) => {
    const {
        comments,
        expandedTasks,
        handleToggleDetails,
        loading,
        error,
    } = CommentsController(clientId);

    const formatDate = (dateString) => {
        if (!dateString) return '--';
        const [year, month, day] = dateString.split('-');
        return `${day}/${month}/${year}`;
    };

    return (
        <>
            <h3 className="basic-info-header">Comments</h3>
            <div className="tasksScreen-task-table-wrapper basic-info-outer-card">
                <table className="tasksScreen-task-table">
                    <thead>
                        <tr>
                            <th style={{ width: '3%' }}></th>
                            <th style={{ width: '13%' }}>Task ID</th>
                            <th style={{ width: '17%' }}>Last Comment By</th>
                            <th style={{ width: '44%' }}>Last Comment</th>
                            <th style={{ width: '15%' }}>Comment Date</th>
                        </tr>
                    </thead>
                    <tbody style={{ backgroundColor: 'white' }}>
                        {comments?.map((task, index) => (
                            <React.Fragment key={index}>
                                <tr
                                    style={{
                                        borderBottom: '1px solid #f8f9fa',
                                    }}
                                >
                                    <td>
                                        <img
                                            src={dropDownIcon}
                                            alt="Dropdown"
                                            style={{
                                                width: '20px',
                                                height: '20px',
                                                cursor: 'pointer',
                                                transform: expandedTasks[task?.task_id] ? 'rotate(180deg)' : 'rotate(0deg)',
                                                transition: 'transform 0.2s ease',
                                            }}
                                            onClick={() => handleToggleDetails(task?.task_id)}
                                        />
                                    </td>
                                    <td style={{ ...styles.tableValue, textDecoration: 'underline', color: '#5A55D2', cursor: 'pointer' }} onClick={() => handleToggleDetails(task.task_id)}>
                                        {task.task_id}
                                    </td>
                                    <td style={styles.tableValue}>{task?.commented_by || '--'}</td>
                                    <td style={{ ...styles.tableValue, ...styles.ellipsis }}>{task?.comment || 'No comment available'}</td>
                                    <td style={styles.tableValue}>{formatToLocalTime(task?.comment_date)}</td>
                                </tr>
                                {expandedTasks[task.task_id] && (
                                    <tr>
                                        <td style={{ padding: '0px' }} colSpan={5}>
                                            <div style={{ backgroundColor: '#F2F2F2', padding: '20px 20px 20px 120px' }}>
                                                {task.comments?.map((subComment, subIndex) => (
                                                    <div key={subIndex} style={styles.timelineItem}>
                                                        <div >
                                                            <div>
                                                                <p style={{ ...styles.commentText, fontSize: '14px', textAlign: 'end' }}>
                                                                    {subComment.commented_by || '--'}
                                                                </p>
                                                                <span style={styles.commentDate}>{formatToLocalTime(subComment.comment_date)}</span>
                                                            </div>
                                                            
                                                        </div>
                                                        <div style={{display:'flex',flexDirection:'column',justifyContent:'center',alignItems:'center'}}>
                                                        <img src={dotImage} alt="Dot" style={styles.dotImage} />
                                                        <div style={styles.timelineLine}></div>
                                                        </div>
                                                        <div style={styles.timelineContainer}>
                                                            <p style={styles.commentText}>
                                                                {subComment.comment_text}
                                                            </p>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </td>
                                    </tr>
                                )}
                            </React.Fragment>
                        ))}
                    </tbody>
                </table>
                {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                {error && <div className="list-error-msg">{error}</div>}
            </div>
        </>
    );
};

const styles = {
    tableValue: {
        color: '#192A3E',
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
    },
    timelineContainer: {
        paddingLeft: '20px',
        marginLeft: '20px',
        position: 'relative',
        wordWrap: 'break-word',
    },
    timelineItem: {
        display: 'flex',
        alignItems: 'flex-start',
        marginBottom: '15px',
        position: 'relative',
        gap:'20px'
    },
    dotImage: {
        width: '19px',
        height: '19px',
        // position: 'absolute',
        // left: '70px',
        // top: '0',
        zIndex: 1,
    },
    timelineLine: {
        // position: 'absolute',
        // left: '79px',
        // top: '20px',
        // minHeight: 'calc(100% - 10px)',
        minHeight: '22px',
        width: '2px',
        background: 'repeating-linear-gradient(#609966, #609966 5px, transparent 4px, transparent 10px)',
        zIndex: 0,
    },
    commentText: {
        fontSize: '12px',
        fontFamily: 'Roboto',
        color: '#192A3E',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    commentDate: {
        fontSize: '12px',
        color: '#767676',
        fontFamily: 'Roboto',
        fontWeight: '400',
        wordWrap: 'break-word',
    },
    ellipsis: {
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        maxWidth: '200px',
    },
};


export default CommentsScreen;
