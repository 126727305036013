import { useState, useEffect, useRef } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { ORG_GET_TEAM_INFO, ORG_GET_TEAM_MEMBERS,ORG_UPDATE_TEAM_STATUS,ORG_GET_TEAM_LEADER_MANAGER_LIST,ORG_UPDATE_EMP_INFO,ORG_DELETE_TEAM,ORG_ADD_TEAM, ORG_REMOVE_EMP_TEAM } from "../../../../ApiServices/BaseURL";
import { getData, postData } from "../../../../ApiServices/ApiService";

const ManageTeamDetailsController = () => {
    const location = useLocation();
    const isTeamInfoEdit = useRef(location.state?.isEdit);
    const isTeamAdd = useRef(location.state?.isAddTeam);
    const [teamID, setTeamID] = useState(location.state?.selTeamInfo?.team_id || null);
    const [statusLoading,setStatusLoading]= useState(false);
    const [statusSuccess,setStatusSuccess]= useState('');
    const [teamStatusError,setTeamStatusError]=useState('');
    const [teamManagerList,setTeamManagerList] = useState([]);
    const [teamManagerLoading, setTeamManagerLoading] = useState(false);
    const [empError, setEmpError] = useState(''); 
    const [teamLeaderList,setTeamLeaderList] = useState([]);
    const [teamLeaderLoading, setTeamLeaderLoading] = useState(false);
    const [teamLeaderError, setTeamLeaderError] = useState(''); 
    const [teamNameError,setTeamNameError]=useState('');
    const [teamLeadError,setTeamLeadError]=useState('');
    const [teamManagerError,setTeamManagerError]=useState('');
    const [teamName, setTeamName] = useState('');
    const [teamDescription, setTeamDescription] = useState('');
    const [teamStatus, setTeamStatus] = useState(null); // Dropdown
    const [teamLead, setTeamLead] = useState(null); // Dropdown
    const [teamManager, setTeamManager] = useState(null); // Dropdown

    const [addLoading, setAddLoading] = useState(false)
    const [addError, setAddError] = useState('');
        // Options for dropdowns
        const teamStatusOptions = [
          { label: 'Active', value: 1 },
          { label: 'Inactive', value: 0 },
      ];

    
    const [primaryData, setPrimaryData] = useState({        
        team_id: null,
        team_name: "",
        team_size: 0,
        team_status: 0,
        team_description: "",
        emp_id_team_lead_name: "",
        emp_id_team_manager_name: "",
        create_by_name: "",
        create_at:'',
        emp_id_team_manager:null,
        emp_id_team_lead:null,
        team_mobile_no:'',
        team_mobile_code:'',
        team_manager_email:''
    });

    const [editPrimaryData, setEditPrimaryData] = useState({        
      team_id: null,
      team_name: "",
      team_size: 0,
      team_status: 0,
      team_description: "",
      emp_id_team_lead_name: "",
      emp_id_team_manager_name: "",
      create_by_name: "",
      create_at:'',
      emp_id_team_manager:null,
      emp_id_team_lead:null,
  });

    const fieldConfigs = [
        { label: "Team Name", key: "team_name", type: "text",error:teamNameError },
        { label: "Team Manager", key: "emp_id_team_manager_name", type: "dropdown", options: teamManagerList,error:teamManagerError },
        { label: "Team Leader", key: "emp_id_team_lead_name", type: "dropdown", options: teamLeaderList,error:teamLeadError  },
        { label: "Created By", key: "create_by_name",type: "text", edit:false},
        { label: "Created On", key: "create_at",type: "text", edit:false, width:'213px' },
    ];
    const teamMembers1 = [
      { name: "Adams Baker", phone: "98900 98900", email: "adams.baker@example.com" },
      { name: "Clark Davis", phone: "98900 98900", email: "clark.davis@example.com" },
      { name: "Evans Frank", phone: "98900 98900", email: "evans.frank@example.com" },
  ];

    const navigate = useNavigate();
    const [userToggleState, setUserToggleState] = useState(true);
    const [teamMemberModalOpen, setTeamMemberModalOpen] = useState(false);
    const [teamInfo, setTeamInfo] = useState({});
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState(''); 
    const [teamMembers, setTeamMembers]  = useState([]);
    const [teamMembersError, setTeamMemebrsError] = useState('');
    const [isTeamMemberDeleteModalOpen, SetIsTeamMemberDeleteModalOpen] = useState(false);
    const selTeamMemberRef = useRef(null);
    const [teamMemDeleteLoading, setTeamMemDeleteLoading] = useState({});
    const [teamMemDeleteError, setTeamMemDeleteError] = useState({});
    const [teamMemDeleteSuccess, setTeamMemDeleteSuccess] = useState({}); 
    const [isTeamDeleteModalOpen, setIsTeamDeleteModalOpen] = useState(false);
    const [addSucess, setAddSucess] = useState('');

    useEffect(()=>{
      setTeamID(location.state?.selTeamInfo?.team_id)

    },[location.state?.selTeamInfo?.team_id])

    useEffect(()=>{
      if(location.state?.isAddTeam){
        //console.log('is Add Team');
        isTeamAdd.current = location.state?.isAddTeam;
  
      }else{
        isTeamInfoEdit.current = location.state?.isEdit
        if(location.state?.selTeamInfo?.team_id){
          fetchTeamInfo(location.state?.selTeamInfo?.team_id);
          fetchTeamMembers(location.state?.selTeamInfo?.team_id)
        }
      }

    },[location.state?.isEdit])
    // //console.log("@#$Team Info", location.state?.selTeamInfo)

    const fetchTeamInfo =async (teamId)=>{
        try {
            setLoading(true);
            setError('');
            // setTeamInfo({});
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_INFO}?team_id=${teamId}&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    setPrimaryData(responseData?.dataJ[0]);
                    setEditPrimaryData(responseData?.dataJ[0])
                    // selTeamInfo.current = responseData?.dataJ[0];
                  } else {
                    setError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setError(responseData?.info || 'Failed to fetch Team Information. Please try again.');
                }
              } else {
                setError(responseData?.info || 'Failed to fetch Team Information.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Information. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setError(`Failed to fetch Team Information. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
          }
      }

      const fetchTeamMembers =async (teamId)=>{
        try {
            setLoading(true);
            setTeamMemebrsError('');
            setTeamMembers([]);
            // setTeamInfo({});


            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_MEMBERS}?emp_id_auth=${storedUserInfo?.emp_id}&team_id=${teamId}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  //console.log("Fetch Member success")
                  if (responseData?.dataJ?.length > 0) {
                    setTeamMembers(responseData?.dataJ);
                    // selTeamInfo.current = responseData?.dataJ[0]
                  } else {
                    setTeamMemebrsError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setTeamMemebrsError(responseData?.info || 'Failed to fetch Team Members. Please try again.');
                }
              } else {
                setTeamMemebrsError(responseData?.info || 'Failed to fetch Team Members.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setTeamMemebrsError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setTeamMemebrsError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setTeamMemebrsError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setTeamMemebrsError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setTeamMemebrsError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Team Members. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setTeamMemebrsError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setTeamMemebrsError(`Failed to fetch Team Members. Please check your network connection and try again.`);
            }
          } finally {
            setLoading(false);
          }
      }

      useEffect(()=>{
        fetchTeamManagerList()
        fetchTeamLeaderList()
      },[])
  
      const fetchTeamManagerList =async ()=>{
        try {
          setTeamManagerLoading(true);
          setEmpError('');
          setTeamManagerError('')
          setTeamManagerList([]);
  
  
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LEADER_MANAGER_LIST}?emp_role=TEAM_MANAGER&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const filterData= responseData?.dataJ.map(service => ({
                      label: service?.emp_name,
                      value: service?.emp_id
                    }));
                    setTeamManagerList(filterData);
                  } else {
                    setTeamManagerError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setTeamManagerError(responseData?.info || 'Failed to fetch User data. Please try again.');
                }
              } else {
                setTeamManagerError(responseData?.info || 'Failed to fetch User data.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setTeamManagerError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setTeamManagerError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setTeamManagerError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setTeamManagerError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setTeamManagerError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setTeamManagerError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setTeamManagerError(`Failed to fetch User data. Please check your network connection and try again.`);
            }
          } finally {
            setTeamManagerLoading(false);
          }
      }

      const fetchTeamLeaderList =async ()=>{
        try {
          setTeamLeaderLoading(true);
          setTeamLeadError('');
          setTeamLeaderList([]);
  
  
            const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        
            // Make the API call
            const response = await getData(`${ORG_GET_TEAM_LEADER_MANAGER_LIST}?emp_role=TEAM_LEAD&emp_id_auth=${storedUserInfo?.emp_id}`);
            const responseData = await response.json();
        
            // Check for success (200) and internal status code
            if (response?.status === 200) {
              if (responseData?.statusCode === 200) {
                if (responseData?.message.toLowerCase() === 'success') {
                  if (responseData?.dataJ?.length > 0) {
                    const filterData= responseData?.dataJ.map(service => ({
                      label: service?.emp_name,
                      value: service?.emp_id
                    }));
                    setTeamLeaderList(filterData);
                  } else {
                    setTeamLeadError(responseData?.info || 'Data Not Found');
                  }
                } else {
                  setTeamLeadError(responseData?.info || 'Failed to fetch User data. Please try again.');
                }
              } else {
                setTeamLeadError(responseData?.info || 'Failed to fetch User data.');
              }
            } 
            // Token-related errors: 400, 401, 403
            else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
              if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
                setTeamLeadError('Unauthorized access. Your session may have expired. Please log in again.');
                // Optionally, redirect to the login page or refresh the token
                // Example: redirectToLogin();
              } else {
                setTeamLeadError(responseData?.info || 'Bad request. Please check the request parameters.');
              }
            } 
            // Internal Server Error: 500
            else if (response?.status === 500) {
              setTeamLeadError(responseData?.info || 'Internal Server Error. Please try again later.');
            } 
            // Unexpected or network-related errors
            else {
              setTeamLeadError('Unexpected error occurred. Please try again later.');
            }
          } catch (error) {
            // Handle various error scenarios
            if (error.response) {
              // Server responded with a status outside the 2xx range
              setTeamLeadError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch User data. Please try again.'}`);
            } else if (error.request) {
              // Request was made but no response was received
              setTeamLeadError('No response received from the server. Please check your network connection and try again.');
            } else {
              // An error occurred in setting up the request
              setTeamLeadError(`Failed to fetch User data. Please check your network connection and try again.`);
            }
          } finally {
            setTeamLeaderLoading(false);
          }
      }

    const handleBack = () => {
        //console.log('handleBack team')
        // navigate('/teams-userlist');
        navigate('/teams-userlist', { state: { selectedTab: location.state?.selectedTab || 0 } });
    }

    const handleUserToggleChange = async (newState,teamId) => {
      //console.log('newState',newState)
      try {
        setStatusLoading(true);
        setStatusSuccess('');
        setTeamStatusError('');


        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
    
        // Make the API call
        const payLoad={
          "emp_id_auth": storedUserInfo?.emp_id,
          "team_status": newState === true ? 1:0,
          "team_id": teamId
        }
        //console.log('team status:---',payLoad);
        const response = await postData(ORG_UPDATE_TEAM_STATUS,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              //console.log("Team Status success");
              setStatusSuccess(responseData?.info)
              setTimeout(()=>{
                setStatusSuccess('');
              },3000);
              
            } else {
              setTeamStatusError(responseData?.info || 'Failed to update team status. Please try again.');
            }
          } else {
            setTeamStatusError(responseData?.info || 'Failed to update team status.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setTeamStatusError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setTeamStatusError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setTeamStatusError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setTeamStatusError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setTeamStatusError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update team status. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setTeamStatusError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setTeamStatusError(`Failed to update team status. Please check your network connection and try again.`);
        }
      } finally {
        setStatusLoading(false);
      }

    }

    const handleEditTeamMember = (teamMember) => {
        //console.log('handleEditTeamMember @@',teamMember);        
        navigate('/manage-user', { state: { isUserEditable: true,userInfo:teamMember } });
    }

  const handleDeleteTeamMember = (teamMember) => {
    //console.log('handleDeleteTeamMember @@', teamMember)
    selTeamMemberRef.current = teamMember;
    SetIsTeamMemberDeleteModalOpen(true);
  }

  const handleCloseTeamMemberDeleteModal = () => {
    SetIsTeamMemberDeleteModalOpen(false);
  }

  const callDeleteTeamMember = async (teamMember) => {
    //console.log('callDeleteTeamMember teamMember @@@', teamMember)
    try {
      setTeamMemDeleteSuccess((prev) => ({ ...prev, [teamMember?.emp_id]: '' }));
      setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: '' }));
      setTeamMemDeleteLoading((prev) => ({ ...prev, [teamMember?.emp_id]: true }));
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "emp_id": teamMember?.emp_id,
        "team_id": primaryData?.team_id
      }

      //console.log('callDeleteTeamMember payload', payLoad);

      // Make the API call
      const response = await postData(ORG_REMOVE_EMP_TEAM, payLoad);
      const responseData = await response.json();
      //console.log('callDeleteTeamMember response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setTeamMemDeleteSuccess((prev) => ({ ...prev, [teamMember?.emp_id]: responseData?.info }));
            setTimeout(() => {
              onValueChangeTeamMember();
              setTeamMemDeleteSuccess((prev) => ({ ...prev, [teamMember?.emp_id]: '' }));
            }, 3000);
          } else {
            setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: responseData?.info || 'Failed to delete team member. Please try again.' }));
          }
        } else {
          setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: responseData?.info || 'Failed to delete team member. Please try again...' }));
        }
      }
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: 'Unauthorized access. Your session may have expired. Please log in again.' }));
        } else {
          setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: responseData?.info || 'Bad request. Please check the request parameters.' }));
        }
      }
      else if (response?.status === 500) {
        setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: responseData?.info || 'Internal Server Error. Please try again later.' }));
      }
      else {
        setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: 'Unexpected error occurred. Please try again later.' }));
      }
    } catch (error) {
      if (error.response) {
        setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: `Error: ${error.response.status}. ${error.response.data?.message || 'Failed to delete service. Please try again.'}` }));
      } else if (error.request) {
        setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: 'No response received from the server. Please check your network connection and try again.' }));
      } else {
        setTeamMemDeleteError((prev) => ({ ...prev, [teamMember?.emp_id]: `Failed to delete team member. Please check your network connection and try again.` }));
      }
    } finally {
      setTeamMemDeleteLoading((prev) => ({ ...prev, [teamMember?.emp_id]: false }));
    }
  }

  const [updateDeleteError, setUpdateDeleteError] = useState('');
  const [updateDeleteSuccess, setUpdateDeleteSuccess] = useState('');
  const [updateLoading, setUpdateLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);


    const handleUpdateTeam = async () => {      

      try {
        setUpdateDeleteError('');
        setUpdateDeleteSuccess('');
        setTeamNameError('');
        setTeamManagerError('');
        setTeamLeadError('');
        if (!editPrimaryData?.team_name.trim()) {
          setTeamNameError('Team Name is required.');
          return;
        }
        if(!editPrimaryData?.emp_id_team_manager){
          setTeamManagerError('Team manager is required.');
          return;
        }
    
      if (!editPrimaryData?.emp_id_team_lead) {
        setTeamLeadError('Team Lead is required.');
        return;
      }
        setUpdateLoading(true);

        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad={
          "emp_id_auth": storedUserInfo?.emp_id,
          "team_name": editPrimaryData?.team_name,
          "team_status": editPrimaryData?.team_status,
          "team_description": editPrimaryData?.team_description,
          "team_id": editPrimaryData?.team_id,
          "emp_id_team_lead": editPrimaryData?.emp_id_team_lead,
          "emp_id_team_manager": editPrimaryData?.emp_id_team_manager
        }
        //console.log('team status:---',payLoad);
        const response = await postData(ORG_UPDATE_EMP_INFO,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              //console.log("Team update success");
              setUpdateDeleteSuccess(responseData?.info || 'Team update success.');
              setTimeout(() => {
                setUpdateDeleteSuccess('')
                // isTeamInfoEdit.current = false
                fetchTeamInfo(editPrimaryData?.team_id);
            }, 3000);
            } else {
              setUpdateDeleteError(responseData?.info || 'Failed to update team status. Please try again.');
            }
          } else {
            setUpdateDeleteError(responseData?.info || 'Failed to update team status.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setUpdateDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setUpdateDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setUpdateDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setUpdateDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setUpdateDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update team status. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setUpdateDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setUpdateDeleteError(`Failed to update team status. Please check your network connection and try again.`);
        }
      } finally {
        setUpdateLoading(false);
      }

    }

    const handleDeleteTeam1 = () => {
      setIsTeamDeleteModalOpen(true);
    }
    
    const handleCloseDeleteTeamModal = () => {
      setIsTeamDeleteModalOpen(false); // Close the modal
  };

    const handleDeleteTeam = async () => {
      try {
        setDeleteLoading(true);

        const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
        const payLoad={
          "emp_id_auth": storedUserInfo?.emp_id,
          "team_id": primaryData?.team_id,
        }
        //console.log('team status:---',payLoad);
        const response = await postData(ORG_DELETE_TEAM,payLoad);
        const responseData = await response.json();
    
        // Check for success (200) and internal status code
        if (response?.status === 200) {
          if (responseData?.statusCode === 200) {
            if (responseData?.message.toLowerCase() === 'success') {
              //console.log("Team update success");
              setUpdateDeleteSuccess(responseData?.info || 'Team Delete success.');
              setTimeout(()=>{
                handleBack();
              },2000);
            } else {
              setUpdateDeleteError(responseData?.info || 'Failed to update team status. Please try again.');
            }
          } else {
            setUpdateDeleteError(responseData?.info || 'Failed to update team status.');
          }
        } 
        // Token-related errors: 400, 401, 403
        else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
          if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
            setUpdateDeleteError('Unauthorized access. Your session may have expired. Please log in again.');
            // Optionally, redirect to the login page or refresh the token
            // Example: redirectToLogin();
          } else {
            setUpdateDeleteError(responseData?.info || 'Bad request. Please check the request parameters.');
          }
        } 
        // Internal Server Error: 500
        else if (response?.status === 500) {
          setUpdateDeleteError(responseData?.info || 'Internal Server Error. Please try again later.');
        } 
        // Unexpected or network-related errors
        else {
          setUpdateDeleteError('Unexpected error occurred. Please try again later.');
        }
      } catch (error) {
        // Handle various error scenarios
        if (error.response) {
          // Server responded with a status outside the 2xx range
          setUpdateDeleteError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to update team status. Please try again.'}`);
        } else if (error.request) {
          // Request was made but no response was received
          setUpdateDeleteError('No response received from the server. Please check your network connection and try again.');
        } else {
          // An error occurred in setting up the request
          setUpdateDeleteError(`Failed to update team status. Please check your network connection and try again.`);
        }
      } finally {
        setDeleteLoading(false);
      }  

    }

    const handleCancelTeam = () => {
      if(isTeamAdd.current){
        resetForm();
      }else{
        isTeamInfoEdit.current = false;
        fetchTeamInfo(teamID || location.state?.selTeamInfo?.team_id);
      }
         
    }

    const handleAddTeamMembers = () => {
        //console.log('handleAddTeamMembers')
        setTeamMemberModalOpen(true);
    }

    const handleCloseTeamMemberModal = () => {
        setTeamMemberModalOpen(false);
    }

    const onValueChangeTeamMember = () => {
      //console.log('onValueChangeTeamMember')
      fetchTeamMembers(teamID || location.state?.selTeamInfo?.team_id);
    }


    const handleInputChange = (key, fieldData) => {
         setEditPrimaryData((prev) => {
          // If the key is "emp_id_team_manager_name", update label and related ID
          if (key === "emp_id_team_manager_name") {
            return {
              ...prev,
              [key]: fieldData.label, // Set the label in emp_id_team_manager_name
              emp_id_team_manager: fieldData.value, // Set the value in emp_id_team_manager
            };
          }
          if (key === "emp_id_team_lead_name") {
            return {
              ...prev,
              [key]: fieldData.label, // Set the label in emp_id_team_lead_name
              emp_id_team_lead: fieldData.value, // Set the value in emp_id_team_lead
            };
          }
          // For other keys, update normally
          return {
            ...prev,
            [key]: fieldData, // Update with the provided value
          };
        });
    };

    const handleAddTeam = async () => {
   
      try {
          setAddError('');   
          setTeamNameError('');
          setTeamLeadError('');
          setTeamManagerError('');        
          setAddLoading(true);
          if(!teamName){
            setTeamNameError("Please enter the name");
            return;
          }
    
          if(!teamManager){
            setTeamManagerError("Please select team manager");
            return;
          }
          if(!teamLead){
            setTeamLeadError("Please select team leader");
            return;
          }
          const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
          const payLoad = {
            emp_id_auth: storedUserInfo?.emp_id,
            team_name: teamName,
            // team_description: teamDescription,
            team_status: 1,
            emp_id_team_lead: teamLead?.value || null,
            emp_id_team_manager: teamManager?.value || null,
          }
      
          //console.log('team Data',payLoad);
          // Make the API call
          const response = await postData(ORG_ADD_TEAM,payLoad);
          const responseData = await response.json();
          //console.log('response Data',responseData);
      
          // Check for success (200) and internal status code
          if (response?.status === 200) {
            if (responseData?.statusCode === 200) {
              if (responseData?.message.toLowerCase() === 'success') {
                setAddSucess(responseData?.info);
                setTimeout(() => {
                  setAddSucess('')
                  handleClose(responseData?.dataJ[0]?.team_id);
                }, 3000);
              } else {
                setAddError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
              }
            } else {
              setAddError(responseData?.info || 'Failed to fetch Task Categorie data.');
            }
          } 
          // Token-related errors: 400, 401, 403
          else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
            if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
              setAddError('Unauthorized access. Your session may have expired. Please log in again.');
              // Optionally, redirect to the login page or refresh the token
              // Example: redirectToLogin();
            } else {
              setAddError(responseData?.info || 'Bad request. Please check the request parameters.');
            }
          } 
          // Internal Server Error: 500
          else if (response?.status === 500) {
            setAddError(responseData?.info || 'Internal Server Error. Please try again later.');
          } 
          // Unexpected or network-related errors
          else {
            setAddError('Unexpected error occurred. Please try again later.');
          }
        } catch (error) {
          // Handle various error scenarios
          if (error.response) {
            // Server responded with a status outside the 2xx range
            setAddError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
          } else if (error.request) {
            // Request was made but no response was received
            setAddError('No response received from the server. Please check your network connection and try again.');
          } else {
            // An error occurred in setting up the request
            setAddError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
          }
        } finally {
          setAddLoading(false);
        }

      // onValueChange(teamData);
      // onClose();
  };

  const handleClose=(teamId)=>{
    //console.log('teamInfo:--',teamId);
    setAddError('');
    resetForm();
    isTeamAdd.current = null
    isTeamInfoEdit.current = false
    fetchTeamInfo(teamId);
    fetchTeamMembers(teamId);
    setTeamID(teamId);
  }

  const resetForm = () => {
      setTeamName('');
      setTeamDescription('');
      setTeamStatus(null);
      setTeamLead(null);
      setTeamManager(null);
      setTeamNameError('');
      setTeamLeadError('');
      setTeamManagerError(''); 
  };

    return {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        handleEditTeamMember,
        handleDeleteTeamMember,
        handleUpdateTeam,
        handleDeleteTeam,
        handleCancelTeam,
        handleAddTeamMembers,
        teamMemberModalOpen,
        handleCloseTeamMemberModal,
        onValueChangeTeamMember,
        teamInfo,
        loading,
        error,
        isTeamInfoEdit,
        primaryData,
        fieldConfigs,
        handleInputChange,
        teamMembers,
        setTeamMembers,
        teamMembersError,
        teamID,
        statusLoading,
        teamStatusError,
        teamManagerList,
        teamManagerLoading,
        empError,
        editPrimaryData,
        updateDeleteError,
        updateLoading,
        deleteLoading,
        updateDeleteSuccess,
        isTeamAdd,
        teamName,setTeamName,
        teamDescription,setTeamDescription,
        teamStatus, setTeamStatus,
        teamLead, setTeamLead,
        teamManager, setTeamManager,
        addLoading,addError,
        teamStatusOptions,
        handleAddTeam,
        teamNameError,
        teamManagerError,
        teamLeadError,
        isTeamMemberDeleteModalOpen,
        selTeamMemberRef, teamMemDeleteLoading,
        teamMemDeleteSuccess, teamMemDeleteError,
        callDeleteTeamMember,
        handleCloseTeamMemberDeleteModal,
        isTeamDeleteModalOpen,
        handleCloseDeleteTeamModal,
        handleDeleteTeam1,
        addSucess,
        statusSuccess,teamLeaderList,teamLeaderError,teamLeaderLoading
    }

}

export default ManageTeamDetailsController;