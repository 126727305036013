import { useState, useRef, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { validateEmailOrMobile, validatePassword, validateVerifyCode } from '../../../Utils/Validation';
import { postDataLogin } from '../../../ApiServices/ApiService';
import { FORGOT_API, CHANGE_PASSWORD_API } from '../../../ApiServices/BaseURL';

const ForgotPasswordController = () => {
    const navigate = useNavigate();
    const [step, setStep] = useState(1); // Step in the forgot password flow
    const [email, setEmail] = useState(''); // User's email input
    const [emailError, setEmailError] = useState(''); // User's email input
    const [newPassword, setNewPassword] = useState(''); // New password
    const [confirmPassword, setConfirmPassword] = useState(''); // Confirm new password
    const [newPasswordError, setNewPasswordError] = useState(''); // New password
    const [confirmPasswordError, setConfirmPasswordError] = useState(''); // Confirm new password
    const [verifyCode, setVerifyCode] = useState('');
    const [verifyCodeError, setVerifyCodeError] = useState('');
    const [error, setError] = useState(''); // Error message
    const [loading, setLoading] = useState(false); // Loading state
    const [emailLoading, setEmailLoading] = useState(false); // Loading state
    const [showNewPassword, setShowNewPassword] = useState(false);
    const [showConfirmPassword, setShowConfirmPassword] = useState(false);
    const [success, setSuccess] = useState('')

    const toggleShowNewPassword = () => {
        setShowNewPassword(!showNewPassword);
    };

    const toggleShowConfirmPassword = () => {
        setShowConfirmPassword(!showConfirmPassword);
    };

    const handleLoginRedirect = () => {
        resetState();
        navigate('/login'); // Replace '/' with the route to your login page
    };

    const handleEmailSubmit = async () => {
        try {
            setError('');
            setEmailError('')
            // if (!validateEmail(email)) {
            //     setEmailError('Please enter a valid email address.');
            //     return;
            // }
            // Validate email or mobile
            const emailValidationError = validateEmailOrMobile(email);
            if (emailValidationError) {
                setEmailError(emailValidationError);
                return;
            }
            const payLoad = {
                "user_name": email
            }
            //console.log('Login Payload:', payLoad);
            // return       
            setEmailLoading(true);
            await postDataLogin(FORGOT_API, payLoad).then(response => {
                //console.log('Login response:', response);
                setEmailLoading(false);
                if (response.statusCode === 200) {
                    if (response.message === 'success') {
                        setSuccess(response?.info);
                        setTimeout(() => {
                            setSuccess('');
                            setStep(2)
                        }, 3000);
                    } else {
                        setError(response.info);
                        //   ExceptionSendService(screenName, 'log-101', response.info)
                    }

                } else if (response.statusCode === 500) {
                    setError(response.info);
                    // ExceptionSendService(screenName, 'log-102', response.info)

                } else {
                    setError("No Internet Connection Detected Or Connection Is Slow");
                    // ExceptionSendService(screenName, 'log-103', 'No Responce')
                }
            })

        } catch (error) {
            setEmailLoading(false);
            let err = String(error)
            //   ExceptionSendService(screenName, 'log-104', err)
            setError("No Internet Connection Detected Or Connection Is Slow !");
        }
    };

    const handlePasswordSubmit = async () => {

        try {
            setError('');
            setNewPasswordError('');
            setConfirmPasswordError('');
            setVerifyCodeError('');
            if (!validateVerifyCode(verifyCode)) {
                setVerifyCodeError('Please enter the 6-digit verification code');
                return
            }

            if (!validatePassword(newPassword)) {
                setNewPasswordError('Password must be 8+ characters, including an uppercase, a lowercase, a number, and a special character.');
                return;
            }
            if (!confirmPassword) {
                setConfirmPasswordError('Please enter confirm password');
                return;
            }

            if (newPassword !== confirmPassword) {
                setConfirmPasswordError('Passwords do not match');
                return;
            }
            const payLoad = {
                "user_name": email,
                "confirmation_code": verifyCode,
                "user_password_new": newPassword
            }
            //console.log('Login Payload:', payLoad);
            // return       
            setLoading(true);
            await postDataLogin(CHANGE_PASSWORD_API, payLoad).then(response => {
                //console.log('Login response:', response);
                setLoading(false);
                if (response.statusCode === 200) {
                    if (response.message === 'success') {
                        setStep(3)
                    } else {
                        setError(response.info);
                        //   ExceptionSendService(screenName, 'log-101', response.info)
                    }

                } else if (response.statusCode === 500) {
                    setError(response.info);
                    // ExceptionSendService(screenName, 'log-102', response.info)

                } else {
                    setError("No Internet Connection Detected Or Connection Is Slow");
                    // ExceptionSendService(screenName, 'log-103', 'No Responce')
                }
            })

        } catch (error) {
            setLoading(false);
            let err = String(error)
            //   ExceptionSendService(screenName, 'log-104', err)
            setError("No Internet Connection Detected Or Connection Is Slow !");
        }
    };

    const resetState = () => {
        setStep(1);
        setEmail('');
        setNewPassword('');
        setConfirmPassword('');
        setError('');
        setConfirmPasswordError('');
        setVerifyCodeError('');
        setNewPasswordError('')
    };

    return {
        step,
        email,
        newPassword,
        confirmPassword,
        error,
        loading,
        setEmail,
        setNewPassword,
        setConfirmPassword,
        handleEmailSubmit,
        handlePasswordSubmit,
        resetState,
        showNewPassword,
        showConfirmPassword,
        toggleShowNewPassword,
        toggleShowConfirmPassword,
        handleLoginRedirect,
        emailError,
        newPasswordError,
        confirmPasswordError,
        verifyCode, setVerifyCode,
        verifyCodeError,
        success,
        emailLoading
    };
};

export default ForgotPasswordController;
