import React, { useState, useRef, useEffect } from "react";
import Input from "./Input";
import Dropdown from "./Dropdown";
import CheckBox from "./CheckBox";
import TextInput from "./TextInput";
import DateInput from "./DateInput";
import closeIcon from "../Assets/Icons/close.png";
import search from "../Assets/Icons/search.png";
import { postData, getData } from '../ApiServices/ApiService';
import { ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, ORG_SCHEDULE_ADD_DASHBOARD, ORG_SCHEDULE_TYPE_LIST } from '../ApiServices/BaseURL';
import Loader from "./Loader";
import AddToDoModal from "./Modals/AddToDoModal";

const AddScheduleReminderModal = ({ isOpen, onClose, onValueChange, fetchPriorityList, fetchToDoList, selectedUpcomingData,clientInfo,clientFileId }) => {
  const [type, setType] = useState("");
  const [dateTime, setDateTime] = useState("");
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [location, setLocation] = useState("");
  const [sendUpdateToClient, setSendUpdateToClient] = useState(false);
  const [sendUpdateToTeam, setSendUpdateToTeam] = useState(false);
  const [clientName, setClientName] = useState("");
  const [error, setError] = useState('');
  const [clientList, setClientList] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [success, setSuccess] = useState('');
  const [selectedClientError, setSelectedClientError] = useState('');
  const [loading, setLoading] = useState(false);
  const [searchUser, setSearchUser] = useState('');
  const [addScheduleError, setAddScheduleError] = useState('');
  const [typeError, setTypeError] = useState('');
  const [dateTimeError, setDateTimeError] = useState('');
  const [titleError, setTitleError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [locationError, setLocationError] = useState('');
  const [clientNameError, setClientNameError] = useState('');
  const [scheduleLoading, setScheduleLoading] = useState(false);
  const [scheduleTypesLoading, setScheduleTypesLoading] = useState(false);
  const [scheduleTypeError, setScheduleTypeError] = useState('');
  const [scheduleTpyeList, setScheduleTypeList] = useState([]);
  const [typeOptions, setTypeOptions] = useState([]);

  // const typeOptions = ["Follow Up","Remainder","Appointment","Meeting", "To-DO"];
  // const locationOptions = ["Online", "Offline"];
  const locationOptions = [
    { label: "Online", value: 2 },
    { label: "Offline", value: 1 },
  ];
  const descriptionOptions = ["Online Meeting", "In-Person", "Call"];

  useEffect(()=>{
    if(clientInfo){
      setSelectedClient(clientInfo);
      setSearchUser(clientInfo?.client_name)
    }

  },[clientInfo])

  useEffect(() => {
    if (isOpen && selectedUpcomingData) {
      //console.log("selectedUpcomingData", selectedUpcomingData);
      // return

      setDescription(selectedUpcomingData?.sc_desc || '');
      // setStaus(selectedUpcomingData?.sc_status);
      setType(selectedUpcomingData?.sc_type_id || '');
      setTitle(selectedUpcomingData?.sc_title)
      setClientName(selectedUpcomingData?.sc_status)
      if (selectedUpcomingData?.sc_time) {
        const currentTime = new Date().toTimeString().split(' ')[0] + '.' + new Date().getMilliseconds(); // Get time in HH:mm:ss.SSS

        const datePart = selectedUpcomingData?.sc_time.split(' ')[0];
        const dateTimeWithTime = `${datePart} ${currentTime}`;

        setDateTime(selectedUpcomingData?.sc_time.replace(' ', 'T'));
      } else {
        setDateTime('');
      }
      setSearchUser(selectedUpcomingData?.client_name || '');
      setLocation(selectedUpcomingData.sc_location);
    }
  }, [isOpen, selectedUpcomingData]);

  const handleAddSchedule = () => {
    const scheduleData = {
      type,
      dateTime,
      title,
      description,
      location,
      sendUpdateToClient,
      sendUpdateToTeam,
      clientName,
    };
    onValueChange(scheduleData);
    onClose();
  };

  const resetForm = () => {
    setType("");
    setDateTime("");
    setTitle("");
    setDescription("");
    setLocation("");
    setSendUpdateToClient(false);
    setSendUpdateToTeam(false);
    setClientName("");
    setSearchUser('');
    setClientList([]);
    setSelectedClient(null);
    setSuccess('')
  };

  const fetchTypesList = async () => {
    try {
      setScheduleTypesLoading(true);
      setScheduleTypeError('');
      setScheduleTypeList([])
      // setTeamInfo({});
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));

      // Make the API call
      const response = await getData(`${ORG_SCHEDULE_TYPE_LIST}?emp_id_auth=${storedUserInfo?.emp_id}`);
      const responseData = await response.json();

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            if (responseData?.dataJ?.length > 0) {
              const fetchedList = responseData?.dataJ
              setScheduleTypeList(fetchedList);
              const options = fetchedList?.map((item) => ({
                label: item.sc_type_name,
                value: item.sc_type_id,
              }));
              const updatedOptions = [
                ...options,
                { label: "To-Do", value: "To-Do" }, // Static option
              ];
              setTypeOptions(updatedOptions);
              // selTeamInfo.current = responseData?.dataJ[0];
            } else {
              setIsAddToDoModalOpen(true)
              // setScheduleTypeError(responseData?.info || 'Data Not Found');
            }
          } else {
            setScheduleTypeError(responseData?.info || 'Failed to fetch schedule types. Please try again.');
          }
        } else {
          setScheduleTypeError(responseData?.info || 'Failed to fetch schedule types.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setScheduleTypeError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setScheduleTypeError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setScheduleTypeError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setScheduleTypeError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setScheduleTypeError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch schedule types. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setScheduleTypeError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setScheduleTypeError(`Failed to fetch schedule types. Please check your network connection and try again.`);
      }
    } finally {
      setScheduleTypesLoading(false);
    }
  }

  useEffect(() => {
    fetchTypesList()
  }, [])


  const handleSelectClient = (client) => {
    setSelectedClient(client); // Set selected client data
    setSearchUser(client?.client_name); // Update TextInput with the selected client's name
    setClientList([]); // Clear the dropdown
    setSelectedClientError('');
  };

  const handleSearchClient = async () => {
    try {
      setError('');
      setSelectedClientError('');
      setLoading(true);
      setClientList([]);
      if (!searchUser) {
        setError('Please enter valid text'); return;
      }
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, search_type: "GENERIC", search_word: searchUser }

      //console.log('team Data', payLoad);
      // Make the API call
      const response = await postData(ORG_CLIENT_SEARCH_TO_ADD_GROUPMEMBER, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setClientList(responseData?.dataJ || []);
            // setSuccess(responseData?.info);
            setTimeout(() => {
              // handleClose();
              // onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch Client. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Client.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Client. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }
  };

  const handleClose = () => {
    setError('');
    setClientNameError('');
    setLocationError('');
    setDescriptionError('');
    setTitleError('');
    setDateTimeError('');
    setTypeError('');
    setSuccess('')
    onClose();
    setDateTime('');
    setDescription('')
    setType('');
    setTitle('')
    setLocation('')
    setSearchUser('')
    setSendUpdateToClient(false);
    setSendUpdateToTeam(false);
    setScheduleTypeError('')
    setAddScheduleError('')
  }

  const handleScheduleRemainderAdd = async () => {

    try {
      setAddScheduleError('');
      setClientNameError('');
      setLocationError('');
      setDescriptionError('');
      setTitleError('');
      setDateTimeError('');
      setTypeError('');

      if (!type) {
        setTypeError("Please enter select type");
        return
      }
      // if(!description){
      //   setDocumentDescriptionError("Please enter document description");
      //   return
      // }
      if (!dateTime) {
        setDateTimeError("Please select date");
        return
      }
      // if(!documentNumber){
      //   setDocumentNUmberError("Please enter document number");
      //   return
      // }
      if (!title) {
        setTitleError("Please enter title");
        return
      }

      if (!description) {
        setDescriptionError("Please enter description");
        return
      }
      if (!location) {
        setLocationError("Please select location");
        return
      }
      if (!searchUser) {
        setClientNameError("Please select client");
        return
      }
      // if (!searchUser) {
      //   setError('Please enter valid text'); return;
      // }         
      setScheduleLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      // const locationValue = location === 'Online' ? 2 : 1;
      const payLoad = { emp_id_auth: storedUserInfo?.emp_id, client_id: selectedClient.client_id, sc_title: title, sc_desc: description, sc_location: location, update_to_client_status: sendUpdateToClient ? 1 : 0, update_to_team_status: sendUpdateToTeam ? 1 : 0, sc_status: 1, sc_time: dateTime.replace('T',' '), sc_type_id: type, emp_id: storedUserInfo?.emp_id }

      //console.log('handleSearchEmployee payload', payLoad);
      // Make the API call
      const response = await postData(ORG_SCHEDULE_ADD_DASHBOARD, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setAddScheduleError('')
            // setEmployeeList(responseData?.dataJ || []);
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              // onValueChange();
              fetchPriorityList();
            }, 3000);
          } else {
            setAddScheduleError(responseData?.info || 'Failed to add schedule. Please try again.');
          }
        } else {
          setAddScheduleError(responseData?.info || 'Failed to add schedule.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setAddScheduleError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setAddScheduleError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setAddScheduleError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setAddScheduleError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setAddScheduleError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to add schedule. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setAddScheduleError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setAddScheduleError(`Failed to add schedule. Please check your network connection and try again.`);
      }
    } finally {
      setScheduleLoading(false);
    }

    // onValueChange(teamData);
    // onClose();
  };

  const handleDateChange = (date) => {
    console.log('date time:-',date)
    setDateTime(date);
    if (!date) {
      setDateTimeError('Please select a date.');
    } else {
      setDateTimeError('');
    }
  };
  const [isAddToDoModalOpen, setIsAddToDoModalOpen] = useState(false);
  const handleToDOClose = () => {
    setIsAddToDoModalOpen(false);
    onClose();
  }
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);


  if (!isOpen) return null;
  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={() => { resetForm(); handleClose(); }}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        <h2 className="modal-title">Add Schedule/Reminder</h2>
        <hr className="title-divider" />

        <div className="form-grid">
          <Dropdown
            ref={(el) => (dropdownRefs.current["typeOptions"] = el)}
            isOpen={openDropdown === "typeOptions"}
            toggleDropdown={() => toggleDropdown("typeOptions")}
            label="Select Type"
            options={typeOptions}
            value={typeOptions.find((option) => option.value === type)}
            // searchBoxWidth={260}
            onChange={(selectedOption) => {
              if (selectedOption.value === "To-Do") {
                setIsAddToDoModalOpen(true)
                // onClose();
              } else {
                setType(selectedOption.value);
              }
            }}
            errorMessage={scheduleTypeError || typeError}
            loading={scheduleTypesLoading} // Pass loading state to show Loader
          />
          {/* <Input
                        label="Date & Time"
                        placeholder="Select Date & Time"
                        value={dateTime}
                        onChange={(e) => setDateTime(e.target.value)}
                    /> */}
          <div style={{ marginTop: '-5px' }}>
            <label style={{ fontSize: '12px', color: '#343C44' }}>Date & Time</label>
            <DateInput
              label="Published Date"
              value={dateTime}
              onChange={(e) => handleDateChange(e.target.value)}
              // width={'250px'}
              errorMessage={dateTimeError}
              // searchBoxWidth={260}
              isDateTime={true}
              // min={new Date().toISOString().slice(0, 16)}
            />
          </div>
          <Input
            label="Title"
            placeholder="Enter Title"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            errorMessage={titleError}
            // searchBoxWidth={260}
          />
          <Input
            label="Description"
            placeholder="Enter Description"
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            errorMessage={descriptionError}
            // searchBoxWidth={260}
          />
          <Dropdown
            ref={(el) => (dropdownRefs.current["Location"] = el)}
            isOpen={openDropdown === "Location"}
            toggleDropdown={() => toggleDropdown("Location")}
            label="Location"
            options={locationOptions}
            value={locationOptions.find((option) => option.value === location)} // Bind location value
            onChange={(selectedOption) => setLocation(selectedOption.value)}
            errorMessage={locationError}
            // searchBoxWidth={260}
          />
          <div>
            <div className="filter-field" style={{ marginTop: '-7px',width:'100%' }}>
              <label>Client</label>
              <TextInput
                type="text"
                placeholder="Client"
                value={searchUser}
                onChange={(e) => setSearchUser(e.target.value)}
                onRightClick={handleSearchClient}
                right={
                  loading ? (
                    <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                  ) : (
                    <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                  )
                }
                style={{ padding: '20px 10px' }}
                errorMessage={clientNameError || error}
                // searchBoxWidth={280}
                readOnly={!!selectedUpcomingData}
              />
              {clientList.length > 0 && (
                <ul style={styles.dropdownList}>
                  {clientList.map((client, index) => (
                    <li
                      key={client?.client_id}
                      onClick={() => handleSelectClient(client)}
                      style={{
                        ...styles.dropdownItem,
                        borderBottom: index !== clientList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                      }}
                    >
                      {client?.client_name}
                    </li>
                  ))}
                </ul>
              )}
            </div>
          </div>
        </div>

        <div className="existing-client-checkbox">
          {/* <input
                        type="checkbox"
                        checked={sendUpdateToClient}
                        onChange={(e) => setSendUpdateToClient(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update to Client</label> */}
          <CheckBox
            label="Send Update to Client"
            checked={sendUpdateToClient}
            onChange={(isChecked) => setSendUpdateToClient(isChecked)}
            style={styles.checkbox}
          />
        </div>
        <div className="existing-client-checkbox">
          {/* <input
                        type="checkbox"
                        checked={sendUpdateToTeam}
                        onChange={(e) => setSendUpdateToTeam(e.target.checked)}
                        style={styles.checkbox}
                    />
                    <label style={styles.exisitingHeading}>Send Update to Team</label> */}
          <CheckBox
            label="Send Update to Team"
            checked={sendUpdateToTeam}
            onChange={(isChecked) => setSendUpdateToTeam(isChecked)}
            style={styles.checkbox}
          />
        </div>
        <hr className="footer-divider" />


        <div className="button-container" style={{ alignItems: 'center' }}>
          {/* <button className="add-client-button" onClick={handleScheduleRemainderAdd} style={{paddingRight:'30px', paddingLeft:'30px'}}>Add</button> */}
          <button
            className="add-client-button"
            onClick={handleScheduleRemainderAdd}
            disabled={scheduleLoading}
            style={{
              paddingLeft: '30px',
              paddingRight: '30px',
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              gap: '10px', // Add spacing between text and loader
              position: 'relative',
              opacity: scheduleLoading ? 0.5 : 1, // Reduce opacity when loading
              cursor: scheduleLoading ? 'not-allowed' : 'pointer',
            }}
          >
            <span>{'Add'}</span>
            {scheduleLoading && (
              <Loader loading={scheduleLoading} color={'var(--background-color)'} size="small" />
            )}
          </button>
          <button className="cancel-button" onClick={() => { resetForm(); handleClose(); }}>Cancel</button>
          {/* {error && <div className="list-error-msg" style={{marginBottom:'1px'}}>{error} </div>} */}
          {success && <div className="list-success-msg" style={{ marginBottom: '1px' }}>{success} </div>}
          {addScheduleError && <div className="list-error-msg" style={{ marginBottom: '1px' }}>{addScheduleError} </div>}
        </div>
      </div>
      <AddToDoModal isOpen={isAddToDoModalOpen} onClose={handleToDOClose} fetchToDoList={fetchToDoList} clientInfo={clientInfo} clientFileId={clientFileId} fetchPriorityList={fetchPriorityList}/>
    </div>
  );
};

const styles = {
  iconImage: {
    width: '15px',
    height: '15px',
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  dropdownList: {
    position: 'absolute',
    top: '100%',
    left: 0,
    right: 0,
    backgroundColor: '#fff',
    border: '1px solid #ccc',
    borderRadius: '4px',
    maxHeight: '200px',
    overflowY: 'auto',
    zIndex: 1000,
    padding: 0, // Remove default padding
    margin: 0, // Remove default margin
    listStyleType: 'none', // Remove the dots
  },
  dropdownItem: {
    padding: '10px',
    cursor: 'pointer',
    borderBottom: '1px solid #eee', // Default for all items
    backgroundColor: '#fff',
    hover: {
      backgroundColor: '#f0f0f0',
    },
  },
};

export default AddScheduleReminderModal;
