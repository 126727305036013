import React, { useState,useEffect } from 'react';
import editIcon from '../../../../Assets/Icons/edit-pen.png'
import uploadIcon from '../../../../Assets/Icons/upload.png'
import calenderIcon from '../../../../Assets/Icons/today.png'
import backIcon from '../../../../Assets/Icons/back.png'
import { useNavigate, useLocation } from 'react-router-dom';
import AddPrimaryInformationScreen from './AddPrimaryInformationScreen';

const AddClientScreen = () => {
    const location = useLocation();
    const [activeTab, setActiveTab] = useState('PrimaryInformation');
    const [isEditable, setIsEditable] = useState(false);
    const [viewingTaskDetails, setViewingTaskDetails] = useState(false); // New state for toggling between task list and task details
    const [selectedTask, setSelectedTask] = useState(null); // Store the selected task
    const [clintInfo,setClientInfo] = useState({})

    // const {clientId} =  || '';
    //console.log('clientId manage:----------',location.state?.clientId);
    const clientInfo = location.state?.clientInfo
  



    const handleUpdateClient = () => {
        // setIsEditable(!isEditable); 
    };

    const handleTaskEdit = (task) => {
        setSelectedTask(task); // Set the selected task
        setViewingTaskDetails(true); // Switch to task details view
      };
    
      const handleBackToTaskList = () => {
        setViewingTaskDetails(false); // Switch back to task list view
        setSelectedTask(null); // Clear the selected task
      };
      const handelClientInfo=(clientData)=>{
        setClientInfo(clientData);
      }
    
    const renderContent = () => {
        switch (activeTab) {
            case 'PrimaryInformation':
                return <AddPrimaryInformationScreen isEditable={true} onUpdate={() => setIsEditable(false)} onCancel={() => setIsEditable(false)} clientInfo={clientInfo}/>;
            // case 'Services':
            //     return <ClientAvailedServicesScreen />;    
            case 'UserIdPassword':
                return <></>;
            default:
                return null;
        }
    };

    const navigate = useNavigate();

  const handleNav = (pageName)=>{
    //console.log('page',pageName);
    navigate(`/${pageName}`)

  }
  const handleBackToClient =()=>{
    navigate('/clientlist')

  }

    return (
        <>
        <div style={{ width: '95%', margin: 'auto' }}>
            <h3 style={styles.header}>
                <div style={styles.headerContainer} onClick={handleBackToClient}>
                    <img
                        src={backIcon}
                        alt="Back"
                        style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    />
                    <span>Add Client</span>
                </div>
            </h3>
        </div>

        <div style={{width:'95%',margin:'auto'}}>
            <div className="header-container-manage-client">
                <div className="header-left">
                    <h1 className="company-name">{clintInfo?.client_name}</h1>
                    {/* <p className="company-details">
                        <span style={{fontWeight:700}}>Company</span> | GST | Income Tax | Accounting | Misc
                    </p> */}
                </div>
                <div style={styles.leftHeader}>
                <div className="header-right">
                    <div className="action-item" style={{ cursor: 'pointer' }}>
                        <img src={uploadIcon} alt="Generate Report" />
                        <span>Generate Report</span>
                    </div>
                    <div className="action-item" style={{ cursor: 'pointer' }}>
                        <img src={calenderIcon} alt="Add Reminder" />
                        <span>Add Reminder</span>
                    </div>
                    <div className="action-item" style={{ cursor: 'pointer' }} >
                        <span style={{fontSize:'18px'}}>+</span>
                        <span>Create Task</span>
                    </div>
                    <div className="action-item" onClick={handleUpdateClient} style={{ cursor: 'pointer' }}>
                        <img src={editIcon} alt="Update Client" />
                        <span>Update Client</span>
                    </div>
                </div>
               
                </div>
            </div>
            {/* Tab Navigation */}
            <div className="tab-container">
                <div
                    className={activeTab === 'PrimaryInformation' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                >
                    Primary Information
                </div>
                {/* <div
                    className={activeTab === 'Services' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('Services')}
                >
                    Services
                </div> */}
                <div
                    className={activeTab === 'UserIdPassword' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{opacity:0.5}}
                >
                    User ID & Passwords
                </div>
                <div
                    className={activeTab === 'KYCDocuments' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{opacity:0.5}}
                >
                    KYC Documents
                </div>
                <div
                    className={activeTab === 'Tasks' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{opacity:0.5}}
                >
                    Tasks
                </div>
                <div
                    className={activeTab === 'BillingFees' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{opacity:0.5}}
                >
                    Billing & Fees
                </div>
                <div
                    className={activeTab === 'Comments' ? 'tab active-tab' : 'tab'}
                    onClick={() => setActiveTab('PrimaryInformation')}
                    style={{opacity:0.5}}
                >
                    Comments
                </div>
            </div>

            {/* Tab Content */}
            <div className="content-container" >
                {renderContent()}
            </div>
        </div>
        </>
    );
};

const styles = {
    header: {
      fontSize: "22px",
      color: "#060606",
      marginBottom: "10px",
      fontWeight:500,
    //   fontFamily:'Montserrat',
    //   marginLeft:'20px'
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
    },
    leftHeader:{
        marginTop:'-5px'
    }
  };

export default AddClientScreen;
