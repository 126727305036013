import React, { useState, useRef, useEffect } from "react";
import Dropdown from '../../../../Components/Dropdown';
import HoldTaskIssuesModal from "../../../../Components/Modals/HoldTaskIssuesModal";
import pdfIcon from "../../../../Assets/Images/document-pdf.png";
import addDocumentIcon from "../../../../Assets/Icons/Downlaod.png";
import backIcon from "../../../../Assets/Icons/back.png";
import uploadIcon from "../../../../Assets/Icons/upload.png";
import circleTick from "../../../../Assets/Icons/circle-tick.png";
import circleIcon from "../../../../Assets/Icons/circle.png";
import plus from "../../../../Assets/Icons/Pluse.png";
import infoIcon from "../../../../Assets/Icons/error.png";
import checkBox from "../../../../Assets/Icons/check_box.png";
import editPen from '../../../../Assets/Icons/edit-pen.png';
import search from '../../../../Assets/Icons/search.png';
import fastForwardIcon from '../../../../Assets/Icons/fast_forward.png'
import ClientTaskDocumentAddModal from "../../../../Components/Modals/ClientTaskDocumentAddModal";
import ClientTaskDetailsController from "../../../Controllers/AdminScreens/Clients/ClientTaskDetailsController";
import Loader from '../../../../Components/Loader';
import LoadingSkeleton from "../../../../Components/LoadingSkeleton";
import Input from "../../../../Components/Input";
import DateInput from "../../../../Components/DateInput";
import TextInput from "../../../../Components/TextInput";
import { formatToLocalTime, formatToDMY, convertToLocalTime } from "../../../../Utils/Validation";
import Textarea from "../../../../Components/Textaera";
import UpdatePriorityModal from "../../../../Components/Modals/UpdatePriorityModal";
import ErrorIcon from "../../../../Assets/Icons/error_message.png";
import UpdateTaskCheckStatusModal from "../../../../Components/Modals/UpdateTaskCheckStatusModal";
import AddScheduleReminderModal from "../../../../Components/AddScheduleReminderModal";

const ClientTaskDetailsScreen = ({ task, onBack, onRefresh, clientId }) => {
    // //console.log("task id", task)

    const {
        kycListError,
        kycDocumentList,
        kycListLoading,
        internalRecords,
        externalRecords,
        fetchKYCDocumentsList,
        downloadDocuments,
        downloadKycLoading,
        kycDownloadError,
        externalDownloadError,
        internalDownloadError,
        externalLoading,
        internalLoading,
        currentDocIndex,
        taskInfo,
        isEditable,
        fieldConfigs,
        taskInfoError,
        taskInfoLoading,
        handleFieldChange,
        onEdit,
        handleSelectedEmployee,
        handleSearchEmployee,
        addEmployeeError,
        employeeList,
        loading,
        updateLoading,
        onUpdateTaskInfo,
        updateStatusError,
        updateStatusSuccess,
        setTaskCheckList,
        taskCheckList,
        taskCheckError,
        taskCheckLoading,
        handleSaveTaskStatus,
        saveLoading,
        saveError,
        saveSuccess,
        handleHoldTask,
        handleCloseModal,
        handleStatusChange,
        handleAssignedToChange,
        handleComplitedBy,
        handleRowClick,
        handleAddDocument,
        handleAddDocumentCloseModal,
        status,
        assignedTo,
        isModalOpen,
        complitedBy,
        selectedDocument,
        modalVisible,
        modalSource,
        setSelectedDocument,
        handleCommentChange,
        taskComment,
        isHoldTask,
        handleUpdatePriority,
        isPriorityModalOpen, handleClosePriorityModal, isPriority, onStatusChangeSuccess,
        taskStatus, setTaskStatus, assignEmployeeList, empLoading, empResError,
        isTaskCheckModalOpen, handleCloseTaskCheckModal, isTaskCheck, onTaskCheckStatusSuccess,
        isScheduleModalOpen, handleCloseScheduleDoModal,
        handleScheduleAppointment,
        kycDocClientError,
        kycDocInternalError
    } = ClientTaskDetailsController(clientId, task)
    // //console.log("Internal and external",internalRecords,externalRecords)


    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);




    if (!task) {
        return <p>No task selected.</p>;
    }
    //console.log('taskInfo:--',taskInfo?.task_status === 12 || taskInfo?.task_status === 13)




    return (
        <div style={styles.container}>
            {/* Back Button */}
            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "space-between", }}>
                <h3 style={styles.taskInfoTitle}>Task Information</h3>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: "flex-end", gap: 20 }}>
                    <button onClick={onBack} style={styles.backButton}>
                        <img
                            src={backIcon}
                            alt="Back"
                            style={{ width: "16px", height: "16px", marginRight: "5px" }}
                        />
                        Back to Task Lists
                    </button>
                    <button onClick={onEdit} style={styles.editButton}>
                        <img
                            src={editPen}
                            alt="editPen"
                            style={{ width: "16px", height: "16px", marginRight: "5px" }}
                        />
                        Edit Task
                    </button>
                </div>

            </div>
            {(taskInfo?.task_status === 12 || taskInfo?.task_status === 13) &&
                <div style={styles.errorMessage}>
                    <img
                        src={ErrorIcon}
                        alt="Error"
                        style={{ width: "40px", height: "40px" }}
                    />

                    <div style={{ flex: 'auto' }}>
                        <div>{taskInfo?.task_comment}
                        </div>

                    </div>
                </div>
            }

            {/* Task Information Section */}
            <div style={styles.section}>
                <div style={styles.taskInfoBox} >
                    <div className='basic-info-row' style={{ display: 'grid', gridTemplateColumns: 'repeat(auto-fit, minmax(200px, 1fr))' }}>
                        {fieldConfigs.map((field) => (
                            <div className="basic-info-item" key={field.key}>
                                {isEditable ?
                                    (
                                        field.type === "dropdown" ? (
                                            <Dropdown
                                                ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                                                isOpen={openDropdown === `${field.label}`}
                                                toggleDropdown={() => toggleDropdown(`${field.label}`)}
                                                label={field?.label} // Dropdown already renders the label internally
                                                options={field?.options}
                                                value={taskInfo[field.key]}
                                                onChange={(selectedValue) => handleFieldChange(field.key, selectedValue)}
                                                loading={field?.loading}
                                                errorMessage={field?.error}
                                            />
                                        ) : field.type === "date" ? (
                                            <div className="basic-info-label">
                                                <label>{field.label}</label>
                                                <DateInput
                                                    value={taskInfo[field.key] || ""}
                                                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                                                />
                                            </div>
                                        ) : field.type === "search" ? (
                                            <div className="filter-field" style={{ marginBottom: '15px', width: '100%' }}>
                                                <label
                                                    style={{
                                                        ...styles.labaleStyle,
                                                        // marginBottom: '8px',
                                                        display: 'block',
                                                    }}
                                                >
                                                    {field.label}
                                                </label>
                                                <TextInput
                                                    type="text"
                                                    placeholder="Search User"
                                                    value={taskInfo[field.key]}
                                                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                                                    onRightClick={handleSearchEmployee}
                                                    right={
                                                        loading ? (
                                                            <Loader loading={loading} color={'var(--primary-color)'} size='small' /> // Show loader when loading
                                                        ) : (
                                                            <img src={search} alt="Search" style={{ cursor: 'pointer' }} />
                                                        )
                                                    }
                                                    style={{ padding: '20px 10px' }}
                                                    errorMessage={addEmployeeError}
                                                />
                                                {employeeList.length > 0 && (
                                                    <ul style={styles.dropdownList}>
                                                        {employeeList.map((employee, index) => (
                                                            <li
                                                                key={employee?.emp_id}
                                                                onClick={() => handleSelectedEmployee(employee)}
                                                                style={{
                                                                    ...styles.dropdownItem,
                                                                    borderBottom: index !== employeeList.length - 1 ? '1px solid #ccc' : 'none', // Add line below except last item
                                                                }}
                                                            >
                                                                {employee?.emp_name}
                                                            </li>
                                                        ))}
                                                    </ul>
                                                )}

                                            </div>
                                        ) :
                                            (
                                                <Input
                                                    label={field.label} // Input already renders the label internally
                                                    placeholder={`Enter ${field.label}`}
                                                    value={taskInfo[field.key]}
                                                    onChange={(e) => handleFieldChange(field.key, e.target.value)}
                                                    errorMessage={field?.error}
                                                    isEdit={field?.readOnly}
                                                />
                                            )
                                    ) : (
                                        <div><span className="basic-info-label">{field.label}</span>
                                            {taskInfoLoading ? <LoadingSkeleton width="40%" height="10px" /> : <span className="basic-info-value">
                                                {field.key === 'task_priority' ? (<>{taskInfo[field.key] === 1 ? 'Yes' : 'No'}</>) :
                                                    field.key === "client_mobile_no" ? (
                                                        `${taskInfo?.client_mobile_code || ""} ${taskInfo[field.key] || ""
                                                        }`
                                                    ) : field.key === "start_date" ? (formatToDMY(taskInfo[field.key])) :
                                                        field.key === "due_date" ? (formatToDMY(taskInfo[field.key])) :
                                                            (taskInfo[field.key] || "--")} </span>}
                                        </div>
                                    )}
                            </div>
                        ))}



                    </div>
                    {isEditable &&
                        <>
                            {updateStatusSuccess && <div className="list-success-msg">{updateStatusSuccess} </div>}
                            {updateStatusError && <div className="list-error-msg">{updateStatusError} </div>}
                            <div className="button-container">
                                <button className="add-client-button" style={{ padding: '10px 30px', opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer', }} onClick={onUpdateTaskInfo} disabled={updateLoading}>
                                    {updateLoading && <span style={{ paddingRight: 10 }}><Loader loading={updateLoading} color='#FFFFFF' size='small' /></span>} Update Task
                                </button>
                            </div>
                        </>}
                </div>
            </div>

            {/* Documents */}
            <div style={styles.section}>
                <h3 style={styles.sectionTitle}>Documents</h3>
                <div style={{ ...styles.taskInfoBox, display: 'flex', alignItems: 'center', flexWrap: 'nowrap', position: 'relative', }}>
                    {/* Scrollable container for external records */}
                    <div style={{
                        display: 'flex',
                        overflowX: 'auto',
                        gap: '25px',
                        // padding: '5px',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                        marginRight: '10px', // Add spacing between scrollable container and "+"
                        paddingBottom: '5px',
                        marginBottom: '15px'
                    }}>
                        {externalRecords.map((doc, index) => (
                            <div key={index} style={{ ...styles.documentCard, flexShrink: 0, marginTop: '15px', width: '130px', height: '100px', }}>
                                <div style={{
                                    ...styles.documentIconWrapper, width: '36px',
                                    height: '36px',
                                }}>
                                    <img src={pdfIcon} alt="PDF Icon" style={styles.documentIcon} />
                                </div>
                                <p style={{ ...styles.documentTitle, whiteSpace: 'normal', wordWrap: 'break-word', }}>{doc.doc_name}</p>
                                <button style={{
                                    ...styles.downloadButton, cursor: externalLoading && currentDocIndex === index ? 'not-allowed' : 'pointer',
                                    opacity: externalLoading && currentDocIndex === index ? 0.5 : 1,
                                }} disabled={externalLoading && currentDocIndex === index} onClick={() => downloadDocuments(doc.doc_id, index, false)}>
                                    <img src={addDocumentIcon} alt="Download Icon" style={styles.downloadIcon} />
                                </button>
                                {externalLoading && currentDocIndex === index && (
                                    <div style={{ marginLeft: '10px', position: 'absolute', top: '10px', right: "2px" }}>
                                        <Loader size="small" loading={externalLoading} color="var(--primary-color)" /> {/* Replace with your loader */}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {/* Fixed Add Document Card */}
                    <label
                        style={{
                            ...styles.addDocumentCard,
                            width: '150px',
                            height: '115px',
                            flexShrink: 0, // Prevent shrinking
                            display: 'flex',
                            flexDirection: 'column',
                            justifyContent: 'center',
                            alignItems: 'center',
                            marginBottom: '10px',
                        }}
                        onClick={() => handleAddDocument("external")}
                    >
                        <div style={styles.addDocumentIconWrapper}>
                            <span>
                                <img src={plus} alt="Plus" style={styles.tickIcon} />
                            </span>
                        </div>
                        <p style={{ marginBottom: '10px', textAlign: 'center', fontSize: '13px', color: '#262626', fontWeight: '500' }}>Add Document</p>
                    </label>
                    {kycListLoading && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                        }}>
                            <Loader loading={kycListLoading} color={'var(--primary-color)'} />
                        </div>
                    )}
                    {kycListError && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                        }}>
                            {kycListError && <div className="list-error-msg">{kycListError} </div>}
                        </div>
                    )}
                    {kycDocClientError && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                        }}>
                            {kycDocClientError && <div className="list-error-msg">{kycDocClientError} </div>}
                        </div>
                    )}
                    {externalDownloadError && (
                        <div style={{
                            position: 'absolute',
                            top: '90%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                            whiteSpace: 'normal', wordWrap: 'break-word'

                        }}>
                            {externalDownloadError && <div className="list-error-msg">{externalDownloadError} </div>}
                        </div>
                    )}
                </div>
            </div>


            {/*Internal Documents*/}
            <div style={styles.section}>
                <h3 style={styles.sectionTitle}>Internal Documents</h3>
                <div style={{ ...styles.taskInfoBox, display: 'flex', alignItems: 'center', flexWrap: 'nowrap', position: 'relative', }}>
                    {/* Scrollable container for documents */}
                    <div style={{
                        display: 'flex',
                        overflowX: 'auto',
                        gap: '10px',
                        // padding: '5px',
                        whiteSpace: 'nowrap',
                        flexGrow: 1,
                        marginBottom: '15px',
                        paddingBottom: '5px',
                    }}>
                        {internalRecords.map((doc, index) => (
                            <div
                                key={index}
                                style={{
                                    ...styles.documentCard,
                                    width: '150px',
                                    height: '110px',
                                    backgroundColor: '#fff',
                                    flexShrink: 0, // Prevents the item from shrinking
                                }}
                            >
                                <div
                                    style={{
                                        ...styles.documentIconWrapper,
                                        width: '36px',
                                        height: '36px',
                                    }}
                                >
                                    <img src={pdfIcon} alt="PDF Icon" style={styles.documentIcon} />
                                </div>
                                <p style={{ ...styles.documentTitle, whiteSpace: 'normal', wordWrap: 'break-word' }}>{doc.doc_name}</p>
                                <button style={{
                                    ...styles.downloadButton, cursor: internalLoading && currentDocIndex === index ? 'not-allowed' : 'pointer',
                                    opacity: internalLoading && currentDocIndex === index ? 0.5 : 1,
                                }} disabled={internalLoading && currentDocIndex === index} onClick={() => downloadDocuments(doc.doc_id, index, true)}>
                                    <img src={addDocumentIcon} alt="Download Icon" style={styles.downloadIcon} />
                                </button>
                                {internalLoading && currentDocIndex === index && (
                                    <div style={{ marginLeft: '10px', position: 'absolute', top: '10px', right: "2px" }}>
                                        <Loader size="small" loading={internalLoading} color="var(--primary-color)" /> {/* Replace with your loader */}
                                    </div>
                                )}
                            </div>
                        ))}
                    </div>
                    {/* "Plus" card stays fixed */}
                    <label
                        style={{
                            ...styles.addDocumentCard,
                            border: "1px dashed #fff",
                            width: '120px',
                            height: '100px',
                            marginLeft: '10px',
                            flexShrink: 0, // Prevents the label from shrinking
                            marginBottom: '15px'
                        }}
                        onClick={() => handleAddDocument("internal")}
                    >
                        <div style={styles.addDocumentIconWrapper}>
                            <span>
                                <img src={plus} alt="Plus" style={styles.tickIcon} />
                            </span>
                        </div>
                    </label>
                    {kycListLoading && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                        }}>
                            <Loader loading={kycListLoading} color={'var(--primary-color)'} />
                        </div>
                    )}
                    {kycListError && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                        }}>
                            {kycListError && <div className="list-error-msg">{kycListError} </div>}
                        </div>
                    )}
                    {kycDocInternalError && (
                        <div style={{
                            position: 'absolute',
                            top: '50%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                        }}>
                            {kycDocInternalError && <div className="list-error-msg">{kycDocInternalError} </div>}
                        </div>
                    )}
                    {internalDownloadError && (
                        <div style={{
                            position: 'absolute',
                            top: '90%',
                            left: '50%',
                            transform: 'translate(-50%, -50%)',
                            zIndex: 2, // Ensure the loader appears above other elements
                            whiteSpace: 'normal', wordWrap: 'break-word'
                        }}>
                            {internalDownloadError && <div className="list-error-msg">{internalDownloadError} </div>}
                        </div>
                    )}
                </div>
            </div>


            {/* Task Checklist */}
            <div style={styles.section}>
                <h3 style={styles.sectionTitle}>Task Check List</h3>
                <div style={{ ...styles.taskInfoBox, padding: '3px 5px' }}>
                    <table style={styles.table}>
                        {taskCheckList.length > 0 &&
                            <tbody>
                                {taskCheckList.map((item, index) => (
                                    <tr
                                        key={index}
                                        onClick={() => handleRowClick(item, index)}
                                        style={styles.tableRow}
                                    >
                                        <td style={{ ...styles.tableCell, width: '40px', paddingRight: 0 }}>
                                            <div style={styles.checkbox}>
                                                {item?.service_check_status_name === "Completed" ? (
                                                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleTick} alt="Tick" style={styles.circleTickIcon} /></span>
                                                ) : (
                                                    <span style={{ display: 'flex', alignItems: 'center' }}><img src={circleIcon} alt="Tick" style={styles.circleTickIcon} /></span>
                                                )}
                                            </div>
                                        </td>
                                        <td style={{ ...styles.tableCell, width: '50%' }}>{item?.service_check_name}</td>
                                        <td style={{ ...styles.tableCell, width: '15%' }}>{item?.emp_id_task_check_completed_name || '--'}</td>
                                        <td style={{ ...styles.tableCell, width: '20%' }}>{item?.emp_id_task_check_completed_at ? convertToLocalTime(item?.emp_id_task_check_completed_at, 'shortDateTime') : '--'}</td>

                                        <td style={{ ...styles.tableCell, width: '15%', textAlign: 'center' }}>
                                            <span
                                                style={{
                                                    ...styles.statusBadge,
                                                    backgroundColor:
                                                        item?.service_check_status_name === "Completed"
                                                            ? "#C7C4C32E"
                                                            : item?.service_check_status_name === "In Progress"
                                                                ? "#1A932E2E"
                                                                : "#f8d7da",
                                                    color:
                                                        item?.service_check_status_name === "Completed"
                                                            ? "#7B7B7B"
                                                            : item?.service_check_status_name === "In Progress"
                                                                ? "#1A932E"
                                                                : "#721c24",
                                                }}
                                            >
                                                {item?.service_check_status_name}
                                            </span>
                                        </td>
                                    </tr>
                                ))}
                            </tbody>}
                    </table>
                    <div>
                        {taskCheckLoading && <Loader loading={taskCheckLoading} color={'var(--primary-color)'} />}
                        {taskCheckError && <div className="list-error-msg">{taskCheckError} </div>}
                    </div>
                </div>
            </div>


            {/* Action Section */}
            <div style={styles.section}>
                <div>
                    <div style={{ ...styles.actionRow }}>
                        <div style={styles.actionField}>
                            <Dropdown
                                ref={(el) => (dropdownRefs.current["Update Status"] = el)}
                                isOpen={openDropdown === "Update Status"}
                                toggleDropdown={() => toggleDropdown("Update Status")}
                                label="Update Status"
                                options={[
                                    { "value": 1, "label": "Not Started" },
                                    { "value": 2, "label": "In Progress" },
                                    { "value": 3, "label": "Completed" },
                                    { "value": 11, "label": "Reopen" },
                                    // { "value": 12, "label": "Issue" },
                                    // { "value": 13, "label": "Hold" }
                                ]}
                                value={status}
                                onChange={(value) => handleStatusChange(value)}
                                searchBoxWidth={230}
                            />
                        </div>
                        {/* <div style={{ ...styles.actionField, flex: 1 }}>
                        <label style={styles.label}>Comments</label>
                        <textarea placeholder="Enter comments here..." style={styles.textarea}></textarea>
                    </div> */}
                        <div style={styles.actionField}>
                            <Dropdown
                                ref={(el) => (dropdownRefs.current["Assign To"] = el)}
                                isOpen={openDropdown === "Assign To"}
                                toggleDropdown={() => toggleDropdown("Assign To")}
                                label="Assign To"
                                options={assignEmployeeList || []}
                                value={assignedTo}
                                onChange={(value) => handleAssignedToChange(value)}
                                searchBoxWidth={230}
                                loading={empLoading}
                                errorMessage={empResError}
                            />
                        </div>
                        {/* <div style={styles.actionField}>
                            <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
                                isOpen={openDropdown === "Completed By"}
                                toggleDropdown={() => toggleDropdown("Completed By")}
                                label="Completed By"
                                options={['Satish R.', 'Manager', 'Staff']}
                                value={complitedBy}
                                onChange={(value) => handleComplitedBy(value)}
                                searchBoxWidth={230}
                            />
                        </div> */}
                    </div>
                    {/* <div>
                    <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
                        isOpen={openDropdown === "Update Task Status"}
                        toggleDropdown={() => toggleDropdown("Update Task Status")}
                        label="Update Task Status"
                        options={[
                            { "value": 1, "label": "Not Started" },
                            { "value": 2, "label": "In Progress" },
                            { "value": 3, "label": "Completed" },
                            { "value": 11, "label": "Reopen" },
                            { "value": 12, "label": "Issue" },
                            { "value": 13, "label": "Hold" }
                        ]}
                        value={taskStatus}
                        onChange={(value) => setTaskStatus(value)}
                        searchBoxWidth={230}
                    />   
                    </div> */}

                </div>
                <div style={{ display: 'flex', justifyContent: 'space-between', alignItems: 'end', gap: 20 }}>
                    <Textarea
                        label="Comments"
                        placeholder="Enter your comments here..."
                        value={taskComment}
                        onChange={handleCommentChange}
                        // errorMessage={errorMessage}
                        maxLength={200}
                        width={520}
                        height={100}
                    />

                    {/* <button style={{...styles.holdTaskButton,width:'fit-content'}} onClick={() => handleHoldTask(taskInfo,null)}>
                        <img
                            src={checkBox}
                            alt="Check Box"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Task Status</button> */}
                </div>
                <div>
                    {saveError && <div className="list-error-msg">{saveError} </div>}
                    {saveSuccess && <div className="list-success-msg">{saveSuccess} </div>}
                </div>
                <div style={styles.actionButtonsDiv}>
                    <div style={styles.actionButtons}>
                        <button onClick={handleSaveTaskStatus} style={{ opacity: saveLoading ? 0.5 : 1, cursor: saveLoading ? 'not-allowed' : 'pointer' }} className="add-client-button">{saveLoading && <span style={{ paddingRight: 10 }}><Loader loading={saveLoading} color={'var(--background-color)'} size="small" /></span>}  Save</button>
                        <button onClick={handleScheduleAppointment} style={styles.scheduleButton}>Schedule Appointment</button>
                        <button style={styles.cancelButton}>Cancel</button>
                    </div>

                    <div style={{ ...styles.actionRow }}>
                        <button style={styles.holdTaskButton} onClick={() => handleUpdatePriority(taskInfo)}><img
                            src={fastForwardIcon}
                            alt="Update Priority"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Update Priority</button>
                        <button style={styles.holdTaskButton} onClick={() => handleHoldTask(taskInfo, 'hold')}><img
                            src={infoIcon}
                            alt="Info"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Hold Task</button>
                        <button style={styles.holdTaskButton} onClick={() => handleHoldTask(taskInfo, 'issue')}><img
                            src={checkBox}
                            alt="Check Box"
                            style={{ width: "16px", height: "16px", marginRight: '5px' }}
                        />Task Issue</button>
                    </div>
                </div>
            </div>
            {isModalOpen && (
                <HoldTaskIssuesModal
                    isOpen={isModalOpen}
                    onClose={handleCloseModal}
                    isTaskInfo={isHoldTask}
                    onSuccess={onStatusChangeSuccess}
                />
            )}
            {isPriorityModalOpen && (
                <UpdatePriorityModal
                    isOpen={isPriorityModalOpen}
                    onClose={handleClosePriorityModal}
                    isTaskInfo={isPriority}
                    onSuccess={onStatusChangeSuccess}
                />
            )}
            {isTaskCheckModalOpen &&
                <UpdateTaskCheckStatusModal
                    isOpen={isTaskCheckModalOpen}
                    onClose={handleCloseTaskCheckModal}
                    isTaskInfo={isTaskCheck}
                    onSuccess={onTaskCheckStatusSuccess}
                />}
            {isScheduleModalOpen &&
                <AddScheduleReminderModal isOpen={isScheduleModalOpen}
                    onClose={handleCloseScheduleDoModal}
                    clientInfo={task}
                    clientFileId={taskInfo?.client_file_id} />
            }
            <ClientTaskDocumentAddModal isOpen={modalVisible} onClose={handleAddDocumentCloseModal} onValueChange={onRefresh} documentData={selectedDocument} setSelectedDocument={setSelectedDocument} clientId={clientId} docType={modalSource} fetchKYCDocumentsList={fetchKYCDocumentsList} taskId={task.task_id} />
        </div>
    );
};

const styles = {
    section: {
        marginBottom: "30px",
    },
    container: {
        fontFamily: "Arial, sans-serif",
    },
    backButton: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        backgroundColor: "transparent",
        border: "none",
        color: "#5A55D2",
        cursor: "pointer",
        fontSize: "14px",
        textDecoration: 'underline',
    },
    editButton: {
        display: "flex",
        alignItems: "center",
        marginBottom: "20px",
        backgroundColor: "transparent",
        border: '1px solid #858585',
        color: "#40513B",
        cursor: "pointer",
        fontSize: "12px",
        padding: '5px 10px',
        borderRadius: '20px'
    },
    taskInfoContainer: {
        marginBottom: "30px",
    },
    taskInfoTitle: {
        fontSize: "20px",
        fontWeight: "bold",
        color: "#609966",
        marginBottom: "15px",
        paddingBottom: "5px",
    },
    sectionTitle: {
        fontSize: "18px",
        fontWeight: "500",
        marginBottom: "5px",
        color: "#4CAF50",
        fontFamily: "'Roboto Slab', serif",
    },
    documentsWrapper: {
        display: "flex",
        gap: "20px",
        flexWrap: "wrap",
        justifyContent: "flex-start",
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "8px",
        backgroundColor: "#fff",
        boxShadow: "0px 2px 5px rgba(0, 0, 0, 0.1)",
    },
    documentCard: {
        width: "220px",
        height: "130px",
        backgroundColor: "#f9f9f9",
        borderRadius: "15px",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        position: "relative",
        textAlign: "center",
    },
    documentIconWrapper: {
        width: "45px",
        height: "45px",
        backgroundColor: "#fff",
        borderRadius: "30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        marginBottom: "10px",
    },
    documentIcon: {
        width: "45px",
        height: "45x",
    },
    documentTitle: {
        fontSize: "13px",
        fontWeight: "500",
        color: "#262626",
        marginBottom: "10px",
    },
    downloadButton: {
        position: "absolute",
        top: "10px",
        right: "15px",
        backgroundColor: "transparent",
        border: "none",
        cursor: "pointer",
    },
    downloadIcon: {
        width: "20px",
        height: "20px",
    },
    addDocumentCard: {
        width: "220px",
        height: "145px",
        border: "1px dashed #ddd",
        borderRadius: "15px",
        backgroundColor: "#fff",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        justifyContent: "center",
        cursor: "pointer",
        position: "relative",
    },
    addDocumentIconWrapper: {
        width: "45px",
        height: "45px",
        backgroundColor: "#f9f9f9",
        borderRadius: "30%",
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        padding: "10px",
        marginBottom: "5px",
    },
    addDocumentIcon: {
        fontSize: "24px",
        fontWeight: "bold",
        color: "#000",
    },
    addDocumentText: {
        fontSize: "14px",
        fontWeight: "500",
        color: "#000",
    },
    table: {
        width: "100%",
        borderCollapse: "collapse",
    },
    tableHeader: {
        border: "1px solid #ddd",
        padding: "10px",
        textAlign: "left",
        fontWeight: "bold",
        backgroundColor: "#f9f9f9",
        color: "#4CAF50",
    },
    tableRow: {
        cursor: "pointer",
        transition: "background-color 0.3s ease",
        backgroundColor: "#fff",
    },
    tableCell: {
        padding: "10px",
        textAlign: "left",
        fontSize: "14px",
        color: "#060606",
        fontWeight: "400",
        fontFamily: 'Roboto'
    },
    link: {
        color: "#5A55D2",
        textDecoration: "underline",
    },
    checkbox: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },
    statusBadge: {
        padding: "5px 10px",
        borderRadius: "15px",
        fontSize: "10px",
        fontWeight: "400",
        textAlign: "center",
    },
    actionRow: {
        display: "flex",
        gap: "20px",
        // justifyContent: "space-between",
        marginBottom: "20px",
        flexWrap: "wrap",
    },
    actionField: {
        display: "flex",
        flexDirection: "column",
    },
    label: {
        fontSize: "12px",
        fontWeight: "500",
        marginBottom: "8px",
        color: "#343C44",
    },
    dropdown: {
        padding: "10px",
        border: "1px solid #ddd",
        borderRadius: "5px",
        backgroundColor: "#fff",
        fontSize: "14px",
        color: "#333",
    },
    textarea: {
        padding: "10px",
        border: "1px solid #D6E4EC",
        borderRadius: "5px",
        resize: "none",
        height: "87px",
        fontSize: "14px",
        color: "#333",
        width: '520px'
    },
    actionButtons: {
        display: "flex",
        gap: "15px",
        justifyContent: "flex-start",
        flexWrap: 'wrap'
    },
    actionButtonsDiv: {
        display: "flex",
        gap: "15px",
        justifyContent: "space-between",
        alignItems: 'center',
        marginTop: '20px',
        flexWrap: 'wrap'
    },
    saveButton: {
        padding: "8px 16px",
        backgroundColor: "#40513B",
        color: "#FBFBFB",
        border: "none",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
    },
    scheduleButton: {
        padding: "8px 16px",
        backgroundColor: "#fff",
        color: "#40513B",
        border: "1px solid #858585",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
    },
    cancelButton: {
        padding: "8px 16px",
        backgroundColor: "#fff",
        color: "#40513B",
        border: "1px solid #858585",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
    },
    taskInfoBox: {
        border: "1px solid #ddd",
        backgroundColor: "#fff",
        padding: "15px",
        borderBottom: "2px solid #060606",
        gap: "15px",
    },
    taskInfoColumn: {
        minWidth: "200px",
        textAlign: "left",
    },
    taskInfoHeading: {
        fontSize: "12px",
        fontWeight: "bold",
        marginBottom: "5px",
        color: "#767676",
    },
    taskInfoDetail: {
        fontSize: "14px",
        fontWeight: "normal",
        color: "#060606",
    },
    emptyImage: {
        width: "18px",
        height: "18px",
        borderRadius: "50%",
        border: "1.5px solid #060606A3",
    },
    tickIcon: {
        width: "20px",
        height: "20px",
        filter: 'brightness(0)',
    },
    circleTickIcon: {
        width: "18px",
        height: "18px",
    },
    holdTaskButton: {
        backgroundColor: "#fff",
        color: "#40513B",
        border: "1px solid #858585",
        borderRadius: "50px",
        cursor: "pointer",
        fontSize: "14px",
        height: '35px',
        // width: '110px',
        display: 'flex',
        flexDirection: 'row',
        padding: "5px 10px",
        alignItems: 'center'
    },
    dropdownList: {
        position: 'absolute',
        top: '100%',
        left: 0,
        right: 0,
        backgroundColor: '#fff',
        border: '1px solid #ccc',
        borderRadius: '4px',
        maxHeight: '200px',
        overflowY: 'auto',
        zIndex: 1000,
        padding: 0, // Remove default padding
        margin: 0, // Remove default margin
        listStyleType: 'none', // Remove the dots
    },
    dropdownItem: {
        padding: '10px',
        cursor: 'pointer',
        borderBottom: '1px solid #eee', // Default for all items
        backgroundColor: '#fff',
        hover: {
            backgroundColor: '#f0f0f0',
        },
    },
    errorMessage: {
        borderRadius: '10px',
        border: "1px #EE201C solid",
        padding: '18px 23px',
        marginBottom: '23px',
        backgroundColor: '#ffbebd2e',
        color: '#EE201C',
        display: 'flex',
        alignItems: 'center',
        gap: 20,
        fontSize: '12px',
        fontFamily: 'Roboto',
        fontWeight: 400,
        wordWrap: 'break-word'
    }
};

export default ClientTaskDetailsScreen;
