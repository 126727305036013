import React, { useState, useEffect, useRef } from 'react';
import Input from '../Input';
import Dropdown from '../Dropdown';
import closeIcon from '../../Assets/Icons/close.png';
import pluseIcon from '../../Assets/Icons/Pluse.png';
import uploadIcon from '../../Assets/Icons/upload.png';
import { postData } from '../../ApiServices/ApiService';
import { ORG_ADD_SERVICE, ORG_UPDATE_SERVICE } from '../../ApiServices/BaseURL';
import Loader from '../Loader';

const AddTaskCategoryModal = ({ isOpen, onClose, onValueChange, editData }) => {
  const [documentName, setDocumentName] = useState('');
  const [description, setDescription] = useState('');
  const [showUpload, setShowUpload] = useState(false);

  const [loading, setLoading] = useState(false)
  const [error, setError] = useState('');
  const [serviceNameError, setServiceNameError] = useState('');
  const [descriptionError, setDescriptionError] = useState('');
  const [groupClientError, setGroupClientError] = useState('');
  const [success, setSuccess] = useState('')

  const fileInputRef = useRef(null);

  // //console.log('addd @@@@', editData)
  const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
  const toggleDropdown = (dropdownName) => {
    setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
  };

  const dropdownRefs = useRef({}); // Ref for dropdown

  // Handle clicks outside dropdowns
  useEffect(() => {
    const handleClickOutside = (event) => {
      const isOutside = Object.keys(dropdownRefs.current).every(
        (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
      );
      if (isOutside) {
        setOpenDropdown(null);
      }
    };

    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  useEffect(() => {
    //console.log('addd ', editData)
    if (editData) {
      //console.log('addd if @@@@', editData)
      setDocumentName(editData?.service_name)
      setDescription(editData?.service_desc)
      setGroupClient(editData?.service_status === 1 ? 'Active' : 'Disabled')
    } else {
      resetForm();
    }

  }, [editData])

  // useEffect(() => {
  //   if (clientName || clientType || identificationType || identificationNumber || groupClient || mobileNumber) {
  //     setShowUpload(true);
  //   } else {
  //     setShowUpload(false);
  //   }
  // }, [clientName, clientType, identificationType, identificationNumber, groupClient, mobileNumber]);

  // Reset form fields to initial values
  const resetForm = () => {
    setDocumentName('');
    setDescription('');
    setGroupClient('');
    setError('');
    setServiceNameError('');
    setDescriptionError('');
    setGroupClientError('');
    setSuccess('')
  };

  // Run resetForm when modal closes
  useEffect(() => {
    if (!isOpen) {
      resetForm();
    }
  }, [isOpen]);

  const handleAddTaskCategory = async () => {

    try {
      setError('');
      setSuccess('')
      setServiceNameError('');
      setDescriptionError('');
      setGroupClientError('');
      if (!documentName) {
        setServiceNameError("Please enter the service name");
        return;
      }

      // if(!description){
      //   setDescriptionError("Please enter the description");
      //   return;
      // }

      if (!groupClient) {
        setGroupClientError("Please select the status");
        return;
      }
      // if(!documentName||!description||!groupClient){
      //   setError('Please Fill All FIleds');return
      // }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_name": documentName,
        "service_status": groupClient === 'Active' ? 1 : 0,
        "service_desc": description
      }

      //console.log('catagory Data', payLoad);
      // Make the API call
      const response = await postData(ORG_ADD_SERVICE, payLoad);
      const responseData = await response.json();
      //console.log('response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to fetch inventory data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Categorie data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }

    // onValueChange(clientData); // Send data to Dashboard
    // onClose(); // Close modal
  };

  // Handle file input change
  const handleFileChange = (event) => {
    const files = event.target.files;
    if (files && files.length > 0) {
      //console.log("Uploaded file:", files[0]);
      // Handle file upload here (e.g., upload to server or process locally)
    }
  };

  // Trigger file input click
  const handleUploadClick = () => {
    fileInputRef.current.click();
  };
  const handleClose = () => {
    setError('');
    onClose();
    setSuccess('')
  }

  const groupClientOptions = ["Active", "Disabled"];
  const [groupClient, setGroupClient] = useState('');

  if (!isOpen) return null;

  const handleUpdateTaskCategory = async () => {

    try {
      setError('');
      setSuccess('')
      setServiceNameError('');
      setDescriptionError('');
      setGroupClientError('');
      if (!documentName) {
        setServiceNameError("Please enter the service name");
        return;
      }

      // if(!description){
      //   setDescriptionError("Please enter the description");
      //   return;
      // }

      if (!groupClient) {
        setGroupClientError("Please select the status");
        return;
      }
      // if(!documentName||!description||!groupClient){
      //   setError('Please Fill All FIleds');return
      // }
      setLoading(true);
      const storedUserInfo = JSON.parse(localStorage.getItem('_userInfo_'));
      const payLoad = {
        "emp_id_auth": storedUserInfo?.emp_id,
        "service_id": editData?.service_id,
        "service_name": documentName,
        "service_status": groupClient === 'Active' ? 1 : 0,
        "service_desc": description
      }

      //console.log('catagory update Data', payLoad);
      // Make the API call
      const response = await postData(ORG_UPDATE_SERVICE, payLoad);
      const responseData = await response.json();
      //console.log('update response Data', responseData);

      // Check for success (200) and internal status code
      if (response?.status === 200) {
        if (responseData?.statusCode === 200) {
          if (responseData?.message.toLowerCase() === 'success') {
            setSuccess(responseData?.info);
            setTimeout(() => {
              handleClose();
              onValueChange();
            }, 3000);
          } else {
            setError(responseData?.info || 'Failed to Update Category Data. Please try again.');
          }
        } else {
          setError(responseData?.info || 'Failed to fetch Task Category data.');
        }
      }
      // Token-related errors: 400, 401, 403
      else if (response?.status === 400 || response?.status === 401 || response?.status === 403) {
        if (responseData?.message === 'Unauthorized' || responseData?.message === 'The incoming token has expired' || responseData?.message === 'Access Denied') {
          setError('Unauthorized access. Your session may have expired. Please log in again.');
          // Optionally, redirect to the login page or refresh the token
          // Example: redirectToLogin();
        } else {
          setError(responseData?.info || 'Bad request. Please check the request parameters.');
        }
      }
      // Internal Server Error: 500
      else if (response?.status === 500) {
        setError(responseData?.info || 'Internal Server Error. Please try again later.');
      }
      // Unexpected or network-related errors
      else {
        setError('Unexpected error occurred. Please try again later.');
      }
    } catch (error) {
      // Handle various error scenarios
      if (error.response) {
        // Server responded with a status outside the 2xx range
        setError(`Error: ${error.response.status}. ${error.response.data?.message || 'Failed to fetch Task Categorie data. Please try again.'}`);
      } else if (error.request) {
        // Request was made but no response was received
        setError('No response received from the server. Please check your network connection and try again.');
      } else {
        // An error occurred in setting up the request
        setError(`Failed to fetch Task Categorie data. Please check your network connection and try again.`);
      }
    } finally {
      setLoading(false);
    }

    // onValueChange(clientData); // Send data to Dashboard
    // onClose(); // Close modal
  }




  return (
    <div className="modal-overlay">
      <div className="modal-content">
        <button className="close-button" onClick={onClose}>
          <img src={closeIcon} alt="Close" style={styles.iconImage} />
        </button>
        {!editData ? <h2 className="modal-title">Add Service</h2> :
          <h2 className="modal-title">Update Service</h2>}
        <hr className="title-divider" />

        <div className="form-grid">
          <div style={styles.fullWidth}>
            <Input
              label="Service Name"
              placeholder="Enter Service Name"
              value={documentName || ''}
              onChange={(e) => {setDocumentName(e.target.value);setServiceNameError('')}}
              width={'99%'}
              errorMessage={serviceNameError}
            />
          </div>
          <div style={styles.fullWidth}>
            <Input
              label="Description"
              placeholder="Enter Description"
              value={description || ''}
              onChange={(e) => setDescription(e.target.value)}
              width={'99%'}
              errorMessage={descriptionError}
            />
          </div>
          <div >
            <Dropdown
            ref={(el) => (dropdownRefs.current["Status"] = el)}
              isOpen={openDropdown === "Status"}
              toggleDropdown={() => toggleDropdown("Status")} label="Status" options={groupClientOptions} value={groupClient} onChange={(value)=>{setGroupClient(value);setGroupClientError('')}} errorMessage={groupClientError} />
          </div>
        </div>

        {/* {showUpload && ( */}
        {/* <div style={styles.uploadContainer} onClick={handleUploadClick} onDrop={(e) => e.preventDefault()}>
            <div style={styles.uploadBox} onDragOver={(e) => e.preventDefault()}>
              <span style={styles.uploadCircle}>
              <img src={pluseIcon} alt="Plus Icon" style={styles.iconImage} />
              </span>
              <p style={styles.uploadText}>Upload Form 16 for faster onboarding, Drop here or click on upload</p>
              <img src={uploadIcon} alt="Upload Icon" style={styles.uploadIconImage} />
  
             
              <input
                type="file"
                ref={fileInputRef}
                style={styles.fileInput}
                onChange={handleFileChange}
              />
            </div>
          </div> */}
        {/* )} */}
        {/* <div className="existing-client-checkbox">
              <label style={styles.exisitingHeading}>Primary Contact?</label>
              <input type="checkbox" checked={primaryContact} onChange={(e) => setPrimartContact(e.target.checked)} />
            </div> */}
        <hr className="footer-divider" />

        <div className="button-container" style={{ alignItems: 'center' }}>
          {!editData ? <button className="add-client-button" onClick={handleAddTaskCategory} style={{ paddingLeft: 30, paddingRight: 30, opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Add</button> :
            <button className="add-client-button" onClick={handleUpdateTaskCategory} style={{ paddingLeft: 30, paddingRight: 30, opacity: loading ? 0.5 : 1, cursor: loading ? 'not-allowed' : 'pointer', }} disabled={loading}><span style={{ paddingRight: loading ? 10 : 0 }}><Loader loading={loading} color={'#FFFFFF'} size='small' /></span>Update</button>}
          <button className="cancel-button" onClick={() => { resetForm(); handleClose(); }}>Cancel</button>
          {error && <div className="list-error-msg" style={{ marginBottom: '3px' }}> {error} </div>}
          {success && <div className="list-success-msg" style={{ marginBottom: '3px' }}>{success} </div>}
        </div>
      </div>
    </div>
  );
};

const styles = {
  uploadContainer: {
    marginTop: '20px',
    width: '97%',
  },
  uploadBox: {
    width: '100%',
    border: '1px dashed #609966',
    borderRadius: '10px',
    padding: '10px',
    display: 'flex',
    alignItems: 'center',
    gap: '10px',
    cursor: 'pointer',
    boxSizing: 'border-box',
    position: 'relative', // Position relative to contain the hidden input
  },
  uploadCircle: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    width: '30px',
    height: '30px',
    borderRadius: '50%',
    backgroundColor: '#f0f0f0',
  },
  plusIcon: {
    fontSize: '16px',
    color: '#333',
  },
  uploadText: {
    flex: 1,
    color: '#262626',
    fontSize: '12px',
    fontFamily: 'Roboto',
    fontWeight: 400
  },
  uploadArrow: {
    fontSize: '18px',
    color: '#555',
  },
  fileInput: {
    position: 'absolute',
    top: 0,
    left: 0,
    width: '100%',
    height: '100%',
    opacity: 0,
    cursor: 'pointer',
  },
  iconImage: {
    width: '11px',
    height: '11px',
  },
  uploadIconImage: {
    width: '25px',
    height: '25px',
    marginRight: '5px'
  },
  exisitingHeading: {
    color: '#343C44',
    fontSize: '10px',
    fontWeight: 500,
    fontFamily: 'Roboto'
  },
  formGrid: {
    display: "grid",
    gridTemplateColumns: "1fr", // Single-column layout by default
    gap: "20px", // Space between rows
  },
  halfWidth: {
    gridColumn: "span 1",
    width: "50%", // Half width
    marginRight: "auto", // Aligns left
    marginBottom: '17px'
  },
  fullWidth: {
    gridColumn: "span 2",
    // width: "97%", // Full width
  },
}

export default AddTaskCategoryModal;