import React, { useState,useEffect,useRef } from 'react';
import backIcon from '../../../../Assets/Icons/back.png';
import ManageTeamDetailsController from '../../../Controllers/AdminManagementScreens/TeamsAndUsers/ManageTeamDetailsController';
import Toggle from '../../../../Components/Toggle';
import Dropdown from '../../../../Components/Dropdown';
import Input from '../../../../Components/Input';
import DateInput from '../../../../Components/DateInput';
import editIcon from '../../../../Assets/Icons/edit.png';
import deleteIcon from '../../../../Assets/Icons/delete.png';
import AddTeamMemberModal from '../../../../Components/Modals/AddTeamMemberModal';
import LoadingSkeleton from '../../../../Components/LoadingSkeleton';
import Loader from '../../../../Components/Loader';
import DeleteConfirmationModal from '../../../../Components/Modals/DeleteConformationModal';

const ManageTeamDetails = () => {

    const {
        handleBack,
        userToggleState,
        handleUserToggleChange,
        handleEditTeamMember,
        handleDeleteTeamMember,
        handleUpdateTeam,
        handleDeleteTeam,
        handleCancelTeam,
        handleAddTeamMembers,
        teamMemberModalOpen,
        handleCloseTeamMemberModal,
        onValueChangeTeamMember,
        loading,
        error,
        isTeamInfoEdit,
        primaryData,
        fieldConfigs,
        handleInputChange,
        teamMembers,
        teamMembersError,
        teamID,
        statusLoading,
        teamStatusError,
        teamManagerList,
        teamManagerLoading,
        empError,
        editPrimaryData,
        updateDeleteError,
        updateLoading, deleteLoading,
        updateDeleteSuccess,
        isTeamAdd,
        teamName, setTeamName,
        teamDescription, setTeamDescription,
        teamStatus, setTeamStatus,
        teamLead, setTeamLead,
        teamManager, setTeamManager,
        addLoading, addError,
        teamStatusOptions,
        handleAddTeam,
        teamNameError,
        teamManagerError,
        teamLeadError,
        isTeamMemberDeleteModalOpen,
        selTeamMemberRef, teamMemDeleteLoading,
        teamMemDeleteSuccess, teamMemDeleteError,
        callDeleteTeamMember,
        handleCloseTeamMemberDeleteModal,
        isTeamDeleteModalOpen,
        handleCloseDeleteTeamModal,
        handleDeleteTeam1,
        addSucess,
        statusSuccess,teamLeaderList,teamLeaderError,teamLeaderLoading
    } = ManageTeamDetailsController();

    // //console.log('isTeamInfoEdit teamdetails @@@:-----', isTeamInfoEdit.current)
    // //console.log('primaryData teamdetails @@@:-----', primaryData)

    // //console.log("##teamMembers##", teamMembers)



    const [openDropdown, setOpenDropdown] = useState(null); // Tracks the currently open dropdown
    const toggleDropdown = (dropdownName) => {
        setOpenDropdown((prev) => (prev === dropdownName ? null : dropdownName));
    };

    const dropdownRefs = useRef({}); // Ref for dropdown

    // Handle clicks outside dropdowns
    useEffect(() => {
        const handleClickOutside = (event) => {
            const isOutside = Object.keys(dropdownRefs.current).every(
                (key) => dropdownRefs.current[key] && !dropdownRefs.current[key].contains(event.target)
            );
            if (isOutside) {
                setOpenDropdown(null);
            }
        };

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, []);


    return (
        <div style={styles.container}>
            {/* Header Section */}
            <div style={styles.headerContainer}>
                <img
                    src={backIcon}
                    alt="Back"
                    style={{ width: '25px', height: '25px', cursor: 'pointer', marginRight: '10px' }}
                    onClick={handleBack}
                />
                <span style={styles.header}>Manage Teams</span>
            </div>
            {/* Team Section */}
            <div>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'center' }}>
                    <div style={{ display: 'flex', flexDirection: 'row', gap: 10, alignItems: 'center' }}>
                        {isTeamAdd.current ?
                            <div className='teamdetails-title'>Team Add</div> :
                            <div className='teamdetails-title'>{primaryData?.team_name}{loading && <LoadingSkeleton width="40%" height="10px" />}</div>
                        }
                        {isTeamAdd.current ? <><Toggle initialStatus={true} size='big' disabled={true} /></> : <Toggle initialStatus={primaryData?.team_status === 1 ? true : false} onToggle={(value) => handleUserToggleChange(value, primaryData?.team_id)} size='big' />}
                        {statusLoading && <Loader loading={statusLoading} color={'#000'} size='small' />}
                        {statusSuccess && <div className="list-success-msg"> {statusSuccess} </div>}
                        {teamStatusError && <div className="list-error-msg"> {teamStatusError} </div>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                        {primaryData?.team_manager_email && (
                            <span>Contact User : {primaryData.team_manager_email}</span>
                        )}
                        {(primaryData?.team_mobile_code || primaryData?.team_mobile_no) && (
                            <span style={{ alignSelf: 'end' }}>
                                {primaryData?.team_mobile_code || '--'} {primaryData?.team_mobile_no || '--'}
                            </span>
                        )}
                    </div>
                </div>
                <div className="company-details">{primaryData?.emp_id_team_manager_name}</div>
            </div>
            {/* {teamStatusError && <div className="list-error-msg"> {teamStatusError} </div>} */}

            {/* Team Information */}
            <div className='teamdetails-outer-card' style={{ marginTop: 20 }}>
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'end' }}>
                    <h3 className='basic-info-header'>Team Information</h3>
                    <button onClick={handleAddTeamMembers} className="add-client-button" disabled={isTeamAdd.current} style={{ opacity: isTeamAdd.current ? 0.5 : 1 }}>Add Team Members</button>
                </div>

                <div className='basic-info-card' style={{ marginTop: 20 }}>
                    {isTeamAdd.current ?
                        <> <div className="basic-info-row" style={{ gap: '50px' }}>
                            <Input
                                label="Team Name"
                                placeholder="Enter Team Name"
                                value={teamName}
                                onChange={(e) => setTeamName(e.target.value)}
                                errorMessage={teamNameError}
                                searchBoxWidth={210}
                            />
                            {/* <Input
                            label="Team Description"
                            placeholder="Enter Team Description"
                            value={teamDescription}
                            onChange={(e) => setTeamDescription(e.target.value)}
                        /> */}
                            {/* <Dropdown
            ref={(el) => (dropdownRefs.current[""] = el)}
isOpen={openDropdown === " "}
toggleDropdown={() => toggleDropdown(" ")}
                            label="Team Status"
                            options={teamStatusOptions}
                            value={teamStatus}
                            onChange={setTeamStatus}
                        /> */}
                            <Dropdown
                                ref={(el) => (dropdownRefs.current["Team Manager"] = el)}
                                isOpen={openDropdown === "Team Manager"}
                                toggleDropdown={() => toggleDropdown("Team Manager")}
                                label="Team Manager"
                                options={teamManagerList}
                                value={teamManager?.label}
                                onChange={(value) => setTeamManager(value)}
                                errorMessage={teamManagerError}
                                loading={teamManagerLoading}
                                searchBoxWidth={210}
                            />
                            <Dropdown
                                ref={(el) => (dropdownRefs.current["Team Leader"] = el)}
                                isOpen={openDropdown === "Team Leader"}
                                toggleDropdown={() => toggleDropdown("Team Leader")}
                                label="Team Leader"
                                options={teamLeaderList}
                                value={teamLead?.label}
                                onChange={(value) => setTeamLead(value)}
                                errorMessage={teamLeadError}
                                loading={teamLeaderLoading}
                                searchBoxWidth={210}
                            />
                        </div>
                            {/* {addError && <div style={{textAlign:'center',color:'red'}}> {addError} </div>} */}
                        </> :
                        <>
                            <div className='basic-info-row'>
                                {fieldConfigs.map((field) => (
                                    <div className="basic-info-item" key={field.key}>
                                        {isTeamInfoEdit.current ? (
                                            field.type === "dropdown" ? (
                                                <Dropdown
                                                    ref={(el) => (dropdownRefs.current[`${field.label}`] = el)}
                                                    isOpen={openDropdown === `${field.label}`}
                                                    toggleDropdown={() => toggleDropdown(`${field.label}`)}
                                                    label={field.label} // Dropdown already renders the label internally
                                                    options={field.options}
                                                    value={editPrimaryData[field.key]}
                                                    onChange={(selectedValue) => handleInputChange(field.key, selectedValue)}
                                                    loading={teamManagerLoading}
                                                    errorMessage={field.error}
                                                />
                                            ) : field.type === "date" ? (
                                                <div className="basic-info-label">
                                                    <label>{field.label}</label>
                                                    <DateInput
                                                        value={editPrimaryData[field.key] || ""}
                                                        onChange={(e) => handleInputChange(field.key, e.target.value)}
                                                    />
                                                </div>
                                            ) : field.type === "inputtext" ? (
                                                <>
                                                    <span className="basic-info-label" style={{ paddingBottom: 10 }}>{field.label}</span>
                                                    {loading && <LoadingSkeleton width="40%" height="10px" />}
                                                    {!loading && <span className="basic-info-value">{primaryData[field.key]} </span>}
                                                </>
                                            ) : (
                                                <Input
                                                    label={field.label} // Input already renders the label internally
                                                    placeholder={`Enter ${field.label}`}
                                                    value={editPrimaryData[field.key]}
                                                    onChange={(e) => handleInputChange(field.key, e.target.value)}
                                                    isEdit={field?.edit}
                                                    errorMessage={field.error}
                                                    searchBoxWidth={field?.width}
                                                />
                                            )
                                        ) : (
                                            <><span className="basic-info-label">{field.label}</span>
                                                <span className="basic-info-value">{primaryData[field.key]} {loading && <LoadingSkeleton width="40%" height="10px" />}</span></>
                                        )}
                                    </div>
                                ))}
                            </div>
                        </>}

                    {error && <div className="list-error-msg">{error} </div>}
                </div>

                <div className="tasksScreen-task-table-wrapper" style={{ marginTop: 20 }}>
                    <h3 className='basic-info-header'>Team Members</h3>
                    <div className="basic-info-outer-card">
                        <table className='tasksScreen-task-table'>
                            <thead>
                                <tr>
                                    <th style={{ width: '25%' }}>Username</th>
                                    <th style={{ width: '30%' }}>Email ID</th>
                                    <th style={{ width: '40%' }}>Phone No.</th>
                                    <th style={{ width: '5%' }}></th>
                                </tr>
                            </thead>
                            <tbody style={{ backgroundColor: 'white' }}>
                                {teamMembers?.map((teamMember, index) => (
                                    <tr key={index}
                                        className="tasksScreen-main-task-row"
                                        style={{ borderBottom: "1px solid #dee2e6", cursor: 'pointer' }} >
                                        <td style={{ ...styles.tableLink, width: '25%' }}>{teamMember?.emp_name}</td>
                                        <td style={{ width: '30%' }}>{teamMember?.emp_email}</td>
                                        <td style={{ width: '40%' }}>
                                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>{teamMember?.emp_mobile_code} {teamMember?.emp_mobile_no}
                                                {teamMemDeleteSuccess[teamMember?.emp_id] &&
                                                    <div style={{ color: 'green', }} >{teamMemDeleteSuccess[teamMember?.emp_id]}</div>}
                                                {teamMemDeleteError[teamMember?.emp_id] &&
                                                    <div style={{ color: 'red', }} >{teamMemDeleteError[teamMember?.emp_id]}</div>}
                                            </div>
                                        </td>
                                        <td style={{ display: 'flex', flexDirection: 'row', justifyContent: 'end' }}>
                                            <button
                                                className="tasksScreen-edit-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                }}
                                                onClick={() => handleEditTeamMember(teamMember)}
                                            >
                                                <img
                                                    src={editIcon}
                                                    alt="Edit"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            <button
                                                className="tasksScreen-delete-button"
                                                style={{
                                                    border: "none",
                                                    background: "transparent",
                                                    cursor: "pointer",
                                                    opacity: teamMemDeleteLoading[teamMember?.emp_id] ? 0.3 : 1
                                                }}
                                                onClick={() => handleDeleteTeamMember(teamMember)}
                                                disabled={teamMemDeleteLoading[teamMember?.emp_id]}
                                            >
                                                <img
                                                    src={deleteIcon}
                                                    alt="Delete"
                                                    style={{ width: "16px", height: "16px" }}
                                                />
                                            </button>
                                            {teamMemDeleteLoading[teamMember?.emp_id] &&
                                                <span><Loader loading={teamMemDeleteLoading[teamMember?.emp_id]} color={'var(--primary-color)'} size='small' /></span>
                                            }
                                        </td>

                                    </tr>
                                ))}
                            </tbody>
                        </table>
                        {loading && <Loader loading={loading} color={'var(--primary-color)'} />}
                        {teamMembersError && <div className="list-error-msg">{teamMembersError} </div>}
                    </div>
                </div>
                {/* {isTeamAdd.current &&
                <div style={{ marginTop: 20 }}>
                    <h3 className='basic-info-header'>Team Description</h3>
                    <div className="basic-info-outer-card">
                        <textarea
                            id="groupNotes"
                            placeholder="Enter your description here..."
                            style={styles.textarea}
                            value={teamDescription}
                            onChange={(e) => setTeamDescription(e.target.value)}
                        ></textarea>

                    </div>

                </div>} */}
                {updateDeleteError && <div className="list-error-msg"> {updateDeleteError} </div>}
                {updateDeleteSuccess && <div className="list-success-msg"> {updateDeleteSuccess} </div>}
                {addSucess && <div className="list-success-msg"> {addSucess} </div>}
                {addError && <div className="list-error-msg"> {addError} </div>}

                <div className="button-container" style={{ gap: 20, flexWrap: 'wrap' }}>
                    {isTeamInfoEdit.current && <button onClick={handleUpdateTeam} className="add-client-button" style={{ opacity: updateLoading ? 0.5 : 1, cursor: updateLoading ? 'not-allowed' : 'pointer' }}>
                        {updateLoading && (
                            <span style={{ paddingRight: 10 }}><Loader loading={updateLoading} color={'var(--background-color)'} size="small" /> </span>
                        )} Update Team</button>}
                    {isTeamAdd.current && <button onClick={handleAddTeam} className="add-client-button" style={{ opacity: addLoading ? 0.5 : 1, cursor: addLoading ? 'not-allowed' : 'pointer' }}>
                        {addLoading && (
                            <span style={{ paddingRight: 10 }}><Loader loading={addLoading} color={'var(--background-color)'} size="small" /> </span>
                        )} Add Team</button>}
                    {isTeamAdd.current && <button onClick={handleCancelTeam} className="tasksScreen-cancel-button">Clear</button>}
                    {isTeamInfoEdit.current && <button onClick={handleCancelTeam} className="tasksScreen-cancel-button">Cancel</button>}
                    <button onClick={handleDeleteTeam1} className="tasksScreen-cancel-button" style={{ marginLeft: 'auto', display: 'flex', alignItems: 'center', gap: 10, opacity: isTeamAdd.current || deleteLoading ? 0.5 : 1, cursor: deleteLoading ? 'not-allowed' : 'pointer' }} disabled={isTeamAdd.current || deleteLoading} >
                        {deleteLoading && (
                            <span><Loader loading={deleteLoading} color={'var(--primary-color)'} size="small" /></span>
                        )} <span>Delete Team</span></button>
                </div>

            </div>
            {teamMemberModalOpen &&
                <AddTeamMemberModal isOpen={teamMemberModalOpen} onClose={handleCloseTeamMemberModal} onValueChange={onValueChangeTeamMember} teamId={teamID} />}
            {isTeamMemberDeleteModalOpen && <DeleteConfirmationModal isOpen={isTeamMemberDeleteModalOpen} message={`Are you sure you want to delete ${selTeamMemberRef?.current?.emp_name}?`}
                onClose={handleCloseTeamMemberDeleteModal}
                onConfirm={() => {
                    handleCloseTeamMemberDeleteModal(); // Close modal
                    callDeleteTeamMember(selTeamMemberRef.current); // Perform delete operation
                }} />}
            {isTeamDeleteModalOpen && <DeleteConfirmationModal isOpen={isTeamDeleteModalOpen} message={`Are you sure you want to delete team?`}
                onClose={handleCloseDeleteTeamModal}
                onConfirm={() => {
                    handleCloseDeleteTeamModal(); // Close modal
                    handleDeleteTeam(); // Perform delete operation
                }} />}
        </div>
    )

}

const styles = {
    container: {
        width: '95%',
        margin: 'auto',
    },
    header: {
        fontSize: "22px",
        color: "#060606",
        fontWeight: 500,
        // fontFamily: 'Montserrat',
        letterSpacing: 0.22
    },
    headerContainer: {
        display: 'flex',
        alignItems: 'center', // Vertically align items
        marginBottom: '20px'
    },
    tableLink: {
        fontFamily: 'Roboto',
        fontWeight: 400,
        fontSize: '12px',
        textDecoration: 'underline',
        color: '#5A55D2'
    },
    labaleStyle: {
        fontSize: '12px',
        // marginBottom: '5px',
        fontWeight: '400',
        fontFamily: 'Roboto',
        color: 'rgb(52, 60, 68)',
    },
    textarea: {
        width: "90%",
        height: "100px",
        border: "none",
        borderRadius: "5px",
        padding: "10px",
        fontSize: "14px",
        resize: "none",
        // backgroundColor: "#f9f9f9",
        outline: "none",
    },

}

export default ManageTeamDetails;